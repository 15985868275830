
import { apiEndpoint, Endpoints } from "@/core/endpoints";
import axios from "axios";

export default {
    loadMedicalServicePackages(context: any, params: any) {        
        axios.get(apiEndpoint + Endpoints.Promotions +`${params.categoryWithLocation}?date=${params.dates}`)
            .then((response) => {
                context.commit('setPackages', response.data)
            });
    },
}