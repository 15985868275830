import axios, { AxiosRequestConfig } from 'axios';
import { apiEndpoint, Endpoints, envMode } from './endpoints';

function prepareAuthHeaders(): Object | undefined {
    const token = localStorage.getItem('token');
    if (token) {
        return { Authorization: 'Bearer ' + token }
    } else {
        return void 0
    }
}

function prepareQueryString(params: any) {
    const esc = encodeURIComponent;
    if (typeof params === 'string')
        return params;

    return Object.keys(params)
        .map(k => esc(k) + '=' + esc(params[k]))
        .join('&');
}

const getListAsync = async (endpoint: string, params?: any, config?: AxiosRequestConfig | undefined): Promise<any> => {
    let url = `${apiEndpoint}${endpoint}`;

    if (params) {
        const queryString = prepareQueryString(params);
        url += '?' + queryString;
    }

    const response = await axios.get(url, {
        headers: prepareAuthHeaders()
    });

    return response.data;
}

const firstAsync = async (endpoint: string, param?: string, config?: AxiosRequestConfig | undefined): Promise<any> => {
    const url = `${apiEndpoint}${endpoint}${param}`;

    const response = await axios.get(url, {
        headers: prepareAuthHeaders()
    });
    if (response.data && response.data.length && response.data.length > 0) {
        return response.data[0];
    }
    return response.data;
}

const getByIdAsync = async (endpoint: string, id: any, config?: AxiosRequestConfig | undefined): Promise<any> => {
    const url = `${apiEndpoint}${endpoint}/${id}`;

    const response = await axios.get(url, {
        headers: prepareAuthHeaders()
    });

    return response.data;
}

const postAsync = async (endpoint: string, payload?: any, config?: AxiosRequestConfig | undefined): Promise<any> => {
    const url = `${apiEndpoint}${endpoint}`;

    const response = await axios.post(
        url,
        payload,
        {
            headers: prepareAuthHeaders()
        });

    return response.data;
}

const putAsync = async (endpoint: string, payload: any, config?: AxiosRequestConfig | undefined): Promise<any> => {
    const url = `${apiEndpoint}${endpoint}`;

    const response = await axios.put(
        url,
        payload,
        {
            headers: prepareAuthHeaders()
        });

    return response.data;
}

const deleteAsync = async (endpoint: string, id: any, config?: AxiosRequestConfig | undefined): Promise<any> => {
    const url = `${apiEndpoint}${endpoint}/${id}`;

    const response = await axios.delete(url, {
        headers: prepareAuthHeaders()
    });

    return response.data;
}



export default {
    envMode,
    apiEndpoint,
    api: {
        getListAsync,
        firstAsync,
        getByIdAsync,
        postAsync,
        putAsync,
        deleteAsync
    },
    endpoints: Endpoints
}