
import { apiEndpoint, Endpoints } from "@/core/endpoints";
import axios from "axios";

export default {
    loadDayPromotions(context: any, params: any) {
        axios.get(apiEndpoint + Endpoints.DayPromotions +`${params.categoryWithLocation}`)
            .then((response) => {
                context.commit('setDayPromotionList', response.data)
            });
    },
}