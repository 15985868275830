import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"margin-top":"3.5rem"}
}
const _hoisted_2 = { class: "w-100" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MedicalServiceFilters = _resolveComponent("MedicalServiceFilters")!
  const _component_MedicalServiceFiltersMobile = _resolveComponent("MedicalServiceFiltersMobile")!
  const _component_MedicalFacilityResults = _resolveComponent("MedicalFacilityResults")!
  const _component_base_card = _resolveComponent("base-card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.isMobile)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (!_ctx.isMobile)
            ? (_openBlock(), _createBlock(_component_MedicalServiceFilters, {
                key: 0,
                onLoadFromCurrentProvince: _ctx.loadFromCurrentProvince,
                onLoadFromCurrentCountry: _ctx.loadFromCurrentCountry,
                onReload: _ctx.reload,
                medFacilitiesResult: _ctx.medFacilitiesResult
              }, null, 8, ["onLoadFromCurrentProvince", "onLoadFromCurrentCountry", "onReload", "medFacilitiesResult"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_base_card, {
      "class-name": _ctx.showMapFlag == true
      ? 'margin-nav container medical-service-details-map-page-container' 
      : 'margin-nav container medical-service-details-page-container'
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_2, [
            (_ctx.isMobile)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_MedicalServiceFiltersMobile, {
                    onLoadFromCurrentProvince: _ctx.loadFromCurrentProvince,
                    onLoadFromCurrentCountry: _ctx.loadFromCurrentCountry,
                    onReload: _ctx.reload,
                    onChangeSortAscending: _ctx.changeSortAscending,
                    medFacilitiesResult: _ctx.medFacilitiesResult
                  }, null, 8, ["onLoadFromCurrentProvince", "onLoadFromCurrentCountry", "onReload", "onChangeSortAscending", "medFacilitiesResult"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.medFacilitiesResult.medicalFacilities.length > 0 || _ctx.medFacilitiesResult.medicalServiceName == '')
              ? (_openBlock(), _createBlock(_component_MedicalFacilityResults, {
                  key: 1,
                  ref: "mfResult",
                  onReload: _ctx.reload,
                  onUpdateShowMapFlag: _ctx.updateShowMapFlag,
                  medFacilitiesResult: _ctx.medFacilitiesResult,
                  categoryTree: _ctx.categoryTree,
                  showMapFlag: _ctx.showMapFlag
                }, null, 8, ["onReload", "onUpdateShowMapFlag", "medFacilitiesResult", "categoryTree", "showMapFlag"]))
              : _createCommentVNode("", true),
            (_ctx.medicalServiceSeoItem.seoText)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 2,
                  class: "seo-text-container",
                  innerHTML: _ctx.medicalServiceSeoItem.seoText
                }, null, 8, _hoisted_4))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _: 1
    }, 8, ["class-name"])
  ], 64))
}