
import { defineComponent, markRaw } from "vue";
import mediHttpService from "@/core/http-service";
import { UserDataDTO } from "@/types";
import { City } from "@/types";
import { apiEndpoint, Endpoints } from "@/core/endpoints";

export default markRaw(
  defineComponent({
    data() {
      return {
        userCity: {} as City,
        userCityInitial: {} as City | null,
        userCityInputSearch: "",
        editMode: false,
        user: {} as UserDataDTO,
      };
    },
    computed: {
      userCityTypeaheadUrl(): string {
        return (
          apiEndpoint +
          Endpoints.GetCityTypeaheadList +
          this.userCityInputSearch
        );
      },
      provincesList(): City[] {
        return this.$store.getters["settingsProvinces/provincesList"];
      },
    },
    methods: {
      async updateUser() {
        const res = await mediHttpService.api.putAsync(
          mediHttpService.endpoints.User,
          { ...this.user, cityId: this.userCity.id }
        );
        await this.init();
        this.editMode = false;
      },
      switchEditMode() {
        this.editMode = !this.editMode;

        if (this.editMode) {
          const city: City = {
            id: this.user?.cityId,
            name: this.user?.city,
            district: "",
            province: "",
            slug: "",
            type: 1,
          };
          this.userCity = city;
          this.userCityInitial = city;
        }
      },
      async init() {
        const user: UserDataDTO = await mediHttpService.api.getListAsync(
          mediHttpService.endpoints.User
        );
        this.user = user;
      },
    },
    async created() {
      await this.init();
    },
  })
);
