
import { defineComponent, PropType } from "vue";
import { IInstitutionListItem } from "@/types";
import InstitutionListItem from "./InstitutionListItem.vue";

export default defineComponent({
  components: { InstitutionListItem },
  props: {
    institutionList: {
      type: Object as PropType<IInstitutionListItem[]>,
      required: true,
    },
  },
});
