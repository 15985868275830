
import { defineComponent } from "@vue/runtime-core";
import $ from "jquery";

export default defineComponent({
	data() {
		return {
			email: "",
			password: "",
			formIsValid: true,
			mode: "login",
			isLoading: false,
			error: null,
		};
	},
	computed: {
		submitButtonCaption() {
			return "Zaloguj";
		},
		switchModeButtonCaption() {
			return "Zamiast tego zarejestruj";
		},
	},
	methods: {
		async submitForm() {
			if (
				this.email === "" ||
				!this.email.includes("@") ||
				this.password == ""
			) {
				this.formIsValid = false;
				return;
			}

			this.isLoading = true;

			const payload = {
				email: this.email,
				password: this.password,
			};
			try {
				await this.$store.dispatch("login", payload);
				const redirect = "/" + (this.$route.query.redirect || "account");
				this.$router.replace(redirect);
			} catch (err) {
				this.error = err.message || "Failed to " + this.mode;
			}

			this.isLoading = false;
		},
		handleError() {
			this.error = null;
		},
	},
	beforeCreate() {
		$(".collapse").collapse("hide");
	},
});
