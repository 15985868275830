
import { UserType } from "@/types/UserType";
import { defineComponent } from "@vue/runtime-core";
import $ from "jquery";

export default defineComponent({
  data() {
    return {
      formIsValid: true,
      step: 1,
      accountType: "" as UserType,
      email: "",
      password: "",
    };
  },
  methods: {
    async submitForm(): Promise<void> {
      this.formIsValid = true;
      if (
        this.email === "" ||
        !this.email.includes("@") ||
        this.password == "" ||
        this.accountType == null
      ) {
        this.formIsValid = false;
        return;
      }

      const payload = {
        email: this.email,
        password: this.password,
      };

      try {
        if (this.accountType == "manager") {
          await this.$store.dispatch("registerManager", payload);
          this.$store.dispatch("tryLogin");
        }
        if (this.accountType == "user") {
          await this.$store.dispatch("register", payload);
          this.$store.dispatch("tryLogin");
        }

        const redirect = "/" + (this.$route.query.redirect || "");
        this.$router.replace(redirect);
      } catch (err) {
        console.log("submit error: ", err);
      }
    },
  },
  beforeCreate(){
    $(".collapse").collapse("hide");
  }
});
