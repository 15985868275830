
import $ from "jquery";
import MarkerClusterer from "@googlemaps/markerclustererplus";
import gmapsInit from "../../utils/gmaps";
import { defineComponent, PropType } from "vue";
import { MedicalFacility } from "@/types";

export default defineComponent({
  async mounted() {
    try {
      const marker = MarkerClusterer as any;
      marker.prototype["getMarkerIcon"] = function (markers: any): any {
        const count = markers.length;
        const minPrice = Math.min(
          ...markers.map((item: any) => item.markerData.priceValue)
        );
        const maxPrice = Math.max(
          ...markers.map((item: any) => item.markerData.priceValue)
        );

        var priceText = "" + minPrice + " - " + maxPrice + " zł";
        //priceText = "" + 1110 + " - " + 1120 + " zł";
        var width = 0;
        if (priceText.length <= 14) {
          width = 167;
        } else {
          width = 182;
        }

        var img =
          '<svg width="{{width}}" height="74" fill="none" xmlns="http://www.w3.org/2000/svg"> \
          <style>.rect {fill:gray;stroke-width:0.5;stroke:gray;}.heavy { font: normal 14px sans-serif; fill:black; font-weight: bold;}</style> \
          <g transform="scale(0.75)"> \
          <path d="M80 40C80 57.6731 61.7143 94.8571 48 94.8571C34.2857 94.8571 16 57.6731 16 40C16 22.3269 30.3269 8 48 8C65.6731 8 80 22.3269 80 40Z" fill="white"/> \
          <path d="M48 12.5714C63.147 12.5714 75.4286 24.853 75.4286 40C75.4286 55.147 63.147 67.4286 48 67.4286C32.853 67.4286 20.5714 55.147 20.5714 40C20.5714 29.1685 26.8487 19.8038 35.963 15.3461C29.5779 31.4028 41.8649 32.1877 39.6873 40.2445C38.4648 44.7647 33.5807 44.0938 32.3013 39.7214C32.3013 40.7562 32.4037 41.8081 32.6198 42.86C34.3539 51.4285 42.7009 56.9723 51.2694 55.2381C59.8379 53.5039 65.3817 45.157 63.6475 36.5885C62.7889 32.3355 60.2985 28.833 56.9666 26.5871C57.3873 28.9239 57.3305 30.2829 55.909 31.5792C55.7896 28.634 53.9928 24.2502 46.2544 22.7662C41.4784 21.8508 37.2196 18.57 35.9687 15.3404C39.5963 13.5722 43.6845 12.5714 48 12.5714Z" fill="#0F0028"/> \
          </g> \
          <rect x="63" y="10" width="{{textWidth}}" height="40" stroke="gray" stroke-width="1" fill="white" /> \
          <text x="73" y="26" class="heavy">{{price}}</text> \
          <text x="73" y="43" class="heavy">Placówki: {{number}}</text> \
          </svg>';

        img = img.replace("{{width}}", "" + width);
        img = img.replace("{{textWidth}}", "" + (width - 63));
        img = img.replace("{{price}}", "" + priceText);
        img = img.replace("{{number}}", "" + count);

        return {
          img: img,
        };
      };
    } catch (error) {
      console.error(error);
    }
  },
  props: {
    medFacilities: {
      type: Object as PropType<MedicalFacility[]>,
      required: true,
    },
  },
  emits: ["showFilters", "showMedFacilityResultPopover", "filterMedFacilitiesByVisibleMarkers"],
  data() {
    return {
      google: null,
      map: null,
      infoWin: null,
      markers: []
    };
  },
  watch: {
    medFacilities: function (newVal: MedicalFacility[]) {
      this.displayMarkers(newVal);
    },
  },
  methods: {
    is_iOS() {
      return (
        [
          "iPad Simulator",
          "iPhone Simulator",
          "iPod Simulator",
          "iPad",
          "iPhone",
          "iPod",
        ].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes("Mac") && "ontouchend" in document)
      );
    },
    isMobile: function () {
      return screen.width <= 768;
    },
    createReturnToListViewControl() {
      const controlButton = document.createElement("button");

      controlButton.style.backgroundColor = "#fff";
      controlButton.style.border = "1px solid #fff";
      controlButton.style.cursor = "pointer";
      controlButton.style.padding = "8px";

      var chevronLeft =
        '<i class="fa fa-angle-left" style="font-size:24px"></i>';

      controlButton.innerHTML = chevronLeft;
      controlButton.type = "button";

      controlButton.addEventListener("click", () => {
        $("#arrow-min-max-control-button").html(
          '<i class="ico-arrow-max"></i>'
        );
        this.$emit("showFilters");
      });

      return controlButton;
    },
    createMinMaxControl() {
      const controlButton = document.createElement("button");
      controlButton.id = "arrow-min-max-control-button";

      controlButton.style.backgroundColor = "#fff";
      controlButton.style.border = "1px solid #fff";
      controlButton.style.borderRadius = "8px";
      controlButton.style.cursor = "pointer";
      controlButton.style.margin = "16px";
      controlButton.style.marginLeft = "0";
      controlButton.style.padding = "7px";

      var arrowMin = '<i class="ico-arrow-min"></i>';
      var arrowMax = '<i class="ico-arrow-max"></i>';

      controlButton.innerHTML = arrowMax;
      controlButton.type = "button";

      controlButton.addEventListener("click", () => {
        controlButton.innerHTML =
        controlButton.innerHTML == arrowMax ? arrowMin : arrowMax;

        var baseCardHolder = document.getElementById("base-card-page");
        var headerFiltersHolder = document.getElementById("header-filters");

        var mapHolder = document.getElementById("medical-facility-map");
        var medFacResultHolder = document.getElementById("medical-facility-results");

        if (controlButton.innerHTML == arrowMin) {
          baseCardHolder.style.cssText = "margin-left: 0px !important; max-width: 100% !important";
          headerFiltersHolder.classList.add("container");
          headerFiltersHolder.style.paddingLeft = "0px";
          mapHolder.style.width = "100%";
          medFacResultHolder.style.display = "none";
        } else {
          baseCardHolder.style.cssText = "margin-left: calc((100% - 1216px) / 2) !important; max-width: calc(100% - ((100% - 1216px) / 2)) !important";
          headerFiltersHolder.classList.remove("container");
          headerFiltersHolder.style.paddingLeft = "0px";
          mapHolder.style.width = "calc(100% - 409px)";
          medFacResultHolder.style.display = "block";
        }
      });

      return controlButton;
    },
    createMinMaxControlForMobile() {
      const controlButton = document.createElement("button");

      controlButton.style.backgroundColor = "#fff";
      controlButton.style.border = "1px solid #fff";
      controlButton.style.borderRadius = "8px";
      controlButton.style.cursor = "pointer";
      controlButton.style.margin = "16px";
      controlButton.style.padding = "7px";

      var arrowMin = '<i class="ico-arrow-min"></i>';

      controlButton.innerHTML = arrowMin;
      controlButton.type = "button";

      controlButton.addEventListener("click", () => {
        $("#medical-facility-map").css("margin-left", "-1500px");
        $("#app").css("width", "");
        $("#app").css("height", "");
        //$(".gm-fullscreen-control").click();
      });

      return controlButton;
    },
    // roundRect(ctx, x, y, width, height, radius, fill, stroke) {
    //   if (typeof stroke == "undefined") {
    //     stroke = true;
    //   }
    //   if (typeof radius === "undefined") {
    //     radius = 5;
    //   }
    //   ctx.beginPath();
    //   ctx.moveTo(x + radius, y);
    //   ctx.lineTo(x + width - radius, y);
    //   ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
    //   ctx.lineTo(x + width, y + height - radius);
    //   ctx.quadraticCurveTo(
    //     x + width,
    //     y + height,
    //     x + width - radius,
    //     y + height
    //   );
    //   ctx.lineTo(x + radius, y + height);
    //   ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
    //   ctx.lineTo(x, y + radius);
    //   ctx.quadraticCurveTo(x, y, x + radius, y);
    //   ctx.closePath();
    //   if (stroke) {
    //     ctx.stroke();
    //   }
    //   if (fill) {
    //     ctx.fill();
    //   }
    // },
    // createMarker(width, height, radius) {
    //   var canvas, ctx;

    //   canvas = document.createElement("canvas");
    //   canvas.width = 61;
    //   canvas.height = 41;

    //   ctx = canvas.getContext("2d");

    //   var rectWidth = 50;
    //   var rectHeight = 30;

    //   ctx.lineWidth = 0;
    //   //ctx.strokeStyle = "#000000";
    //   ctx.fillStyle = "white";
    //   this.roundRect(ctx, 10, 10, rectWidth, rectHeight, 5, true);
    //   ctx.font = "bold 13px Poppins";
    //   ctx.textAlign = "center";
    //   ctx.textBaseline = "middle";
    //   ctx.fillStyle = "black";
    //   var rectX = 10;
    //   var rectY = 10;
    //   ctx.fillText("120 zł", rectX + rectWidth / 2, rectY + rectHeight / 2);

    //   // context.clearRect(0, 0, width, height);

    //   // // background is yellow
    //   // context.fillStyle = "rgba(255,255,0,1)";

    //   // // border is black
    //   // context.strokeStyle = "rgba(0,0,0,1)";

    //   // context.beginPath();
    //   // context.moveTo(radius, 0);
    //   // context.lineTo(width - radius, 0);
    //   // context.quadraticCurveTo(width, 0, width, radius);
    //   // context.lineTo(width, height - radius);
    //   // context.quadraticCurveTo(width, height, width - radius, height);
    //   // context.lineTo(radius, height);
    //   // context.quadraticCurveTo(0, height, 0, height - radius);
    //   // context.lineTo(0, radius);
    //   // context.quadraticCurveTo(0, 0, radius, 0);
    //   // context.closePath();

    //   // context.fill();
    //   // context.stroke();

    //   return canvas.toDataURL();
    // },
    async displayMarkers(medFacilities: MedicalFacility[]) {
      var that = this;
      that.google = await gmapsInit();

      var isMobile = this.isMobile();

      const map = new that.google.maps.Map(document.getElementById("map"), {
        zoom: 7,
        center: { lat: 52, lng: 19 },
        fullscreenControl: false,
        mapId: "691fc4de8d141d70",
        //https://stackoverflow.com/questions/41831023/how-to-disable-two-fingers-zoom-option-in-maps-in-mobile-devices-using-javascrip
        //https://developers.google.com/maps/documentation/javascript/interaction#gesture-handling
        gestureHandling: "greedy",
      });

      const minMaxControlDiv = document.createElement("div");
      const minMaxControl = isMobile
        ? this.createMinMaxControlForMobile(map)
        : this.createMinMaxControl(map);
      minMaxControlDiv.appendChild(minMaxControl);

      map.controls[that.google.maps.ControlPosition.RIGHT_TOP].push(
        minMaxControlDiv
      );

      if (isMobile == false) {
        const returnToListViewControlDiv = document.createElement("div");
        const returnToListViewControl = this.createReturnToListViewControl(map);
        returnToListViewControlDiv.appendChild(returnToListViewControl);

        map.controls[that.google.maps.ControlPosition.LEFT_CENTER].push(
          returnToListViewControlDiv
        );
      }

      const infoWin: any = new that.google.maps.InfoWindow({height : 500});

      that.markers = medFacilities.map(function (
        medFacility: MedicalFacility
      ) {
        var width = 0;
        if (medFacility.priceValue < 1000) {
          width = 105;
        } else if (medFacility.priceValue < 10000) {
          width = 120;
        } else {
          width = 130;
        }

        var svg =
          '<svg width="{{width}}" height="50" fill="none" xmlns="http://www.w3.org/2000/svg"> \
            <style>.heavy { font: normal 16px sans-serif; fill:black; font-weight: bold;}</style>" \
            <g filter="url(#filter0_d_725_10568)"> \
            <path d="M44 22C44 29.732 36 46 30 46C24 46 16 29.732 16 22C16 14.268 22.268 8 30 8C37.732 8 44 14.268 44 22Z" fill="white"/> \
            <path d="M30 10C36.6268 10 42 15.3732 42 22C42 28.6268 36.6268 34 30 34C23.3732 34 18 28.6268 18 22C18 17.2612 20.7463 13.1642 24.7338 11.2139C21.9403 18.2387 27.3159 18.5821 26.3632 22.107C25.8284 24.0846 23.6915 23.791 23.1318 21.8781C23.1318 22.3308 23.1766 22.791 23.2711 23.2512C24.0298 27 27.6816 29.4254 31.4303 28.6667C35.1791 27.908 37.6045 24.2562 36.8458 20.5075C36.4701 18.6468 35.3806 17.1144 33.9229 16.1318C34.107 17.1542 34.0821 17.7488 33.4602 18.3159C33.408 17.0274 32.6219 15.1094 29.2363 14.4602C27.1468 14.0597 25.2836 12.6244 24.7363 11.2114C26.3234 10.4378 28.112 10 30 10Z" fill="#0F0028"/> \
            <rect x="42" y="0" width="{{textWidth}}" height="24" stroke="gray" stroke-width="1" fill="white" /><text x="50" y="35%" class="heavy">{{price}}</text> \
            </g> \
            <defs> \
            <filter id="filter0_d_725_10568" x="0" y="0" width="60" height="70" color-interpolation-filters="sRGB"> \
            <feFlood flood-opacity="0" result="BackgroundImageFix"/> \
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/> \
            <feComposite in2="hardAlpha" operator="out"/> \
            <feColorMatrix type="matrix" values="0 0 0 0 0.0588235 0 0 0 0 0 0 0 0 0 0.156863 0 0 0 0.1 0"/> \
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_725_10568"/> \
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_725_10568" result="shape"/> \
            </filter> \
            </defs> \
            </svg>';

        var subTextWidth = that.is_iOS() ? 50 : 40;

        svg = svg.replace("{{price}}", "" + medFacility.price); //FIXME: ''+ ??
        svg = svg.replace("{{width}}", "" + (width));
        var textWidth = width - subTextWidth;
        svg = svg.replace("{{textWidth}}", "" + textWidth);

        const priceTag = document.createElement("div");

        priceTag.className = "price-tag";
        priceTag.textContent = medFacility.price;

        var marker = null;

        if (isMobile == true) {
          marker = new that.google.maps.marker.AdvancedMarkerView({
            map: map,
            position: { lat: medFacility.latitude, lng: medFacility.longitude },
            content: priceTag,
            // markerData: medFacility
          });

          that.google.maps.event.addListener(marker, "click", function () {
            that.$emit("showMedFacilityResultPopover", medFacility);
          });
        } else {
          // var marker = new google.maps.Marker({
          // position: { lat: medFacility.latitude, lng: medFacility.longitude },
          // map: map,
          // icon: that.createMarker(25, 25, 15),
          // markerData: medFacility,
          // });

          marker = new that.google.maps.Marker({
            position: { lat: medFacility.latitude, lng: medFacility.longitude },
            map: map,
            icon: {
              url:
                "data:image/svg+xml;charset=UTF-8;base64," +
                btoa(unescape(encodeURIComponent(svg))),
              scaledSize: new that.google.maps.Size(width, 50),
              anchor: new that.google.maps.Point(textWidth / 2, 50),
            },
            markerData: medFacility,
          });

          that.google.maps.event.addListener(marker, "click", function () {
            const contentString =
              '<div id="info-window-content">' +
              '<div class="med-facility-logo"><i class="ico-teeth" /></div>' +
              '<div class="header-content">' +
              '<span class="h5 d-block" style="margin-bottom: 1px">' +
              medFacility.name +
              "</span>" +
              '<p class="mt-2 mb-0 main-text-3-b" style="color: #8B8299;">' +
              medFacility.medicalServiceName +
              "</p>" +
              '<div class="gradient contact-data">' +
              '<p class="normal-text-3-go"><i class="ico-location mr-1"></i>ul. ' +
              medFacility.street +
              ", " +
              medFacility.city +
              "</p>" +
              '<p class="normal-text-3-go mt-2">' +
              '<a class="normal-text-3-go" href="tel:' + medFacility.phoneNumber + '"><i class="ico-phone mr-1"></i>' + medFacility.phoneNumber + '</a>' +
              "</p>" +
              '<div class="d-flex mt-3" style="justify-content: space-between;align-items: self-end;">' +
              '<p class="mt-3 main-text-1-b"><i class="ico-flame mr-1"></i>' +
              medFacility.price +
              "</p>" +
              '<p id="link' + medFacility.id + '" style="cursor:pointer" class="main-text-2-b">Zobacz więcej ></p>' +
              "</div></div>" +
              "</div>";

            infoWin.setContent(contentString);
            infoWin.setOptions({ pixelOffset: new that.google.maps.Size(-20, 0) });
            infoWin.open(map, marker);

            that.google.maps.event.addListener(infoWin, "domready", function () {
              $("#link" + medFacility.id).on(
                "click",
                function () {
                  that.$router.push(medFacility.url + `,${medFacility.id}`);
                }
              );
            });
          });
        }

        return marker;
      });

      if (isMobile == false) {
        const markerCluster = new MarkerClusterer(map, that.markers, {
          minimumClusterSize: 6,
          gridSize: 100,
          averageCenter: true,
          maxZoom: 12,
          clusterClass: "cluster-5",
        });
      }

      function filterMedicalFacilityResultsByVisibleMarkers()
      {
          // var zoomLevel = map.getZoom();
          const bounds = map.getBounds();
          var filteredMarkers = that.markers.filter(m => m.isAdded && bounds.contains(m.getPosition()));

          that.$emit("filterMedFacilitiesByVisibleMarkers", filteredMarkers);
      }

      that.google.maps.event.addListener(map, 'zoom_changed', filterMedicalFacilityResultsByVisibleMarkers);
      that.google.maps.event.addListener(map, 'dragend', filterMedicalFacilityResultsByVisibleMarkers);
      that.map = map;
    },
    showMarkerPopup(medicalFacility){
      //console.log(medicalFacility);
      var marker = this.markers.filter(m => m.markerData.id == medicalFacility.id)[0];
      //console.log(marker.getPosition());
      this.map.setCenter(marker.getPosition());
      //console.log(this.map);
      if(this.map.zoom < 12){
        this.map.setZoom(12);
      }
      this.google.maps.event.trigger(marker, 'click');
    }
  },
});
