
import { apiEndpoint, Endpoints } from "@/core/endpoints";
import axios from "axios";

export default {
    loadMostSearchedList(context: any) {        
        axios.get(apiEndpoint + Endpoints.MostSearched)
            .then((response) => {
                context.commit('setMostSearchedList', response.data)
            });
    },
}