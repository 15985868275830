
import { defineComponent } from "vue";
import { MostSearchedHomeItem } from "@/types";
import MostSearchedItem from "../../components/home-page/MostSearchedItem.vue";
import $ from "jquery";
import { apiEndpoint, Endpoints } from "@/core/endpoints";
import { City, MedicalServiceTypeaheadItem } from "@/types";
import mediHttpService from "@/core/http-service";
import Slider from '@vueform/slider';

const component = defineComponent({
  components: {
    MostSearchedItem,
    Slider
  },
  props: ["medFacilitiesResult"],
  emits: ["loadFromCurrentProvince", "loadFromCurrentCountry", "reload", "changeSortAscending"],
  data: () => {
    return {
      includeForeignCountries: false,
      isLoading: false,
      isLocationInitialized: false,
      isMedicalServiceInitialized: false,
      selectedLocation: {} as City,
      selectedLocationInitial: {} as City | null,
      selectedMedicalService: {} as MedicalServiceTypeaheadItem,
      selectedMedicalServiceInitial: {} as MedicalServiceTypeaheadItem | null,
      medicalServiceInputSearch: "",
      locationInputSearch: "",
      priceSliderFormat: {
        suffix: ' zł',
        decimals: 0
      },
      minFilterPrice: null,
      maxFilterPrice: null,
      distanceSliderValue: [0, 50],
      distanceSliderFormat: {
        suffix: ' km',
        decimals: 0
      },
      sortAscending: true
    };
  },
  created() {
    window.onscroll = function () {
      const filterSticky = document.getElementById("filter-sticky");
      if (window.pageYOffset >= 200 && filterSticky != null) {
        filterSticky.style.display = 'block';
      } else {
        filterSticky.style.display = 'none';
      }
    };
  },
  computed: {
    medFacilitiesCount: function (): any {
      var medFacilities = this.medFacilitiesResult.medicalFacilities;
      if (medFacilities.length == 0) return "brak ofert";
      if (medFacilities.length == 1) return medFacilities.length + " oferta";
      if (medFacilities.length < 5) return medFacilities.length + " oferty";
      return medFacilities.length + " ofert";
    },
    medicalServiceTypeaheadUrl(): string {
      return (
        apiEndpoint + `MedicalService/Search/` + this.medicalServiceInputSearch
      );
    },
    cityTypeaheadUrl(): string {
      return (
        apiEndpoint + Endpoints.GetCityTypeaheadList + this.locationInputSearch
      );
    },
    mostSearchedList(): MostSearchedHomeItem[] {
      return this.medFacilitiesResult.mostSearched;
    },
    priceSliderValue: function(){
      var priceFrom = this.medFacilitiesResult.minFilterPrice;
      var priceTo = this.medFacilitiesResult.maxFilterPrice;
      return [priceFrom, priceTo];
    },
    minPriceSliderValue: function(){
      return this.medFacilitiesResult.minProvincePrice != null
        ? this.medFacilitiesResult.minProvincePrice
        : this.medFacilitiesResult.minCountryPrice
    },
    maxPriceSliderValue: function(){
      return this.medFacilitiesResult.maxProvincePrice != null
        ? this.medFacilitiesResult.maxProvincePrice
        : this.medFacilitiesResult.maxCountryPrice
    }
  },
  watch: {
    selectedLocation: function (newVal) {
      if (newVal == null) {
        return;
      }

      this.reload();
    },
    selectedMedicalService: function (newVal) {
      if (newVal == null) {
        return;
      }

      this.reload();
    },
    medFacilitiesResult: function (newVal) {
      var medicalServiceTypeahead = {
        id: this.medFacilitiesResult.medicalServiceId,
        name: this.medFacilitiesResult.medicalServiceName,
        url: this.medFacilitiesResult.medicalServiceUrl
      };

      this.isMedicalServiceInitialized = true;
      this.selectedMedicalServiceInitial = medicalServiceTypeahead;
      this.selectedMedicalService = medicalServiceTypeahead;

      if (this.medFacilitiesResult.locationId != undefined) {
        var name = this.medFacilitiesResult.cityName != null
          ? this.medFacilitiesResult.cityName
          : this.medFacilitiesResult.provinceName;

        var slug = this.medFacilitiesResult.citySlug != null
          ? this.medFacilitiesResult.citySlug
          : this.medFacilitiesResult.provinceSlug;

        var cityTypeahead = {
          id: this.medFacilitiesResult.locationId,
          name: name,
          slug: slug
        };

        this.selectedLocation = cityTypeahead;
        this.selectedLocationInitial = cityTypeahead;
      }

      this.isLocationInitialized = true;
    },
  },
  methods: {
    showOnMap: function () {
      $("#medical-facility-map").css("margin-left", "0");
      $("#app").css("width", "0");
      $("#app").css("height", "0");
    },
    loadFromCurrentProvince: function () {
      //this.includeForeignCountries = false;
      window.location.assign(`${this.medFacilitiesResult.medicalServiceUrl}-f/${this.medFacilitiesResult.provinceSlug}${window.location.search}`);
    },
    loadFromCurrentCountry: function () {
      window.location.assign(`${this.medFacilitiesResult.medicalServiceUrl}-f${window.location.search}`);
      // this.includeForeignCountries = true;
      // this.$emit("loadFromCurrentCountry");
    },
    reload: function () {
      if (
        this.isLocationInitialized == false ||
        this.isMedicalServiceInitialized == false
      ) {
        return;
      }

      if (
        this.medFacilitiesResult.medicalServiceId == this.selectedMedicalService.id &&
        this.medFacilitiesResult.locationId == this.selectedLocation.id
      ) {
        return;
      }

      this.includeForeignCountries = false;
      var url = this.selectedMedicalService.url;

      const location =
        this.selectedLocation.slug != undefined
          ? `/${this.selectedLocation.slug}`
          : "";

      window.location.assign(`${url}${location}`);
    },
    searchInProvince: function (province) {
      window.location.assign(`${province}`);
    },
    searchFromUrl: function (categoryName, url) {
      if (url == "" || categoryName == "Stomatologia") {
        return;
      }

      window.location.assign(url);
    },
    descriptionCount: function (count): any {
      if (count == 0) return "brak ofert";
      if (count == 1) return count + " oferta";
      if (count < 5) return count + " oferty";
      return count + " ofert";
    },
    filterByProvince: function (province) {
      window.location.assign(
        `${this.medFacilitiesResult.medicalServiceUrl}/${province.slug}`
      );
    },
    isProvinceActive: function(province){
      if(this.medFacilitiesResult){
        return this.medFacilitiesResult.cityName == province.provinceName;
      }
      return false;
    },
    onPriceSliderValueChanged: function(value){
      this.minFilterPrice = value[0];
      this.maxFilterPrice = value[1];
    },
    filterByPriceAndDistance: function()
    {
      var minPrice = this.minFilterPrice != null ? this.minFilterPrice : this.minPriceSliderValue;
      var maxPrice = this.maxFilterPrice != null ? this.maxFilterPrice : this.maxPriceSliderValue;

      var priceFilterUrl = '?price-from=' + minPrice + '&price-to=' + maxPrice;
      console.log(priceFilterUrl);

      window.location.assign(window.location.href + priceFilterUrl);
    },
    goToCategory: function(){
      let locationSlug = this.$route.params.location != undefined && this.medFacilitiesResult.parentMedicalServiceCategoryName != 'Stomatologia' 
          ? `/${this.$route.params.location}`
          : "";
      window.location.assign(`${this.medFacilitiesResult.medicalServiceCategoryUrl}${locationSlug}`);
    },
    goToLocation: function(){
      let locationSlug = this.medFacilitiesResult.cityName != null
       ? `/${this.medFacilitiesResult.provinceSlug}`
       : '';

      window.location.assign(`${this.medFacilitiesResult.medicalServiceUrl}${locationSlug}`);
    },
    goToMainPage: function(){
      window.location.assign("/");
    },
    changeSortAscending() {
      this.sortAscending = !this.sortAscending; 
      this.$emit("changeSortAscending");
    },
    showFilterPopup() {
      $("#medical-facility-result-filter-popup").addClass("show");
      $("#medical-facility-result-filter-popup").css("display", "block");
      $("#medical-facility-result-filter-popup").css("background-color", "inherit");
    },
    closeFilterPopup() {
      $("#medical-facility-result-filter-popup").removeClass("show");
      $("#medical-facility-result-filter-popup").css("display", "");
      $("#medical-facility-result-filter-popup").css("background-color", "");
    },
  },
});

export default component;
