export enum EnvMode {
	DEV,
	PROD,
}

export enum Endpoints {
	MedicalFacilityList = "user/medicalfacility",
	User = "user",
	MedicalFacilityManagement = "MedicalFacilityManagement",

	/* store */
	Login = `authentication/login`,
	RegisterUser = `authentication/register`,
	RegisterManager = `authentication/register-manager`,
	DayPromotions = `MedicalService/Promotions`,
	PromotionsWithMostSearched = "MedicalService/PromotionsWithMostSearched",
	Categories = "Category/Categories/",
	MedicalServices = "Category/MedicalServices/",
	MostSearched = `MedicalService/MostSearched`,
	Promotions = `MedicalService/Promotions/`,
	UserData = `userData`,
	GetCityTypeaheadList = `Location/Search/`,
	GetCityBySlug = `Location/GetCityBySlug/`,
	GetLocationBySlug = `Location/GetLocationBySlug/`,
	GetMedicalServiceBySlug = "Category/GetBySlug/",
	GetMedicalServiceById = "MedicalService/",
	GetMedicalFacilityById = "MedicalFacility/",
}

export const envMode: EnvMode =
	process.env.NODE_ENV === "development" ? EnvMode.DEV : EnvMode.PROD;

//export const apiEndpoint: string = envMode === EnvMode.DEV ? 'http://localhost:55781/api/' : 'https://api.infomedi.care.hostingasp.pl/api/'; // FIXME:
export const apiEndpoint: string = 'https://api.infomedi.care.hostingasp.pl/api/';
