import mutations from './mutations'
import actions from './actions'
import getters from './getters'

export default {
  namespaced: true,
  state() {
    return {
      medicalServicePackages: [],      
      medicalServiceLocation: [],
      medicalServiceCategory: []
    }
  },
  mutations: mutations,
  getters: getters,
  actions: actions
}