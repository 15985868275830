<template>
  <section>
    <div hover class="rounded z-depth-2 mb-4" v-if="isMobile() == false">
      <img class="img-fluid" :src="getImgUrl(promotion.imagePath)" :alt="'Zdjęcie kategorii ' + promotion.categoryName" />
    </div>
    <div class="promotion-item" @click="showPromotionPopup(promotion)">
      <div class="header">
        <p id="promotion" class="main-text-4-b mb-1">
          <span class="f-right"
            >Promocja dnia
            <i class="ico-flame" />
          </span>
          <br />
        </p>
        <p id="service-name" class="main-text-2-b">
          <!-- {{ promotion.medicalServiceName }} -->
          Wypełnienie w zębie mlecznym
        </p>
        <p class="main-text-3-f4 mt-2 mb-0">
          {{ promotion.medicalFacilityName }}
          <!-- {{ medFacility.name }} -->
        </p>
      </div>
      <div class="flex-jc mt-2">
        <div class="address-wrapper">
          <p class="normal-text-4-go mb-0">
            <i class="ico-location"></i> ul. {{ promotion.street }},
            {{ promotion.city }}
          </p>
        </div>
        <div class="prize-wrapper">
          <span class="main-text-2-f5 ml-auto">
            <s>
              {{ promotion.oldPrice }}
            </s>
          </span>
          <h4 class="orange-text mb-0 ml-auto">
            {{ promotion.newPrice }}
          </h4>
        </div>
      </div>
      <div class="flex-jc">
        <img class="img-fluid" src="../../assets/medfemina_logo.png" :alt="'Zdjęcie profilowe ' + promotion.medicalFacilityName" />
        <span class="normal-text-4-go l30dayprize mt-2 mb-0">
          Cena z ostatnich 30 dni:
          {{ promotion.oldPrice }}
        </span>
      </div>
      <!-- <div class="text-center" v-if="showReservationBtn">
        <button
          class="btn reservation-button px-5"
          :id="'reservation-button' + promotion.id"
        >
          Umów wizytę
        </button>
      </div> -->
    </div>
    <div
      class="modal fade in"
      id="lab-promotion-popup"
      data-keyboard="false"
      data-backdrop="false"
    >
      <div class="lab-modal-body">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          @click="closePromotionPopup()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="reservation p-3">
          <p class="main-text-2-b mb-1">Jak chcesz umówić wizytę?</p>
          <p class="normal-text-3-go mb-1">Za pośrednictwem serwisu</p>
          <div class="px-4">
            <button class="btn btn-sm res-btn">medicalprogress.pl</button>
          </div>
          <p class="normal-text-3-go mt-3 mb-1">
            Placówka skontaktuje się ze mną
          </p>
          <div class="px-4">
            <button class="btn btn-sm leave-contact-btn">
              Zostaw dane kontkatowe
            </button>
          </div>
        </div>
        <div class="mt-3 med-facility-info p-3">
          <p class="main-text-3-b">{{ promotion.medicalFacilityName }}</p>
          <p class="normal-text-3-go mb-2">
            <IconMapPin :size="16" /> ul. {{ promotion.street }},
            {{ promotion.city }}
          </p>
          <div class="flex-jc">
            <p class="normal-text-3-go mb-0">
              <IconPhone :size="16" />
              {{ promotion.phoneNumber }}
            </p>
            <img class="img-fluid" src="../../assets/medfemina_logo.png" :alt="'Logo placówki ' + promotion.medicalFacilityName" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import $ from "jquery";
import { IconPhone, IconMapPin } from "@tabler/icons-vue";
export default {
  components: {
    IconPhone,
    IconMapPin,
  },
  props: ["promotion", "showReservationBtn"],
  methods: {
    getImgUrl(pic) {
      return require("../../assets/" + pic + ".jpg");
    },
    isMobile: function () {
      return screen.width <= 768;
    },
    showPromotionPopup(promotion) {
      console.log(promotion);
      $("#lab-promotion-popup").addClass("show");
      $("#lab-promotion-popup").css("display", "block");
    },
    closePromotionPopup() {
      //$("#lab-slide-bottom-popup").modal("hide");
      $("#lab-promotion-popup").removeClass("show");
      $("#lab-promotion-popup").css("display", "");
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../scss/vars.scss";

section {
  padding: 0.5rem;
  padding-top: 1rem;
  border: 1px solid $orange; //white;
  background-color: white;
  height: 100%;
  border-radius: 1.25rem;

  @include respondto(xs, sm) {
    // padding: 1rem 0.75rem;
    padding: 0.9rem;
  }
}

.flex-jc {
  display: flex;
  justify-content: space-between;
}

.promotion-item {
  .header {
    #service-name {
      margin-bottom: 5px;
    }

    #promotion {
      font-weight: 500;
      color: $orange;

      .ico-flame {
        vertical-align: middle;
        background-size: cover;
        display: inline-block;
        width: 16px;
        height: 16px;

        background-image: url("../../assets/hot-medi-flame.svg");
      }
    }
  }

  .ico-location {
    vertical-align: top;
    background-size: cover;
    display: inline-block;
    width: 16px;
    height: 16px;
    background-image: url("../../assets/location.svg");
  }

  .prize-wrapper {
    min-width: 35%;
    display: flex;
    flex-direction: column;
  }

  .l30dayprize {
    font-size: 11px;
    float: right;
  }
}

#lab-promotion-popup {
  overflow-y: hidden;
}

.modal.fade.in .lab-modal-body {
  bottom: 0;
  opacity: 1;
}

.lab-modal-body {
  position: relative;
  //bottom: -250px;
  //margin: 150px auto 0;
  margin-top: 43vh;
  min-height: 57vh;
  padding: 20px;
  //max-width: 60%;
  height: auto;
  background-color: $background-color;
  border-top: 1px solid #bebebe;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-out, bottom 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out, bottom 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out, bottom 0.3s ease-out;
  transition: opacity 0.3s ease-out, bottom 0.3s ease-out;

  .med-facility-info {
    background-color: white;
    border-radius: 1.25rem;
  }

  .reservation {
    background-color: white;
    border-radius: 1.25rem;

    p {
      text-align: center;
    }

    .res-btn {
      color: $main-text-color;
      background-color: $complementary-color;
      border-radius: 0.5rem;
      width: 100%;
      font-weight: bold;
    }

    .leave-contact-btn {
      color: $main-text-color;
      border: 1px solid $complementary-color;
      border-radius: 0.5rem;
      width: 100%;
      font-weight: bold;
      padding: 6px;
    }
  }
}

.close {
  margin-top: -22px;
  margin-right: -17px;
  text-shadow: 0 1px 0 #ffffff;
}
/*
li {
  padding: 1rem;
}

li:hover,
li:active {
  background-color: rgb(252, 230, 230);
}

.active {
  background-color: red;
} */
</style>