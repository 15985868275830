import { createApp } from 'vue'
import { createMetaManager, defaultConfig, plugin as metaPlugin } from 'vue-meta'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from './store/index'
import router from './router';

import BaseCard from './components/ui/BaseCard.vue'
import BaseButton from './components/ui/BaseButton.vue'
import BaseSpinner from './components/ui/BaseSpinner.vue'
import BaseDialog from './components/ui/BaseDialog.vue'

import ImVueNextTypeahead from './components/typeahead/ImVueNextTypeahead.vue';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret, faCog, faCameraRetro, faPhone, faEnvelope, faGlobe, faAtlas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const app = createApp(App);
library.add(faUserSecret , faCog, faCameraRetro, faPhone, faEnvelope, faGlobe, faAtlas);

app.use(router);
app.use(store);
app.use(createMetaManager(false, {
    ...defaultConfig,
    meta: { tag: 'meta', nameless: true },
}));
app.use(metaPlugin);

app.use(VueAxios, axios);
app.component('font-awesome-icon', FontAwesomeIcon);
app.component('base-card', BaseCard);
app.component('base-button', BaseButton);
app.component('base-spinner', BaseSpinner);
app.component('base-dialog', BaseDialog);
app.component('im-vue-next-typeahead', ImVueNextTypeahead);


router.isReady().then(function () {
    app.mount('#app')
});

