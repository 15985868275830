import UserHome from './UserHome.vue';
import ViewUserData from  '../shared/ViewUserData.vue';
import { NavigateOption } from '@/types';

const loggedUserOptions: NavigateOption[] = [
    {
        label: 'Start',
        component: UserHome,
        default: true,
        name: '',
        path: 'account',        
    },
    {
        label: 'Twoje dane',
        component: ViewUserData,
        default: false,
        name: 'user-data',
        path: 'account/user-data'
    },
    {
        label: 'Twoje wizyty',
        component: ViewUserData,
        default: false,
        name: 'user-data',        
        path: 'account/user-data'
    }]

export default loggedUserOptions;