
import { defineComponent, markRaw, PropType } from "vue";
import { IInstitutionListItem } from "@/types";

export default markRaw(
  defineComponent({
    props: {
      institution: {
        type: Object as PropType<IInstitutionListItem>,
        required: true,
      },
    },
    data() {
      return {};
    },
    methods: {
      editInstitution(item: IInstitutionListItem) {
        return `/account/medical-services/${item.id}`;
      }
    }
  })
);
