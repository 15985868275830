
import { MostSearchedHomeItem } from "@/types";
import { defineComponent } from "@vue/runtime-core";
import MostSearchedItem from "../../components/home-page/MostSearchedItem.vue";
import { apiEndpoint, Endpoints } from "@/core/endpoints";
import { City, MedicalServiceTypeaheadItem } from "@/types";

export default defineComponent({
  components: {
    MostSearchedItem,
  },
  computed: {
    mostSearchedServices(): MostSearchedHomeItem[] {
      return this.$store.getters["mostSearched/mostSearchedList"];
    },
    medicalServiceTypeaheadUrl(): string {
      return (
        apiEndpoint + `MedicalService/Search/` + this.medicalServiceInputSearch
      );
    },
    cityTypeaheadUrl(): string {
      return (
        apiEndpoint + Endpoints.GetCityTypeaheadList + this.locationInputSearch
      );
    },
  },
  data() {
    return {
      isLoading: false,
      error: null,
      medicalServiceInputSearch: "",
      locationInputSearch: "",
      selectedLocation: {} as City,
      selectedLocationInitial: {} as City | null,
      selectedMedicalService: {} as MedicalServiceTypeaheadItem,
      selectedMedicalServiceInitial: {} as MedicalServiceTypeaheadItem | null,
    };
  },
  methods: {
    async getMostSearched(refresh = false) {
      this.isLoading = true;
      try {
        await this.$store.dispatch("mostSearched/loadMostSearchedList", {
          forceRefresh: refresh,
        });
      } catch (error) {
        console.error("methods: getMostSearched error: ", error);
        this.error = error.methods || "Something went wrong";
      }
      this.isLoading = false;
    },
    goToMainPage: function(){
      window.location.assign("/");
    },
    isMobile: function () {
      return screen.width <= 768;
    },
    searchMedicalService: function () {
      var url = this.selectedMedicalService.url;

      const location =
        this.selectedLocation != undefined &&
        this.selectedLocation.slug != undefined
          ? `/${this.selectedLocation.slug}`
          : "";

      window.location.assign(`${url}${location}`);
    },
  },
  created() {
    this.getMostSearched();
  },
});
