
import { defineComponent, markRaw } from "vue";
import mediHttpService from "@/core/http-service";
import { AddInstitutionDTO } from "@/types";

// email
// flatNumber
// cityId
// city
// provinceId
// province
// phoneNumber2
// website
// faxNumber
// medicalFacilityType
export default markRaw(
  defineComponent({
    data() {
      return {
        institution: {
          cityId: 17,
          name: "",
          medicalFacilityTypeId: 1,
          phoneNumber: "",
          postCode: "",
          street: "",
          buildingNumber: "",
        } as AddInstitutionDTO,
      };
    },
    methods: {
      addInstitution() {
        mediHttpService.api.postAsync(
          mediHttpService.endpoints.MedicalFacilityManagement,
          this.institution
        );
      },
    },
  })
);
