import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-73d7b189"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex flex-row row list-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_institution_list_item = _resolveComponent("institution-list-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.institutionList, (institution) => {
      return (_openBlock(), _createBlock(_component_institution_list_item, {
        class: "col-3",
        key: institution.id,
        institution: institution
      }, null, 8, ["institution"]))
    }), 128))
  ]))
}