
import { defineComponent } from "vue";
export default defineComponent({
  methods: {
    isMobile: function () {
      return screen.width <= 768;
    },
    openHotMediFb: function() {
      window.open("https://www.facebook.com/profile.php?id=100088485822427", "_blank");
    }
  }
});
