
import { defineComponent } from "vue";
import { useMeta } from 'vue-meta'
import TheHeader from "./components/layout/TheHeader.vue";
import TheFooter from "./components/layout/TheFooter.vue";
import axios from "axios";
import $ from "jquery";
import {
  IconCategory2,
  IconHeart,
  IconSearch,
  IconUser,
} from "@tabler/icons-vue";
import ImVueNextTypeahead from "./components/typeahead/ImVueNextTypeahead.vue";
import MedicalServiceTree from "./components/medical-service/MedicalServiceTree.vue";
import { MedicalServiceCategoryTree } from "@/types";
import Bootstrap from "bootstrap";
import { apiEndpoint } from "@/core/endpoints";

export default defineComponent({
  name: "App",
  setup () {
  },
  beforeCreate() {
    $;
    Bootstrap;
    ImVueNextTypeahead;
  },
  data: () => {
    return {
      rule1Signed: true,
      rule2Signed: false,
      rule3Signed: false,
      rule4Signed: false,

      showRuleDesc1: false,
      showRuleDesc2: false,
      showRuleDesc3: false,
      showRuleDesc4: false,

      showCategories: false,

      categoryTree: [] as MedicalServiceCategoryTree[],
    };
  },
  created() {
    this.$store.dispatch("tryLogin");
  },
  mounted() {
    var approvalCookies = localStorage.getItem("approvalCookies");

    if (approvalCookies == null) {
      setTimeout(function () {
        var cookiePopup = $("#cookie-popup");
        cookiePopup.addClass("show");
        cookiePopup.css("display", "block");
      }, 3000);
    }
  },
  methods: {
    rememberChoosed() {
      var data = {
        rule1Signed: this.rule1Signed,
        rule2Signed: this.rule2Signed,
        rule3Signed: this.rule3Signed,
        rule4Signed: this.rule4Signed,
      };

      localStorage.setItem("approvalCookies", JSON.stringify(data));
      $("#cookie-popup").removeClass("show");
      $("#cookie-popup").css("display", "");
    },
    approveAll() {
      var data = {
        rule1Signed: true,
        rule2Signed: true,
        rule3Signed: true,
        rule4Signed: true,
      };

      localStorage.setItem("approvalCookies", JSON.stringify(data));
      $("#cookie-popup").removeClass("show");
      $("#cookie-popup").css("display", "");
    },
    goToMainPage() {
      this.showCategories = false;
      window.location.assign("/");
    },
    displayCategories() {
      if(this.showCategories == false){
        //console.log(this.$route);
        //console.log(this.$route.params);
        var categoryTreeUrl = "Category/GetTree";
        if(this.$route.path.startsWith("/uslugi-medyczne")){
          const names = this.$route.params.name; 
          var apiParam = names[names.length - 2] + "&&" + names[names.length - 1];
          categoryTreeUrl += "/" + apiParam;
        }
        axios
          .get(
            apiEndpoint +
              categoryTreeUrl
          )
          .then((response) => {
            this.categoryTree = response.data;
            this.showCategories = true;
          });
      }
      else{
        this.showCategories = false;
      }
    },
    isMobile: function () {
      return screen.width <= 768;
    },
  },
  components: {
    TheHeader,
    TheFooter,
    MedicalServiceTree,
    IconCategory2,
    IconHeart,
    IconSearch,
    IconUser,
  },
  provide() {
    return {};
  },
});
