import mutations from './mutations'
import actions from './actions'
import getters from './getters'

export default {
    state() {
        return {
            userType: 'user',
            token: null as unknown,
            tokenExpiration: null,
            didAutoLogout: false
        }
    },
    mutations: mutations,
    getters: getters,
    actions: actions
}