let timer: any;
import { LoginData, TryLoginData } from "../../../types";
import axios from "axios";
import { apiEndpoint, Endpoints } from "@/core/endpoints";

export default {
    async login(context: any, payload: LoginData) {
        await axios.post(apiEndpoint + Endpoints.Login, payload)
            .then((response) => {
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('tokenExpiration', response.data.expiration);
                const userType = response.data.userType || 'manager';///FIXME: 
                localStorage.setItem('userType', userType);
                
                context.commit('setUser', {
                    token: response.data.token,
                    userType: userType
                })
            }).catch(err => {
                const error = new Error(err.message || 'Failed to login');
                throw error;
            });
    },
    async register(context: any, payload: LoginData) {
        await axios.post(apiEndpoint + Endpoints.RegisterUser, payload)
            .then((response) => {
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('tokenExpiration', response.data.expiration);
                localStorage.setItem('userType', 'user');
            });
    },
    async registerManager(context: any, payload: LoginData) {
        await axios.post(apiEndpoint + Endpoints.RegisterManager, payload)
            .then((response) => {
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('tokenExpiration', response.data.expiration);
                localStorage.setItem('userType', 'manager');
            });
    },
    logout(context: any) {
        localStorage.removeItem('token');
        localStorage.removeItem('userType');
        localStorage.removeItem('tokenExpiration');

        clearTimeout(timer);

        context.commit('setUser', {
            userId: null,
            token: null,
        })
    },
    tryLogin(context: any, payload: TryLoginData) {
        if (payload) {
            localStorage.setItem('token', payload.token);
            localStorage.setItem('tokenExpiration', payload.expiration);
            localStorage.setItem('userType', payload.userType);
        }

        const token = localStorage.getItem('token')
        const userType = localStorage.getItem('userType')
        const tokenExpiration: any = localStorage.getItem('tokenExpiration')
        const date = new Date(tokenExpiration).getTime();
        const expiresIn = +date - new Date().getTime();
        if (expiresIn < 0) {
            return;
        }

        timer = setTimeout(() => {
            context.dispatch('autoLogout');
        }, expiresIn);

        if (token) {
            localStorage.setItem('token', token);
            localStorage.setItem('tokenExpiration', tokenExpiration);
            localStorage.setItem('userType', userType || 'user'); ///FIXME: 

            context.commit('setUser', {
                token: token,
                userType: userType
            })
        }
    },
    autoLogout(context: any) {
        context.dispatch('logout');
        context.commit('setAutoLogout')
    }
}