
import { defineComponent } from "vue";

export default defineComponent({
	components: {},
	data() {
		return {
			error: null,
			isLoading: false,
		};
	},
	methods: {
		isMobile: function () {
			return screen.width <= 768;
		},
	},
});
