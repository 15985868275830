
import { defineComponent } from "vue";

const component = defineComponent({
  props: ["categoryTree", "level"],
  data: () => {
    return {};
  },
  computed: {
    medFacilitiesCount: function (): any {
      var medFacilities = this.medFacilitiesResult.medicalFacilities;
      if (medFacilities.length == 0) return "brak ofert";
      if (medFacilities.length == 1) return medFacilities.length + " oferta";
      if (medFacilities.length < 5) return medFacilities.length + " oferty";
      return medFacilities.length + " ofert";
    },
  },
  methods: {
    elapse: function (category) {
      category.isElapsed = !category.isElapsed;
    },
    showFrom(item) {
      var url = `${item.url}-f`;
      if(this.$route.params.location){
        url += "/" + this.$route.params.location;
      }

      window.location.assign(url);
    }
  },
});

export default component;
