import mutations from './mutations'
import actions from './actions'
import getters from './getters'

export default {
  namespaced: true,
  state() {
    return {
      userData: {
        name: 'Adamsxxx',
        email: 'your@email.com'
      },
    }
  },
  mutations: mutations,
  getters: getters,
  actions: actions
}