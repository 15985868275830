
import { defineComponent } from "vue";

export default defineComponent({
  components: {},
  data() {
    return {
      error: null,
      isLoading: false,
    };
  },
  created() {
    if(this.isMobile() == false)
    {
      const navbar = document.querySelector(".navbar");
      navbar.classList.add("navbar-transparent");

      window.onscroll = function () {
        const navbar = document.querySelector(".navbar");
        if (window.pageYOffset >= 30) {
          navbar.classList.remove("navbar-transparent");
        } else {
          navbar.classList.add("navbar-transparent");
        }
      };
    }
  },
  methods: {
    isMobile: function () {
      return screen.width <= 768;
    },
  },
});
