
import $ from "jquery";
import { defineComponent } from "vue";
import { MedicalFacility } from "@/types";
import MedicalFacilitiesMap from "../../components/medical-service/MedicalFacilitiesMap.vue";
import MedicalServiceTree from "../../components/medical-service/MedicalServiceTree.vue";
import {
  IconMapPin,
  IconAdjustments,
} from "@tabler/icons-vue";
import Slider from '@vueform/slider';

export default defineComponent({
  components: {
    IconMapPin,
    IconAdjustments,
    MedicalFacilitiesMap,
    MedicalServiceTree,
    Slider
  },
  props: ["medFacilitiesResult", "categoryTree", "showMapFlag"],
  emits: ["reload", "updateShowMapFlag"],
  data: () => {
    return {
      includeForeignCountries: false,
      //showMapFlag: false,
      showOneResultInLine: true,
      showAllProvinces: false,
      sortAscending: true,
      selectedMedicalFacility: {} as MedicalFacility,
      visibleMarkers: [],
      priceSliderFormat: {
        suffix: ' zł',
        decimals: 0
      },
      distanceSliderValue: [0, 50],
      distanceSliderFormat: {
        suffix: ' km',
        decimals: 0
      }
    };
  },
  mounted() {
    if (this.isMobile() == false) {

      var showOneResultInLine = localStorage.getItem("showOneResultInLine");
      this.showOneResultInLine = showOneResultInLine === "true";

      if (this.$route.href.endsWith("-f")) {
        this.showFilters();
      }
      else {
        this.showMap();
      }
    }
  },
  computed: {
    sortedMedFacilities: function () {
      // filter by displayed markers in map (if showMap and desktop)
      var medicalFacilities = this.medFacilitiesResult.medicalFacilities;
      if (
        this.showMapFlag == true &&
        this.isMobile() == false &&
        this.visibleMarkers.length > 0
      ) {
        var medicalFacilityidsToFilter = this.visibleMarkers.map(function (
          marker
        ) {
          return marker.markerData.id;
        });

        //console.log(medicalFacilityidsToFilter);
        //console.log(medicalFacilities.length);

        var filteredMedicalFacilities = medicalFacilities.filter(function (
          medicalFacility
        ) {
          return medicalFacilityidsToFilter.includes(medicalFacility.id);
        });

        //console.log(filteredMedicalFacilities);

        return this.sortMedFacilities(filteredMedicalFacilities);
      } else {
        return this.sortMedFacilities(medicalFacilities);
      }
    },
    provinces: function(){
      return this.showAllProvinces == false && this.medFacilitiesResult.provinces
        ? this.medFacilitiesResult.provinces.slice(0, 2)
        : this.medFacilitiesResult.provinces;
    },
    priceSliderValue: function(){
      var priceFrom = this.medFacilitiesResult.minFilterPrice;
      var priceTo = this.medFacilitiesResult.maxFilterPrice;
      return [priceFrom, priceTo];
    },
    minPriceSliderValue: function(){
      return this.medFacilitiesResult.minProvincePrice != null
        ? this.medFacilitiesResult.minProvincePrice
        : this.medFacilitiesResult.minCountryPrice
    },
    maxPriceSliderValue: function(){
      return this.medFacilitiesResult.maxProvincePrice != null
        ? this.medFacilitiesResult.maxProvincePrice
        : this.medFacilitiesResult.maxCountryPrice
    }
  },
  methods: {
    isMobile: function () {
      return screen.width <= 768;
    },
    showMap: function () {
      this.$emit('updateShowMapFlag', true);

      this.showOneResultInLine = true;

      var baseCardHolder = document.getElementById("base-card-page");

      baseCardHolder.style.cssText = "margin-left: calc((100% - 1216px) / 2) !important; max-width: calc(100% - ((100% - 1216px) / 2)) !important";

      $("#medical-facility-results").css("width", "409px");
      $("#medical-serve-result-scroll-wrapper").css("height", "87vh");
    },
    changeResultsInLineMode: function() {
      this.showOneResultInLine = !this.showOneResultInLine;
      localStorage.setItem("showOneResultInLine", this.showOneResultInLine);
    },
    changeSortAscending: function(){
      this.sortAscending = !this.sortAscending;
    },
    filterMedFacilitiesByVisibleMarkers: function (markers) {
      if (markers.length == 0) {
        return;
      }

      this.visibleMarkers = markers;
    },
    showFilters: function () {
      this.$emit('updateShowMapFlag', false);
      var baseCardHolder = document.getElementById("base-card-page");
      var headerFiltersHolder = document.getElementById("header-filters");
      var minMaxButton = document.getElementById("arrow-min-max-control-button");

      if(minMaxButton){
        minMaxButton.innerHTML = '<i class="ico-arrow-max"></i>';
      }

      baseCardHolder.style.cssText = "margin-left: auto !important; max-width: 1216px !important";
      headerFiltersHolder.classList.remove("container");
      headerFiltersHolder.style.paddingLeft = "0px";

      $("#medical-facility-results").css("width", "64%");
      $("#medical-facility-results").css("display", "block");
      $("#medical-serve-result-scroll-wrapper").css("height", "100%");

      var showOneResultInLine = localStorage.getItem("showOneResultInLine");
      this.showOneResultInLine = showOneResultInLine === "true";
    },
    descriptionCount: function (count): any {
      if (count == 0) return "brak ofert";
      if (count == 1) return count + " oferta";
      if (count < 5) return count + " oferty";
      return count + " ofert";
    },
    onPriceSliderValueChanged: function(value){
      history.pushState(
        {},
        null,
        this.$route.path + '?'
              + 'price-from=' + value[0] + '&'
              + 'price-to=' + value[1]);

        // let queries = {};
        // queries["price-from"] = value[0];
        // queries["price-to"] = value[1];
        // this.$router.replace({ query: queries });

        this.$emit("reload");
    },
    loadFromCurrentProvince: function () {
      this.includeForeignCountries = false;
      window.location.assign(`${this.medFacilitiesResult.medicalServiceUrl}/${this.medFacilitiesResult.provinceSlug}${window.location.search}-f`);
    },
    loadFromCurrentCountry: function () {
      window.location.assign(`${this.medFacilitiesResult.medicalServiceUrl}${window.location.search}-f`);
      // this.includeForeignCountries = true;
      // this.$emit("loadFromCurrentCountry");
    },
    openMedicalFacilityProfile(medicalFacility) {
      if (this.showMapFlag) {
        this.$refs.mfMap.showMarkerPopup(medicalFacility);
        return;
      }
      if (this.showMapFlag == false) {
        //window.open(medicalFacility.site, "_blank").focus();
        if(this.isMobile() == false){
          this.$router.push(medicalFacility.url + `,${medicalFacility.id}`);
        }
        else{
          console.log(medicalFacility);
          this.$router.push(medicalFacility.url + `,${medicalFacility.id}`);
        }
        
        return;
      }
      // this.selectedMedicalFacility = medicalFacility;
      // $("#lab-slide-bottom-popup").addClass("show");
      // $("#lab-slide-bottom-popup").css("display", "block");
    },
    showMedFacilityResultPopoverForMap(medicalFacility) {
      this.selectedMedicalFacility = medicalFacility;
      $("#lab-slide-bottom-popup").addClass("show");
      $("#lab-slide-bottom-popup").css("display", "block");
      $("#lab-slide-bottom-popup").css("background-color", "inherit");
      setTimeout(function () {
        var modal = document.getElementById('lab-modal-body');
        $("#lab-slide-bottom-popup").css("min-height", modal.offsetHeight + 1);
        //console.log(modal.offsetHeight);
      }, 0);
    },
    closeMedFacilityResultPopup() {
      $("#lab-slide-bottom-popup").removeClass("show");
      $("#lab-slide-bottom-popup").css("display", "");
      $("#lab-slide-bottom-popup").css("background-color", "");
    },
    isPromotionItem: function (medicalFacility) {
      return false; //medicalFacility.id % 9 == 0;
    },
    sortMedFacilities: function (medFacilities) {
      if (medFacilities == undefined) {
        return [];
      }

      var that = this;
      var res = medFacilities.sort(function (l, r) {
        // return this.isPromotionItem(l) > this.isPromotionItem(r) ? 1 : -1;
        //var lIsPromotion = l.id % 9 == 0;
        //var rIsPromotion = r.id % 9 == 0;
        // if(lIsPromotion && rIsPromotion){
        //   return 1; //l.price < r.price ? -1 : 1;
        // }
        // if(lIsPromotion && !rIsPromotion){
        //   return -1;
        // }
        // if(!lIsPromotion && rIsPromotion){
        //   return 1;
        // }
          if(that.sortAscending) {
            return l.priceValue < r.priceValue ? -1 : 1;
          }
          else{
            return l.priceValue < r.priceValue ? 1 : -1;
          }
      });

      return res;
    },
    filterByProvince: function (province) {
      window.location.assign(
        //`${this.medFacilitiesResult.medicalServiceUrl}/${province.slug}${window.location.search}-f`
        `${this.medFacilitiesResult.medicalServiceUrl}/${province.slug}-f`
      );
    },
    filterByMedService: function (medicalService) {
      // TODO change to filter / map view
      //console.log(this.$route.params);
      const location =
        this.$route.params.location != undefined
          ? `/${this.$route.params.location}`
          : "";
      window.location.assign(`${medicalService.url}${location}`);
    },
    isProvinceActive: function(province){
      if(this.medFacilitiesResult){
        return this.medFacilitiesResult.cityName == province.provinceName;
      }
      return false;
    }
  },
});
