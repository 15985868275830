<template>
  <header>
    <h3>User home</h3>
  </header>
</template>

<script>
import { defineComponent, markRaw } from "vue";

export default markRaw(defineComponent({
  
}));
</script>