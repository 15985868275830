
import { defineComponent } from "vue";
import { MostSearchedHomeItem } from "@/types";
import MostSearchedItem from "../components/home-page/MostSearchedItem.vue";

const component = defineComponent({
  components: {
    MostSearchedItem,
  },
  data: () => {
    return {
    };
  },
  computed: {
    mostSearchedServices(): MostSearchedHomeItem[] {
      return this.$store.getters["mostSearched/mostSearchedList"].slice(0, 3);
    },
  },
  methods: {
    async getMostSearched(refresh = false) {
      await this.$store.dispatch("mostSearched/loadMostSearchedList", {
        forceRefresh: refresh,
      });
    },
    goToMainPage: function () {
      window.location.assign("/");
    },
  },
  created() {
    this.getMostSearched();
  }
});

export default component;
