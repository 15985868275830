
import { MostSearchedHomeItem } from "@/types";
import { defineComponent } from "vue";
import MostSearchedItem from "../../components/home-page/MostSearchedItem.vue";
import { apiEndpoint, Endpoints } from "@/core/endpoints";
import { City, MedicalServiceTypeaheadItem } from "@/types";
import $ from "jquery";
import mediHttpService from "@/core/http-service";

const component = defineComponent({
  components: {
    MostSearchedItem,
  },
  props: ["medFacilitiesResult"],
  emits: ["loadFromCurrentProvince, loadFromCurrentCountry", "reload"],
  data: () => {
    return {
      includeForeignCountries: false,
      isLocationInitialized: false,
      isMedicalServiceInitialized: false,
      selectedLocation: {} as City,
      selectedLocationInitial: {} as City | null,
      selectedMedicalService: {} as MedicalServiceTypeaheadItem,
      selectedMedicalServiceInitial: {} as MedicalServiceTypeaheadItem | null,
      medicalServiceInputSearch: "",
      locationInputSearch: "",
    };
  },
  computed: {
    medFacilitiesCount: function (): any {
      var medFacilities = this.medFacilitiesResult.medicalFacilities;
      if (medFacilities.length == 0) return "brak ofert";
      if (medFacilities.length == 1) return medFacilities.length + " oferta";
      if (medFacilities.length < 5) return medFacilities.length + " oferty";
      if (medFacilities.length % 10 > 1 && medFacilities.length % 10 < 5)
        return medFacilities.length + " oferty";
      return medFacilities.length + " ofert";
    },
    medicalServiceTypeaheadUrl(): string {
      return (
        apiEndpoint + `MedicalService/Search/` + this.medicalServiceInputSearch
      );
    },
    cityTypeaheadUrl(): string {
      return (
        apiEndpoint + Endpoints.GetCityTypeaheadList + this.locationInputSearch
      );
    },
    mostSearchedList(): MostSearchedHomeItem[] {
      return this.medFacilitiesResult.mostSearched;
    },
    locationName(): string {
      if (this.medFacilitiesResult.locationId == null){
        return "";
      }

      return this.medFacilitiesResult.cityName != null
        ? " - " + this.medFacilitiesResult.cityName
        : " - " + this.medFacilitiesResult.provinceName;
    }
  },
  watch: {
    medFacilitiesResult: function (newVal) {
      var medicalServiceTypeahead = {
        id: this.medFacilitiesResult.medicalServiceId,
        name: this.medFacilitiesResult.medicalServiceName,
        url: this.medFacilitiesResult.medicalServiceUrl
      };

      this.isMedicalServiceInitialized = true;
      this.selectedMedicalServiceInitial = medicalServiceTypeahead;
      this.selectedMedicalService = medicalServiceTypeahead;

      if (this.$route.params.location != undefined){
        var name = this.medFacilitiesResult.cityName != null
          ? this.medFacilitiesResult.cityName
          : this.medFacilitiesResult.provinceName;

        var slug = this.medFacilitiesResult.citySlug != null
          ? this.medFacilitiesResult.citySlug
          : this.medFacilitiesResult.provinceSlug;

        var cityTypeahead = {
          id: this.medFacilitiesResult.locationId,
          name: name,
          slug: slug
        };

        this.selectedLocation = cityTypeahead;
        this.selectedLocationInitial = cityTypeahead;
      }

      this.isLocationInitialized = true;
    },
  },
  methods: {
    showOnMap: function () {
      console.log("opening");
      $(".gm-fullscreen-control").click();
    },
    loadFromCurrentProvince: function () {
      this.includeForeignCountries = true;
      this.$emit("loadFromCurrentProvince");
    },
    loadFromCurrentCountry: function () {
      this.includeForeignCountries = false;
      this.$emit("loadFromCurrentCountry");
    },
    searchMedicalService: function () {
      var url = this.selectedMedicalService.url;

      const location =
        this.selectedLocation != undefined &&
        this.selectedLocation.slug != undefined
          ? `/${this.selectedLocation.slug}`
          : "";

      window.location.assign(`${url}${location}`);

      // this.$router.push({ path: `${url}${location}` });
      // this.$emit("reload");
    },
    searchInProvince: function (province) {
      window.location.assign(`${province}`);
    },
    goToCategory: function(){
      let locationSlug = this.$route.params.location != undefined && this.medFacilitiesResult.parentMedicalServiceCategoryName != 'Stomatologia' 
          ? `/${this.$route.params.location}`
          : "";
      window.location.assign(`${this.medFacilitiesResult.medicalServiceCategoryUrl}${locationSlug}`);
    },
    goToLocation: function(){
      let locationSlug = this.medFacilitiesResult.cityName != null
       ? `/${this.medFacilitiesResult.provinceSlug}`
       : '';

      window.location.assign(`${this.medFacilitiesResult.medicalServiceUrl}${locationSlug}`);
    },
    goToMainPage: function(){
      window.location.assign("/");
    }
  }
});

export default component;
