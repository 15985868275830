
import MostSearchedItem from "../../components/home-page/MostSearchedItem.vue";
import PromotionDayItem from "../../components/home-page/PromotionDayItem.vue";
import RecommendedItem from "../../components/home-page/RecommendedItem.vue";
import $ from "jquery";
import { defineComponent } from "vue";
import { DayPromotion, City, MedicalServiceTypeaheadItem } from "../../types";
import { apiEndpoint, Endpoints } from "@/core/endpoints";
import axios from "axios";

export default defineComponent({
  components: {
    MostSearchedItem,
    PromotionDayItem,
    RecommendedItem
  },
  data() {
    return {
      error: null,
      isLoading: false,
      medicalServiceInputSearch: "",
      locationInputSearch: "",
      carouselCurrentPage: 0,
      carouselItemsPerPage: 3,
      carouselItems: [] as DayPromotion[],
      selectedLocation: {} as City,
      selectedMedicalService: {} as MedicalServiceTypeaheadItem,
      promotionsList: [] as DayPromotion[],
      mostSearchedList: [] as any[],
    };
  },
  methods: {
    async getPromotions(refresh = false) {
      this.isLoading = true;
      try {
        // await this.$store.dispatch("homePage/loadPromotions", {
        //   forceRefresh: refresh,
        // })
        // .then(response => { console.log(response)  });
        axios.get(apiEndpoint + Endpoints.PromotionsWithMostSearched).then(
          (response) => {
            this.promotionsList = response.data.promotions;
            this.mostSearchedList = response.data.mostSearched;
            this.isLoading = false;
            // context.commit('setPromotions', <DayPromotion[]>response.data.promotions)
            // context.commit('setMostSearched', response.data.mostSearched)
          },
          (error) => {
            console.error("methods: getPromotions error: ", error);
            this.isLoading = false;
          }
        );
      } catch (error) {
        this.error = error.methods || "Something went wrong";
      }
      this.carouselCurrentPage = 0;
    },
    getArrayChunkForCarousel: function (array: any, step: any) {
      const multipler = this.isMobile() ? 1 : 3;
      return array && array.slice(step * multipler, (step + 1) * multipler);
    },
    getArrayChunkForCarouselWithMult: function (array: any, index: any) {
      if (!array || !array.length) {
        return [];
      }

      if (this.isMobile()) {
        return [array[index]];
      }

      return array.slice(index, index + 3);
    },
    searchMedicalService: function () {
      
      // const successCallback = (position) => {
      // console.log(position);
      // };

      // const errorCallback = (error) => {
      //   console.log(error);
      // };

      // navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
      
      var url = this.selectedMedicalService.url;

      if (url == undefined) {
        return;
      }

      const location =
        this.selectedLocation.slug != undefined
          ? `/${this.selectedLocation.slug}`
          : "";

      //this.$router.push({ path: `${url}${location}` });
      window.location.assign(`${url}${location}`);
    },
    isMobile: function () {
      return screen.width <= 768;
    },
  },
  computed: {
    medicalServiceTypeaheadUrl(): string {
      return (
        apiEndpoint + `MedicalService/Search/` + this.medicalServiceInputSearch
      );
    },
    cityTypeaheadUrl(): string {
      return (
        apiEndpoint + Endpoints.GetCityTypeaheadList + this.locationInputSearch
      );
    },
    // promotionsList() {
    //   return this.$store.getters["homePage/promotions"] || [];
    // },
    // mostSearchedList() {
    //   return this.$store.getters["homePage/mostSearched"];
    // },
  },
  watch: {
    promotionsList() {
      if (this.promotionsList?.length > 2) {
        this.carouselItems[0] = this.promotionsList[0];
        this.carouselItems[1] = this.promotionsList[1];
        this.carouselItems[2] = this.promotionsList[2];
      }
    },
  },
  created() {
    if(this.isMobile() == false){
      const navbar = document.querySelector(".navbar");
      navbar.classList.add("navbar-transparent");

      window.onscroll = function () {
        const navbar = document.querySelector(".navbar");
        if (window.pageYOffset >= 50) {
          navbar.classList.remove("navbar-transparent");
        } else {
          navbar.classList.add("navbar-transparent");
        }
      };
    }

    function success(position) {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;

      console.log(latitude, longitude);
    }

    function error() {
      console.log("Unable to retrieve your location");
    }

    //after popup on success
    //navigator.geolocation.getCurrentPosition(success, error);

    this.getPromotions();
  },
  mounted() {
    var icoFlameEl = $(".ico-flame");
    var i = 0;
    var that = this;

    //var intervalId = setInterval(frame, 700);

    // function frame(){
    //   if(that.isLoading == false) {
    //     clearInterval(intervalId);
    //     return;
    //   }
    //   i++;
    //   //console.log(that.isLoading);
    //   if(i % 2 == 0){
    //     icoFlameEl.css("display", "block");
    //   }
    //   else{
    //     icoFlameEl.css("display", "none");
    //   }
    // }
  },
});
