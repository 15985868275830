
import { defineComponent, markRaw, PropType } from "vue";
import mediHttpService from "@/core/http-service";
import { AddInstitutionDTO } from "@/types";
import { IInstitutionListItem } from "@/types";

export default markRaw(
  defineComponent({
    data() {
      return {
        institution: {
          cityId: 17,
          name: "",
          medicalFacilityTypeId: 1,
          phoneNumber: "",
          postCode: "",
          street: "",
          buildingNumber: "",
        } as AddInstitutionDTO,
      };
    },
    methods: {
      async saveAsync() {
        await mediHttpService.api.postAsync(
          mediHttpService.endpoints.MedicalFacilityManagement,
          this.institution
        );
      },
    },
  })
);
