
import axios from "axios";
import { apiEndpoint, Endpoints } from "@/core/endpoints";

export default {
    getProvinces(context: any) {        
        // axios.get(apiEndpoint + Endpoints.GetProvinces)
        //     .then((response) => {
        //         context.commit('setProvinces', response.data)
        //     });
    },
}