
import { defineComponent } from "vue";

const component = defineComponent({
  components: {},
  props: {
    mostSearched: { type: Object },
    index: Number,
    prizeColor: {
      type: String,
      default: "#0F0028",
    },
  },
  computed: {
    mostSearchedCount: function (): any {
      var mostSearchedCount = this.$props.mostSearched.count;
      if (mostSearchedCount == 1) return mostSearchedCount + " oferta";
      if (mostSearchedCount < 5) return mostSearchedCount + " oferty";
      if (mostSearchedCount % 10 >= 2 && mostSearchedCount % 10 < 5)
        return mostSearchedCount + " oferty";
      return mostSearchedCount + " ofert";
    },
  },
  methods: {
    getImgUrl(imagePath) {
      //return require("../../assets/" + pic + ".jpg");

      return require("../../assets/Category/" + imagePath + ".png");
    },
    isMobile: function () {
      return screen.width <= 768;
    },
  },
});

const __default__ = component;

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "5cb4ee68": (_ctx.prizeColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__