
import { apiEndpoint, Endpoints } from "@/core/endpoints";
import axios from "axios";

export default {
    async loadMedicalServicesCategories(context: any) {
        await axios.get(apiEndpoint + Endpoints.Categories)
            .then((response) => {
                context.commit('setCategories', response.data)
            });
    },
    loadMedicalServicesByCategory(context: any, categoryName: any) {
        axios.get(apiEndpoint + Endpoints.MedicalServices + categoryName)
            .then((response) => {
                context.commit('setMedicalServicesByCategory', response.data)
            });
    }
}