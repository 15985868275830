<template>
  <div class="base-card-wrapper">
    <div id="base-card-page" :class="[className]">
      <slot> </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseCard',
  props: {
    className: {
      type: String,
      default: 'margin-nav container'
    },
    bgDesktopColor: {
      type: String,
      default: '#F7F7F9'
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../scss/custom.scss";

.base-card-wrapper{
  background-color: v-bind(bgDesktopColor);

  @include respondto(xs, sm) {
    background-color: $background-color;
  }
}

.margin-nav {
  margin: auto;
  //width: 65vw;  
  margin-top: 6rem;
}
</style>