export default {
    token(state: any) {
        return state.token;
    },
    isAuthenticated(state: any): boolean {
        return !!state.token;
    },
    didAutoLogout(state: any) {
        return state.didAutoLogout;
    },
    userType(state: any) {
        return !!state.token && state.userType;
    }
}