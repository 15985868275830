
import { apiEndpoint, Endpoints } from "@/core/endpoints";
import { DayPromotion } from "@/types";
import axios from "axios";

export default {
    loadPromotions(context: any) {
        axios.get(apiEndpoint + Endpoints.PromotionsWithMostSearched)
            .then((response) => {
                context.commit('setPromotions', <DayPromotion[]>response.data.promotions)
                context.commit('setMostSearched', response.data.mostSearched)
            });
    }
}