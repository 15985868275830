
import { defineComponent, markRaw } from "vue";
import mediHttpService from "@/core/http-service";
import { NavigateOption } from "@/types/NavigateOption";
import InstitutionList from "@/components/manager/institutions/InstitutionList.vue";
import { IInstitutionListItem } from "@/types/IInstitutionListItem";

export default markRaw(
  defineComponent({
    components: {
      InstitutionList,
    },
    data() {
      return {
        addInstitutionButton: {
          label: "Dodaj",
          default: false,
          path: "/account/add-institution",
        } as NavigateOption,
        institutionsList: [] as IInstitutionListItem[],
      };
    },
    async created() {
      const institutions = await mediHttpService.api.getListAsync(
        mediHttpService.endpoints.MedicalFacilityList
      );
      this.institutionsList = institutions;
    },
    methods: {
      addClicked() {
        console.log("add");
      },
    },
  })
);
