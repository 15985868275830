import { createRouter, createWebHistory } from "vue-router";
import store from "./store/index";
import HomePage from "./pages/home-page/HomePage.vue";
import MedicalServicesHome from "./pages/medical-services/MedicalServicesHome.vue";
import MedicalServicesByCategory from "./pages/medical-services/MedicalServicesByCategory.vue";
import MedicalServicesPackagesList from "./pages/service-packages/MedicalServicesPackagesList.vue";
import DayPromotionsPage from "./pages/day-promotions/DayPromotionsPage.vue";
import MedicalServiceDetailsPage from "./pages/medical-services/MedicalServiceDetailsPage.vue";
import MedicalFacilityProfile from "./pages/medical-facility/MedicalFacilityProfile.vue";
import MostSearchedHome from "./pages/most-searched-page/MostSearchedHome.vue";
import UserAuth from "./pages/auth/UserAuth.vue";
import UserRegister from "./pages/auth/UserRegister.vue";
import UserLoginOrRegister from "./pages/auth/UserLoginOrRegister.vue";
import AccountHome from "./pages/account/AccountHome.vue";
import RegisterHome from "./pages/auth/RegisterHome.vue";
import AboutUs from "./pages/desc/AboutUs.vue";
import PatientDesc from "./pages/desc/PatientDesc.vue";
import PartnerDesc from "./pages/desc/PartnerDesc.vue";
import HmRegulation from "./pages/desc/HmRegulation.vue";
import NotFound from "./pages/NotFound.vue";
import ViewInstitutions from "./pages/account/manager/ViewInstitutions.vue";
import ViewUserData from "./pages/account/shared/ViewUserData.vue";
import AddInstitutionForm from "./pages/account/manager/institutions/add/AddInstitutionForm.vue";
import EditInstitutionForm from "./pages/account/manager/institutions/edit/EditInstitutionForm.vue";
import ManagerHome from "./pages/account/manager/ManagerHome.vue";
import medicalServiceSeoMap from './types/MedicalServiceSeoMap';
import locationSeoMap from './types/locationSeoMap';
import { useMeta } from 'vue-meta'

store.dispatch("tryLogin");

function redirectMedicalServiceWithId(to, from, next){
    const id = "-id" + to.params.id;
    const splittedUrl = to.href.split(id);
    const firstPart = "/uslugi-medyczne/" + to.params.name[0] + "/" + to.params.name[to.params.name.length - 1];
    const url = firstPart + splittedUrl[1];
    next(url);
}

const router = createRouter({
    history: createWebHistory(),
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition && to.path.startsWith('/uslugi-medyczne')) {
            return savedPosition
        } else {
           return { top: 0 }
        }
    },
    routes: [
        { path: '/', component: HomePage },
        // { path: '/promocje', component: DayPromotionsPage, meta: { requiresAuth: false } },
        // { path: '/promocje/:name*', component: DayPromotionsPage, meta: { requiresAuth: false } },
        // { path: '/uslugi-medyczne', component: MedicalServicesHome },
        // { path: '/uslugi-medyczne/:kategoriaUslugi', name: 'uslugi-kategoria', props: true, component: MedicalServicesByCategory },
        { path: '/uslugi-medyczne/:name*-id:id' , props: true, component: MedicalServiceDetailsPage, beforeEnter(to, from, next){ redirectMedicalServiceWithId(to, from, next)} },
        { path: '/uslugi-medyczne/:name*-id:id/:location' , props: true, component: MedicalServiceDetailsPage, beforeEnter(to, from, next){ redirectMedicalServiceWithId(to, from, next) }},
        { path: '/uslugi-medyczne/:name*' , props: true, component: MedicalServiceDetailsPage },
        { path: '/uslugi-medyczne/:name*-f' , props: true, component: MedicalServiceDetailsPage },
        //{ path: '/uslugi-medyczne/:name*/:location' , props: true, component: MedicalServiceDetailsPage },
        //{ path: '/uslugi-medyczne/:name*-f/:location' , props: true, component: MedicalServiceDetailsPage },
        { path: '/placowki/:name*-id:id' , props: true, component: MedicalFacilityProfile },
        // { path: '/pakiety-uslug', component: MedicalServicesPackagesList },
        { path: '/najczesciej-wyszukiwane', component: MostSearchedHome },
        { path: '/o-nas', component: AboutUs },
        { path: '/strefa-klienta', component: PatientDesc },
        { path: '/strefa-partnera', component: PartnerDesc },
        { path: '/regulamin', component: HmRegulation },
        { path: '/logowanie', component: UserAuth, meta: { requiresUnauth: true } },
        { path: '/rejestracja', component: RegisterHome, meta: { requiresUnauth: true } },
        {
            path: '/account', name: 'account', component: AccountHome, meta: { requiresAuth: true },
            children: [
                { path: 'home', component: ManagerHome },
                { path: 'user-data', component: ViewUserData },
                { path: 'add-institution', name:'add-institution', component: AddInstitutionForm },
                { path: 'medical-services', component: ViewInstitutions },
                { path: 'medical-services/:id', name:'edit-institution', component: EditInstitutionForm }
            ]
        },
        { path: '/:notFound(.*)', component: NotFound }
    ]
});

function setSeo(title, description){
    document.title = title;
    document.querySelector('meta[name="description"]').setAttribute("content", description);
}

function setSeoForMedicalService(to, next) {
    const lastUrlName = to.params.name[to.params.name.length - 1];
    const lastButOneUrlName = to.params.name[to.params.name.length - 2];

    const locationSeoItem = locationSeoMap.find(x => x.slug == lastUrlName);
    let medicalServiceSeoItem = {slug:"", name:""};
    if(locationSeoItem == null) {
        medicalServiceSeoItem = medicalServiceSeoMap.find(x => x.slug == lastUrlName);

        if(medicalServiceSeoItem == null) {
            medicalServiceSeoItem = medicalServiceSeoMap.find(x => x.slug == lastButOneUrlName);    
        }
    }
    else {
        medicalServiceSeoItem = medicalServiceSeoMap.find(x => x.slug == lastButOneUrlName);
    }

    let title = "";
    let description = "";

    if(medicalServiceSeoItem != null) {
        if(locationSeoItem == null){
            title = `${medicalServiceSeoItem.name} - ceny | Hot Medi`;
            if(medicalServiceSeoItem.name.length % 2 == 0){
                description = `Chcesz dowiedzieć się ile kosztuje ${medicalServiceSeoItem.name}? Wybierz najatrakcyjniejszą cenę tej usługi medycznej dzięki Hot Medi!`;
            }
            else{
                description = `Chcesz dowiedzieć się ile kosztuje ${medicalServiceSeoItem.name}? W Hot Medi dysponujemy bogatą bazą placówek medycznych. Wejdź i znajdź najlepszą ofertę!`;
            }
        }
        else {
            title = `${medicalServiceSeoItem.name} - ${locationSeoItem.name} - ceny | Hot Medi`;
            if(medicalServiceSeoItem.name.length % 2 == 0){
                description = `Chcesz dowiedzieć się ile kosztuje ${medicalServiceSeoItem.name} ${locationSeoItem.descName}. W Hot Medi pomożemy Ci znaleźć najlepszą ofertę w Twojej okolicy!`;
            }
            else{
                description = `Chcesz dowiedzieć się ile kosztuje ${medicalServiceSeoItem.name} ${locationSeoItem.descName}? W Hot Medi pokażemy Ci usługi medyczne w atrakcyjnych cenach! Zapraszamy!`;
            }
        }

        to.params.slug = medicalServiceSeoItem.slug;

        setSeo(
            title,
            description
        );
    }
    else{
        next("/404");
    }
}

router.beforeEach(function (to, _, next) {
    if(to.path == '/'){
        setSeo(
            'Hot Medi - porównywarka cen usług i pakietów medycznych',
            "Hot Medi to porównywarka usług medycznych w Polsce i za granicą. Poznaj ceny usług i pakietów medycznych w Twojej okolicy!"
        );
    }
    else if(to.path=='/regulamin'){
        document.title = "Regulamin";
        const meta = document.createElement('meta');
        meta.name = "robots";
        meta.content = "noindex";
        document.getElementsByTagName('head')[0].appendChild(meta);
    }
    else if(to.path.startsWith("/uslugi-medyczne/")) {
        setSeoForMedicalService(to, next);
    }
    else if(to.path.startsWith("/placowki")) {
        setSeo(
            'Profil placówki',
            "Profil placówki"
        );
    }
    else if(to.path.startsWith("/najczesciej-wyszukiwane")) {
        setSeo(
            'Najczęściej wyszukiwane',
            "Najczęściej wyszukiwane usługi medyczne"
        );
    }
    else if(to.path.startsWith("/o-nas")) {
        setSeo(
            'O nas',
            "O nas"
        );
    }
    else if(to.path.startsWith("/strefa-klienta")) {
        setSeo(
            'Strefa klienta',
            "Strefa klienta"
        );
    }
    else if(to.path.startsWith("/strefa-partnera")) {
        setSeo(
            'Strefa partnera',
            "Strefa partnera"
        );
    }

	if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
		next("/auth");
	} else if (to.meta.requiresUnauth && store.getters.isAuthenticated) {
		next("/promocje");
	} else {
		next();
	}
});

export default router;
