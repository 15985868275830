
import { apiEndpoint, Endpoints } from "@/core/endpoints";
import axios from "axios";

export default {
    getUserData(context: any) {        
        axios.get(apiEndpoint + Endpoints.UserData)
            .then((response) => {
                context.commit('getUserData', response.data)
            });
    },
}