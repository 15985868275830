import { DayPromotion } from '../../../types'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export default {
  namespaced: true,
  state() {
    return {
      promotions: [] as DayPromotion[],
      mostSearched: []
    }
  },
  mutations: mutations,
  getters: getters,
  actions: actions
}