
import { defineComponent } from "@vue/runtime-core";
import $ from "jquery";
import BaseButton from "../ui/BaseButton.vue";

export default defineComponent({
  // components: { BaseButton },
  computed: {
    isLoggedIn(): boolean {
      return this.$store.getters.isAuthenticated;
    },
  },
  methods: {
    logout(): void {
      this.$store.dispatch("logout");
      this.$router.replace("/promocje");
    },
    goToClientPage: function(){
      window.location.assign('/strefa-klienta');
    },
    goToPartnerPage: function(){
      window.location.assign('/strefa-partnera');
    },
    closeNavbar(): void{
      $(".navbar-toggler").click();
    }
  },
});
