<template>
  <span
    class="vue-next-typeahead"
    :class="[className]">
    <span
      v-if="title && showTitle"
      class="vue-next-typeahead__title">
      {{ title }}
    </span>
    <div
      class="vue-next-typeahead__wrapper-input"
      :class="{
        'vue-next-typeahead__wrapper-input--focusing': focusing
      }"
      >
      <input
        type="text"
        autocomplete="false"
        :placeholder="placeholder"
        :value="dataInput"
        @keyup.esc="reset()"
        @input="changeInput($event)"
        @paste="changeInput($event)"
        @focus="onFocusin()"
        @blur="onFocusout()">
      <IconCurrentLocation :size="20" :color="additionalIconColor" v-if="!searching && !loadingParentComponent && additionalIcon != ''" />
      <i class="ico-loader" v-if="searching || loadingParentComponent" />
    </div>
    <ul
      v-if="dataInput.length && !searching && !loadingParentComponent && showDropdown"
      class="vue-next-typeahead__dropdown">
      <template v-if="items.length">
        <li
          v-for="(item, ix) in items"
          :key="ix"
          @click="clickItem(item)">
          <slot v-bind:item="item">
            <span v-html="keyItem ? highlight(item[nameItem], dataInput) : item" />
          </slot>
        </li>
      </template>
      <li v-else class="li-no-results">
        <slot name="no-results">{{ noResultsText }}</slot>
      </li>
    </ul>
  </span>
</template>

<script>
import { IconCurrentLocation  } from '@tabler/icons-vue';
export default {
  name: 'ImVueNextTypeahead',
  components: {
     IconCurrentLocation
  },
  props: {
    apiUrl: {
      type: String,
      required: true
    },
    input: {
      type: String,
      default: ''
    },
    value: {
      type: Object,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    className: {
      type: String,
      default: ''
    },
    keyItem: {
      type: String,
      default: null
    },
    nameItem: {
      type: String,
      default: null
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    noResultsText: {
      type: String,
      default: 'Brak wyników wyszukiwania...'
    },
    additionalIcon: {
      type: String,
      default: 'ico-search'
    },
    additionalIconColor: {
      type: String,
      default: 'rgb(33 37 41 / 80%)'
    },
    initValue: {
      type: Object,
      default: null
    },
    minChars: {
      type: Number,
      default: 2,
      validator: (value) => {
        return value > 0
      }
    },
    delay: {
      type: Number,
      default: 500
    },
    loadingParentComponent: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      searching: false,
      focusing: false,
      showDropdown: true,
      anyItemWasChoosed: false,
      timeout: '',
      dataInput: '',
      items: []
    }
  },
  watch: {
    'input' (newVal) {
      this.dataInput = newVal
    },
    initValue(newVal) {
      this.$emit('update:value', newVal);
      this.dataInput = newVal[this.$props.nameItem]
      this.searching = false;
      this.showDropdown = false;
      this.anyItemWasChoosed = true;
      this.items.push(newVal);
    }
  },
  mounted () {
    var vm = this
    document.addEventListener('click', (e) => {
      if (e && e.path && vm.anyItemWasChoosed == false && e.path.map(item => item.className).indexOf("vue-next-typeahead") < 0) {
        this.reset()
      }
    })
  },
  methods: {
    /**
     * Main method to make the query of the URL received as a prop.
     * The parent component is in charge of processing that first response.
     */
    search () {
      try {
        this.searching = true
        clearTimeout(this.timeout)
        this.timeout = setTimeout(async () => { // Begin timeout
          const response = await fetch(this.apiUrl)
          await this.getResponse(response)
          clearTimeout(this.timeout) // Clean interval
          this.showDropdown = true
          this.searching = false
        }, this.delay)
      } catch (error) {
        this.$emit('error', error)
        this.searching = false
        console.error('Check your API URL you have introduced.')
        throw error
      }
    },
    getPriceTitle() {
        return 'test price';
    },
    /**
     * Emit value input each time you write.
     * It is only triggered if it has a value and if it meets the minimum number of characters.
     * When you write here the search event is triggered.
     */
    changeInput (event) {
      if (event.target && event.target.value && event.target.value.length >= this.minChars) {
        this.search()
      }
      this.$emit('update:input', event.target.value)
      if (event.target && event.target.value.length === 0) {
        this.reset()
      }
    },
    /**
     * When active focus input.
     */
    onFocusin () {
      this.focusing = true
    },
    /**
     * When remove focus input.
     */
    onFocusout () {
      this.focusing = false
      this.searching = false
    },
    /**
     * Emit event when select one option in list.
     */
    clickItem (item) {
      this.dataInput = item[this.$props.nameItem]
      this.$emit('update:input', this.dataInput)
      this.$emit('update:value', item)
      this.anyItemWasChoosed = true
      this.showDropdown = false
      this.searching = false
    },
    /**
     * Highlight in bold the text that matches our search.
     * Case insensitive.
     */
    highlight (text, term) {
      if (text && term) {
        var index = text.toLowerCase().indexOf(term.toLowerCase())
        if (index >= 0) {
          text = `${text.substring(0, index)}<span style="font-weight: 600;">${text.substring(index, index + term.length)}</span>${text.substring(index + term.length)}`
        }
      }
      return text
    },
    async getResponse(responseJson) {
      this.isTypeaheadLoading = true;
      const response = await responseJson.json();
      this.items = response;
      this.isTypeaheadLoading = false;
    },
    reset () {
      this.showDropdown = false
      this.searching = false
      this.dataInput = ''
      this.items = [];
      this.$emit('update:input', '')
      this.$emit('update:value', null)
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../scss/vars.scss";

  .vue-next-typeahead {
    display: block;
    position: relative;
    &__title {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: left;
      display: block;
      margin-bottom: 8px;
    }
    &__wrapper-input {
      height: 40px;
      background-color: $background-color !important;
      border: 1px solid rgba(22, 6, 48, 0.15);
      padding: 0 15px 0 16px;
      border-radius: 0.5rem;
      > input {
        height: 48px;
        border: none;
        font-size: 16px;
        font-weight: 500;
        margin-right: 8px;
        width: calc(100% - 32px);
        padding: 0;
        max-height: 100%;
        outline: none;
        //color: $greyed-out-color;
        background: transparent;
        &::placeholder {
          color: $greyed-out-color;
          opacity: 0.6;
        }
      }
      .ico-search,
      .ico-loader {
        vertical-align: middle;
        background-size: cover;
        display: inline-block;
        width: 24px;
        height: 24px;
      }
      .ico-search {
        background-image: url('../../assets/search.svg');
      }
      .ico-loader {
        background-image: url('../../assets/loader-orange.svg');
        -webkit-animation:spin 4s linear infinite;
        -moz-animation:spin 4s linear infinite;
        animation:spin 4s linear infinite;
      }
      .tabler-icon-current-location {
        margin-bottom: 3px;
      }
      @-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
      @-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
      @keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

      // Focus state
      &--focusing {
          border: 1px solid $complementary-color;
          color: white;
          .ico-search {
            background-image: url('../../assets/search-orange.svg');
          }
      }
    }
    &__dropdown {
      border-radius: 8px 4px 4px 8px;
      background: #FFF;
      box-shadow: 2px 12px 24px 0px rgba(15, 0, 40, 0.13);
      list-style: none;
      width: calc(100% - 2px);
      padding: 0;
      margin: 0;
      position: absolute;
      max-height: 306px;
      overflow-y: auto;
      z-index: 1;
      > li {
        min-height: 42px;
        padding: 0 16px;
        font-size: 16px;
        font-style: normal;
        letter-spacing: 0px;
        text-align: left;
        cursor: pointer;
        background-color: white;
        &:hover {
          background-color: $li-hover-item-color;
          cursor: pointer;
        }
        > span {
          line-height: 42px;
        }
        &.li-no-results {
          line-height: 42px;
          cursor: auto;
        }
      }
      li:last-child {
        padding-bottom: 8px;
      }
      /** SCSS Para el scroll */
      &::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 8px;
      }
      &::-webkit-scrollbar-thumb {
          border-radius: 30px;
          border: 4px solid rgba(255, 255, 255, 0);
          background-color: $main-text-color;
      }
      &::-webkit-scrollbar-track-piece{
        border-radius: 30px;
        background-color: $li-hover-item-color;
      }
      &::-webkit-scrollbar-corner {
          background-color: transparent;
      }

      scrollbar-width: thin;
      scrollbar-color: $main-text-color $background-color;

    }
    // Responsive
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
</style>