import { createStore } from 'vuex';

import authModule from './modules/auth/index'
import homePageModule from './modules/home-page/index'
import medicalServicesModule from './modules/medical-services/index'
import medicalServiceModule from './modules/service-packages/index'
import dayPromotionsModule from './modules/day-promotions/index'
import mostSearchedModule from './modules/most-searched/index'
import userModule from './modules/user/index'
import settingsProvincesModule from './modules/settings/provinces/index'

const store = createStore({
    modules: {
        authModule: authModule,
        homePage: homePageModule,
        medicalServices: medicalServicesModule,
        medicalServicePackages: medicalServiceModule,
        dayPromotions: dayPromotionsModule,
        mostSearched: mostSearchedModule,
        user: userModule,
        settingsProvinces: settingsProvincesModule
    },
});

export default store;