
import { defineComponent } from "@vue/runtime-core";
import { apiEndpoint, Endpoints } from "@/core/endpoints";
import { City, MedicalServiceTypeaheadItem } from "@/types";
import mediHttpService from "@/core/http-service";
import $ from "jquery";

export default defineComponent({
  computed: {
    medicalServiceTypeaheadUrl(): string {
      return (
        apiEndpoint + `MedicalService/Search/` + this.medicalServiceInputSearch
      );
    },
    cityTypeaheadUrl(): string {
      return (
        apiEndpoint + Endpoints.GetCityTypeaheadList + this.locationInputSearch
      );
    },
  },
  data() {
    return {
      isLoading: false,
      error: null,
      medicalFacility: {},
      medicalFacilityPricing: [],
      medicalServiceInputSearch: "",
      locationInputSearch: "",
      selectedLocation: {} as City,
      selectedLocationInitial: {} as City | null,
      selectedMedicalService: {} as MedicalServiceTypeaheadItem,
      selectedMedicalServiceInitial: {} as MedicalServiceTypeaheadItem | null,
    };
  },
  methods: {
    async getMedicalFacilityProfile() {
      mediHttpService.api
      .firstAsync(Endpoints.GetMedicalFacilityById, this.$route.params.id)
      .then((response) => {
        if (response) {
          this.medicalFacility = response.medicalFacility;
          this.medicalFacilityPricing = response.pricingData;
        }
      });
    },
    goBack: function(){
      this.$router.back();
    },
    goToMainPage: function(){
      window.location.assign("/");
    },
    gotToMedicalFacilitySite: function() {
      window.open(this.medicalFacility.website, "_blank");
    },
    isMobile: function () {
      return screen.width <= 768;
    },
    isRootCategoryOpened: function(pricingItem) {
      console.log(pricingItem.rootCategoryId);
      var rootCategoryItem = this.medicalFacilityPricing.items.filter(item => item.id == pricingItem.rootCategoryId)[0];

      return rootCategoryItem != null && rootCategoryItem.isOpened;
    },
    scrollToCategory: function(category){
      var categoryItem = this.medicalFacilityPricing.items.filter(item => item.id == category.id)[0];

      var addOffset = this.isMobile() ? 130 : 80;

      $([document.documentElement, document.body]).animate({
        scrollTop: $("#rootCategory" + categoryItem.id).offset().top - addOffset
      }, 2000);

      console.log(categoryItem);
    },
    searchMedicalService: function () {
      var url = this.selectedMedicalService.url;

      const location =
        this.selectedLocation != undefined &&
        this.selectedLocation.slug != undefined
          ? `/${this.selectedLocation.slug}`
          : "";

      window.location.assign(`${url}${location}`);
    },
  },
  created() {
    this.getMedicalFacilityProfile();

    window.onscroll = function () {
      const goBackSticky = document.getElementById("go-back-sticky");
      if(goBackSticky != null) {
        if (window.pageYOffset >= 200) {
          goBackSticky.style.display = 'block';
        } else {
          goBackSticky.style.display = 'none';
        }
      }   
    };
  },
});
