
// import MedicalServiceDetails from "../../components/medical-service/MedicalServiceDetails.vue";
import axios from "axios";
import $ from "jquery";
import MedicalFacilityResults from "../../components/medical-service/MedicalFacilityResults.vue";
import MedicalServiceFilters from "../../components/medical-service/MedicalServiceFilters.vue";
import MedicalServiceFiltersMobile from "../../components/medical-service/MedicalServiceFiltersMobile.vue";
import { defineComponent } from "@vue/runtime-core";
import medicalServiceSeoMap from '../../types/MedicalServiceSeoMap';
import { MedicalFacilitiesResult, MedicalServiceCategoryTree} from "@/types";
import { apiEndpoint } from "@/core/endpoints";
import BaseCard from "@/components/ui/BaseCard.vue";

export default defineComponent({
  name: 'MedicalServiceDetailsPage',
  components: {
    // MedicalServiceDetails,
    MedicalFacilityResults,
    MedicalServiceFilters,
    MedicalServiceFiltersMobile,
    BaseCard,
  },
  data: () => {
    return {
      medFacilitiesResult: {
        medicalFacilities: [],
        medicalServiceName: "",
      } as MedicalFacilitiesResult,
      categoryTree: [] as MedicalServiceCategoryTree[],
      showMapFlag: false,
      medicalServiceSeoItem: {}
    };
  },
  created() {
    const names = this.$route.params.name; 
    var apiParam = names[names.length - 2] + "&&" + names[names.length - 1];

    axios
      .get(
        apiEndpoint + "MedicalService/MedicalFacilities/" + apiParam
      )
      .then((response) => {
        var seoItem = medicalServiceSeoMap.find(x => x.slug == this.$route.params.slug);
        this.medFacilitiesResult = response.data;
        
        if(seoItem) {
          this.medicalServiceSeoItem = seoItem;

          this.medFacilitiesResult.medicalServiceName = seoItem.name;
        }
      });

    axios
      .get(
        apiEndpoint +
          "Category/GetTree/" + apiParam
      )
      .then((response) => {
        this.categoryTree = response.data;
      });
  },
  computed: {
    isMobile: function () {
      return screen.width <= 768;
    }
  },
  methods: {
    loadFromCurrentProvince: function () {
      axios
        .get(
          apiEndpoint +
            "MedicalService/" +
            this.$route.params.id +
            "/MedicalFacilities/dolnoslaskie"
        )
        .then((response) => {
          this.medFacilitiesResult = response.data;
        });
    },
    loadFromCurrentCountry: function () {
      axios
        .get(
          apiEndpoint +
            "MedicalService/" +
            this.$route.params.id +
            "/MedicalFacilities"
        )
        .then((response) => {
          this.medFacilitiesResult = response.data;
        });
    },
    changeSortAscending: function(){
      this.$refs.mfResult.changeSortAscending();
    },
    reload: function () {
      var location =
        this.$route.params.location != undefined
          ? `/${this.$route.params.location}`
          : "";

      let queryUrl = this.getFilterUrlParamString();

      //console.log(queryUrl);

      axios
        .get(
          apiEndpoint +
            "MedicalService/" +
            this.$route.params.id +
            "/MedicalFacilities" +
            location
            + queryUrl
        )
        .then((response) => {
          this.medFacilitiesResult = response.data;
        });
    },
    getFilterUrlParamString: function(){
      return window.location.search;
    },
    updateShowMapFlag: function(value){
      this.showMapFlag = value;
    }
  },
});
