import { shallowReactive } from "vue";

const medicalServiceSeoMap = [
	{ slug: "aleksandrow-lodzki", name: "Aleksandrów Łódzki", descName: "w Aleksandrowie Łódzkim" },
    { slug: "andrychow", name: "Andrychów", descName: "w Andrychowie" },
    { slug: "babice-nowe", name: "Babice Nowe", descName: "w Babicach Nowych" },
    { slug: "bialystok", name: "Białystok", descName: "w Białymstoku" },
    { slug: "bielany-wroclawskie", name: "Bielany Wrocławskie", descName: "w Bielanach Wrocławskich" },
    { slug:"bielsko-biala", name:"Bielsko-Biała", descName:"w Bielsku-Białej" },
    { slug:"boleslawiec", name:"Bolesławiec", descName:"w Bolesławcu" },
    { slug:"brzeg-id35407", name:"Brzeg", descName:"w Brzegu" },
    { slug:"brzeszcze", name:"Brzeszcze", descName:"w Brzeszcze" },
    { slug:"bydgoszcz", name:"Bydgoszcz", descName:"w Bydgoszczy" },
    { slug:"bytom", name:"Bytom", descName:"w Bytomiu" },
    { slug:"chorzow", name:"Chorzów", descName:"w Chorzowie" },
    { slug:"czarny-las-id1495", name:"Czarny Las", descName:"w Czarnym Lesie" },
    { slug:"czestochowa", name:"Częstochowa", descName:"w Częstochowie" },
    { slug:"dabrowa-gornicza", name:"Dąbrowa Górnicza", descName:"w Dąbrowie Górniczej" },
    { slug:"elblag", name:"Elbląg", descName:"w Elblągu" },
    { slug:"gdansk", name:"Gdańsk", descName:"w Gdańsku" },
    { slug:"gdynia", name:"Gdynia", descName:"w Gdyni" },
    { slug:"gliwice", name:"Gliwice", descName:"w Gliwicach" },
    { slug:"gorzow-wielkopolski", name:"Gorzów Wielkopolski", descName:"w Gorzowie Wielkopolskim" },
    { slug:"grajewo", name:"Grajewo", descName:"w Grajewie" },
    { slug:"gubin", name:"Gubin", descName:"w Gubinie" },
    { slug:"katowice", name:"Katowice", descName:"w Katowicach" },
    { slug:"kielce", name:"Kielce", descName:"w Kielcach" },
    { slug:"kolobrzeg", name:"Kołobrzeg", descName:"w Kołobrzegu" },
    { slug:"koszalin", name:"Koszalin", descName:"w Koszalinie" },
    { slug:"krakow", name:"Kraków", descName:"w Krakowie" },
    { slug:"legnica", name:"Legnica", descName:"w Legnicy" },
    { slug:"luban-id23091", name:"Lubań", descName:"w Lubaniu" },
    { slug:"lubawa", name:"Lubawa", descName:"w Lubawie" },
    { slug:"lublin", name:"Lublin", descName:"w Lublinie" },
    { slug:"lubsko", name:"Lubsko", descName:"w Lubsku" },
    { slug:"lodz", name:"Łódź", descName:"w Łodzi" },
    { slug:"olsztyn", name:"Olsztyn", descName:"w Olsztynie" },
    { slug:"opole-id1033", name:"Opole", descName:"w Opolu" },
    { slug:"piekary-slaskie", name:"Piekary Śląskie", descName:"w Piekarach Śląskich" },
    { slug:"plock", name:"Płock", descName:"w Płocku" },
    { slug:"poznan", name:"Poznań", descName:"w Poznaniu" },
    { slug:"pruszcz-gdanski", name:"Pruszcz Gdański", descName:"w Pruszczu Gdańskim" },
    { slug:"radom", name:"Radom", descName:"w Radomiu" },
    { slug:"ruda-slaska", name:"Ruda Śląska", descName:"w Rudzie Śląskiej" },
    { slug:"rybnik", name:"Rybnik", descName:"w Rybniku" },
    { slug:"rzeszow", name:"Rzeszów", descName:"w Rzeszowie" },
    { slug:"siemianowice-slaskie", name:"Siemianowice Śląskie", descName:"w Siemianowicach Śląskich" },
    { slug:"sosnowiec", name:"Sosnowiec", descName:"w Sosnowcu" },
    { slug:"stare-babice", name:"Stare Babice", descName:"w Starych Babicach" },
    { slug:"starowa-gora", name:"Starowa Góra", descName:"w Starowej Górze" },
    { slug:"suchy-las-id6644", name:"Suchy Las", descName:"w Suchym Lesie" },
    { slug:"szczecin", name:"Szczecin", descName:"w Szczecinie" },
    { slug:"swinoujscie", name:"Świnoujście", descName:"w Świnoujściu" },
    { slug:"tarnowskie-gory", name:"Tarnowskie Góry", descName:"w Tarnowskiej Górze" },
    { slug:"tarnow", name:"Tarnów", descName:"w Tarnowie" },
    { slug:"torun", name:"Toruń", descName:"w Toruniu" },
    { slug:"tychy", name:"Tychy", descName:"w Tychach" },
    { slug:"walbrzych", name:"Wałbrzych", descName:"w Wałbrzychu" },
    { slug:"warszawa", name:"Warszawa", descName:"w Warszawie" },
    { slug:"wloclawek", name:"Włocławek", descName:"we Włocławku" },
    { slug:"wodzislaw-slaski", name:"Wodzisław Śląski", descName:"w Wodzisławie Śląskim" },
    { slug:"wroclaw", name:"Wrocław", descName:"we Wrocławiu" },
    { slug:"zabrze", name:"Zabrze", descName:"w Zabrzu" },
    { slug:"zielona-gora", name:"Zielona Góra", descName:"w Zielonej Górze" },
    { slug:"zagan", name:"Żagań", descName:"w Żaganiu" },
    { slug:"dolnoslaskie", name:"dolnośląskie", descName:"w województwie dolnośląskim" },
    { slug:"kujawsko-pomorskie", name:"kujawsko-pomorskie", descName:"w województwie kujawsko-pomorskim" },
    { slug:"lubelskie", name:"lubelskie", descName:"w województwie lubelskim" },
    { slug:"lubuskie", name:"lubuskie", descName:"w województwie lubuskim" },
    { slug:"lodzkie", name:"łódzkie", descName:"w województwie łódzkim" },
    { slug:"malopolskie", name:"małopolskie", descName:"w województwie małopolskim" },
    { slug:"mazowieckie", name:"mazowieckie", descName:"w województwie mazowieckim" },
    { slug:"opolskie", name:"opolskie", descName:"w województwie opolskim" },
    { slug:"podkarpackie", name:"podkarpackie", descName:"w województwie podkarpackim" },
    { slug:"podlaskie", name:"podlaskie", descName:"w województwie podlaskim" },
    { slug:"pomorskie", name:"pomorskie", descName:"w województwie pomorskim" },
    { slug:"slaskie", name:"śląskie", descName:"w województwie śląskim" },
    { slug:"swietokrzyskie", name:"świętokrzyskie", descName:"w województwie świętokrzyskim" },
    { slug:"warminsko-mazurskie", name:"warmińsko-mazurskie", descName:"w województwie warmińsko-mazurskim" },
    { slug:"wielkopolskie", name:"wielkopolskie", descName:"w województwie wielkopolskim" },
    { slug:"zachodniopomorskie", name:"zachodniopomorskie", descName:"w województwie zachodniopomorskim" },
];

export default medicalServiceSeoMap;
