
import BaseCard from "../../components/ui/BaseCard.vue";

import loggedUserOptions from "./user/loggedUserOptions";
import loggedManagerOptions from "./manager/loggedManagerOptions";

import UserHome from "./user/UserHome.vue";
import ManagerHome from "./manager/ManagerHome.vue";
import { defineComponent } from "@vue/runtime-core";
import { Component } from "vue";
import { NavigateOption } from "@/types";

export default defineComponent({
  components: { BaseCard },
  data() {
    return {
      userType: null,
      activePage: {} as Component | undefined,
    };
  },
  computed: {
    userData() {
      // let userData = this.$store.getters["user/userData"];
      return this.$store.getters["user/userData"];
    },
    menuOptions(): NavigateOption[] {
      const userType = this.$store.getters.userType;
      return userType === "user" ? loggedUserOptions : loggedManagerOptions;
    },
  },
  methods: {
    accountOptionClicked(item: NavigateOption) {
      this.activePage = item.component;
    },
  },
  created() {
    this.userType = this.$store.getters.userType;
    this.activePage = this.userType === "user" ? UserHome : ManagerHome;
  },
});
