import { shallowReactive } from "vue";

const medicalServiceSeoMap = [
	{
		slug: "aparat-ruchomy-invisalign-nakladki",
		name: "Aparat ruchomy nakładki Invisalign",
		seoText: `<h2>Przezroczyste aparaty (nakładki) na zęby</h2>
        <p>Aparat Invisalign jest wykorzystywany do korekcji wad zgryzu w postaci niewidocznych z
        daleka, zdejmowalnych nakładek na zęby, których kształt jest dobierany indywidualnie pod
        okiem specjalisty. Ich zadaniem jest stopniowa korekcja kształtu uzębienia, co wymaga
        wymiany wkładki średnio co 2 tygodnie.
        </p>
        <h2>Niewidoczne aparat na zęby Invisalign - zalecenia</h2>
        <p>Z metody mogą korzystać osoby dorosłe i młodzież, praktycznie w przypadku każdej wady
        zgryzu. Do najbardziej popularnych przypadłości leczonych tą metodą zalicza się diastemę,
        przodozgryz, zgryz krzyżowy, lub stłoczenie uzębienia
        </p>
        <h2>Jaka jest przewaga aparatu Invisalign nad tradycyjnym?</h2>
        <p>Najbardziej oczywistą przewagą będzie tutaj fakt, że nakładki Invisalign nie są widoczne, nie
        mając tym samym wpływu na wygląd. Co więcej, jest to bezbolesna metoda leczenia, co ma
        szczególne znaczenie u osób wrażliwych na ból. Dużo łatwiejsza jest też tutaj higiena jamy
        ustnej - nie są potrzebne dedykowane akcesoria do czyszczenia aparatów, specjalne
        szczoteczki, nie rośnie też ryzyko stanów zapalnych na skutek niedokładnego wyczyszczenia
        zębów. Do tego też aparat można w każdej chwili łatwo samodzielnie zdjąć, chociaż, aby mógł
        on działać jak najlepiej, zaleca się jego noszenie przez co najmniej 22 godziny w ciągu doby.
        </p>
        <h2>Przezroczyste nakładki na zęby - cena</h2>
        <p>Popularność przezroczystych aparatów osłonowych wciąż rośnie i trudno się temu dziwić –
        wiele osób nie chce nosić widocznych z daleka, nieestetycznych aparatów, utrudniających
        codzienną pielęgnację uzębienia, mając w zasięgu rozwiązanie takie jak niewidzialne wkładki
        ortodontyczne. Cena za przezroczyste nakładki waha się w przedziale od kilkuset złotych do
        nawet kilkudziesięciu tysięcy zł,. dlatego też zachęcamy Cię do porównania cen założenia
        ruchomego aparatu Invisalign i wybrania oferty wg indywidualnych preferencji.
        </p>
        <h2>Cena za aparat ortodontyczny invisalign - od czego zależy</h2>
        <p>Do czynników mających wpływ na ostateczną cenę za przezroczyste nakładki na zęby
        zaliczamy:
        </p>
        <ul>
            <li>rodzaj wady zgryzu</li>
            <li>oczekiwany efekt leczenia</li>
            <li>stopień rozwoju wady</li>
            <li>leczenie pojedynczych zębów lub całego uzębienia</li>
            <li>czas leczenia</li>
            <li>ilość niezbędnych wizyt kontrolnych</li>
            <li>ewentualne komplikacje</li>
            <li>ilość zastosowanych nakładek</li>
            <li>leczenie jednego lub dwóch łuków zębowych</li>
            <li>wybór określonej wersji aparatu</li>
        </ul>
        <h2>Aparaty (nakładki) na zęby Invisalign w porównywarce cen usług medycznych Hot Medi</h2>
        <p>Porównywarka cen usług medycznych Hot Medi powstała w celu szybkiego znalezienia w
        odpowiednim przedziale cenowym placówek oferujących m.in. przezroczyste nakładki na zęby
        Invisalign. To właśnie tutaj możesz porównać ceny przezroczystego aparatu i dzięki
        odnośnikom do strony, zapoznać się z pełną ofertą interesującej Cię placówki w Twojej okolicy,
        na terenie całej Polski i na świecie.</p>
        <h2>Porównanie cen przezroczystych aparatów na zęby z Hot Medi</h2>
        <p>Z szukaniem odpowiedniej oferty założenia przezroczystego aparatu na zęby jest podobnie jak
        z szukaniem najlepszego ubezpieczenia – porównujemy ceny, opinie, dostępność i pozostałe
        czynniki, jakie mogą wpłynąć na ostateczny koszt. Z porównywarką usług medycznych HOT
        MEDI możesz znaleźć odpowiednią ofertę spełniającą Twoje oczekiwania. <br/>
        Zachęcamy również do zapoznania się z <a href="/uslugi-medyczne/stomatologia/aparat-staly-metalowy-czesciowy" target="_blank">
        cenami stałych aparatów ortodontycznych</a>, <a href="/uslugi-medyczne/stomatologia/konsultacja-stomatologiczna" target="_blank">konsultacji stomatologicznych</a> oraz 
        <a href="/uslugi-medyczne/stomatologia/ortodoncja" target="_blank">ściągnięcia aparatów ortodontycznych</a>.
        </p>`,
	},
	{
		slug: "aparat-staly-metalowy-czesciowy",
		name: "Aparat stały metalowy częściowy",
		seoText: `<h2>Stały aparat ortodontyczny</h2>
        <p>Budowa aparatu ortodontycznego opiera się o zamki połączone łukiem ortodontycznym,
        stanowiącym najbardziej istotną część w procesie leczenia. Leczenie wady zgryzu polega tutaj
        na wywieraniu delikatnego nacisku na uzębienie. Do mocowania aparatu używany jest
        specjalny klej ortodontyczny.</p>
        <h2>Metalowy aparat ortodontyczny do leczenia wad zgryzu</h2>
        <p>Klasyczny aparat metalowy jest najlepszym rozwiązaniem w sytuacji, gdy mamy do czynienia z
        zaawansowaną wadą zgryzu lub dużymi dysproporcjami w rozmiarze żuchwy i szczęki. Ze
        względu na większą siłę korekcyjną, niż w przypadku coraz popularniejszych w ostatnim
        czasie przezroczystych nakładek, aparat tradycyjny może znacznie skrócić czas leczenia.
        Będzie on też lepszym wyborem w przypadku małych dzieci, wymagających większej kontroli
        w noszeniu aparatu.
        </p>
        <h2>Stały aparat ortodontyczny - komu jest zalecany?</h2>
        <p>Nie ma w tym przypadku ograniczeń wiekowych, chociaż trzeba mieć tutaj na uwadze fakt, że
        w przypadku osób starszych proces leczenia wady zgryzu będzie trwał dłużej. Aparat stały jest
        dziś często wypierany na rzecz nakładek przezroczystych, jednak wciąż istnieje duża grupa
        osób decydujących się właśnie na tę metodę leczenia m.in. z uwagi na dużo niższe koszty.</p>
        <h2>Stały aparat ortodontyczny - ceny</h2>
        <p>Samo założenie aparatu ortodontycznego z metalowymi zamkami waha się od kilkuset do
        kilku tysięcy złotych. Należy również uwzględnić
        <a href="/uslugi-medyczne/stomatologia/konsultacja-ortodontyczna" target="_blank">cenę konsultacji ortodontycznych</a>, czy
        <a href="/uslugi-medyczne/stomatologia/ortodoncja" target="_blank">zdjęcia aparatu na zęby</a>, które są uwzględniane w
        <a href="/uslugi-medyczne/stomatologia/plan-leczenia-ortodontycznego" target="_blank">planie leczenia ortodontycznego<a/>.
        </p>
        <h2>Od czego zależy cena aparatu stałego ortodontycznego?</h2>
        <p>Cena aparatu może się wahać w zależności od wielu czynników, dlatego też każdy przypadek
        będzie rozpatrywany indywidualnie. Wyceniając leczenie z wykorzystaniem aparatu
        ortodontycznego stałego, bierzemy pod uwagę następujące kwestie:</p>
        <ul>
            <li>stopień zaawansowania wady zgryzu</li>
            <li>przewidywany czas leczenia</li>
            <li>wykonanie zdjęć RTG</li>
            <li>opracowanie planu leczenia</li>
            <li>wykonanie aparatu</li>
            <li>montaż aparatu na zębach</li>
            <li>wizyty kontrolne</li>
            <li>demontaż aparatu</li>
            <li>założenie aparatu retencyjnego, utrwalającego efekty leczenia</li>
        </ul>
        <h2>Stały aparat ortodontyczny w porównywarce cen usług medycznych Hot Medi</h2>
        <p>Na naszej stronie możesz wygodnie porównać dostępność interesujących Cię usług z zakresu
        stomatologii i ortodoncji, w tym aparat ortodontyczny metalowy. Cena jego założenia różni się
        w zależności od placówki, a także od województwa, w którym decydujesz się na podjęcie
        leczenia. W Hot Medi można znaleźć odpowiednią ofertę odpowiadającą indywidualnym potrzebom. 
        Dzięki wygodnym odnośnikom do stron internetowych można umówić się na
        wizytę w danej placówce.
        </p>
        <h2>Porównanie cen stałych aparatów ortodontycznych z metalowymi ząbkami z Hot Medi</h2>
        <p>W porównywarce usług medycznych Hot Medi można znaleźć placówki oferujące założenie
        stałego aparatu ortodontycznego. Wygodne porównanie wszystkich kosztów oraz możliwość
        zapoznania się z pełną ofertą wybranych placówek w jednym miejscu pomoże wybrać
        sprawnie odpowiednią ofertę.
        </p>
        <p>Zachęcamy również do zapoznania się z 
        <a href="/uslugi-medyczne/stomatologia/aparat-ruchomy-invisalign-nakladki" target="_blank">cenami przezroczystych aparatów na zęby</a>.</p>`,
	},
	{
		slug: "wybielanie-zebow",
		name: "Wybielanie zębów",
		seoText: `<h2>Zabieg wybielania zębów</h2>
        <p>Proces wybielania polega na rozpadzie nadtlenku wodoru i penetracji wolnych rodników w
        szkliwie i we wnętrzu przebarwionej zębiny, prowadząc do utlenienia się przebarwień.
        Powoduje to zmianę barwy uzębienia na jaśniejszą. Przeprowadzenie zabiegu pod okiem
        specjalisty nie narazi tkanek twardych zębów na osłabienie.</p>
        <h2>Zabieg wybielania zębów - przeciwwskazania</h2>
        <p>Choć wybielanie zębów może przynieść wspaniałe efekty, to niestety nie jest to zabieg do
        przeprowadzenia u wszystkich. Do istotnych przeciwwskazań zaliczamy:
        </p>
        <ul>
            <li>próchnicę</li>
            <li>choroby przyzębia</li>
            <li>choroby dziąseł</li>
            <li>paradontozę</li>
            <li>odsłonięte korzenie zębowe</li>
            <li>noszenie aparatu ortodontycznego i czas 6 mies. po jego zdjęciu</li>
            <li>alergia na składniki zawarte w środkach wybielających</li>
            <li>przyjmowanie niektórych leków</li>
            <li>leczenie kanałowe</li>
            <li>plomby</li>
            <li>korony</li>
            <li>zęby martwe</li>
            <li>niedawne zabiegi chirurgiczne w jamie ustnej</li>
            <li>pojawienie się uszkodzeń mechanicznych</li>
            <li>afty, opryszczka, owrzodzenia</li>
            <li>ciąża</li>
            <li>karmienie piersią</li>
        </ul>
        <h2>Jak przygotować się do wybielania zębów?</h2>
        <p>Podstawowym zabiegiem, jaki należy koniecznie przeprowadzić przed wybielaniem zębów, jest
        skaling i piaskowanie. Ma to duże znaczenie, dlatego że miejsca, w których znajduje się
        kamień nazębny, nie poddadzą się wybielaniu. Pamiętaj również o tym, by pomiędzy
        skalingiem a wybielaniem zębów zachować tydzień odstępu. Pozwoli to na wygojenie się
        ewentualnych podrażnień</p>
        <h2>Wybielanie zębów w gabinecie dentystycznym– cena</h2>
        <p>Cena wybielania zębów waha się od kilkuset do nawet kilku tysięcy. Kwota ta jednak raczej nie
        przekracza 2 tysięcy złotych.</p>
        <h2>Ceny za zabieg wybielania zębów - od czego zależy?</h2>
        <p>Cena za zabieg wybielania zębów zależy to przede wszystkim od wyboru metody, spośród
        których możemy zdecydować się zarówno na metody domowe, obejmujące stosowanie
        pasków, żeli lub past wybielających, jak i na profesjonalne zabiegi wykonywane w gabinecie
        medycyny estetycznej lub u stomatologa.</p>
        <h2>Dlaczego warto skorzystać z zabiegu wybielania zębów?</h2>
        <p>Piękne, białe zęby to marzenie większości z nas. Dlatego, jeśli tylko nie masz istotnych
        przeciwwskazań do wykonania takiego zabiegu, zdecyduj się na wybielanie zębów u dentysty.
        Cena nie musi być zawrotna – wystarczy, że dzięki naszej wyszukiwarce sprawdzisz, jakich
        kosztów możesz się spodziewać. Porównaj oferowane metody i wspólnie ze specjalistą ustal,
        jaka będzie dla Ciebie najlepsza.</p>
        <h2>Wybielanie zębów w porównywarce cen usług medycznych Hot Medi</h2>
        <p>Platforma Hot Medi powstała, by ułatwić pacjentom szybsze wyszukiwanie interesujących ich
        usług z zakresu stomatologii i ortodoncji. Wygodne filtry wyszukiwania mają na celu:</p>
        <ul>
            <li>ułatwić znalezienie odpowiedniej placówki medycznej oferującej zabiegi wybielania
            zębów w obrębie danej lokalizacji,
            </li>
            <li>porównać ceny, a także zapoznać się z pełną ofertą wybielania zębów w wybranych
            placówkach</li>
        </ul>
        <h2>Porównanie cen wybielania zębów w Polsce i na świecie</h2>
        <p>W naszej porównywarce cen usług medycznych znajdziesz informacje na temat najlepszych
        placówek z zakresu wybielania zębów na terenie całej Polski, a także na świecie. Za pomocą
        naszych filtrów można znaleźć odpowiedni gabinet stomatologiczny w odpowiednim
        przedziale cenowym oraz wygodnym dla Ciebie położeniu, gdzie zostanie przeprowadzony
        zabieg wybielania zębów.
        </p>
        <p>Zachęcamy również do zapoznania się z 
        <a href="/uslugi-medyczne/stomatologia/scaling-usuwanie-kamienia" target="_blank">cenami usuwanie kamienia nazębnego</a> oraz
        <a href="/uslugi-medyczne/stomatologia/konsultacja-stomatologiczna" target="_blank">konsultacji stomatologicznych</a> w Twojej okolicy!</p>`,
	},
	{
		slug: "protezy",
		name: "Protezy",
		seoText: `<h2>Protezy zębowe</h2>
        <p>Jest to metoda leczenia stosowana w przypadku całkowitej lub częściowej utraty zębów, przy
        czym nie musi ona koniecznie następować wyłącznie z racji podeszłego wieku lub zaniedbań.
        Wiemy już dzisiaj, że do utraty uzębienia może dojść na skutek chorób, urazów lub poważnych
        wypadków.
        </p>
        <p>Protezy dzielimy na stałe i ruchome. Ruchome zakładane są w przypadku całościowej lub
        bardzo dużej utraty uzębienia. Wyróżniamy tutaj m.in. 
        <a href="/uslugi-medyczne/stomatologia/proteza-akrylowa" target="_blank">protezy akrylowe</a> i
        <a href="/uslugi-medyczne/stomatologia/proteza-szkieletowa" target="_blank">szkieletowe</a>.
        </p>
        <p>Protezy stałe są stosowane w przypadku mocno zniszczonych zębów (korony) lub
        odbudowywania pojedynczych braków (mosty). Dodatkową możliwością jest dziś również
        zakładanie licówek, umożliwiających poprawę koloru, kształtu lub nawet rozmiaru zęba.
        </p>
        <h2>Założenie protez zębowych - wskazania</h2>
        <p>Najważniejszym z nich jest całościowa lub bardzo znaczna utrata uzębienia. Protetyka
        stomatologiczna pomaga w uzupełnieniu uzębienia nie tylko ze względów estetycznych –
        poprawia również codzienne funkcjonowanie, jako że kompletne uzębienie odgrywa istotną
        rolę w wyraźnej mowie i dokładnym przeżuwaniu pokarmów.<br/>
        Niedokładnie przeżute jedzenie może podnosić ryzyko wystąpienia chorób żołądka, zatem
        kompletne, zadbane uzębienie staje się szczególnie ważne. Braki w uzębieniu mogą prowadzić
        do poważnych zaników dziąseł, co z kolei będzie przekładało się na powstawanie kolejnych
        ubytków, zatem podjęcie szybkich działań staje się szczególnie ważne.</p>
        <h2>Jak przygotować się do założenia protezy stomatologicznej?</h2>
        <p>Aby jama ustna była w pełni przygotowana na założenie protezy zębowej, konieczne jest
        wyleczenie próchnicy, stanów zapalnych, a także przeprowadzenie 
        <a href="/uslugi-medyczne/stomatologia/leczenie-kanalowe-endodontyczne" target="_blank">leczenia kanałowego</a> we
        wszystkich zębach, jakie tego wymagają. Liczy się również zadbanie o dobre nawyki w
        zakresie higieny jamy ustnej.</p>
        <p>Dlaczego ma to tak duże znaczenie? Chodzi o to, że wszelkie stany zapalne są w głównej
        mierze spowodowane występowaniem bakterii. Jeśli się ich dokładnie nie pozbędziesz przed
        założeniem protezy, zwłaszcza stałej, mogą one dostać się pod spód i tam rozwijać stany
        zapalne, jakich wyleczenie będzie dosyć skomplikowane.</p>
        <h2>Założenie protez zębowych - cena</h2>
        <p>Koszty założenia protezy mogą zaczynać się już od ok. 100 zł, a kończyć się nawet na
        stawkach powyżej 20 tys. zł.</p>
        <h2>Od czego zależy cena za protezę zębową:</h2>
        <p>Na cenę założenia protezy zębowej wpływa wiele czynników tj.:</p>
        <ul>
            <li>rodzaj protezy</li>
            <li>wielkość protezy</li>
            <li>ilość utraconych zębów</li>
            <li>ilość i jakość filarów podparcia w protezie</li>
            <li>marka wykonywanych implantów</li>
            <li>ew. podniesienie dna zatoki szczękowej</li>
            <li>ew. odbudowa kości szczęki</li>
            <li>leczenie ubytków</li>
            <li>usuwanie próchnicy, kamienia, stanów zapalnych itp.</li>
            <li>rodzaj materiału, z jakiego wykonano protezę</li>
        </ul>
        <h2>Porównanie cen za protezy zębowe w Hot Medi</h2>
        <p>W Hot Medi można w prosty i intuicyjny sposób znaleźć oferty z zakresu protez zębowych w
        określonym przedziale cenowym w danej lokalizacji dzięki której szybko zapoznasz się z
        usługami najbardziej interesujących placówek z całej Polski i całego świata. Cena za
        wykonanie i założenie protezy może się różnić w zależności od regionu, a także czynników
        indywidualnych, dlatego też tym bardziej zachęcamy do porównania cen usług medycznych z
        zakresu protetyki stomatologicznej.
        </p>
        <p>Zachęcamy do zapoznania się z 
        <a href="/uslugi-medyczne/stomatologia/proteza-calkowita" target="_blank">cenami protez całkowitych</a> oraz 
        <a href="/uslugi-medyczne/stomatologia/konsultacja-stomatologiczna" target="_blank">konsultacji stomatologicznych</a> w Hot Medi!</p>`,
	},
	{
		slug: "scaling-piaskowanie-lakierowanie-flooryzacja",
		name: "Scaling - piaskowanie - lakierowanie - flooryzacja",
		seoText: `<h2>Scaling - piaskowanie - fluoryzacja zębów</h2>
        <h3>Na czym polega scaling?</h3>
        <p>Skaling jest powszechnie stosowaną metodą usuwania kamienia nazębnego. Kamień odkłada
        się na zębach m.in. w wyniku niedostatecznej higieny, jednak pamiętajmy, że nawet bardzo
        dbając o zęby, również trzeba 1-2 razy do roku skorzystać z zabiegu. Na kamień składają się
        resztki jedzenia, bakterie, oraz niektóre składniki zawarte w ślinie.</p>
        <h3>Piaskowanie zębów - na czym polega?</h3>
        <p>Piaskowanie zębów, tak samo jak skaling, jest to kolejna metoda usuwania złogów kamienia
        nazębnego, tym razem jednak odbywa się to za pomocą wykorzystania specjalnej zawiesiny z
        drobinkami substancji czyszczącej, najczęściej jest to soda oczyszczona.<br/>
        Piaskowanie dzielimy na poddziąsłowe i naddziąsłowe – w pierwszym przypadku używamy
        past o twardszych drobinkach i kończymy polerowaniem zębów, zaś w przypadku piaskowania
        poddziąsłowego stosowane są specjalne szczotki i gumki, dzięki czemu polerowanie zębów
        na koniec zabiegu nie jest już konieczne.</p>
        <h3>Czym jest fluoryzacja zębów?</h3>
        <p>Jest to zabieg polegający na stworzeniu na zębach warstwy ochronnej za pomocą produktów
        bogatych we fluor. Do przeprowadzenia fluoryzacji stosuje się specjalne lakiery, pasty, żele lub
        pianki. Regularna fluoryzacja zębów skutecznie zapobiega rozprzestrzenianiu się próchnicy.
        </p>
        <h2>Scaling, piaskowanie, fluoryzacja zębów - korzyści z zakupienia pakietu
        higienizacyjnego</h2>
        <p>Skaling, piaskowanie i fluoryzacja wykonywane łącznie, są nazywane higienizacją, jakiej warto
        poddać się 1-2 razy do roku. Łączenie tych wszystkich zabiegów przynosi korzyść w postaci
        jednoczesnego pozbycia się kamienia i przebarwień, a także zabezpieczenia zębów przed
        ponownym osadzaniem się kamienia, a także przed rozwojem próchnicy.
        </p>
        <h2>Fluoryzacja, piaskowanie i usuwanie kamienia nazębnego (scaling) - zalecenia</h2>
        <p>Skaling i piaskowanie są zalecane w sytuacjach, gdy na zębach pojawią się twarde, niedające
        się usunąć za pomocą szczoteczki lub nici dentystycznej złogi. Szybsze odkładanie się
        kamienia nazębnego zachodzi również w sytuacji, gdy często spożywasz kawę lub herbatę.</p>
        <p>Z kolei wskazaniem do fluoryzacji będzie wiek poniżej 14 roku życia – do tego czasu trwa
        proces dojrzewania szkliwa. U osób starszych fluoryzacja jest wskazana w przypadku dużych
        skłonności do występowania próchnicy lub w przypadku noszenia
        <a href="/uslugi-medyczne/stomatologia/ortodoncja" target="_blank">aparatu ortodontycznego</a>.</p>
        <h2>Przeciwwskazania do fluoryzacji, piaskowania i scalingu zębów</h2>
        <h3>Skaling - przeciwwskazania:</h3>
        <ul>
            <li>osteoporoza</li>
            <li>świeżo wyrżnięte zęby stałe</li>
            <li>ciąża</li>
            <li>infekcje</li>
            <li>zaburzenia odporności</li>
            <li>cukrzyca</li>
        </ul>
        <h3>Piaskowanie zębów - przeciwwskazania:</h3>
        <ul>
            <li>stany zapalne</li>
            <li>uszkodzenia błony śluzowej</li>
            <li>nieprawidłowe funkcjonowanie płuc</li>
            <li>zaawansowane stadia chorób przyzębia</li>
            <li>wrzody żołądka</li>
        </ul>
        <h3>Fluoryzacja zębów - przeciwwskazania:</h3>
        <ul>
            <li>zatrucie fluorem</li>
            <li>nadwrażliwość lub alergia na stosowaną substancję</li>
            <li>owrzodzenia</li>
            <li>stany zapalne</li>
        </ul>
        <h2>Skaling i piaskowanie zębów – ceny</h2>
        <p>Pakiety higienizacyjne (scaling, piaskowanie, fluoryzacja zębów) razem z instruktażem
        dotyczącym prawidłowego dbania o jamę ustną mieszczą się w granicach 100- 1000 zł.</p>
        <p>Cena za realizację pakietu higienizacyjnego (scalingu, piaskowania, fluoryzacji zębów) zależy
        od danej lokalizacji oraz zakresu usług w danym gabinecie stomatologicznym.</p>
        <h2>Porównanie cen pakietów - scalingu, piaskowania i fluoryzacji zębów w Hot Medi</h2>
        <p>W Hot Medi znajdziesz oferty gabinetów stomatologicznych, świadczących usługi z zakresu
        scalingu, piaskowania, fluoryzacji zębów w wybranej przez Ciebie lokalizacji. W Hot Medi masz
        możliwość porównać w jednym miejscu ceny usług medycznych w Polsce oraz poza
        granicami kraju.</p>
        <p>Zachęcamy również do zapoznania się z 
        <a href="/uslugi-medyczne/stomatologia/leczenie-kanalowe-endodontyczne" target="_blank">cenami leczenia kanałowego</a> oraz 
        <a href="/uslugi-medyczne/stomatologia/wybielanie-zebow" target="_blank">wybielania zębów</a>.</p>`,
	},
	{
		slug: "proteza-akrylowa",
		name: "Proteza akrylowa",
		seoText: `<h2> Zębowe protezy akrylowe - ceny</h2>
      <p>Braki w uzębieniu powodują zarówno dyskomfort, jak i spadek poczucia własnej wartości.
      Ładny, pełny uśmiech wpływa pozytywnie na psychikę osób, które do tej pory nie mogły się nim
      cieszyć. Ruchoma proteza akrylowa to estetyczne rozwiązanie, jeśli dla własnego komfortu
      pragniesz zastąpić brak uzębienia.
      </p>
      <h2> Przezroczysta proteza akrylowa – właściwości</h2>
      <p>Proteza akrylowa to wyjmowane uzupełnienie protetyczne, które umożliwia estetyczne
      odtworzenie braków w uzębieniu w całej jamie ustnej bądź pojedynczych zębów. Proteza
      akrylowa składa się z kilku elementów – bazy akrylowej określanej jako zamiennik tkanki
      kostnej oraz akrylowych koron zębowych.</p>
      <p>Proteza akrylowa to świetne rozwiązanie dla osób, które szukają sposobu na uzupełnienie
      braków w uzębieniu, bez konieczności inwestowania w implanty zębowe. Jest ona przystępna
      cenowo, łatwa w czyszczeniu oraz przechowywaniu. Wiele klinik stomatologicznych,
      specjalizujących się w protetyce, pomaga dobrać odpowiednią protezę do potrzeb swoich
      pacjentów tak, aby efekt był jak najbardziej naturalny, a sama proteza – funkcjonalna.</p>
      <h2>Założenie protezy akrylowej - wskazania</h2>
      <p>Proteza akrylowa to nie tylko sposób na wizualną poprawę swojego uśmiechu. To rozwiązanie
      daje możliwość przywrócenia czynności życia oraz wymowy – zwłaszcza u osób z dużymi
      brakami w uzębieniu. Profesjonalnie wykonana proteza akrylowa ułatwi codzienne
      funkcjonowanie oraz znacznie podniesie komfort życia.
      </p>
      <h2>Protezy akrylowe - rodzaje</h2>
      <ul>
         <li><a href="/uslugi-medyczne/stomatologia/proteza-calkowita" target="_blank">protezę całkowitą</a> – stosowaną w przypadku kompletnych braków w uzębieniu na
         górnej lub dolnej szczęce;</li>
         <li> protezę częściową – wykonywaną w przypadku braków pojedynczych zębów w
         szczęce.</li>
      </ul>
      <h2> Ceny za protezy akrylowe w Polsce i na świecie</h2>
      <p>Cena za wykonanie i założenie jest uzależnione od lokalizacji, wykorzystywanej metody, czy
      zakresu realizowanych usług. Dobry lekarz stomatolog podczas wizyty dokładnie ustali
      rozmiar oraz wygląd akrylowej protezy zębowej. Na tej podstawie estetyczna odbudowa zęba
      lub zębów zostanie wykonana przez protetyka.</p>
      <p>Znalezienie zaufanego specjalisty, który zaoferuje korzystną cenę, nie jest wcale łatwe.
      Dlatego warto spróbować z platformą Hot Medi, na której zebrani są stomatolodzy
      wykonujący protezy akrylowe w całej Polsce</p>
      <h3> Ile kosztuje proteza akrylowa?</h3>
      <p>W zależności od różnych czynników cena za wykonanie protezy akrylowej zaczyna się od 1000
      zł, a może wynieść nawet z ponad 2000 zł za jedną część (dolną lub górną).</p>
      <h2>Platforma Hot Medi – sprawdź ceny protezy akrylowej w całej Polsce</h2>
      <p>Porównywarka cen usług medycznych Hot Medi to miejsce dla osób, którzy szukają usług
      medycznych w korzystnych cenach. Pacjenci stomatologiczni, zdecydowani na wykonanie
      protezy akrylowej, mogą porównać cenę usługi w całej Polsce i wybrać ofertę odpowiadającą
      ich potrzebom oraz możliwościom finansowym.</p>
      <p>Cena protezy akrylowej, pomimo tego że jest stosunkowo niska, może się różnić w zależności
      od miasta czy prestiżu kliniki stomatologicznej. Nie ma jednak co przepłacać, dlatego warto
      przed umówieniem wizyty skorzystać z gotowego rozwiązania, jakie dostarcza nam Hot Medi,
      i porównać cenę tej usługi medycznej. Dzięki temu rozwiązaniu zapoznasz się z cenami
      panującymi na rynku w zakresie protetyki, a także znajdziesz placówkę odpowiadającą Twoim
      potrzebom.</p>
      <p>Zachęcamy również do zapoznania się z <a href="/uslugi-medyczne/stomatologia/proteza-szkieletowa" target="_blank">cenami protez szkieletowych</a> oraz <a href="/uslugi-medyczne/stomatologia/leczenie-kanalowe-endodontyczne" target="_blank">leczenia kanałowego.</a>
      `,
	},
	{
		slug: "proteza-szkieletowa",
		name: " Proteza szkieletowa",
		seoText: `<h2> Ceny protez szkieletowych</h2>
      <p>Istnieje wiele sposobów na uzupełnienie braków zębowych u pacjentów stomatologicznych.
      Osoby, które z różnych przyczyn nie posiadają części zębów w dolnej lub górnej szczęce, mogą
      zdecydować się na protezę szkieletową, będącą doskonałym zamiennikiem bardziej tradycyjnej <a href="/uslugi-medyczne/stomatologia/proteza-akrylowa" target="_blank">protezy akrylowej.</a> Każdy kto zmaga się z brakiem uzębienia, powinien rozważyć protezę szkieletową i porównać ceny jej wykonania na platformie Hot Medi.</p>
      <h2>Zębowa proteza szkieletowa</h2>
      <p>Częściowa lub całkowita utrata zębów spowodowana jest różnymi czynnikami. Zalicza się do
      nich nieleczoną próchnicę zębową, której zaawansowany stan może doprowadzić do
      konieczności usunięcia korzenia zęba. Innym czynnikiem są różnego rodzaju choroby
      przyzębia. Zdarza się jednak, że braki w uzębieniu są wynikiem nieszczęśliwego wypadku.
      Metodą, która umożliwia estetyczne wypełnienie braków zębów w szczęce dolnej oraz górnej,
      jest wstawienie protezy szkieletowej.</p>
      <p>Protezy szkieletowe to typ protezy ruchomej, która w dowolnym momencie może być
      wyjmowana przez pacjentów stomatologicznych.</p>
      <p>Nowoczesne protezy szkieletowe świetnie zastępują zęby, chroniąc jednocześnie kości
      szczęki przed zanikiem. Siła żucia w protezach szkieletowych opiera się na ozębnej, a zatem
      kość w dalszym ciągu odczuwa nacisk.</p>
      <h2>Jakie właściwości mają protezy szkieletowe?</h2>
      <p>Protezy szkieletowe uznawane są jedne z najtrwalszych metod uzupełnienia braków
      zębowych. Wyróżniają się one solidną podbudową wykonaną z metalu, dzięki czemu jest
      odporna na różnego rodzaju uszkodzenia mechaniczne spowodowane nieprawidłowym
      użytkowaniem czy spożywaniem twardego pokarmu. Oprócz warstwy metalowej, montowany
      jest również akryl, na którym montowane są zęby.</p>
      <h2>Protezy szkieletowe - rodzaje</h2>
      <p>W gabinetach stomatologicznych możliwe jest zamontowanie:</p>
      <ul>
      <li>protezy szkieletowej dolnej,</li>
      <li> protezy szkieletowej górnej.</li>
      </ul>
      <h2>Zębowa proteza szkieletowa - cena</h2>
      <p>Czynnikami wpływającymi na cenę protezy szkieletowej jest m.in.:</p>
      <p>-  jej wielkość</p>
      <p>-  rodzaj i jakość materiałów</p>
      <p>-  zakresu realizacji usług</p>
      <p>-  wykorzystywanych metod i udogodnień (tj. zatrzaski)</p>
      <p>Dopasowanie protezy szkieletowej poprzedza cały szereg usług medycznych. W zależności od
      wybranego gabinetu, w ich skład wchodzi między innymi wizyta konsultacyjna, pobranie
      odlewu szczęki, dopasowanie protezy zastępczej i wybór koloru koron zębowych, a następnie
      dopasowanie właściwej protezy szkieletowej.</p>
      <p>Przed całym procesem stomatolog powinien jednak w pierwszej kolejności wyleczyć zęby,
      które tego wymagają.</p>
      <h3> Ile kosztuje proteza szkieletowa?</h3>
      <p>Koszt wykonania protezy szkieletowej jest zależny od wielu czynników. Ceny za realizację
      wahają się od 1000 zł, aż do 3500 zł.</p>
      <h2> Protezy szkieletowe w Hot Medi</h2>
      <p>Protezy szkieletowe pomimo swoich właściwości, nie są tak często wykonywane i
      proponowane przez stomatologów, jak innego typu protezy ruchome. Warto skorzystać z
      możliwości, jakie daje platforma Hot Medi. To właśnie tam znajdziesz gabinety
      stomatologiczne z całej Polski, które oferują właśnie tę usługę medyczną.</p>
      <h2> Protezy szkieletowe – porównaj ceny gabinetów stomatologicznych</h2>
      <p>Platforma Hot Medi oferuje możliwość wyboru usług medycznych w gabinetach prywatnych
      w całej Polsce. Dzięki temu pacjenci mogą znaleźć lekarza, który wykona dla nich między
      innymi protezę szkieletową górną lub dolną w atrakcyjnej cenie. Dzięki platformie Hot Medi
      każdy może znaleźć gabinet stomatologiczny, dopasowany do jego możliwości oraz potrzeb.</p>
      <p>Sprawdź również<a href="/uslugi-medyczne/stomatologia/proteza-calkowita" target="_blank">
      ceny protez całkowitych</a> oraz <a href="/uslugi-medyczne/stomatologia/leczenie-kanalowe-endodontyczne" target="_blank">leczenia kanałowego.</a></p> `,
	},
	{
		slug: "proteza-calkowita",
		name: "Proteza całkowita",
		seoText: `<h2> Zębowe protezy całkowite</h2>
      <p>Proteza całkowita to rodzaj protezy, dzięki której można bez problemu zastąpić brak
      wszystkich zębów w szczęce. Ruchoma proteza, zakładana do bezzębnej szczęki, pełni
      funkcję estetyczną, podnosząc tym samym komfort życia pacjentów stomatologicznych.
      Oprócz tego proteza całkowita ma przede wszystkim działanie rehabilitacyjne. Odpowiednio
      dobrana i wykonana, z wystarczającą retencją, wspomaga odbudowę narządu żucia.</p>
      <p>Profesjonalnie wymierzona proteza do dolnej i górnej szczęki, zmniejsza obciążenie podczas
      żucia na stawie skroniowo–żuchwowym. Dzięki temu pacjenci nie odczuwają dyskomfortu w
      trakcie jedzenia. Z tego też powodu tak istotne jest znalezienie stomatologa specjalizującego
      się w wykonywaniu protez całkowitych.</p>
      <h2> Protezy całkowite na implantach</h2>
      <p>Innym rozwiązaniem są protezy mocowane na implantach. W tym przypadku akrylowa
      powłoka jest montowana do kilku implantów, umieszczonych w kości szczęki górnej lub
      dolnej. Jest to najskuteczniejsza i najtrwalsza metoda uzupełnienia uzębienia u pacjentów.</p>
      <h2> Właściwości protez całkowitych</h2>
      <p>Podstawowym zadaniem protezy zębowej całkowitej jest zastąpienie naturalnych, utraconych
      wcześniej zębów. Aby była trwała, wykonuje się ją z tworzywa sztucznego (akrylu lub metalu),
      stanowiącego jej trzon. Sztuczne zęby natomiast są w całości z akrylu. Takie rozwiązanie w
      leczeniu protetycznym umożliwia swobodne spożywanie posiłków, a także znacznie wpływa
      na lepszą wymowę. Pacjenci, którzy zdecydowali się na protezę całkowitą cieszą się także
      poprawą komfortu życia.</p>
      <p>Proteza całkowita lub proteza całkowita na implantach to rozwiązanie, na które powinien
      zdecydować się każdy pacjent, zmagający się z bezzębiem górnej i dolnej szczęki.</p>
      <h2> Rodzaje protez całkowitych</h2>
      <p> Wśród protez całkowitych możemy wyróżnić:</p>
      <p> - protezy akrylowe</p>
      <p> - protezy szkieletowe</p>
      <p> - protezy nylonowe</p>
      <p> - protezy akronowe</p>
      <p> - protezy acetalowe</p>
      <p> - protezy na implantach</p>
      <h2> Założenie protezy całkowitej - od czego zależy cena?</h2>
      <p>Cena za wykonanie protezy całkowitej jest zależna od wielu czynników tj. lokalizacja,
      wykorzystywany sposób i materiały. Warto zaznaczyć, że cena za protezę całkowitą na
      implantach będzie wyższa w prównaniu do innych metod.</p>
      <p>Leczenie protetyczne całkowitego bezzębia jest skomplikowaną procedurą dla nawet
      najbardziej doświadczonych stomatologów</p>
      <p>Proteza całkowita jest tworzona etapami. W pierwszej kolejności stomatolog przeprowadzi
      dokładną konsultację z pacjentem, a następnie pobierze wycisk szczęki. Na jego podstawie
      stworzona zostanie proteza tymczasowa, a po jej dopasowaniu – proteza właściwa.</p>
      <p>Można również zdecydować się na protezę całkowitą montowana na implantach, której cena
      różni się od protezy ruchomej. Jednak dzięki platformie Hot Medi można znaleźć gabinet
      stomatologiczny, oferujący leczenie protetyczne w przystępnych cenach.</p>
      <h2> Hot Medi – sprawdź cenę protez całkowitych u specjalistów w całej Polsce</h2>
      <p>Hot Medi umożliwia wyszukiwanie usług medycznych i realizujących je gabinetów prywatnych
      w całej Polsce. Platforma pokazuje ceny usług, w tym wykonania protezy całkowitej lub
      protezy całkowitej na implantach, w czasie rzeczywistym. Jeśli zatem szukasz gabinetu
      stomatologicznego w swojej okolicy, a jednocześnie nie chcesz przepłacać, warto zapoznać
      się z ofertami znajdującymi się w naszej porównywarce cen usług medycznych.</p>
      <p>Zachęcamy również do zapoznania się z cenami <a href="/uslugi-medyczne/stomatologia/most-adhezyjny" target="_blank"> mostów adhezyjnych </a> oraz usług w akresie<a href="/uslugi-medyczne/stomatologia/implantologia" target="_blank"> implantologii.</a></p>
      `,
	},
	{
		slug: "leczenie-kanalowe-endodontyczne",
		name: "Leczenie kanałowe (endodontyczne)",
		seoText: `<h2> Leczenie kanałowe ceny</h2>
      <p>Ból zęba nie oznacza konieczności jego natychmiastowego usunięcia. Obecnie w stomatologii
      wykorzystuje się leczenie endodontyczne. Leczenie kanałowe to jednak usługa medyczna, za
      którą przyjdzie trochę zapłacić. Aby znaleźć gabinet stomatologiczny oferujący leczenie
      kanałowe w całej Polsce, warto skorzystać z możliwości jakie daje wyszukiwarka cen usług
      medycznych Hot Medi.</p>
      <h2> Leczenie kanałowe – na czym polega?</h2>
      <p>Leczenie endodontyczne jest przeprowadzane przez stomatologa w momencie, kiedy miazga
      zęba jest nieodwracalnie zniszczona. Oznacza to, że lekarz w pełnym znieczuleniu usuwa
      uszkodzone tkanki i następnie wypełnia kanały korzenia zębowego specjalnym preparatem. W
      efekcie nie trzeba usuwać zęba, który z pozoru jest martwy. Dzięki temu pacjenci po leczeniu
      kanałowym w dalszym ciągu mogą cieszyć się pełnym uśmiechem, bez konieczności
      inwestowania w implant zębowy.</p>
      <h2>Wskazania i przeciwwskazania do leczenia kanałowego</h2>
      <p>Dokuczliwy ból zęba to najczęstsza przyczyna z jaką zgłaszamy się do gabinetu
      stomatologicznego. Ból w większości przypadków oznacza powstanie stanu zapalnego w
      zębie, który wymaga pilnej interwencji specjalisty. Konieczne jest wtedy przeprowadzenie
      leczenia kanałowego, w celu uratowania zęba przed jego usunięciem.</p>
      <p>Wskazaniem do przeprowadzenia leczenia kanałowego jest:</p>
      <ul>
      <li> rozległa próchnica zęba i związany z tym stan zapalny miazgi,</li>
      <li>martwica miazgi zębowej,</li>
      <li>uraz zęba po złamaniu korony,</li>
      <li>nieprawidłowo wykonane leczenie kanałowe,</li>
      <li> próchnica wtórna.</li>
      </ul>
      <p>Niestety, ze względu na specyfikę leczenia kanałowego i trudności związanych z tą dziedziną
      stomatologii, istnieją również przeciwwskazania do jego wykonania. Stomatolog może
      zdecydować o ekstrakcji, jeśli nie można uratować zęba, istnieje szansa, że konieczne będzie
      powtórne leczenie kanałowe, gdy ząb nie jest podparty kością. Przeciwwskazaniem jest również
      niewłaściwa higiena jamy ustnej pacjenta.</p>
      <h2> Leczenie kanałowe zęba – cena</h2>
      <p>Leczenie kanałowe daje możliwość uratowania zębów, które jeszcze do niedawna nadawały
      się tylko do usunięcia. Nic zatem dziwnego, że cena tego typu leczenia jest wysoka i różni się
      w zależności od stopnia zaawansowania, przyczyny, a także rodzaju zęba.</p>
      <p>W jamie ustnej znajdują cztery grupy zębów, zawierające określoną liczbę kanałów. To właśnie
      one w dużej mierze mogą zaważyć na ostatecznej cenie usługi. Cztery kanały i więcej są
      najczęściej leczone pod mikroskopem, co również wpływa na wzrost kosztów. Zatem cena
      leczenia uzależniona jest od kilku czynników.</p>
      <h2> Leczenie endodontyczne (kanałowe) w wyszukiwarce cen usług medycznych
      Hot Medi</h2>
      <p>Wysokie koszty związane z leczeniem kanałowym można zrównoważyć, poprzez znalezienie
      gabinetu stomatologicznego oferującego przystępną cenę leczenia kanałowego w Twojej
      okolicy. Takie oferty można wyszukać za pomocą platformy i dedykowanej aplikacji Hot Medi.
      Celem naszej platformy jest dostarczenie jak największej liczby ofert w całej Polsce, tak, aby
      każdy znalazł rozwiązanie dopasowane do swoich możliwości finansowych.</p>
      <p>Zachęcamy do zapoznania się z <a href="/uslugi-medyczne/stomatologia/scaling-usuwanie-kamienia" target="_blank"> cenami usuwania kamienia nazębnego</a> oraz <a href="/uslugi-medyczne/stomatologia/wybielanie-zebow" target="_blank"> wybielania zębów.</a></p>
      `,
	},
	{
		slug: "scaling-usuwanie-kamienia",
		name: " Usuwanie kamienia nazębnego (scaling)",
		seoText: `<h2> Ceny za usuwanie kamienia z zębów</h2>
      <p>Odpowiednia higiena jamy ustnej jest niezwykle istotna, gdyż zapobiega rozwojowi chorób
      przyzębia i próchnicy. Ważne jest, aby oprócz domowej pielęgnacji jamy ustnej, zgłosić się do
      gabinetu stomatologicznego na zabieg higienizacji. Zabieg usuwania kamienia z zębów w
      cenach dostosowanych do możliwości finansowych można znaleźć dzięki platformie Hot
      Medi.</p>
      <h2> Scaling jamy ustnej – na czym polega?</h2>
      <p>Scaling to zabieg higienizacji jamy ustnej, który polega na usunięciu z płytki kamienia
      nazębnego. Wielu pacjentów traktuje zabieg jako element pielęgnacyjny jamy ustnej, dbając
      tym samym o ładny i biały uśmiech. W praktyce jednak scaling umożliwia dokładne
      wyczyszczenie resztek pokarmowych, bakterii czy śliny, odkładających się na powierzchni
      zębów.</p>
      <p>Kamień nazębny, będący efektem niewłaściwej higieny jamy ustnej, prowadzi do powstania
      stanów zapalnych, a w zaawansowanych przypadkach – do paradontozy.</p>
      <p>Do przeprowadzenia zabiegu wykorzystuje się skaler ultradźwiękowy i narzędzia
      stomatologiczne. Scaling jest bezbolesny, ale u niektórych pacjentów może być on na tyle
      niekomfortowy, iż wykonuje się go w znieczuleniu miejscowym.</p>
      <h2> Jakie są wskazania do usunięcia kamienia nazębnego?</h2>
      <p>Scaling to przede wszystkim zabieg higienizacyjny, który powinien być u każdego pacjenta
      przeprowadzany minimum raz do roku. Wskazaniem do jego wykonania jest przede wszystkim
      duża ilość nagromadzonego kamienia nazębnego. Zaniedbanie tej kwestii prowadzi do
      pojawienia się stanów zapalnych oraz krwawienia dziąseł czy nawet do odsłonięcia szyjek
      zębowych i pojawienia się nadwrażliwości.</p>
      <p>Pomimo tego, że zabieg usuwania kamienia nazębnego nie jest inwazyjny, specjalista przed
      jego rozpoczęciem przeprowadza dodatkowy wywiad. Jeśli wystąpią przeciwwskazania, na
      przykład konieczność wyleczenia stanu zapalnego antybiotykiem, zabieg nie może zostać
      wykonany.</p>
      <p>Zaletą scalingu jest również delikatne rozjaśnienie zębów, dzięki czemu są one bielsze. Jeśli
      zmagasz się z nawracającym kamieniem na zębach, które nadaje im żółtawy odcień, warto
      zdecydować się na scaling zębów w gabinecie stomatologicznym.</p>
      <h2> Usuwanie kamienia z zębów – cena usługi medycznej</h2>
      <p>Cena usuwania kamienia nazębnego jest różna. W zależności od gabinetu stomatologicznego
      wysokość usługi może wahać się pomiędzy 100 a nawet 500 zł. Wszystko jest uzależnione od
      ilości nagromadzonego kamienia, a także możliwości skorzystania z pakietów
      higienizacyjnych obejmujących <a href="/uslugi-medyczne/stomatologia/scaling-piaskowanie-lakierowanie-flooryzacja" target="_blank"> scaling,
      piaskowanie i fluoryzację zębów.</a></p>
      <p>Na ostateczną cenę usługi usunięcia kamienia nazębnego, może również mieć wpływ
      lokalizacja, w której znajduje się gabinet stomatologicznej. Warto zatem skorzystać z gotowej
      wyszukiwarki usług medycznych w całej Polsce i porównać ceny.</p>
      <h2>Zabieg usuwania kamienia nazębnego w porównywarce cen usług medycznych
      Hot Medi</h2>
      <p>Platforma Hot Medi to zbiór certyfikowanych, prywatnych placówek medycznych z całej Polski.
      Jeśli zatem chcesz zadbać o higienę jamy ustnej, ale jednocześnie nie chcesz przepłacać za
      usługi medyczne – warto sprawdzić i porównać ceny gabinetów lekarskich i
      stomatologicznych. Hot Medi wychodzi naprzeciw potrzebom pacjentów i podaje ceny
      konkretnej usługi wraz z adresem oraz możliwością sprawdzenia opinii.</p>
      <p>Zachęcamy również do sprawdzenia <a href="/uslugi-medyczne/stomatologia/leczenie-kanalowe-endodontyczne" target="_blank"> cen leczenia kanałowego</a> oraz <a href="/uslugi-medyczne/stomatologia/wybielanie-zebow" target="_blank">wybielania zębów.</a></p>
      `,
	},
	{
		slug: "korona-porcelanowa-na-metalu",
		name: "Korona porcelanowa (na metalu)",
		seoText: `<h2> Korony porcelanowe - ceny</h2>
      <p>Korony zębowe porcelanowe to jedno z najpopularniejszych stałych uzupełnień protetycznych,
      całkowicie zakrywające uszkodzony ząb. Wyjątkowe właściwości tego materiału zapewniają
      koronie nawet kilkudziesięcioletnią wytrzymałość oraz pod wieloma względami upodobniają je
      do prawdziwych zębów.</p>
      <p>Korony porcelanowe to niezawodne rozwiązanie dla pacjentów o słabych lub martwych
      zębach, zniszczonych leczeniem kanałowym oraz mocno przebarwionych, którym dodatkowo
      zależy na naturalnej estetyce. Prawidłowo wykonane uzupełnienie ochrania zęby i wzmacnia
      je, jednocześnie poprawiając wygląd uzębienia.</p>
      <h2>Od czego zależą ceny za korony porcelanowe?</h2>
      <p>Koszt za koronę porcelanową jest zależny od zaawansowania uszkodzenia zębów. Cena za
      wykonanie korony porcelanowej wynosi więc od kilkuset do kilku tysięcy złotych i różni się w
      zależności od wybranej placówki medycznej, a także miejscowości, w jakiej się ona znajduje.
      Na ostateczną kwotę wpływ mają także rodzaj materiału oraz technologie wykorzystane do
      produkcji uzupełnienia protetycznego.</p>
      <p>Dodatkowo bardzo często pacjenci muszą przejść przez szereg badań, zanim założone
      zostaną im korony zębowe porcelanowe. Ceny usług związanych z wykonaniem tego
      uzupełnienia często zależą więc również od ilości wymaganych konsultacji i wizyt lekarskich,
      niezbędnych do przeprowadzenia prawidłowego zabiegu.</p>
      <h2>Korony porcelanowe - zalety</h2>
      <p>Korony wykonane z porcelany nie bez powodu cieszą się tak dużą popularnością wśród
      pacjentów. Tym, co wyróżnia je spośród innych stałych uzupełnień protetycznych, są ich
      wysokie walory estetyczne. Odpowiednio pielęgnowane porcelanowe korony nie przebarwiają
      się, a dzięki właściwie dobranym kolorom, nie odznaczają się także od prawdziwych zębów.</p>
      <p>Warto pamiętać, że korona, której podbudowa została wykonana ze stopów metali, będzie o
      wiele tańsza, niż np. korona na cyrkonie. Przed podjęciem decyzji klient powinien więc
      zapoznać się ze szczegółową ofertą gabinetów stomatologicznych i wybrać najlepszą dla
      niego metodę uzupełnienia protetycznego.</p>
      <h2> Ceny za założenie korony porcelanowej - jak znaleźć odpowiednią placówkę?</h2>
      <p>Ze względu na różnorodność ofert oraz mnogość placówek wykonujących usługi z zakresu
      uzupełnienia protetycznego, wybór właściwego specjalisty w odpowiedniej cenie bywa
      niezwykle trudny. Z pomocą przychodzi wówczas platforma Hot Medi, gdzie pacjenci w prosty
      i szybki sposób mogą m.in:</p>
      <ul>
      <li>porównać cenniki różnych placówek,</li>
      <li>poznać ich lokalizacje,</li>
      <li>zapoznać się ze szczegółową ofertą.</li>
      </ul>
      <p>Za pomocą<a href= "/" target="_blank"> wyszukiwarki usług medycznych Hot Medi</a>
      każdy użytkownik może zaoszczędzić czas, a także pieniądze, ponieważ ma szansę zdecydować się na wizytę w miejscu, które
      gwarantuje dogodne dla niego warunki oraz atrakcyjną cenę.</p> 
      <h2>Ceny za koronę metalowo-porcelanową w Hot Medi</h2>
      <p>Wszyscy partnerzy współpracujący z platformą Hot Medi to znakomici specjaliści oferujący<a href="/uslugi-medyczne/stomatologia/korony"target="_blank"> korony zębowe</a> w całej Polsce. Bez względu na to, jaka metoda uzupełnienia interesuje
      pacjenta – korona metalowo porcelanowa czy wykonana na cyrkonie – może on wybierać
      spośród setek ofert gabinetów zlokalizowanych w mniejszych i większych miastach.</p>
      <p>Zobacz także ceny za<a href="/uslugi-medyczne/stomatologia/korona-pelnoceramiczna" target="_blank"> korony pełnoceramiczne</a> oraz<a href="/uslugi-medyczne/stomatologia/korona-cyrkonowa" target="_blank"> korony na cyrkonie.</a></p> 
      `,
	},
	{
		// title: "Korony zębowe - ceny | Hot Medi",
		// description:
		// 	" Zapoznaj się z cenami koron zębowych." +
		// 	" W Hot Medi pomożemy Ci znaleźć ofertę dopasowaną do indywidualnych potrzeb w Twojej okolicy! Zapraszamy!",
        slug: "korony",
		name: "Korony zębowe",
		seoText: `<h2> Ceny koron zębowych</h2>
      <p>Korony na zęby to jedno ze stałych uzupełnień protetycznych, które mocowane są do zębów, w
      celu zakrycia uszkodzenia. W zależności od tego, z jakiego materiału wykonana zostanie
      korona, cena całej usługi może wynosić kilkaset lub kilka tysięcy złotych. Pacjent może
      zdecydować się na:</p>
      <ul>
      <li><a href="/uslugi-medyczne/stomatologia/korona-porcelanowa-na-metalu" target="_blank"> koronę porcelanową,</a></li>
      <li><a href="/uslugi-medyczne/stomatologia/korona-pelnoceramiczna" target="_blank"> koronę pełnoceramiczną,</a></li>
      <li><a href="/uslugi-medyczne/stomatologia/korona-metalowa" target="_blank"> koronę metalową,</a></li>
      <li><a href="/uslugi-medyczne/stomatologia/korona-kompozytowa" target="_blank"> koronę kompozytową,</a></li>
      <li>koronę akrylową.</li>
      </ul>
      <p>Korony wykonane z kompozytu oraz akrylu stosowane są zwykle w celu tymczasowego
      uzupełnienia, jednak w przypadku pacjentów mających przeciwwskazania do założenia korony
      porcelanowej, mogą zostać wykorzystane również jako stałe uzupełnienia protetyczne.</p>
      <h2>Korony na zęby - wskazania</h2>
      <p>Wstawienie korony zębowej zaleca się pacjentom, których zęby wymagają rekonstrukcji po
      leczeniu kanałowym. Ten środek zabezpieczenia stosuje się również w celu ochrony zębów
      uszkodzonych oraz pokrycia implantu lub zniekształconego zęba.</p>
      <p>Specjalista stomatolog, po odpowiednim wywiadzie i zbadaniu stanu pacjenta, podejmuje
      decyzję o konieczności założenia korony na zęby. Cena takiego uzupełnienia jest zawsze
      adekwatna do rodzaju uszkodzenia oraz jego wielkości. Istnieją jednak pewne
      przeciwwskazania przed założeniem korony zębowej, takie jak silna próchnica czy zbyt małe
      pozostałości zęba. Wówczas lekarz podejmuje leczenie, mające na celu umożliwienie
      pacjentom założenie stałego uzupełniania.</p>
      <h2> Założenie koron na zęby - korzyści</h2>
      <p>Korona zębowa pełni głównie funkcję ochraniającą i wzmacniającą, jednak prawidłowo
      dopasowane uzupełnienie daje również gwarancję właściwego i bezproblemowego działania
      aparatu żucia. Odpowiednio wykonana korona, odtwarzająca zgryz pacjenta to także niezwykle
      estetyczne rozwiązanie, które pozwala cieszyć się życiem bez żadnych ograniczeń.</p>
      <p>Uzupełnienie protetyczne to idealne wyjście dla pacjentów, mających pozbawione własnej
      korony zęby. Cena wykonania takiego zabiegu zależna jest od lokalizacji danego gabinetu
      stomatologicznego, ważne więc by przed podjęciem decyzji wybrać najodpowiedniejszą dla
      nas placówkę.</p>
      <h2>Wstawienie korony zębowej – cena w poszczególnych placówkach</h2>
      <p>Cena koron zębowych różni się nie tylko w zależności od tego, w jakim miejscu pacjent chce
      wykonać zabieg. Placówki niekiedy również zmieniają cennik swoich usług lub oferują korzystne promocje. Aby z łatwością znaleźć odpowiedniego specjalistę, warto skorzystać zaplikacji Hot Medi, która pozwala w wygodny sposób porównać cenniki usług poszczególnych placówek medycznych, a także wybrać najdogodniejsze miejsce, w którym zostanie wykonany
      zabieg.</p>
      <h2>Porównaj ceny założenia koron na zęby w Hot Medi</h2>
      <p>Na<a href="/" target="_blank"> porównywarce cen pakietów i usług medycznych Hot Medi</a>
      pacjenci mają możliwość
      śledzenia ceny, skorzystania ze specjalnych promocji, a także sortowania usług konkretnych
      placówek, które oferują założenie korony na zęba. Cena każdego zabiegu może w niektórych
      przypadkach zostać ustalona indywidualnie lub ulec nagłej zmianie. Używając profesjonalnej
      aplikacji, pacjenci mają szansę poznać wszelkie potrzebne szczegóły dotyczące wybranego
      zabiegu na terenie całej Polski oraz wybrać usługę w najatrakcyjniejszej dla nich cenie.</p>
      <p>Sprawdź także<a href="/uslugi-medyczne/stomatologia/proteza-szkieletowa"target="_blank"> ceny za protezy szkieletowe</a> oraz<a href="/uslugi-medyczne/stomatologia/leczenie-kanalowe-endodontyczne"target="_blank"> leczenie kanałowe.</a></p>
      `,
	},
	{
		slug: "korona-cyrkonowa",
		name: "Korona cyrkonowa",
		seoText: `<h2>Zębowe korony na cyrkonie - ceny</h2>
      <p>Korony cyrkonowe wykonane są z tlenku cyrkonu, czyli krystalicznego i białego materiału, który
      wyglądem przypomina prawdziwe zęby. To jedna z metod stałego uzupełniania protetycznego,
      stosowana przez pacjentów, których uzębienie uległo zniszczeniu lub nieodwracalnemu
      przebarwieniu. Korona całkowicie przykrywa zęby, jednocześnie wzmacnia je i chroni.</p>
      <p>Ze względu na ilość pracy oraz przygotowania, których wymaga od specjalistów odbudowa
      korony zęba, cena takiej usługi bywa bardzo wysoka. Na ostateczną kwotę składa się zarówno
      cena materiału, jak i wielkość uszkodzenia zęba, dodatkowo obejmuje również wszelkie
      czynności, które podczas zabiegu wykonuje lekarz. Mimo, że jest to kosztowne rozwiązanie,
      okazuje się także niezwykle wytrzymałe oraz trwałe i gwarantuje pacjentom codzienną
      wygodę.</p>
      <p>Zobacz także:<a href="/uslugi-medyczne/stomatologia/korona-porcelanowa-na-metalu"target="_blank"> korony porcelanowe</a></p>
      <h2> Założenie korony porcelanowej - jak wygląda proces?</h2>
      <p>Przed podjęciem decyzji o zastosowaniu korony cyrkonowej na zęby, lekarz musi wykluczyć
      wszelkie przeciwwskazania oraz upewnić się, że będzie to najlepsze rozwiązanie dla danego
      pacjenta. Zanim zostanie wykonany zabieg nałożenia korony na cyrkonie, lekarz odpowiednio
      przygotowuje zęby i szlifuje je.</p>
      <p>Uzupełnienie tworzone jest według odtworzonego wcześniej wycisku, a więc idealnie
      dopasowuje się do uzębienia pacjenta. Po ostatecznym przytwierdzeniu odbudowy konieczne
      są właściwa higiena jamy ustnej oraz wizyty kontrolne, podczas których lekarz upewni się, że
      korona na zęby spełnia swoje zadanie i jest szczelna.</p>
      <h2>Cena za założenie korony na cyrkonie - od czego zależy?</h2>
      <p>Ze względu na wysoką wytrzymałość tworzywa oraz estetyczny wygląd, coraz więcej
      pacjentów decyduje się na korony cyrkonowe. Ceny tego uzupełnienia wahają się od 1000 do
      2500 zł, w zależności od placówki, w której wykonywany jest zabieg. Metoda ta jest zwykle
      droższa od pozostałych, jednak jest warta swojej ceny.</p>
      <p>Ogromną zaletą koron cyrkonowych jest ich nieskazitelny wygląd, wysoka odporność
      chemiczna oraz biozgodność, dzięki której<a href="/uslugi-medyczne/stomatologia/korony" target="_blank"> korona zębowa</a> nie wywołuje w organizmie
      człowieka żadnej reakcji alergicznej. Korona na cyrkonie z wyglądu jest niemal taka sama, jak
      naturalne zęby, dlatego to rozwiązanie pozostaje jednym z najbardziej pożądanych uzupełnień protetycznych.</p>
      <p>Cena za założenie korony na cyrkonie może wahać się pomiędzy 200, a nawet 4 000 zł.</p>
      <h2>Porównaj ceny koron porcelanowych na cyrkonie na Hot Medi</h2>
      <p>W Hot Medi pacjenci mogą znaleźć wszystkie współpracujące z nami placówki medyczne z
      całej Polski, które oferują usługę zakładania korony na cyrkonie. Cena takiego zabiegu różni
      się w zależności od miejsca oraz indywidualnej oferty konkretnego gabinetu
      stomatologicznego.</p>
      <p>Dzięki<a href="/" target="_blank"> wyszukiwarce usług medycznych Hot Medi</a>  pacjenci mogą wybrać placówkę, która spełnia ich wszystkie wymagania i znajduje się w dogodnym dla nich miejscu. Platforma
      pozwala porównać ceny usług i znaleźć specjalistę, który wykona zabieg na najbardziej
      atrakcyjnych dla nas warunkach. Dodatkowo przedstawiane na niej szczegółowe opisy każdego zabiegu oraz dokładne cenniki są zawsze aktualne.</p>
      <p>Poznaj również<a href="/uslugi-medyczne/stomatologia/korona-pelnoceramiczna"target="_blank"> ceny koron pełnoceramicznych</a> oraz<a href="/uslugi-medyczne/stomatologia/korona-na-implancie" target="_blank"> koron na implantach</a>.</p>
      `,
	},
	{
		slug: "korona-pelnoceramiczna",
		name: "Korona pełnoceramiczna",
		seoText: `<h2> Korony pełnoceramiczne ceny</h2>
      <p>Korona wykonana z czystej ceramiki to jedna z najczęściej wybieranych metod uzupełnienia
      protetycznego w odcinku przednim zębów. Dzięki swoim właściwościom korony
      pełnoceramiczne są wyjątkowo funkcjonalne i pozytywnie wpływają na komfort życia
      pacjenta. Dodatkowo uzupełnienie wytworzone z tak odpornego materiału wykazuje cechy
      charakterystyczne dla naturalnych zębów – korony pełnoceramiczne przepuszczają i odbijają
      światło, dając niezwykle estetyczny efekt.</p>
      <p>Stałe uzupełnienia protetyczne wiążą się zawsze z wysokimi kosztami oraz wieloma wizytami
      u specjalisty, jednak korzyści płynące z takiego rozwiązania zwykle przewyższają wszelkie
      niedogodności. Korony pełnoceramiczne to nie tylko piękny wygląd, ale i ochrona dla
      zniszczonych zębów, która ułatwia pacjentom codzienne funkcjonowanie.</p>
      <h2> Założenie koron pełnoceramicznych - wskazania</h2>
      <p>Pacjenci stosują korony pełnoceramiczne do odbudowy zębów po nieszczęśliwych wypadkach
      czy leczeniu kanałowym, a także w przypadku wystąpienia próchnicy, niszczącej strukturę
      zęba. Ten rodzaj uzupełnienia rzadko wykorzystywany jest w przypadku odcinków bocznych
      szczęki, natomiast doskonale sprawdza się na zębach przednich. Dzięki prawidłowo
      dopasowanej koronie, wykonanej z odpowiedniego materiału, uśmiech pacjenta staje się
      codziennością, a ochraniane zęby odzyskują dawną funkcjonalność.</p>
      <h2>Ceny za założenie koron pełnoceramicznych</h2>
      <p>Względy praktyczne oraz estetyczne to główne powody, dla których pacjenci wybierają korony
      pełnoceramiczne. Cena uzupełnień wykonanych z ceramiki to ich dodatkowa zaleta – są one
      nieco droższe od koron z podbudową z metalu, jednak pozostają tańsze od tych wykonanych z
      cyrkonu. Koszty całego zabiegu zależą również od:</p>
      <ul>
      <li>wielkości zęba,</li>
      <li>wielkości ubytku, który należy zakryć,</li>
      <li>ilości zniszczonych zębów.</li>
      </ul>
      <p>Kwota, jaką należy przeznaczyć na zabieg, może wynosić więc kilkaset, a nawet 5 000 złotych.
      Warto pamiętać, że w całej Polsce istnieje wielu specjalistów oraz gabinetów medycznych, w
      których zakładane są korony ceramiczne. Ceny tych uzupełnień będą zatem różnić się także w
      zależności od rodzaju i lokalizacji danej placówki medycznej.</p>
      <p>Zanim pacjent zdecyduje się na konkretny rodzaj <a href="/uslugi-medyczne/stomatologia/korony" target="_blank"> korony zębowej</a> oraz właściwy gabinet
      stomatologiczny, powinien więc zapoznać się z ofertą różnych specjalistów i wybrać tę, która okaże się dla niego najlepsza.</p>
      <p>Sprawdź również:<a href="/uslugi-medyczne/stomatologia/korona-porcelanowa-na-metalu" target="_blank"> korony porcelanowe</a>,<a href="/uslugi-medyczne/stomatologia/korona-kompozytowa" target="_blank"> korony kompozytowe</a> oraz<a href="/uslugi-medyczne/stomatologia/korona-na-implancie"target="_blank"> korony na implantach</a>.</p>
      <h2>Porównaj ceny ceramiczne i pełnoceramiczne w Hot Medi</h2>
      <p>Ze względu na coraz większą popularność zakładania uzupełnień protetycznych w postaci
      koron, zabiegi te stały się dostępne w większości gabinetów w wielu miastach. Jednak
      znalezienie specjalisty, który spełni wszystkie oczekiwania pacjenta i zagwarantuje usługę w
      przystępnej cenie, bywa niezwykle trudne.</p>
      <p>Z pomocą przychodzi więc platforma Hot Medi stworzona z myślą o pacjentach, którzy pragną
      skorzystać z konkretnych usług medycznych. Platforma udostępnia lokalizację placówek
      medycznych, a także ich cenniki, dzięki czemu klient z łatwością może porównać koszt
      wykonania zabiegu zakładania koron pełnoceramicznych w różnych miejscach i wybrać ofertę,
      która najbardziej mu odpowiada.</p>
      <p>Sprawdź również<a href="/uslugi-medyczne/stomatologia/leczenie-kanalowe-endodontyczne" target="_blank"> ceny leczenia kanałowego</a> oraz założenia<a href="/uslugi-medyczne/stomatologia/protezy" target="_blank"> protez zębowych</a>.</p>
      `,
	},
	{
		slug: "korona-kompozytowa",
		name: "Korona kompozytowa",
		seoText: `<h2>Ceny koron kompozytowych</h2>
      <p>Jednym z często wybieranych uzupełnień protetycznych są korony kompozytowe, które
      stosuje się zwykle jako odbudowę tymczasową. Wykorzystywana jest więc przez pacjentów,
      których zęby potrzebują ochrony protetycznej, jednak nadal wymagają innych zabiegów,
      zmieniających ich kształt czy układ, np. podczas leczenia ortodontycznego czy
      implantologicznego.</p>
      <p>Ze względu na to, że korony kompozytowe stosowane są tylko przez określony czas, ich
      trwałość jest znacznie niższa od pozostałych, stałych uzupełnień. Ich zaletą jest natomiast o
      wiele łatwiejsze mocowanie oraz stosunkowo prosta naprawa, w razie jakichkolwiek
      uszkodzeń. Wówczas specjalista usuwa jedynie wadliwy element, nie wymieniając całej
      korony, co może zdarzyć się w przypadku<a href="/uslugi-medyczne/stomatologia/korona-pelnoceramiczna" target="_blank"> koron ceramicznych</a> czy<a href="/uslugi-medyczne/stomatologia/korona-porcelanowa-na-metalu" target="_blank"> porcelanowych</a>.</p>
      <h2> Właściwości koron kompozytowych</h2>
      <p>Właściwością kompozytu jest również jego jednolity kolor, który różni się nieco od naturalnych
      zębów. W związku z tym, korony wykonane z tego materiału nieznacznie odznaczają się od
      reszty uzębienia.</p>
      <h2> Dla kogo przeznaczone są korony kompozytowe?</h2>
      <p><a href="/uslugi-medyczne/stomatologia/korony" target="_blank"> Korony zębowe </a> również te wytworzone z kompozytu, stosowane są w celu odbudowy zębów
      uszkodzonych w wyniku urazu mechanicznego, leczenia kanałowego czy próchnicy. Dzięki
      temu, że korony tworzone są indywidualnie dla każdego pacjenta, poprawiają funkcjonalność
      chorych zębów i ułatwiają codzienne przeżuwanie.</p>
      <p>Jako tymczasowe uzupełnienie protetyczne korony kompozytowe przeznaczone są dla
      pacjentów, których zęby są w trakcie leczenia, ale jednocześnie wymagają dodatkowej ochrony
      i wzmocnienia przez koronę. To rozwiązanie okazuje się także doskonałą alternatywą dla osób
      uczulonych na jakikolwiek składnik uzupełnień wykonanych z pozostałych materiałów oraz dla
      tych, którym zależy na stosunkowo tanim wypełnieniu protetycznym.</p>
      <h2>Ceny koron kompozytowych - od czego zależą ceny?</h2>
      <p>Pacjenci, którzy pragną odbudować swoje zęby w łatwy i szybki sposób, coraz częściej
      decydują się na korony kompozytowe. Cena takiego rozwiązania to kolejny powód ich rosnącej
      popularności – w odróżnieniu od pozostałych form stałych uzupełnień, za koronę wykonaną z
      kompozytu pacjent musi zapłacić jedynie kilkaset złotych.</p>
      <p>Całkowity koszt założenia tego uzupełnienie protetycznego zależy także od ilości
      zniszczonych zębów oraz wielkości uszkodzeń, które należy pokryć. Dodatkowo kwota ta
      może różnić się w zależności od aktualnych cenników danej placówki medycznej, renomy
      pracujących w niej stomatologów oraz jej lokalizacji.</p>
      <h2>Porównaj ceny koron kompozytowych w Hot Medi</h2>
      <p>Decydując się na odbudowę swoich zębów za pomocą korony kompozytowej, pacjenci
      powinni najpierw zapoznać się z ofertą różnych gabinetów stomatologicznych. Dzięki<a href="/" target="_blank"> porównywarce cen pakietów i usług medycznych Hot Medi</a> staje się to jeszcze szybsze i
      łatwiejsze. Dzięki wyszukiwarce placówek medycznych, klienci korzystający z aplikacji mogą
      znaleźć odpowiednich specjalistów, którzy wykonają zabieg założenia korony zębowej w
      dogodnej dla pacjenta lokalizacji oraz w atrakcyjnej dla niego cenie.</p>
      <p>Sprawdź rówież<a href="/uslugi-medyczne/stomatologia/leczenie-kanalowe-endodontyczne"target="_blank"> ceny leczenia endodontycznego</a>, założenia<a href="/uslugi-medyczne/stomatologia/protetyka-stomatologiczna/protezy"target="_blank"> protez zębowych</a> oraz<a href="/uslugi-medyczne/stomatologia/aparat-staly-metalowy-czesciowy"target="_blank"> aparatów ortodontycznych</a>.</p>
      `,
	},
	{
		slug: "korona-na-implancie",
		name: "Korona na implancie",
		seoText: `<h2>Ceny koron na implantach</h2>
      <p>Korony na implantach to niezawodne rozwiązanie, umożliwiające uzupełnienie braku
      pojedynczych zębów. Stanowią alternatywę dla mostów protetycznych, która pozwala cieszyć
      się pięknym i zdrowym uśmiechem bez żadnych ubytków. Wszczepienie implantów jest
      całkowicie bezpieczne dla pozostałych zębów, ponieważ nie wymaga ich szlifowania. Zabieg
      wykonuje się w znieczuleniu miejscowym, a więc cały jego przebieg jest zupełnie bezbolesny
      dla pacjenta.</p>
      <p>Implanty, wraz z przykręcanymi do nich koronami, to niezwykle trwały sposób na pozbycie się
      ubytków w zębach. Takie uzupełnienie protetyczne nie jest odczuwalne podczas spożywania
      posiłków i ułatwia codzienne funkcjonowanie. Ważną zaletą koron na implantach jest także ich
      estetyczny wygląd, możliwy do uzyskania dzięki odpowiednio dobranym materiałom, których
      odcień przypomina naturalne zęby.</p>
      <h2>Korony na implantach - wskazania</h2>
      <p>Uzupełnienie protetyczne przeznaczone jest dla pacjentów, którzy utracili ząb lub jego część w
      wyniku wypadku, uszkodzenia czy choroby. Korony na implantach najlepiej sprawdzają się w
      przypadku ochrony pojedynczych zębów. Podczas zabiegu stomatolog umieszcza w tkance
      kostnej implanty, następnie pobiera wycisk pod przyszłą koronę, która w końcu zostaje
      osadzona na implancie. Korony na implantach to znakomite rozwiązanie dla wszystkich, którzy
      pragną przywrócić swój piękny uśmiech.</p>
      <h2>Korony na implantach –od czego zależy cena?</h2>
      <p>Mocowanie korony na implantach to zwykle spory wydatek, jednak całkowicie wart swojej
      ceny. Koszty całego zabiegu w gabinetach stomatologicznych w całej Polsce są bardzo
      zróżnicowane, ponieważ na całość kwoty składają się:</p>
      <ul>
      <li>wielkość zęba,</li>
      <li>poziom jego zniszczenia,</li>
      <li>technologie wykorzystane do zabiegu,</li>
      <li>stosowany materiał.</li>
      </ul>
      <p>W zależności od wybranej placówki, jej lokalizacji oraz renomy pacjent może zapłacić za
      korony na implantach od kilku do nawet 5 600 zł.</p>
      <p>W wyniku niewłaściwej higieny lub innych niespodziewanych wypadków<a href="/uslugi-medyczne/stomatologia/korony"target="_blank"> korona zębowa</a> może
      z czasem ulec uszkodzeniu. Wówczas możliwa jest również wymiana samej korony na
      implancie. Cena takiej usługi zależna jest głównie od materiału, jaki zostanie wybrany do korony oraz jakości danego gabinetu stomatologicznego.</p>
      <p>Sprawdź również<a href="/uslugi-medyczne/stomatologia/korona-porcelanowa-na-metalu" target="_blank"> korony porcelanowe</a>,<a href="/uslugi-medyczne/stomatologia/korona-pelnoceramiczna" target="_blank"> korony pełnoceramiczne</a> oraz<a href="/uslugi-medyczne/stomatologia/korona-kompozytowa"target="_blank"> korony kompozytowe</a>.</p>
      <h2>Porównaj ceny założenia i wymiany koron na implantach w Hot Medi</h2>
      <p>Decyzja o wykonaniu korony na implantach oraz wybór odpowiedniej placówki do wykonania
      takiego zabiegu, zwykle wymaga od pacjenta wielu godzin poszukiwań. W celu ułatwienia pacjentom tego etapu, poprzedzającego właściwe leczenie, stworzona została<a href="/" target="_blank"> porównywarka cen usług
      medycznych Hot Medi</a>. Dzięki niej klienci mogą uzyskać wszystkie potrzebne
      informacje na temat danej usługi czy gabinetu oraz porównać cenniki różnych placówek.</p>
      <p>Pacjent nie musi już dłużej wybierać specjalisty, który jest najbliżej jego miejsca zamieszkania
      lub oferuje mało przystępne ceny. Platforma umożliwia wyszukanie gabinetów medycznych w
      całej Polsce, w wielu mniejszych i większych miastach, ułatwiając pacjentom wybór
      najkorzystniejszej oferty.</p>
      <p>Zapoznaj się również z<a href="/uslugi-medyczne/stomatologia/leczenie-kanalowe-endodontyczne" target="_blank"> cenami leczenia kanałowego</a>,a także<a href="/uslugi-medyczne/stomatologia/protezy" target="_blank"> protezami zębowymi</a>.</p>
      `
	},
{slug:"wklad-koronowo-korzeniowy-osiowy-pod-proteze", name:"Wkład koronowo-korzeniowy osiowy pod protezę"},
{slug:"kiretaz-zamkniety-znieczuleniem-aplikacja-leku-do-8-zebow", name:"Kiretaż zamknięty + znieczuleniem + aplikacja leku - do 8 zebów"},
{slug:"kiretaz-otwarty-1-zab", name:"Kiretaż otwarty - 1 ząb"},
{slug:"badanie-stomatologiczne-z-instruktazem-higieny-jamy-ustnej", name:"Badanie stomatologiczne z instruktażem higieny jamy ustnej"},
{slug:"koktail-witaminowy", name:"Koktail witaminowy"},
{slug:"kiretaz-zamkniety-znieczuleniem-aplikacja-leku-1-zab", name:"Kiretaż zamknięty + znieczuleniem + aplikacja leku - 1 ząb"},
{slug:"hemisekcja-korzenia-odseparowanie", name:"Hemisekcja korzenia (odseparowanie)"},
{slug:"kolejna-kontrola-po-zabiegu-w-narkozie", name:"Kolejna kontrola po zabiegu w narkozie"},
{slug:"gaz-rozweselajacy-sedacja-wziewna-30-min", name:"Gaz rozweselający (sedacja wziewna) - 30 min."},
{slug:"hemisekcja-z-osteoplastyka-z-szyciem", name:"Hemisekcja z osteoplastyką z szyciem"},
{slug:"radektomia-usuniecie-jednego-korzenia", name:"Radektomia (usunięcie jednego korzenia)"},
{slug:"usuniecie-zeba-ekstrakcja-znieczulenie-zdjecie-rvg", name:"Usunięcie zęba (ekstrakcja) + znieczulenie + zdjęcie RVG"},
{slug:"wklad-koronowo-korzeniowy-z-wlokna-szklanego", name:"Wkład koronowo-korzeniowy z włókna szklanego"},
{slug:"wszczepienie-implantu-zebowego-cortex", name:"Wszczepienie implantu zębowego Cortex"},
{slug:"augmentacja-kostna-wyrostka-zebodolowego", name:"Augmentacja kostna wyrostka zębodołowego"},
{slug:"dewitalizacja-zeba-zatrucie-opatrunek", name:"Dewitalizacja zęba (zatrucie) + opatrunek"},
{slug:"hemisekcja-odseparowanie", name:"Hemisekcja (odseparowanie)"},
{slug:"usuniecie-zeba-nadliczbowego-ekstrakcja-dodatkowa", name:"Usunięcie zęba nadliczbowego (ekstrakcja dodatkowa)"},
{slug:"usuniecie-zeba-ekstrakcja-wypelnienie-zebodolu-materialem", name:"Usunięcie zęba (ekstrakcja) + wypełnienie zębodołu materiałem"},
{slug:"konsultacja-plan-leczenia-rtg-pantomograficzne-panorama", name:"Konsultacja + plan leczenia + RTG pantomograficzne (panorama)"},
{slug:"usuniecie-zeba-ekstrakcja-plastyka-polaczenia-ustno-zatokowego", name:"Usunięcie zęba (ekstrakcja) +  plastyka połączenia ustno - zatokowego"},
{slug:"wszczepienie-implantu-zebowego-osstem", name:"Wszczepienie implantu zębowego Osstem"},
{slug:"badanie-stomatologiczne-wizyta-adaptacyjna", name:"Badanie stomatologiczne / wizyta adaptacyjna"},
{slug:"autoprzeszczep-kosci", name:"Autoprzeszczep kości"},
{slug:"wszczepienie-implantu-zebowego-astra-ev", name:"Wszczepienie implantu zębowego Astra EV"},
{slug:"wklad-koronowo-korzeniowy-z-zatrzaskiem-kulowym", name:"Wkład koronowo-korzeniowy z zatrzaskiem kulowym"},
{slug:"badanie-histopatologiczne", name:"Badanie histopatologiczne"},
{slug:"gingivoplastyka", name:"Gingivoplastyka"},
{slug:"dewitalizacja-zeba-zatrucie-znieczulenie", name:"Dewitalizacja zęba (zatrucie) + znieczulenie"},
{slug:"gingivectomia", name:"Gingivectomia"},
{slug:"badanie-stomatologiczne-plan-leczenia-przeglad", name:"Badanie stomatologiczne / plan leczenia/ przegląd "},
{slug:"badanie-stomatologiczne-wydanie-zaswiadczenia", name:"Badanie stomatologiczne + wydanie zaświadczenia"},
{slug:"wszczepienie-implantu-zebowego-cyrkonowego", name:"Wszczepienie implantu zębowego cyrkonowego"},
{slug:"fibryna-bogatoplytkowa-prf", name:"Fibryna bogatopłytkowa PRF"},
{slug:"endokorona-kompozytowa", name:"Endokorona kompozytowa"},
{slug:"endokorona-porcelanowa", name:"Endokorona porcelanowa"},
{slug:"wszczepienie-implantu-zebowego-straumann-ankylos", name:"Wszczepienie implantu zębowego Straumann / Ankylos"},
{slug:"aparat-ruchomy-retencyjny-plytka-hawleya", name:"Aparat ruchomy retencyjny - płytka Hawleya"},
{slug:"usuniecie-zeba-zlamanego-ekstrakcja", name:"Usunięcie zęba złamanego (ekstrakcja)"},
{slug:"chirurgiczne-zaopatrzenie-rany", name:"Chirurgiczne zaopatrzenie rany"},
{slug:"usuniecie-zeba-rozchwianego-ekstrakcja", name:"Usunięcie zęba rozchwianego (ekstrakcja)"},
{slug:"chirurgiczne-usuniecie-torbieli", name:"Chirurgiczne usunięcie torbieli"},
{slug:"usuniecie-zeba-paradontalnego-ekstrakcja", name:"Usunięcie zęba paradontalnego (ekstrakcja)"},
{slug:"usuniecie-zeba-madrosci-ekstrakcja-8-prf-osocze-bogatoplytkowe", name:"Usunięcie zęba mądrości  (ekstrakcja 8) + PRF (osocze bogatopłytkowe)"},
{slug:"usuniecie-zeba-ekstrakcja-w-reinkluzji-usuniecie-wyrzynajacego-sie-zeba", name:"Usunięcie zęba (ekstrakcja) w reinkluzji ( usunięcie wyrzynającego się zęba)"},
{slug:"bizuteria-nazebna", name:"Biżuteria nazębna"},
{slug:"usuniecie-zeba-ekstrakcja-w-reinkluzji-usuniecie-wyrzynajacego-sie-zeba-znieczulenie", name:"Usunięcie zęba (ekstrakcja) w reinkluzji ( usunięcie wyrzynającego się zęba) + znieczulenie"},
{slug:"usuniecie-zeba-zatrzymanego-ekstrakcja", name:"Usunięcie zęba zatrzymanego (ekstrakcja)"},
{slug:"wszczepienie-implantu-zebowego", name:"Wszczepienie implantu zębowego"},
{slug:"usuniecie-zeba-madrosci-ekstrakcja-8-znieczulenie", name:"Usunięcie zęba mądrości (ekstrakcja 8) + znieczulenie"},
{slug:"usuniecie-zeba-madrosci-zatrzymanego-ekstrakcja-8-zatrzymanej", name:"Usunięcie zęba mądrości zatrzymanego (ekstrakcja 8 zatrzymanej)"},
{slug:"kiretaz-otwarty-do-3-zebow", name:"Kiretaż otwarty - do 3 zębów"},
{slug:"kiretaz-otwarty-z-regeneracja-kosci", name:"Kiretaż otwarty z regeneracją kości "},
{slug:"usuniecie-zeba-zatrzymanego-ekstrakcja-znieczulenie-zaopatrzenie-zebodolu-szycie", name:"Usunięcie zęba zatrzymanego (ekstrakcja) + znieczulenie + zaopatrzenie zębodołu + szycie"},
{slug:"kiretaz-zamkniety-1-zab", name:"Kiretaż zamknięty - 1 ząb"},
{slug:"kiretaz-zamkniety-do-3-zebow", name:"Kiretaż zamknięty - do 3 zębów"},
{slug:"usuniecie-zeba-zatrzymanego-ekstrakcja-narkoza", name:"Usunięcie zęba zatrzymanego (ekstrakcja) + narkoza"},
{slug:"kiretaz-zamkniety-do-6-zebow", name:"Kiretaż zamknięty - do 6 zębów"},
{slug:"usuniecie-zeba-zatrzymanego-ekstrakcja-znieczulenie-zdjecie-rvg", name:"Usunięcie zęba zatrzymanego (ekstrakcja) + znieczulenie + zdjęcie RVG"},
{slug:"kiretaz-otwarty-do-6-zebow", name:"Kiretaż otwarty - do 6 zebów"},
{slug:"kiretaz-zamkniety-laserem-1-zab", name:"Kiretaż zamknięty laserem - 1 ząb"},
{slug:"usuniecie-zeba-wielokorzeniowego-ekstrakcja-narkoza", name:"Usunięcie zęba wielokorzeniowego (ekstrakcja) + narkoza"},
{slug:"aparat-ruchomy-clear-aligner-nakladki", name:"Aparat ruchomy Clear Aligner (nakładki)"},
{slug:"kiretaz-zamkniety-laserem-2-luki", name:"Kiretaż zamknięty laserem - 2 łuki"},
{slug:"usuniecie-zeba-skomplikowane-separacja-korzeni", name:"Usunięcie zęba skomplikowane + separacja korzeni"},
{slug:"usuniecie-zeba-skomplikowane-separacja-korzeni-wykonanie-plata-usuniecie-chirurgiczne", name:"Usunięcie zęba skomplikowane + separacja korzeni + wykonanie płata (Usunięcie chirurgiczne)"},
{slug:"kiretaz-zamkniety-1-luk", name:"Kiretaż zamknięty - 1 łuk"},
{slug:"radektomia-z-osteoplastyka-z-szyciem", name:"Radektomia z osteoplastyką z szyciem"},
{slug:"infiltracja-prochnicy-icon-leczenie-bialych-plam-na-zebach", name:"Infiltracja próchnicy ICON (leczenie białych plam na zębach)"},
{slug:"badanie-stomatologiczne-wizyta-kontrolna", name:"Badanie stomatologiczne / wizyta kontrolna"},
{slug:"kiretaz-zamkniety-do-8-zebow", name:"Kiretaż zamknięty - do 8 zębów"},
{slug:"badanie-wstepne-przeglad-jamy-ustnej", name:"Badanie wstępne - przegląd jamy ustnej"},
{slug:"dewitalizacja-zeba-mlecznego-zatrucie-opatrunek", name:"Dewitalizacja zęba mlecznego (zatrucie) + opatrunek"},
{slug:"wszczepienie-implantu-zebowego-bego", name:"Wszczepienie implantu zębowego Bego"},
{slug:"wszczepienie-implantu-zebowego-premium", name:"Wszczepienie implantu zębowego Premium"},
{slug:"dewitalizacja-zatrucie-zeba-mlecznego", name:"Dewitalizacja - zatrucie zęba mlecznego"},
{slug:"biostymulacja", name:"Biostymulacja"},
{slug:"ekwilibracja-korekta-zgryzu", name:"Ekwilibracja (korekta zgryzu)"},
{slug:"usuniecie-zeba-ekstrakcja-zeba-trzonowego-67", name:"Usunięcie zęba (ekstrakcja) zęba trzonowego (6,7)"},
{slug:"usuniecie-zeba-ekstrakcja-zeba-przedtrzonowego-45", name:"Usunięcie zęba (ekstrakcja) zęba przedtrzonowego (4,5)"},
{slug:"usuniecie-zeba-wielokorzeniowego-ekstrakcja", name:"Usunięcie zęba wielokorzeniowego (ekstrakcja)"},
{slug:"usuniecie-zeba-dwukorzeniowego-ekstrakcja", name:"Usunięcie zęba dwukorzeniowego (ekstrakcja)"},
{slug:"kiretaz-otwarty-do-8-zebow", name:"Kiretaż otwarty - do 8 zębów"},
{slug:"badanie-stomatologiczne-zdjecie-pantomograficzne", name:"Badanie stomatologiczne + zdjęcie pantomograficzne"},
{slug:"badanie-stomatologiczne-konsultacja", name:"Badanie stomatologiczne + konsultacja"},
{slug:"wszczepienie-implantu-zebowego-z-odbudowa-protetyczna", name:"Wszczepienie implantu zębowego z odbudową protetyczną"},
{slug:"wszczepienie-implantu-zebowego-mini", name:"Wszczepienie implantu zębowego Mini"},
{slug:"dlutowanie-zeba-chirurgiczne-usuniecie-znieczulenie", name:"Dłutowanie zęba (chirurgiczne usunięcie) + znieczulenie  "},
{slug:"dewitalizacja-zeba-zatrucie-znieczulenie-opatrunek", name:"Dewitalizacja zęba (zatrucie) + znieczulenie + opatrunek"},
{slug:"aparat-staly-grubolukowy-podniebienny-wg-hassa", name:"Aparat stały grubołukowy podniebienny wg. Hassa"},
{slug:"inlay-onlay-overlay-wklad-naklad-nadklad-koronowy", name:"Inlay, Onlay, Overlay (wkład, nakład, nadkład koronowy)"},
{slug:"usuniecie-zeba-ekstrakcja-atraumatyczne-material-kosciozastepczy", name:"Usunięcie zęba (ekstrakcja) atraumatyczne + materiał kościozastepczy"},
{slug:"usuniecie-zeba-ekstrakcja-atraumatyczne-delikatne-usuniecie-zeba", name:"Usunięcie zęba (ekstrakcja) atraumatyczne (delikatne usunięcie zęba)"},
{slug:"aparat-staly-retencyjny-dystalizer-carriere", name:"Aparat stały retencyjny Dystalizer Carriere"},
{slug:"dostawienie-zeba-do-protezy-klamry-peloty", name:"Dostawienie zęba do protezy / klamry / peloty"},
{slug:"usuniecie-zeba-mlecznego-ekstrakcja-mleczaka", name:"Usunięcie zęba mlecznego (ekstrakcja mleczaka)"},
{slug:"usuniecie-zeba-mlecznego-trzonowego-ekstrakcja-mleczaka-45", name:"Usunięcie zęba mlecznego trzonowego (ekstrakcja mleczaka 4,5)"},
{slug:"doplata-protetyka", name:"Dopłata protetyka"},
{slug:"dlutowanie-zeba-chirurgiczne-usuniecie-separacja-korzeni", name:"Dłutowanie zęba (chirurgiczne usunięcie) + separacja korzeni"},
{slug:"wklad-koronowo-korzeniowy-indywidualny-lany-metalowy-przedtrzonowce-zeby-4-5", name:"Wkład koronowo-korzeniowy indywidualny lany metalowy - przedtrzonowce (zęby 4 - 5)"},
{slug:"usuniecie-zeba-madrosci-gornego-ekstrakcja-8-gornej", name:"Usunięcie zęba mądrości górnego (ekstrakcja 8 górnej)"},
{slug:"leczenie-nakladkowe", name:"Leczenie nakładkowe"},
{slug:"wklad-koronowo-korzeniowy-lany-metalowy-kazdy-nastepny-kanal", name:"Wkład koronowo-korzeniowy lany metalowy - każdy następny kanał"},
{
    slug:"impregnacja-lapisowanie-zebow-mlecznych",
    name:"Impregnacja / Lapisowanie zębów mlecznych",
    seoText: `<h2>Impregnacja / lapisowanie zębów u dzieci - ceny</h2>
    <p>Impregnacja zębów mlecznych, zwana także lapisowaniem, to procedura stomatologiczna mająca na
    celu ochronę zębów przed próchnicą. Jest to szczególnie istotne dla zębów mlecznych, które są
    delikatniejsze i bardziej podatne na próchnicę niż zęby stałe. Podczas impregnacji na zęby dziecka
    nakładana jest specjalna substancja, która tworzy cienką, ochronną powłokę. Ta powłoka zabezpiecza
    zęby przed szkodliwym działaniem bakterii, które są odpowiedzialne za rozwój próchnicy. Substancja
    do impregnacji jest bezpieczna dla dzieci i nie powoduje żadnych dolegliwości. Procedura jest
    bezbolesna i zazwyczaj trwa tylko kilka minut. Właściwe przeprowadzenie impregnacji zębów
    mlecznych może znacznie zmniejszyć ryzyko rozwoju próchnicy i pomaga utrzymać zdrowe zęby u
    dzieci.</p>
    <p>W Hot Medi pomożemy Ci znaleźć odpowiednią placówkę w adekwatnych cenach w Twojej okolicy!</p>
    <h2>Wskazania do impregnacji i lapisowania zębów</h2>
    <p>Impregnacja zębów jest szczególnie wskazana dla dzieci ze skłonnościami do próchnicy, wynikającymi
    np. z genetyki, diety bogatej w cukry i skrobie, czy trudności w utrzymaniu prawidłowej higieny jamy
    ustnej. Procedura ta jest również pomocna dla zębów z naturalnymi wypukłościami lub wgłębieniami,
    które są trudniejsze do utrzymania w czystości. Dzieci pijące mleko lub inne napoje słodzone z butelki
    tuż przed snem, które są narażone na tzw. próchnicę butelkową, również mogą skorzystać z ochrony
    jaką zapewnia impregnacja. Wreszcie, nowo wyrastające zęby, będące szczególnie podatne na
    próchnicę, mogą być chronione przez impregnację od samego początku. Pamiętaj jednak, że
    impregnacja nie zastępuje regularnej higieny jamy ustnej i wizyt u dentysty.</p>
    <h2>Ceny za lapisowanie zębów u dzieci</h2>
    <p>Ceny lapisowania, czyli impregnacji zębów mlecznych, mogą różnić się w zależności od wielu
    czynników, takich jak region, w którym znajduje się praktyka stomatologiczna, ilość zębów do
    impregnacji czy ewentualny stopień zaawansowania próchnicy. Na ogół, cena za impregnację zębów
    mlecznych może wynosić od około 100 do 300 zł. Jednak, szczegółowe informacje na temat kosztów
    lapisowania zębów mlecznych najlepiej jest uzyskać bezpośrednio od kliniki stomatologicznej.</p>
    <h2>Poznaj koszt za impregnację zębów mlecznych!</h2>
    <p>W Hot Medi jesteśmy gotowi udzielić pełnej informacji na temat kosztów związanych z impregnacją
    zębów mlecznych. Zachęcamy do kontaktu z nami w celu uzyskania dokładnych i aktualnych informacji
    na temat cennika oraz w celu odnalezienia najbliższego gabinetu oferującego tego typu usługę.</p>
    <h2>Ceny za impregnację / lapisowanie zębów mlecznych u dzieci w Hot Medi</h2>
    <p>Lapisowanie zębów mlecznych, czyli impregnacja zębów, to usługa dostępna w wielu gabinetach
    stomatologicznych na terenie całej Polski. Bez względu na to, czy mieszkasz w dużym mieście czy
    małej miejscowości, z pewnością znajdziesz specjalistów oferujących tę istotną dla zdrowia jamy
    ustnej Twojego dziecka usługę. Z myślą o komforcie i wygodzie naszych pacjentów, stworzyliśmy
    platformę Hot Medi. Jest to proste i intuicyjne narzędzie, które umożliwia szybkie i łatwe wyszukiwanie
    gabinetów stomatologicznych oferujących lapisowanie zębów mlecznych w Twojej okolicy. Dzięki niej,
    znalezienie odpowiedniego specjalisty nigdy nie było tak łatwe.</p>
    <p>Zobacz także ceny za <a href="/uslugi-medyczne/stomatologia/leczenie-kanalowe-endodontyczne"target="_blank"> leczenie kanałowe</a>,<a href="/uslugi-medyczne/stomatologia/ortodoncja"target="_blank"> założenie aparatu ortodontycznego</a> i<a href="/uslugi-medyczne/stomatologia/scaling-piaskowanie-lakierowanie-flooryzacja"target="_blank"> fluoryzację zębów</a>.</p>
`
},
{slug:"usuniecie-zeba-madrosci-dolnego-ekstrakcja-8-dolnej", name:"Usunięcie zęba mądrości dolnego (ekstrakcja 8 dolnej)"},
{slug:"dlutowanie-korzenia-chirurgiczne-usuniecie-zeba-1-korzeniowego", name:"Dłutowanie korzenia (chirurgiczne usunięcie zęba 1 korzeniowego)"},
{slug:"wszczepienie-implantu-przy-uzyciu-szablonu-chirurgicznego", name:"Wszczepienie implantu przy użyciu szablonu chirurgicznego"},
{slug:"wszczepienie-implantow-przy-uzyciu-szablonu-chirurgicznego-powyzej-dwoch-implantow", name:"Wszczepienie implantów przy uzyciu szablonu chirurgicznego powyżej dwóch implantów"},
{slug:"usuniecie-zeba-madrosci-ekstrakcja-8", name:"Usunięcie zęba mądrości (ekstrakcja 8)"},
{slug:"wszczepienie-implantu-zebowego-nobel-biocare-active-parallel", name:"Wszczepienie implantu zębowego Nobel Biocare / Active / Parallel"},
{slug:"aparat-staly-grubolukowy-podniebienny-typu-hyrax", name:"Aparat stały grubołukowy podniebienny typu Hyrax"},
{slug:"komplet-nakladek", name:"Komplet nakładek"},
{slug:"usuniecie-zeba-jednokorzeniowego-ekstrakcja-znieczulenie", name:"Usunięcie zęba jednokorzeniowego (ekstrakcja) + znieczulenie"},
{slug:"usuniecie-zeba-ekstrakcja-przygotowanie-do-wszczepienia-implantu", name:"Usunięcie zęba (ekstrakcja) + przygotowanie do wszczepienia implantu"},
{slug:"inlay-onlay-overlay-kompozytowy-wklad-naklad-nadklad-koronowy", name:"Inlay, Onlay, Overlay - kompozytowy (wkład, nakład, nadkład koronowy)"},
{slug:"aparat-staly-cienkolukowy-typu-damon", name:"Aparat stały cienkołukowy typu Damon"},
{slug:"aparat-staly-retencyjny-retainer", name:"Aparat stały retencyjny (Retainer)"},
{slug:"inlay-onlay-overlay-porcelanowy-pelnoceramiczny-lub-ceramiczny-wklad-naklad-nadklad-koronowy", name:"Inlay, Onlay, Overlay - porcelanowy (pełnoceramiczny lub ceramiczny wkład, nakład, nadkład koronowy)"},
{slug:"aparat-staly-cienkolukowy-estetycznykosmetyczny", name:"Aparat stały cienkołukowy estetyczny/kosmetyczny"},
{slug:"doplata-za-aparat-staly-cienkolukowy-estetyczny", name:"Dopłata za aparat stały cienkołukowy estetyczny"},
{slug:"usuniecie-zeba-ekstrakcja-atraumatyczne-prf-osocze-bogatoplytkowe", name:"Usunięcie zęba (ekstrakcja) atraumatyczne + PRF (osocze bogatopłytkowe)"},
{slug:"aparat-staly-cienkolukowy-estetyczny-krysztalowy", name:"Aparat stały cienkołukowy estetyczny kryształowy"},
{slug:"aparat-staly-cienkolukowy-metalowy", name:"Aparat stały cienkołukowy metalowy"},
{slug:"aparat-staly-estetyczny-ceramiczny", name:"Aparat stały estetyczny ceramiczny"},
{slug:"aparat-staly-cienkolukowy-samoligaturujacy-estetyczny", name:"Aparat stały cienkołukowy samoligaturujący estetyczny"},
{slug:"inlay-onlay-ceramiczny-cadcam-protetyka-komputerowa-3d-wklad-naklad-koronowy", name:"Inlay, Onlay - ceramiczny CAD/CAM (protetyka komputerowa 3D) (wkład, nakład koronowy)"},
{slug:"wklad-koronowo-korzeniowy-indywidualny-lany-metalowy-trzonowce-zeby-6-8", name:"Wkład koronowo-korzeniowy indywidualny lany metalowy - trzonowce (zęby 6 - 8)"},
{slug:"wklad-koronowo-korzeniowy-indywidualny-lany-metalowy-siekacze-zeby-1-3", name:"Wkład koronowo-korzeniowy indywidualny lany metalowy - siekacze (zęby 1 - 3)"},
{slug:"usuniecie-korzenia-zeba", name:"Usunięcie korzenia zęba"},
{slug:"usuniecie-zeba-jednokorzeniowego-ekstrakcja", name:"Usunięcie zęba jednokorzeniowego (ekstrakcja)"},
{slug:"aparat-staly-estetyczny-porcelanowy", name:"Aparat stały estetyczny porcelanowy"},
{slug:"aparat-staly-cienkolukowy-estetyczny-szafirowy", name:"Aparat stały cienkołukowy estetyczny szafirowy"},
{slug:"wklad-koronowo-korzeniowy-lany-metalowy", name:"Wkład koronowo-korzeniowy lany metalowy"},
{slug:"aparat-staly-grubolukowy-podniebienny-typu-nance", name:"Aparat stały grubołukowy podniebienny typu Nance"},
{slug:"aparat-staly-grubolukowy-podniebienny-quad-helix", name:"Aparat stały grubołukowy podniebienny Quad-Helix"},
{slug:"wszczepienie-implantu-zebowego-bicon", name:"Wszczepienie implantu zębowego Bicon"},
{slug:"wszczepienie-implantu-zebowego-neodent", name:"Wszczepienie implantu zębowego Neodent"},
{slug:"usuniecie-zeba-madrosci-dolnego-lezacego-ekstrakcja-8-dolnej-lezacej", name:"Usunięcie zęba mądrości dolnego ,,leżącego'' (ekstrakcja 8 dolnej leżącej)"},
{slug:"usuniecie-zeba-mlecznego-ekstrakcja-narkoza", name:"Usunięcie zęba mlecznego (ekstrakcja) + narkoza"},
{slug:"usuniecie-zeba-mlecznego-ekstrakcja-wytworzenie-plata", name:"Usunięcie zęba mlecznego (ekstrakcja) + wytworzenie płata"},
{slug:"usuniecie-zeba-mlecznego-ekstrakcja-znieczulenie", name:"Usunięcie zęba mlecznego (ekstrakcja) + znieczulenie"},
{slug:"dlutowanie-wewnatrzzebodolowe-chirurgiczne-usuniecie-narkoza", name:"Dłutowanie wewnątrzzębodołowe (chirurgiczne usunięcie) + narkoza"},
{slug:"usuniecie-zeba-mlecznego-ekstrakcja-znieczulenie-komputerowe", name:"Usunięcie zęba mlecznego (ekstrakcja) + znieczulenie komputerowe"},
{slug:"aparat-staly-zamki-rodowane-klasyczne", name:"Aparat stały zamki rodowane klasyczne"},
{slug:"usuniecie-zeba-ekstrakcja-znieczulenie", name:"Usunięcie zęba (ekstrakcja) + znieczulenie"},
{slug:"usuniecie-zeba-ekstrakcja-znieczulenie-komputerowe", name:"Usunięcie zęba (ekstrakcja) + znieczulenie komputerowe"},
{slug:"aparat-ruchomy-retencyjny-retainer-2-luki", name:"Aparat ruchomy retencyjny (Retainer) - 2 łuki"},
{slug:"dlutowanie-zeba-chirurgiczne-usuniecie", name:"Dłutowanie zęba (chirurgiczne usunięcie)"},
{slug:"aparat-ruchomy-retencyjny-retainer-1-luk", name:"Aparat ruchomy retencyjny (Retainer) - 1 łuk"},
{slug:"aparat-ruchomy-typu-twin-block", name:"Aparat ruchomy typu Twin - Block"},
{slug:"dlutowanie-korzeni-pozostawionych-po-ekstrakcji-chirurgiczne-usuniecie", name:"Dłutowanie korzeni pozostawionych po ekstrakcji (chirurgiczne usunięcie)"},
{slug:"aparat-ruchomy-zdejmowany-deprogramator", name:"Aparat ruchomy zdejmowany - deprogramator"},
{slug:"diagnostyka", name:"DIAGNOSTYKA"},
{slug:"usuniecie-zeba-ekstrakcja-narkoza", name:"Usunięcie zęba (ekstrakcja) + narkoza"},
{slug:"aparat-ortodontyczny-ruchomy-trainer-elastyczny", name:"Aparat ortodontyczny ruchomy Trainer (elastyczny)"},
{slug:"aparat-ruchomy-retencyjny-plytka-termoformowana", name:"Aparat ruchomy retencyjny - płytka termoformowana"},
{
    slug:"usuniecie-zeba-ekstrakcja",
    name:"Usunięcie zęba (ekstrakcja)",
    seoText: `
        <h2> Usunięcie zęba (ekstrakcja) - na czym polega?</h2>
        <p>Usunięcie zęba (ekstrakcja) to zabieg chirurgiczny, który polega na usunięciu zęba z jamy
        ustnej. Zabieg wykonywany jest w znieczuleniu miejscowym i jest zupełnie bezbolesny dla
        pacjenta. Zabieg ekstrakcji zęba często wykonuje się w gabinetach stomatologicznych, ale
        tylko wtedy, gdy istnieje taka konieczność lub jeśli pacjent sam podejmie taką decyzję, np.
        ze względu na brak środków finansowych niezbędnych do <a href="/uslugi-medyczne/stomatologia/leczenie-kanalowe-endodontyczne" target="_blank">leczenia kanałowego zęba</a>.</p>
        <h2>Jak przebiega zabieg usunięcia (ekstrakcji) zęba?</h2>
        <p>Po kilku minutach od podania środka znieczulającego stomatolog zrywa wiązadło okrężne,
        które otacza ząb. Korzystając z kleszczy, doprowadza do zwichnięcia zęba, co pozwala na
        jego rozchwianie i oddzielenie zęba od kości szczęki. Dzięki odpowiednim ruchom rozrywają
        się włókna ozębnej i ząb oddziela się od kości szczękowej. Pozostaje zdecydowany ruch
        wyjęcia zęba z zębodołu.
        </p>
        <h3>Ekstrakcja zęba - zalecenia po zabiegu</h3>
        <p>W miejscu po ekstrakcji zęba stomatolog zakłada tampon stomatologiczny, który pacjent
        powinien zagryzać przez 20 minut w celu zatamowania krwawienia. W tym miejscu
        wytworzy się skrzep. Nie należy naruszać go językiem i płukać jamy ustnej po zabiegu.
        Powinno się bardzo delikatnie szczotkować zęby blisko wyrwanego zęba, chłodzić policzek,
        unikać gorących pokarmów i płynów oraz tymczasowo zrezygnować z palenia papierosów.
        </p>
        <h2>Dolegliwości wpływające na powikłania po usunięciu zęba</h2>
        <p>W trakcie ekstrakcji, jak i po zabiegu usunięcia zęba, mogą wystąpić pewne powikłania.
        Ważne jest, aby poinformować lekarza o stanie zdrowia, a w szczególności o:</p>
        <ul>
            <li>wrodzonych wadach serca,</li>
            <li>chorobach układu krwionośnego, np. uszkodzonej lub wymienianej zastawce,
            zapaleniu wsierdzia,</li>
            <li>chorobach osłabiających układ odpornościowy,</li>
            <li>chorobach wątroby,</li>
            <li>cukrzycy,</li>
            <li>nadciśnieniu,</li>
            <li>nadczynności tarczycy,</li>
            <li>zakaźnych chorobach wirusowych takich jak HIV, HCV, HBV.</li>
        </ul>
        <h2>Ekstrakcja zęba - powikłania</h2>
        <p>Wyżej wymienione choroby niosą ze sobą niebezpieczeństwo wystąpienia powikłań
        podczas usunięcia (ekstrakcji) zęba, dlatego tak ważne jest poinformowanie stomatologa o
        tym, czy cierpimy na którąś z nich. Zatajenie tych informacji może spowodować
        dolegliwości w postaci trudności w gojeniu się rany, czy też zaburzeń krzepnięcia krwi. W
        trakcie samego zabiegu może nastąpić uszkodzenie zęba lub ekstrakcja sąsiadującego
        zęba, uszkodzenie naczyń krwionośnych, a nawet może dojść do złamania kości.</p>
        <p>Po zabiegu może wystąpić:</p>
        <ul>
            <li>suchy zębodół, który powoduje silny ból i jest skutkiem zapalenia zakończeń
            nerwowych w zębodole,</li>
            <li>przedłużające się krwawienie,</li>
            <li>ropne zapalenie zębodołu poprzez bakteryjne zakażenie rany, wynikające z
                pozostawienia fragmentu kości.</li>
        </ul>
        <h2>Chirurgiczne usunięcie (ekstrakcja) zęba</h2>
        <p>Zabiegu chirurgicznego usunięcia zęba (ekstrakcji chirurgicznej) podejmuje się chirurg
        stomatologiczny, gdyż jest to zabieg wymagający dużej precyzji, umiejętności i
        doświadczenia. Zabieg wykonywany jest w znieczuleniu miejscowym i jest dla pacjenta
        całkowicie bezbolesny. Dentysta nacina dziąsło i rozdziela ząb od tkanek wokół niego. Za
        pomocą wiertła usuwa warstwę kości otaczającej ząb, a następnie za pomocą
        specjalistycznych narzędzi wyjmuje go z zębodołu. Po usunięciu zakłada kilka szwów, które
        są zdejmowane po około 7-10 dniach.</p>
        <h2> Od czego zależy cena ekstrakcji zęba?</h2>
        <p>W Hot Medi można w prosty sposób znaleźć oferty z zakresu usunięcia (ekstrakcji) zęba w
        dogodnej dla Państwa lokalizacji i najbardziej zadowalającej cenie. Cena za zabieg
        ekstrakcji może się różnić w zależności od regionu, a także czynników indywidualnych,
        dlatego zachęcamy do porównania cen usług medycznych za pomocą naszej
        wyszukiwarki.</p>
        <h2>Usunięcie (ekstrakcja) korzenia zęba</h2>
        <p>Zabieg usunięcia (ekstrakcji) korzenia zęba jest wykonywany przez dentystę lub chirurga
        stomatologa. Zdaniem pacjentów jest to zabieg, który budzi najwięcej obaw, gdyż często
        korzeń jest w całości zarośnięty i konieczne jest nacięcie błony śluzowej i jej odwarstwienie.
        Zabieg przeprowadzany jest w znieczuleniu miejscowym, ale możliwe jest też, aby odbył się
        pod narkozą. W trakcie zabiegu, jeśli jest to konieczne, lekarz nacina dziąsło, by odsłonić
        korzeń. Za pomocą wiertła rozdziela korzenie, które są ze sobą połączone, a następnie
        przystępuje do dłutowania. Usuwany korzeń jest oddzielany od kości i usuwany za pomocą
        kleszczy. Zdarza się, że istnieje potrzeba usunięcia nadmiaru kości, wtedy lekarz używa
        również wiertła.</p>
        <h2>Usunięcie (ekstrakcja) korzenia zęba - cena</h2>
        <p>W naszej porównywarce cen usług medycznych znajdziesz informacje na temat placówek z
        zakresu usunięcia (ekstrakcji) korzenia zęba na terenie całej Polski, a także na świecie. Za
        pomocą naszych filtrów można znaleźć odpowiedni gabinet stomatologiczny we
        wskazanym przedziale cenowym oraz wygodnym dla Ciebie położeniu, gdzie zostanie
        przeprowadzony zabieg usunięcia (ekstrakcji) korzenia zęba.</p>
    `
},
{slug:"wszczepienie-implantu-zebowego-ziacom", name:"Wszczepienie implantu zębowego ZIACOM"},
{slug:"aparat-ruchomy-retencyjny-plytka-schwarza", name:"Aparat ruchomy retencyjny - płytka Schwarza"},
{slug:"aparat-ruchomy-retencyjny-klejony-retainer-1-luk", name:"Aparat ruchomy retencyjny klejony (Retainer) - 1 łuk"},
{slug:"usuniecie-zeba-ekstrakcja-graf-kostyny-membrana", name:"Usunięcie zęba (ekstrakcja) + graf kostyny + membrana"},
{slug:"dlutowanie-zeba-chirurgiczne-usuniecie-regeneracja-kosci", name:"Dłutowanie zęba (chirurgiczne usunięcie) + regeneracja kości"},
{slug:"wszczepienie-implantu-zebowego-alphabio-neo-izrael", name:"Wszczepienie implantu zębowego AlphaBio Neo (Izrael)"},
{slug:"dlutowanie-zeba-zatrzymanego", name:"Dłutowanie zęba zatrzymanego"},
{slug:"dlutowanie-zeba-madrosci-zatrzymanego-chirurgiczne-usuniecie-8", name:"Dłutowanie zęba mądrości zatrzymanego (chirurgiczne usunięcie 8)"},
{slug:"dlutowanie-zeba-madrosci-szycie-zebodolu", name:"Dłutowanie zęba mądrości + szycie zębodołu"},
{slug:"aparat-staly-grubolukowy-podniebienny-bi-helix", name:"Aparat stały grubołukowy podniebienny Bi-Helix"},
{slug:"rotator-podniebienny", name:"Rotator podniebienny"},
{slug:"usuniecie-zeba-madrosci-ekstrakcja-8-znieczulenie-i-zdjecie-rvg", name:"Usunięcie zęba mądrości (ekstrakcja 8) + znieczulenie i zdjęcie RVG"},
{slug:"aparat-staly-grubolukowy-podniebienny-goshgarian", name:"Aparat stały grubołukowy podniebienny Goshgarian"},
{slug:"wszczepienie-implantu-zebowego-straumann", name:"Wszczepienie implantu zębowego Straumann"},
{slug:"wszczepienie-implantu-zebowego-thommen", name:"Wszczepienie implantu zębowego Thommen"},
{slug:"dlutowanie-zeba-madrosci-chirurgiczne-usuniecie-osemki", name:"Dłutowanie zęba mądrości (chirurgiczne usunięcie ósemki)"},
{slug:"dlutowanie-zeba-mlecznego-w-reinkluzji-chirurgiczne-usuniecie-wyrzynajacego-sie-zeba", name:"Dłutowanie zęba mlecznego w reinkluzji (chirurgiczne usunięcie wyrzynającego się zęba)"},
{slug:"aparat-podniebienny-niti-niklowo-tytanowy-ekspander-ortodontyczny", name:"Aparat podniebienny NiTi niklowo-tytanowy (ekspander ortodontyczny)"},
{slug:"dlutowanie-zeba-mlecznego-chirurgiczne-usuniecie-zeba", name:"Dłutowanie zęba mlecznego (chirurgiczne usunięcie zęba)"},
{slug:"chirurgiczne-usuniecie-zmiany-na-blonie-sluzowej-z-szyciem", name:"Chirurgiczne usunięcie zmiany na błonie śluzowej z szyciem"},
{slug:"dlutowanie-zeba-chirurgiczne-usuniecie-szycie", name:"Dłutowanie zęba (chirurgiczne usunięcie) + szycie"},
{slug:"instruktaz-higieny-jamy-ustnej", name:"Instruktaż higieny jamy ustnej"},
{slug:"aparat-staly-cienkolukowy-samoligaturujacy", name:"Aparat stały cienkołukowy samoligaturujący"},
{slug:"dlutowanie-zeba-chirurgiczne-usuniecie-prf-osocze-bogatoplytkowe", name:"Dłutowanie zęba (chirurgiczne usunięcie) + PRF (osocze bogatopłytkowe)"},
{slug:"aparat-staly-iconix", name:"Aparat stały ICONIX"},
{slug:"dlutowanie-zeba-chirurgiczne-usuniecie-znieczulenie-zdjecie-rvg", name:"Dłutowanie zęba (chirurgiczne usunięcie) + znieczulenie + zdjęcie RVG"},
{slug:"wszczepienie-implantu-zebowego-biomet", name:"Wszczepienie implantu zębowego Biomet"},
{slug:"wszczepienie-implantu-zebowego-neo-biotech", name:"Wszczepienie implantu zębowego Neo - Biotech"},
{slug:"wszczepienie-implantu-zebowego-mis", name:"Wszczepienie implantu zębowego MIS"},
{slug:"zaopatrzenie-zebodolu-osoczem-bogatoplytkowym-prf-osocze-bogatoplytkowe", name:"Zaopatrzenie zębodołu osoczem bogatopłytkowym PRF (osocze bogatopłytkowe)"},
{slug:"lacznik-indywidualny-pelnoceramiczny", name:"Łącznik indywidualny pełnoceramiczny "},
{slug:"konsultacja-plan-leczenia-rtg-i-analiza-modeli-diagostycznych", name:"Konsultacja + plan leczenia + RTG i analiza modeli diagostycznych"},
{slug:"rejestracja-zwarcia", name:"Rejestracja zwarcia"},
{slug:"remineralizacja-szkliwa-zebow", name:"Remineralizacja szkliwa zębów"},
{slug:"resekcja-wierzcholka-korzenia-zeba-z-augmentacja-kosci", name:"Resekcja wierzchołka korzenia zęba z augmentacją kości"},
{slug:"resekcja-wierzcholka-korzenia-siekacze-kly-zeby-nr-123", name:"Resekcja wierzchołka korzenia (siekacze, kły - zęby nr. 1,2,3)"},
{slug:"resekcja-wierzcholka-korzenia-przedtrzonowce-zeby-nr-45", name:"Resekcja wierzchołka korzenia (przedtrzonowce - zęby nr. 4,5)"},
{slug:"resekcja-wierzcholka-korzenia-trzonowce-zeby-nr-678", name:"Resekcja wierzchołka korzenia (trzonowce - zęby nr. 6,7,8)"},
{slug:"rejestracja-po-deprogramacji-zgryzu", name:"Rejestracja po deprogramacji zgryzu"},
{slug:"resekcja-wierzcholka-korzenia-zeba-usuniecie-torbieli", name:"Resekcja wierzchołka korzenia zęba + usunięcie torbieli"},
{slug:"resekcja-wierzcholka-korzenia-mikroskop", name:"Resekcja wierzchołka korzenia + mikroskop"},
{slug:"resekcja-wierzcholka-korzenia-material-kosciozastepczy", name:"Resekcja wierzchołka korzenia + materiał kościozastępczy"},
{slug:"resekcja-wierzcholka-kazdego-nastepnego-korzenia", name:"Resekcja wierzchołka każdego następnego korzenia "},
{slug:"resekcja-wierzcholka-korzenia-zeba-dwukorzeniowego", name:"Resekcja wierzchołka korzenia zęba dwukorzeniowego"},
{slug:"resekcja-wierzcholka-korzenia-zeba-wielokorzeniowego", name:"Resekcja wierzchołka korzenia zęba wielokorzeniowego"},
{slug:"zdjecie-rtg-cefalometryczne", name:"Zdjęcie RTG cefalometryczne"},
{slug:"zdjecie-rtg-pantomograficzne", name:"Zdjęcie RTG pantomograficzne (ortopantomograficzne, OPG, zdjęcie panoramiczne, zdjęcie przeglądowe, panorama zębów)"},
{slug:"resekcja-wierzcholka-korzeni-z-wypelnieniem-wstecznym", name:"Resekcja wierzchołka korzeni z wypełnieniem wstecznym"},
{slug:"relacja-centryczna-cr-centric-relation-cr-najbardziej-doprzednio-doczaszkowe-polozenie-klykcia-w-panewce-ssz", name:"Relacja centryczna CR (Centric Relation – CR) (najbardziej doprzednio-doczaszkowe położenie kłykcia w panewce SSŻ)"},
{slug:"replantacja-zeba-po-urazie-z-unieruchomieniem-szyna-adhezyjna", name:"Replantacja zęba po urazie z unieruchomieniem szyną adhezyjną"},
{slug:"reimplantacja-zeba", name:"Reimplantacja zęba"},
{slug:"proteza-szkieletowa-na-zamkach", name:"Proteza szkieletowa na zamkach"},
{slug:"proteza-szkieletowa-na-zatrzaskach", name:"Proteza szkieletowa na zatrzaskach"},
{slug:"proteza-szkieletowa-zeby-kompozytowe", name:"Proteza szkieletowa + zęby kompozytowe"},
{slug:"proteza-teleskopowa", name:"Proteza teleskopowa"},
{slug:"proteza-termoplastyczna", name:"Proteza termoplastyczna"},
{slug:"przeszczep-kosci", name:"Przeszczep kości"},
{slug:"przeszczep-lacznotkankowy", name:"Przeszczep łącznotkankowy"},
{slug:"przeszczep-dziaslowy-fgg-ctg", name:"Przeszczep dziąsłowy FGG / CTG"},
{slug:"rebazacja-protezy-wymiana-calego-tworzywa-z-ktorego-wykonana-jest-proteza-zebowa-z-zachowaniem-luku-zebowego", name:"Rebazacja protezy (wymiana całego tworzywa, z którego wykonana jest proteza zębowa, z zachowaniem łuku zębowego)"},
{slug:"renowacja-protezy", name:"Renowacja protezy"},
{slug:"reperacja-protezy", name:"Reperacja protezy"},
{slug:"separacja-gumki-separacyjne", name:"Separacja (gumki separacyjne)"},
{slug:"separacje", name:"Separacje"},
{slug:"siatka-wzmacniajaca", name:"Siatka wzmacniająca"},
{slug:"sinus-lift-otwarty-podniesienie-zatoki-szczekowej-metoda-otwarta", name:"Sinus lift otwarty - podniesienie zatoki szczękowej, metoda otwarta"},
{slug:"szynowanie-zebow-wloknem-szklanym", name:"Szynowanie zębów włóknem szklanym"},
{slug:"szynowanie-zebow-przy-uzyciu-kompozytu", name:"Szynowanie zębów przy użyciu kompozytu"},
{slug:"szynowanie-zebow-przy-uzyciu-tasmy", name:"Szynowanie zębów przy użyciu taśmy"},
{slug:"tomografia-cbct-wiazki-stozkowej-cone-beam-computed-tomography", name:"Tomografia CBCT (wiązki stożkowej - Cone Beam Computed Tomography)"},
{slug:"tomografia-komputerowa-szczeki-zuchwy", name:"Tomografia komputerowa – szczęki/ żuchwy"},
{slug:"trepanacja-dewitalizacja-zeba", name:"Trepanacja / dewitalizacja zęba"},
{slug:"usuniecie-implantu-zeba", name:"Usunięcie implantu zęba"},
{slug:"szyna-nagryzowa-leczenie-bruksizmu", name:"Szyna nagryzowa (leczenie bruksizmu)"},
{slug:"proteza-szkieletowa-elastyczna", name:"Proteza szkieletowa / elastyczna"},
{slug:"szyna-wybielajaca", name:"Szyna wybielająca"},
{slug:"szyna-ochronna", name:"Szyna ochronna"},
{slug:"sinus-lift-zamkniety-podniesienie-zatoki-szczekowej-metoda-zamknieta", name:"Sinus lift zamknięty - podniesienie zatoki szczękowej, metoda zamknięta"},
{slug:"regeneracja-kosci", name:"Regeneracja kości"},
{slug:"dysfunkcja-stawu-skroniowo-zuchwowego-ssz", name:"Dysfunkcja stawu skroniowo-żuchwowego (SSŻ)"},
{slug:"szablon-chirurgiczny-na-1-implant", name:"Szablon chirurgiczny na 1 implant"},
{slug:"szablon-chirurgiczny-na-2-3-implanty", name:"Szablon chirurgiczny na 2-3 implanty"},
{slug:"szablon-chirurgiczny-powyzej-4-implantow", name:"Szablon chirurgiczny powyżej 4 implantów"},
{slug:"szablon-chirurgiczny-z-drukarki-3d-cyfrowe-planowanie-zabiegu", name:"Szablon chirurgiczny z drukarki 3D + cyfrowe planowanie zabiegu"},
{slug:"szycie-rany", name:"Szycie rany"},
{slug:"szycie-zebodolu", name:"Szycie zębodołu"},
{slug:"szyna-akrylowa", name:"Szyna akrylowa"},
{slug:"szyna-akrylowa-w-zwarciu-konstrukcyjnym", name:"Szyna akrylowa w zwarciu konstrukcyjnym"},
{slug:"aparat-staly-cienkolukowy-metalowy-zamki-ligaturowe", name:"Aparat stały cienkołukowy metalowy + zamki ligaturowe"},
{slug:"szyna-relaksacyjna-michigan", name:"Szyna relaksacyjna Michigan"},
{slug:"szyna-nti", name:"Szyna NTI"},
{slug:"szyna-relaksacyjna", name:"Szyna relaksacyjna"},
{slug:"piaskowanie-fluoryzacja", name:"Piaskowanie + fluoryzacja"},
{slug:"proteza-szkieletowa-standardowa", name:"Proteza szkieletowa (standardowa)"},
{slug:"proteza-szkieletowa-acetalowa", name:"Proteza szkieletowa - acetalowa"},
{slug:"plytka-nagryzowa", name:"Płytka nagryzowa"},
{slug:"plytka-przdsionkowa", name:"Płytka przdsionkowa"},
{slug:"podciecie-wedzidelka-znieczulenie", name:"Podcięcie wędzidełka + znieczulenie"},
{slug:"podciecie-wedzidelka-jezykowego-znieczulenie-komputerowe", name:"Podcięcie wędzidełka językowego + znieczulenie komputerowe"},
{slug:"podciecie-wedzidelka-zabieg-frenulotomii-podciecie-zbyt-krotkiego-wedzidelka-zmniejszajacego-ruchomosc-jezyka-lub-warg", name:"Podcięcie wędzidełka - zabieg frenulotomii (podcięcie zbyt krótkiego wędzidełka zmniejszającego ruchomość jeżyka lub warg)"},
{slug:"podciecie-wedzidelka-zabieg-frenulektomii-wyciecie-zbyt-grubego-i-nieprawidlowo-polozonego-wedzidelka-gornej-wargi-przemieszczeniu-go-i-wszyciu-w-odpowiednim-miejscu", name:"Podcięcie wędzidełka - zabieg frenulektomii (wycięcie zbyt grubego i nieprawidłowo położonego wędzidełka, górnej wargi, przemieszczeniu go i wszyciu w odpowiednim miejscu)"},
{slug:"podciecie-wedzidelka-wargi-znieczulenie-komputerowe", name:"Podcięcie wędzidełka wargi + znieczulenie komputerowe"},
{slug:"podciecie-wedzidelka-wargi-laserem", name:"Podcięcie wędzidełka wargi laserem"},
{slug:"plastyka-wyrostka-zebodolowego", name:"Plastyka wyrostka zębodołowego"},
{slug:"podklejenie-retencji", name:"Podklejenie retencji"},
{slug:"podparcie-szklane", name:"Podparcie szklane"},
{slug:"podscielenie-protezy-w-laboratorium", name:"Podścielenie protezy (w laboratorium)"},
{slug:"podscielenie-protezy-akrylowej", name:"Podścielenie protezy akrylowej"},
{slug:"podklejenie-retencji-z-innego-gabinetu", name:"Podklejenie retencji z innego gabinetu"},
{slug:"plastyka-podciecie-wedzidelka-laserem", name:"Plastyka (podcięcie) wędzidełka laserem"},
{slug:"plastyka-podciecie-wedzidelka-jezykawargi", name:"Plastyka (podcięcie) wędzidełka języka/wargi"},
{slug:"ozonowanie-ubytku", name:"Ozonowanie ubytku"},
{slug:"peeling-pca-skin", name:"Peeling PCA Skin"},
{slug:"peeling-pca-skin-2-maska-terapeutyczna", name:"Peeling PCA Skin + 2 maska terapeutyczna"},
{slug:"peeling-pca-skin-1-maska-terapeutyczna", name:"Peeling PCA Skin + 1 maska terapeutyczna"},
{slug:"peeling-migdalowy", name:"Peeling migdałowy"},
{slug:"peeling-epionce", name:"Peeling Epionce"},
{slug:"aparat-staly-grubolukowy-podniebienny-typu-pendulum", name:"Aparat stały grubołukowy podniebienny typu Pendulum"},
{slug:"pierwsza-wizyta-ze-wstepym-oczyszczeiem-zeba", name:"Pierwsza wizyta ze wstępym oczyszczeiem zęba"},
{slug:"pierwsza-wizyta-adaptacyjna", name:"Pierwsza wizyta adaptacyjna"},
{slug:"plan-leczenia-wycena-w-j-niemieckim-heil-und-kostenplan", name:"Plan leczenia - wycena w j. niemieckim (Heil – und Kostenplan)"},
{slug:"plan-leczenia-aparatem-stalym", name:"Plan leczenia aparatem stałym"},
{slug:"plan-leczenia-implantologicznego", name:"Plan leczenia implantologicznego"},
{slug:"plan-leczenia-ortodontycznego", name:"Plan leczenia ortodontycznego"},
{slug:"plan-leczenia-aparatem-invisalign", name:"Plan leczenia aparatem Invisalign"},
{slug:"plastyka-podciecie-wedzidelka-wargi-gornej-laser", name:"Plastyka (podcięcie) wędzidełka wargi górnej + laser"},
{slug:"plastyka-podciecie-wedzidelka-znieczulenie-szycie", name:"Plastyka (podcięcie) wędzidełka + znieczulenie + szycie"},
{slug:"poglebienie-przedsionka-jamy-ustnej-metoda-kazanjana", name:"Pogłębienie przedsionka jamy ustnej (metoda Kazanjana)"},
{slug:"poglebienie-przedsionka-jamy-ustnej-przeszczep-blony-sluzowej-z-podniebienia-twardego", name:"Pogłębienie przedsionka jamy ustnej + przeszczep błony śluzowej z podniebienia twardego"},
{slug:"poglebienie-przedsionka-jamy-ustnej-u-dzieci", name:"Pogłębienie przedsionka jamy ustnej u dzieci"},
{slug:"pokrycie-recesji-dziasla-1-zab", name:"Pokrycie recesji dziąsła - 1 ząb"},
{slug:"proteza-akrylowa-calkowita-dolna", name:"Proteza akrylowa całkowita - dolna"},
{slug:"proteza-akrylowa-calkowita-gorna", name:"Proteza akrylowa całkowita - górna"},
{slug:"proteza-akronowa-elastyczna", name:"Proteza akronowa (elastyczna)"},
{slug:"proteza-dziecieca", name:"Proteza dziecięca"},
{slug:"proteza-elastyczna", name:"Proteza elastyczna"},
{slug:"proteza-akrylowa-natychmiastowa", name:"Proteza akrylowa natychmiastowa"},
{slug:"proteza-na-implantach", name:"Proteza na implantach"},
{slug:"proteza-overdenture-metoda-wtryskowa-zeby-akrylowe", name:"Proteza overdenture metoda wtryskowa + zęby akrylowe"},
{slug:"proteza-overdenture-metoda-wtryskowa-zeby-kompozytowe", name:"Proteza overdenture metoda wtryskowa + zęby kompozytowe"},
{slug:"proteza-szkieletowa-metalowa", name:"Proteza szkieletowa – metalowa"},
{slug:"proteza-szkieletowa-bezmetalowa", name:"Proteza szkieletowa - bezmetalowa"},
{slug:"proteza-szkieletowa-bezmetalowa-bio-hpp", name:"Proteza szkieletowa - bezmetalowa bio HPP"},
{slug:"proteza-akrylowa-calkowita", name:"Proteza akrylowa całkowita"},
{slug:"proteza-biofunkcyjna", name:"Proteza biofunkcyjna"},
{slug:"pokrycie-mnogich-recesji-dziaslowych", name:"Pokrycie mnogich recesji dziąsłowych"},
{slug:"pokrycie-recesji-dziasla-biomaterialem", name:"Pokrycie recesji dziąsła biomateriałem"},
{slug:"pokrycie-recesji-dziasla-z-przeszczepem", name:"Pokrycie recesji dziąsła z przeszczepem"},
{slug:"pokrycie-recesji-dziasla-platem-z-sasiedztwa", name:"Pokrycie recesji dziąsła płatem z sąsiedztwa"},
{slug:"premolaryzacja-przeciecie-zeba-trzonowego-tak-aby-powstaly-z-niego-dwa-niezalezne-zeby", name:"Premolaryzacja (przecięcie zęba trzonowego tak, aby powstały z niego dwa niezależne zęby)"},
{slug:"proteza-acetalowa-akronowa-nylonowa", name:"Proteza acetalowa / akronowa/ nylonowa"},
{slug:"proteza-akrylowa-osiadajaca", name:"Proteza akrylowa osiadająca"},
{slug:"proteza-akrylowa-czesciowa", name:"Proteza akrylowa częściowa"},
{slug:"proteza-czesciowa-osiadajaca", name:"Proteza częściowa osiadająca"},
{slug:"proteza-akrylowa-od-1-do-3-zebow", name:"Proteza akrylowa od 1 do 3 zębów"},
{slug:"proteza-akrylowa-od-4-do-8-zebow", name:"Proteza akrylowa od 4 do 8 zębów"},
{slug:"proteza-akrylowa-od-8-zebow", name:"Proteza akrylowa od 8 zębów"},
{slug:"proteza-akrylowa-z-zebami", name:"Proteza akrylowa z zębami"},
{slug:"proteza-nylonowa", name:"Proteza nylonowa"},
{slug:"otwarcie-zeba-leczonego-w-innym-gabinecie-z-plukaniem-kanalow", name:"Otwarcie zęba leczonego w innym gabinecie z płukaniem kanałów"},
{slug:"piaskowanie-polerowanie", name:"Piaskowanie + polerowanie"},
{slug:"scalling-poddziaslowy", name:"Scalling poddziąsłowy"},
{slug:"wypelnienie-ubytku-zeba-mlecznego-w-narkozie", name:"Wypełnienie ubytku zęba mlecznego w narkozie"},
{slug:"wypelnienia-ubytkow-zeba-mlecznego-glass-jonomerowe-chemoutwardzalne", name:"Wypełnienia ubytków zęba mlecznego glass-jonomerowe / chemoutwardzalne"},
{slug:"wypelnienie-ubytkow-zeba-kolorowe", name:"Wypełnienie ubytków zęba kolorowe"},
{slug:"wypelnienie-ubytkow-zeba-glass-jonomerowe-chemoutwardzalne", name:"Wypełnienie ubytków zęba glass-jonomerowe / chemoutwardzalne"},
{slug:"wypelnienie-ubytkow-zeba-kosmetyczne", name:"Wypełnienie ubytków zęba kosmetyczne"},
{slug:"wypelnienie-ubytkow-zeba-estetyczne", name:"Wypełnienie ubytków zęba estetyczne"},
{slug:"wypelnienia-ubytkow-zeba-mlecznego-pasta-amputacyjna", name:"Wypełnienia ubytków zęba mlecznego + pasta amputacyjna"},
{slug:"wypelnienie-ubytkow-zeba-kompozytowe-mikroskop-lub-lupa", name:"Wypełnienie ubytków zęba kompozytowe + mikroskop lub lupa"},
{slug:"wypelnienie-ubytkow-zeba-przyszyjkowe", name:"Wypełnienie ubytków zęba przyszyjkowe"},
{slug:"wypisanie-recepty", name:"Wypisanie recepty"},
{slug:"wyluszczenie-torbieli-z-badaniem-histopatologicznym", name:"Wyłuszczenie torbieli z badaniem histopatologicznym"},
{slug:"wzmocnienie-protezy-siatka", name:"Wzmocnienie protezy siatką"},
{slug:"wypelnienie-ubytkow-zeba-mikroskop-koferdam-odbudowa-anatomiczna", name:"Wypełnienie ubytków zęba + mikroskop + koferdam + odbudowa anatomiczna"},
{slug:"wypelnienie-ubytkow-zeba-plomba-z-uzyciem-wkladu-okolomiazgowego", name:"Wypełnienie ubytków zęba (plomba) z użyciem wkładu okołomiazgowego"},
{slug:"wypelnienie-ubytkow-zeba-plomba-po-leczeniu-kanalowym", name:"Wypełnienie ubytków zęba (plomba) po leczeniu kanałowym"},
{slug:"wydanie-zaswiadczenia", name:"Wydanie zaświadczenia"},
{slug:"wydruk-modeli", name:"WYDRUK MODELI"},
{slug:"wymiana-ligatur-oba-luki", name:"Wymiana ligatur oba łuki"},
{slug:"wymiana-luku-zamka-elementow-elastycznych", name:"Wymiana łuku / zamka / elementów elastycznych"},
{slug:"wymiana-zatrzasku-do-implantu", name:"Wymiana zatrzasku do implantu  "},
{slug:"wypelnienie-ubytkow-zeba-3-powierzchni-i-wiecej-wypelnienia-duze", name:"Wypełnienie ubytków zęba 3 powierzchni i więcej - wypełnienia duże"},
{slug:"wypelnienie-ubytkow-zeba-3-powierzchni-i-wiecej-w-narkozie", name:"Wypełnienie ubytków zęba 3 powierzchni i więcej w narkozie"},
{slug:"wypelnienie-ubytkow-zeba-2-powierzchni-wypelnienia-srednie", name:"Wypełnienie ubytków zęba 2 powierzchni -  wypełnienia średnie"},
{slug:"wypelnienie-ubytkow-zeba-1-powierzchni-wypelnienia-male", name:"Wypełnienie ubytków zęba 1 powierzchni - wypełnienia małe"},
{slug:"wypelnienie-ubytkow-zeba-na-czesci-licowej-material-wysokoestetyczny", name:"Wypełnienie ubytków zęba na części licowej - materiał wysokoestetyczny"},
{slug:"wypelnienie-ubytkow-zeba-w-narkozie", name:"Wypełnienie ubytków zęba w narkozie"},
{slug:"wypelnienie-ubytkow-zeba-prochnicowego", name:"Wypełnienie ubytków zęba próchnicowego"},
{slug:"wypelnienie-ubytkow-zeba-nieprochnicowego", name:"Wypełnienie ubytków zęba niepróchnicowego"},
{slug:"wypelnienie-ubytku-klinowego", name:"Wypełnienie ubytku klinowego"},
{slug:"wypelnienie-ubytkow-zeba-plomba-technologia-nanofilowa", name:"Wypełnienie ubytków zęba (plomba) - technologia nanofilowa"},
{slug:"cementowanie-korony-mostu", name:"Cementowanie korony / mostu"},
{slug:"cementowanie-korony-mostu-z-innego-gabinetu", name:"Cementowanie korony / mostu z innego gabinetu"},
{slug:"cementowanie-korony-odcementowanej", name:"Cementowanie korony (odcementowanej)"},
{slug:"demontaz-aparatu-ortodontycznego-zakladanego-w-innym-gabinecie", name:"Demontaż aparatu ortodontycznego zakładanego w innym gabinecie"},
{slug:"zdjecie-korony", name:"Zdjęcie korony"},
{slug:"zdjecie-korony-i-usuniecie-wkladu-z-kanalu", name:"Zdjęcie korony i usunięcie wkładu z kanału"},
{slug:"zdjecie-korony-metalowej-zlozonej", name:"Zdjęcie korony metalowej / złożonej"},
{slug:"zdjecie-korony-na-implancie", name:"Zdjęcie korony na implancie"},
{slug:"zdjecie-korony-na-zebie-wlasnym", name:"Zdjęcie korony na zębie własnym"},
{slug:"zdjecie-korony-protetycznej", name:"Zdjęcie korony protetycznej"},
{slug:"zdjecie-korony-za-kazdy-przecinany-element", name:"Zdjęcie korony za każdy przecinany element"},
{slug:"zdjecie-mostu", name:"Zdjęcie mostu"},
{slug:"zdjecie-punktowe-rvg-radiowizjografia", name:"Zdjęcie punktowe RVG (radiowizjografia)"},
{slug:"zdjecie-punktowe-rvg-radiowizjografia-dla-pacjentow-spoza-gabinetu", name:"Zdjęcie punktowe RVG (radiowizjografia) dla pacjentów spoza gabinetu"},
{slug:"zdjecie-rtg", name:"Zdjęcie RTG"},
{slug:"znieczulenie-komputerowe", name:"Znieczulenie komputerowe"},
{slug:"znieczulenie-miejscowe-przewodowe-standardowe-nasiekowe", name:"Znieczulenie miejscowe przewodowe (standardowe nasiękowe)"},
{slug:"znieczulenie-pakiet", name:"Znieczulenie pakiet"},
{slug:"znieczulenie-ust", name:"Znieczulenie ust"},
{
    slug:"wycisk-pod-modele-orientacyjne-diagnostyczne",
    name:"Wycisk pod modele orientacyjne (diagnostyczne)",
    seoText: `<h2>Ortodontyczny wycisk zębów - cena</h2>
    <p>Wycisk zębów to procedura stomatologiczna, która polega na stworzeniu dokładnej kopii uzębienia
    pacjenta. Wykorzystuje się do tego specjalistyczne masy wyciskowe, które nanosi się na formę
    dopasowaną do kształtu jamy ustnej. Masa ta, po umieszczeniu w ustach pacjenta, zastyga, tworząc
    wierną replikę struktury zębów i dziąseł. Wycisk taki jest następnie używany do projektowania i
    tworzenia różnych wyrobów ortodontycznych, protez, mostów, koron oraz innych tego typu rozwiązań.</p>
    <p>W Hot Medi pomożemy Ci znaleźć odpowiednią placówkę w adekwatnych cenach w Twojej okolicy!</p>
    <h2>Wycisk zębów do aparatu ortodontycznego - zastosowanie</h2>
    <p>Wycisk zębów wykonuje się w różnych sytuacjach, zwykle kiedy jest to potrzebne do precyzyjnego
    planowania i realizacji różnych procedur stomatologicznych. Na przykład, może to być konieczne przed
    założeniem aparatu ortodontycznego, wykonaniem protezy czy implantów, a także przed innymi
    zabiegami wymagającymi dokładnego odwzorowania uzębienia pacjenta.</p>
    <h2>Ile kosztuje wycisk zębów?</h2>
    <p>Na pytanie „ile kosztuje wycisk zębów” nie ma jednoznacznej odpowiedzi. Cena za wycisk zębów
    zależy od wielu czynników, takich jak: ilość i rodzaj materiału użytego do wykonania wycisku, złożoność
    procedury oraz typ wycisku, który jest potrzebny. Średnio można jednak przyjąć, że w Polsce usługa
    wykonania wycisku zębów to koszt od 100 zł do około 300 zł. Chcąc poznać dokładną cenę warto
    skontaktować się z konkretnym gabinetem oferującym tego typu usługę, w celu dokonania jej wyceny.</p>
    <h2>Wycisk ortodontyczny cyfrowy a tradycyjny - ceny</h2>
    <p>Podczas porównywania wycisku ortodontycznego tradycyjnego i cyfrowego, widać pewne różnice
    cenowe. Cena za cyfrowy wycisk zębów  jest często wyższa od tradycyjnej, ale oferuje wiele zalet.
    Procedura jest bardziej komfortowa dla pacjenta i pozwala na wykonanie niezwykle precyzyjnego
    modelu, co przekłada się na skuteczniejsze leczenie.</p>
    <h2>Cena za ortodontyczny wycisk zębów w Polsce</h2>
    <p>Usługi stomatologiczne w Hot Medi znajdziesz dostępne na terenie całego kraju. Bez względu na to,
    gdzie mieszkasz, możesz skorzystać z naszej platformy i porównać ceny za usługi medyczne.
    Przeważnie koszt za wykonanie wycisku zębów waha się pomiędzy 100 zł, a 300 zł.</p>
    <h2>Ceny za ortodontyczny wycisk zębów do aparatu w Hot Medi</h2>
    <p>Hot Medi to intuicyjna i skuteczna wyszukiwarka usług stomatologicznych. Usługi dostępne w
    naszym systemie są realizowane przez doświadczonych specjalistów, a komfort i zadowolenie
    pacjentów jest naszym priorytetem. W związku z tym stworzyliśmy przyjazną wyszukiwarkę usług
    medycznych, która ma na celu szybko i skutecznie odnaleźć usługę medyczną w korzystnej cenie. W Hot Medi
    współpracujemy z placówkami medycznymi, których jakość usług idą w parze z przystępnymi cenami.</P>
    <p>Jeśli więc szukasz profesjonalnego gabinetu oferującego usługę wycisku zębów lub jakąkolwiek inną
    usługę medyczną, zachęcamy do skorzystania z naszej platformy!</p>
    <p>Sprawdź również <a href="/uslugi-medyczne/stomatologia/ortodoncja"target="_blank"> ceny za założenie aparatu ortodontycznego</a> ,<a href="/uslugi-medyczne/stomatologia/aparat-ruchomy-invisalign-nakladki"target="_blank"> przezroczyste nakładki na zęby</a> oraz<a href="/uslugi-medyczne/stomatologia/wybielanie-zebow"target="_blank"> wybielanie zębów</a>.</p>
`
},
{slug:"demontaz-aparatu-ortodontycznego-scaling-fluoryzacja-1-luk", name:"Demontaż aparatu ortodontycznego + scaling + fluoryzacja - 1 łuk"},
{slug:"cementowanie-korony-po-gwarancji", name:"Cementowanie korony po gwarancji"},
{slug:"cementowanie-ponowne-starej-korony", name:"Cementowanie ponowne starej korony"},
{slug:"zakotwiczenie-jarzmowe", name:"Zakotwiczenie jarzmowe"},
{slug:"zalozenie-opatrunku-chirurgicznego", name:"Założenie opatrunku chirurgicznego"},
{slug:"zalozenie-szwow", name:"Założenie szwów"},
{slug:"zamkniecie-zatoki", name:"Zamkniecie zatoki"},
{slug:"zamkniecie-diastemy-kompozytem", name:"Zamknięcie diastemy kompozytem"},
{slug:"zamkniecie-perforacji-korzenia-pod-mikroskopem", name:"Zamknięcie perforacji korzenia pod mikroskopem"},
{slug:"zamkniecie-perforacji-mta-mineral-trioxide-aggregate", name:"Zamknięcie perforacji MTA (Mineral Trioxide Aggregate)"},
{slug:"leczenie-biologiczne-z-niezakonczonym-rozwojem-korzenia", name:"Leczenie biologiczne z niezakończonym rozwojem korzenia"},
{slug:"leczenie-biologiczne-biodentin", name:"Leczenie biologiczne Biodentin"},
{slug:"leczenie-biologiczne-mta-mineral-trioxide-aggregate", name:"Leczenie biologiczne MTA (Mineral Trioxide Aggregate)"},
{slug:"zaopatrzenie-rany", name:"Zaopatrzenie rany"},
{slug:"demontaz-aparatu-ortodontycznego-1-luk", name:"Demontaż aparatu ortodontycznego - 1 łuk"},
{slug:"scaling-piaskowanie", name:"Scaling + piaskowanie"},
{slug:"wyciecie-zmiany-blony-sluzowej-badanie-histopatologiczne", name:"Wycięcie zmiany błony śluzowej + badanie histopatologiczne"},
{slug:"wycena-indywidualna", name:"Wycena indywidualna"},
{slug:"wizyta-awaryjna-doklejenie-zamka-zgubiony-zamek-pierscien", name:"Wizyta awaryjna doklejenie zamka, zgubiony zamek, pierścień"},
{slug:"wizyta-awaryjna", name:"Wizyta awaryjna"},
{slug:"wizyta-kontrolna-1-luk", name:"Wizyta kontrolna – 1 łuk"},
{slug:"wizyta-kontrolna-2-luki", name:"Wizyta kontrolna – 2 łuki"},
{slug:"wizyta-kontrolna-badanie-przeglad", name:"Wizyta kontrolna / badanie / przegląd"},
{slug:"wizyta-kontrolna-po-higienizacji-kontrola-higienizacyjna", name:"Wizyta kontrolna po higienizacji (kontrola higienizacyjna)"},
{slug:"wizyta-kontrolna-z-lakierowaniem-zebow", name:"Wizyta kontrolna z lakierowaniem zębów"},
{slug:"wax-up-woskowa-symulacja-przyszlego-uzupelnienia-protetycznego", name:"WAX UP - woskowa symulacja przyszłego uzupełnienia protetycznego"},
{slug:"wizyta-kontrolna-implantologiczna", name:"Wizyta kontrolna implantologiczna"},
{slug:"wizyta-kontrolna-ortodontyczna", name:"Wizyta kontrolna ortodontyczna"},
{slug:"wizyta-kontrolna-z-badaniem-wewnatrzustnym", name:"Wizyta kontrolna z badaniem wewnątrzustnym"},
{slug:"wizyta-kontrolna-usuwanie-szwow-po-ekstrakcjach-wykonanych-w-innym-gabinecie", name:"Wizyta kontrolna, usuwanie szwów po ekstrakcjach wykonanych w innym gabinecie"},
{slug:"wizyta-kontrolna-dziecka", name:"Wizyta kontrolna dziecka"},
{slug:"wizyta-kontrolna-aparat-ruchomy-1-luk", name:"Wizyta Kontrolna - aparat ruchomy (1 łuk)"},
{slug:"wizyta-kontrolna-aparat-ruchomy-2-luki", name:"Wizyta Kontrolna - aparat ruchomy (2 łuki)"},
{slug:"wizyta-kontrolna-aparat-ruchomy-dzieciecy", name:"Wizyta Kontrolna - aparat ruchomy dziecięcy"},
{slug:"wizyta-kontrolna-periodontologiczna", name:"Wizyta kontrolna periodontologiczna"},
{slug:"utrzymywacz-przestrzeni-bezklamrowy", name:"Utrzymywacz przestrzeni bezklamrowy"},
{slug:"utrzymywacz-przestrzeni-lutowany-do-pierscienia", name:"Utrzymywacz przestrzeni (lutowany do pierścienia)"},
{slug:"scaling-polerowaniem", name:"Scaling + polerowaniem"},
{slug:"scaling-lakierowanie", name:"Scaling + lakierowanie"},
{slug:"scaling-fluoryzacja", name:"Scaling + fluoryzacja"},
{slug:"znoszenie-nadwiazliwosci", name:"Znoszenie nadwiażliwości"},
{slug:"scaling-gleboki-root-planing", name:"Scaling głęboki (Root planing)"},
{slug:"usuniecie-szwow-pacjenci-z-innych-gabinetow", name:"Usunięcie szwów (pacjenci z innych gabinetów)"},
{slug:"usuniecie-wkladu-koronowo-korzeniowego", name:"Usunięcie wkładu koronowo - korzeniowego"},
{slug:"usuniecie-wkladu-z-wlokna-szklanego", name:"Usunięcie wkładu z włókna szklanego"},
{slug:"usuniecie-zebniaka-z-komory", name:"Usunięcie zębniaka z komory"},
{slug:"wizyta-kontrolna-aparat-staly-1-luk", name:"Wizyta kontrolna - aparat stały (1 łuk)"},
{slug:"wizyta-kontrolna-aparat-staly-w-systemie-niskiego-tarcia", name:"Wizyta kontrolna - aparat stały w systemie niskiego tarcia"},
{slug:"wizyta-kontrolna-aparat-staly-wymiana-gumek", name:"Wizyta kontrolna - aparat stały (wymiana gumek)"},
{slug:"wizyta-kontrolna-aparat-staly-2-luki", name:"Wizyta kontrolna - aparat stały (2 łuki)"},
{slug:"wybielanie-gabinetowe-zelem-opalescence", name:"Wybielanie gabinetowe żelem Opalescence"},
{slug:"wybielanie-zebow-w-gabinecie-jednoseansowe", name:"Wybielanie zębów w gabinecie jednoseansowe"},
{slug:"wybielanie-zebow-w-gabinecie", name:"Wybielanie zębów w gabinecie"},
{slug:"wybielanie-hybrydowe-w-gabinecie-i-w-domu", name:"Wybielanie hybrydowe (w gabinecie i w domu)"},
{slug:"wybielanie-zebow-nakladkowe-szynowe-1-luk", name:"Wybielanie zębów nakładkowe / szynowe - 1 łuk"},
{slug:"wybielanie-zebow-nakladkowe-szynowe-2-luki", name:"Wybielanie zębów nakładkowe / szynowe - 2 łuki"},
{slug:"wybielanie-zebow-nakladkowe-szynowe", name:"Wybielanie zebów nakładkowe / szynowe"},
{slug:"wybielanie-zebow-lampa-prevdent-gabinetowe", name:"Wybielanie zębów lampą PREVDENT (gabinetowe)"},
{slug:"wybielanie-zebow-nakladkowe-uzupelniajace", name:"Wybielanie zębów nakładkowe uzupełniające"},
{slug:"wybielanie-zeba-martwego-kolejna-wizyta", name:"Wybielanie zęba martwego - kolejna wizyta"},
{slug:"wybielanie-zeba-martwego-zdjecie-rvg", name:"Wybielanie zęba martwego + zdjęcie RVG"},
{slug:"wybielanie-zebow-lampa-plazmowa-w-gabinecie", name:"Wybielanie zębów lampą plazmową (w gabinecie)"},
{slug:"wybielanie-zebow-laserem-w-gabinecie", name:"Wybielanie zębów laserem (w gabinecie)"},
{slug:"wybielanie-zeba-przebarwionego-po-leczeniu-endodontycznym", name:"Wybielanie zęba przebarwionego po leczeniu endodontycznym"},
{slug:"wybielanie-zebow-metoda-blanc-one", name:"Wybielanie zębów metodą Blanc One"},
{slug:"wybielanie-zebow-wewnatrzkomorowe", name:"Wybielanie zębów wewnątrzkomorowe"},
{slug:"wybielanie-zebow-lampa-beyond-w-gabinecie", name:"Wybielanie zębów lampą Beyond (w gabinecie)"},
{slug:"wizyta-kontrolna-aparat-staly-krysztalowy-ceramiczny-2-luki", name:"Wizyta kontrolna - aparat stały kryształowy / ceramiczny (2 łuki)"},
{slug:"wizyta-kontrolna-po-zdjeciu-aparatu", name:"Wizyta kontrolna po zdjęciu aparatu"},
{slug:"wizyta-kontrolna-aparat-invisalign", name:"Wizyta kontrolna - aparat INVISALIGN"},
{slug:"wizyta-kontrolna-aparat-retencyjny-1-luk", name:"Wizyta kontrolna - aparat retencyjny (1 łuk)"},
{slug:"wizyta-kontrolna-aparat-samoligaturujacy-1-luk", name:"Wizyta kontrolna - aparat samoligaturujący (1 łuk)"},
{slug:"wizyta-kontrolna-aparat-samoligaturujacy-2-luki", name:"Wizyta kontrolna - aparat samoligaturujący (2 łuki)"},
{slug:"wizyta-kontrolna-aparat-kosmetyczny-samoligaturujacy-2-luki", name:"Wizyta kontrolna - aparat kosmetyczny samoligaturujący (2 łuki)"},
{slug:"wizyta-kontrolna-aparat-estetyczny-2-luki", name:"Wizyta kontrolna - aparat estetyczny (2 łuki)"},
{slug:"wizyta-kontrolna-clear-aligner-ca", name:"Wizyta kontrolna - Clear Aligner (CA)"},
{slug:"wizyta-kontrolna-aparat-pendulum", name:"Wizyta kontrolna - aparat Pendulum"},
{slug:"wizyta-kontrolna-szyna-relaksacyjna", name:"Wizyta kontrolna - szyna relaksacyjna"},
{slug:"wizyta-kontrolna-aparat-staly-h4-i-pitts-1-luk", name:"Wizyta kontrolna - aparat stały H4 i Pitts (1 łuk)"},
{slug:"wizyta-kontrolna-aparat-staly-h4-i-pitts-2-luki", name:"Wizyta kontrolna - aparat stały H4 i Pitts (2 łuki)"},
{slug:"wizyta-kontrolna-ze-sliderem", name:"Wizyta kontrolna ze sliderem"},
{slug:"wizyta-kontrolna-damon-clear-1-luk", name:"Wizyta kontrolna - Damon Clear (1 łuk)"},
{slug:"wizyta-kontrolna-damon-clear-2-luki", name:"Wizyta kontrolna - Damon Clear (2 łuki)"},
{slug:"wybielanie-zebow-lampa-led", name:"Wybielanie zębów lampą LED"},
{slug:"wybielanie-zebow-lampa-philips-zoom", name:"Wybielanie zębów lampą Philips Zoom"},
{slug:"otwarcie-zeba-stany-ropne", name:"Otwarcie zęba (stany ropne)"},
{slug:"otwarcie-zeba-opatrunek", name:"Otwarcie zęba + opatrunek"},
{slug:"otwarcie-zeba-w-stanie-zapalnym-znieczulenie", name:"Otwarcie zęba w stanie zapalnym + znieczulenie"},
{slug:"korona-porcelanowa-na-implancie", name:"Korona porcelanowa na implancie"},
{slug:"korona-porcelanowa-na-implancie-z-lacznikiem-tytanowym", name:"Korona porcelanowa na implancie z łącznikiem tytanowym"},
{slug:"korona-porcelanowa-podbudowa-z-tytanu", name:"Korona porcelanowa - podbudowa z tytanu"},
{slug:"korona-porcelanowa-na-zebie-filarowym", name:"Korona porcelanowa na zębie filarowym"},
{slug:"korona-porcelanowa-na-zlocie-cena-za-gramature-zlota", name:"Korona porcelanowa na złocie (+ cena za gramaturę złota)"},
{slug:"korona-porcelanowa-na-zlocie-galwanicznym", name:"Korona porcelanowa na złocie galwanicznym"},
{slug:"korona-porcelanowa-metal-szlachetny-zloto-platyna", name:"Korona porcelanowa - metal szlachetny (złoto - platyna)"},
{slug:"korona-premium-porcelana-na-metalu", name:"Korona premium porcelana na metalu"},
{slug:"korona-teleskopowa", name:"Korona teleskopowa"},
{slug:"korona-teleskopowa-na-zlocie-galwanicznym", name:"Korona teleskopowa na złocie galwanicznym"},
{slug:"kortykotomia-1-zab", name:"Kortykotomia - 1 ząb"},
{slug:"kortykotomia-1-luk", name:"Kortykotomia - 1 łuk"},
{slug:"koszt-maseczki", name:"Koszt maseczki"},
{slug:"korona-porcelanowa-z-malowaniem-specjalnych-efektow", name:"Korona porcelanowa z malowaniem specjalnych efektów"},
{slug:"korona-porcelanowa-na-metalu-frezowanie-cadcam", name:"Korona porcelanowa na metalu frezowanie CAD/CAM"},
{slug:"korona-pelnoceramiczna-bez-metalu", name:"Korona pełnoceramiczna (bez metalu)"},
{slug:"korona-pelnoceramiczna-przykrecana-ze-zintegrowanym-lacznikiem", name:"Korona pełnoceramiczna przykręcana ze zintegrowanym łącznikiem "},
{slug:"korona-pelnoceramiczna-przykrecana-ze-zintegrowanym-lacznikiem-tytanowym", name:"Korona pełnoceramiczna przykręcana ze zintegrowanym łącznikiem tytanowym"},
{slug:"korona-cyrkonowa-na-laczniku-indywidualnym", name:"Korona cyrkonowa na łączniku indywidualnym"},
{slug:"korona-cyrkonowa-na-implancie", name:"Korona cyrkonowa na implancie"},
{slug:"korona-cyrkonowa-na-implancie-klasy-optimum", name:"Korona cyrkonowa na implancie klasy Optimum"},
{slug:"korona-cyrkonowa-na-implancie-klasy-premium", name:"Korona cyrkonowa na implancie klasy Premium"},
{slug:"korona-cyrkonowa-licowana-ceramika", name:"Korona cyrkonowa licowana ceramiką"},
{slug:"korona-porcelanowa-na-stali-chr-ni-chlorek-niklu", name:"Korona porcelanowa na stali Chr-Ni (chlorek niklu)"},
{slug:"korona-porcelanowa-na-stali-szlachetnej", name:"Korona porcelanowa na stali szlachetnej"},
{slug:"korona-porcelanowa-na-stopie-bezniklowym", name:"Korona porcelanowa na stopie bezniklowym"},
{slug:"korona-porcelanowa-na-stopie-cr-co-chrom-kobalt", name:"Korona porcelanowa na stopie Cr-Co (chrom-kobalt)"},
{slug:"korona-porcelanowa-na-stopie-cr-co-chrom-kobalt-spiekanie-laserowe-cadcam", name:"Korona porcelanowa na stopie Cr-Co (chrom-kobalt) - spiekanie laserowe CAD/CAM"},
{slug:"korona-porcelanowa-na-stopach-dentystycznych", name:"Korona porcelanowa na stopach dentystycznych"},
{slug:"korona-porcelanowa-na-metalu-frezowana", name:"Korona porcelanowa na metalu (frezowana)"},
{slug:"korona-porcelanowa-na-metalu-ze-stopniem-porcelanowym", name:"Korona porcelanowa na metalu ze stopniem porcelanowym"},
{slug:"lakierowanie-zebow-mlecznych", name:"Lakierowanie zębów mlecznych"},
{slug:"lakierowanie-zebow-preparatami-przeciwprochnicowymi", name:"Lakierowanie zębów preparatami przeciwprochnicowymi"},
{slug:"lakierowanie-fluorkowe-fluoryzacja-2-luki", name:"Lakierowanie fluorkowe (fluoryzacja) - 2 łuki"},
{slug:"lakierowanie-fluorkowe-fluoryzacja-1-luk", name:"Lakierowanie fluorkowe (fluoryzacja) - 1 łuk"},
{slug:"leczenie-kanalowe-endodontyczne-3-kanaly", name:"Leczenie kanałowe (endodontyczne) - 3 kanały"},
{slug:"leczenie-kanalowe-endodontyczne-rtg-3-kanaly", name:"Leczenie kanałowe (endodontyczne) + RTG - 3 kanały"},
{slug:"leczenie-kanalowe-endodontyczne-4-kanaly", name:"Leczenie kanałowe (endodontyczne) - 4 kanały"},
{slug:"leczenie-kanalowe-endodontyczne-rtg-4-kanaly", name:"Leczenie kanałowe (endodontyczne) + RTG - 4 kanały"},
{slug:"leczenie-kanalowe-endodontyczne-rtg-znieczulenie-zeby-123-siekacze-kly", name:"Leczenie kanałowe (endodontyczne) + RTG + znieczulenie - zęby 1,2,3 (siekacze, kły)"},
{slug:"leczenie-kanalowe-endodontyczne-rtg-koferdam-zeby-123-siekacze-kly", name:"Leczenie kanałowe (endodontyczne) + RTG + koferdam - zęby 1,2,3 (siekacze, kły)"},
{slug:"leczenie-kanalowe-endodontyczne-rtg-znieczulenie-zeby-45-przedtrzonowce", name:"Leczenie kanałowe (endodontyczne) + RTG + znieczulenie - zęby 4,5 (przedtrzonowce)"},
{slug:"leczenie-kanalowe-endodontyczne-rtg-koferdam-zeby-45-przedtrzonowce", name:"Leczenie kanałowe (endodontyczne) + RTG + koferdam - zęby 4,5 (przedtrzonowce)"},
{slug:"leczenie-kanalowe-endodontyczne-rtg-koferdam-zeby-678-trzonowce", name:"Leczenie kanałowe (endodontyczne) + RTG + koferdam - zęby 6,7,8 (trzonowce)"},
{slug:"leczenie-kanalowe-endodontyczne-dodatkowy-kanal", name:"Leczenie kanałowe (endodontyczne) -  dodatkowy kanał"},
{slug:"leczenie-kanalowe-endodontyczne-powtorne-re-endo-dodatkowy-kanal", name:"Leczenie kanałowe (endodontyczne) powtórne re-endo -  dodatkowy kanał"},
{slug:"leczenie-kanalowe-endodontyczne-powtorne-re-endo-1-kanal", name:"Leczenie kanałowe (endodontyczne) powtórne (re-endo) - 1 kanał"},
{slug:"leczenie-kanalowe-endodontyczne-powtorne-re-endo-2-kanaly", name:"Leczenie kanałowe (endodontyczne) powtórne (re-endo) - 2 kanały"},
{slug:"leczenie-kanalowe-endodontyczne-powtorne-re-endo-3-kanaly", name:"Leczenie kanałowe (endodontyczne) powtórne (re-endo) - 3 kanały"},
{slug:"leczenie-kanalowe-endodontyczne-powtorne-re-endo-4-kanaly", name:"Leczenie kanałowe (endodontyczne) powtórne (re-endo) - 4 kanały"},
{slug:"leczenie-kanalowe-endodontyczne-powtorne-re-endo-zeby-123-siekacze-kly", name:"Leczenie kanałowe (endodontyczne) powtórne (re-endo zęby 1,2,3 (siekacze, kły))"},
{slug:"leczenie-kanalowe-endodontyczne-powtorne-re-endo-zeby-678-trzonowce", name:"Leczenie kanałowe (endodontyczne) powtórne (re-endo zęby 6,7,8 (trzonowce))"},
{slug:"leczenie-kanalowe-endodontyczne-powtorne-re-endo-zeby-45-przedtrzonowce", name:"Leczenie kanałowe (endodontyczne) powtórne (re-endo zęby 4,5 (przedtrzonowce))"},
{slug:"leczenie-kanalowe-endodontyczne-rtg-2-kanaly", name:"Leczenie kanałowe (endodontyczne) + RTG - 2 kanały"},
{slug:"korona-pelnoceramiczna-ips-emax", name:"Korona pełnoceramiczna IPS E.MAX"},
{slug:"leczenie-kanalowe-endodontyczne-2-kanaly", name:"Leczenie kanałowe (endodontyczne) - 2 kanały"},
{slug:"leczenie-kanalowe-endodontyczne-rtg-1-kanal", name:"Leczenie kanałowe (endodontyczne) + RTG - 1 kanał"},
{slug:"lakierowanie-fluorkowe-fluoryzacja-1-zab", name:"Lakierowanie fluorkowe (fluoryzacja) - 1 ząb"},
{slug:"lakierowanie-fluorkowe-fluoryzacja", name:"Lakierowanie fluorkowe (fluoryzacja)"},
{slug:"fluoryzacja-kontaktowa-lakierowanie-pianka", name:"Fluoryzacja kontaktowa (lakierowanie pianką)"},
{slug:"fluoryzacja-kontaktowa-lakierowanie-pianka-1-luk", name:"Fluoryzacja kontaktowa (lakierowanie pianką) - 1 łuk"},
{slug:"fluoryzacja-kontaktowa-lakierowanie-pianka-2-luki", name:"Fluoryzacja kontaktowa (lakierowanie pianką) - 2 łuki"},
{slug:"lakowanie-zebow-stalych-wypelnienie-bruzd-i-szczelin", name:"Lakowanie zębów stałych (wypełnienie bruzd i szczelin)"},
{slug:"lakowanie-zebow-stalych-wypelnienie-bruzd-i-szczelin-ozonowanie-1-zab", name:"Lakowanie zębów stałych (wypełnienie bruzd i szczelin) + ozonowanie - 1 ząb"},
{slug:"lakowanie-zebow-stalych-wypelnienie-bruzd-i-szczelin-1-zab", name:"Lakowanie zębów stałych (wypełnienie bruzd i szczelin) - 1 ząb"},
{slug:"lakowanie-zebow-mlecznych-1-zab", name:"Lakowanie zębów mlecznych - 1 ząb"},
{slug:"lakowanie-zebow-mlecznych", name:"Lakowanie zębów mlecznych"},
{slug:"lakowanie-zebow-stalych-poszerzone-wypelnienie-bruzd-i-szczelin", name:"Lakowanie zebów stałych poszerzone (wypełnienie bruzd i szczelin)"},
{slug:"lakowanie-zebow-stalych-poszerzone-wypelnienie-bruzd-i-szczelin-1-zab", name:"Lakowanie zebów stałych poszerzone (wypełnienie bruzd i szczelin) - 1 ząb"},
{slug:"leczenie-aft-opryszczki", name:"Leczenie aft/ opryszczki "},
{slug:"leczenie-amputacyjne-przyzyciowe-pulpotomia-wypelnienie-glassjonomerowe", name:"Leczenie amputacyjne przyżyciowe (Pulpotomia) +  wypełnienie glassjonomerowe"},
{slug:"leczenie-kanalowe-endodontyczne-1-kanal", name:"Leczenie kanałowe (endodontyczne) - 1 kanał"},
{slug:"doplata-za-leczenie-knalalowe-przez-korone", name:"Dopłata za leczenie knałalowe przez koronę"},
{slug:"korona-pelnoceramiczna-premium", name:"Korona pełnoceramiczna Premium"},
{slug:"korona-pelnoceramiczna-na-zebie-wlasnym", name:"Korona pełnoceramiczna na zębie własnym"},
{slug:"konsultacja-chirurgiczna-implantologiczna", name:"Konsultacja chirurgiczna + implantologiczna"},
{slug:"aparat-ruchomy-jednoszczekowy-ze-specjalna-sruba-proteza-ortodontyczna-ze-sruba", name:"Aparat ruchomy jednoszczękowy ze specjalną śrubą (Proteza ortodontyczna ze śrubą)"},
{slug:"konsultacja-ortodontyczna-wyciski", name:"Konsultacja ortodontyczna + wyciski"},
{slug:"konsultacja-endodondyczna-z-ocena-zdjec-tk", name:"Konsultacja endodondyczna z oceną zdjęć TK"},
{slug:"konsultacja-endodontyczna-z-rewizja-pod-mikroskopem-i-opatrunkiem", name:"Konsultacja endodontyczna z rewizją pod mikroskopem i opatrunkiem"},
{slug:"konsultacja-chirurgiczna-zdjecie-panoramiczne", name:"Konsultacja chirurgiczna + zdjęcie panoramiczne"},
{slug:"analiza-ortodontyczna", name:"Analiza ortodontyczna"},
{slug:"konsultacja-ortodontyczna-cyfrowa-wizualizacja-usmiechu-skanerem", name:"Konsultacja ortodontyczna (cyfrowa wizualizacja uśmiechu skanerem)"},
{slug:"konsultacja-ortodontyczna-dziecka-do-6-roku-zycia", name:"Konsultacja ortodontyczna dziecka do 6 roku życia"},
{slug:"konsultacja-ortodontyczna-plan-leczenia-clear-aligner", name:"Konsultacja ortodontyczna + plan leczenia Clear Aligner "},
{slug:"konsultacja-ortodontyczna-plan-leczenia-zdjecia-rtg", name:"Konsultacja ortodontyczna + plan leczenia + zdjęcia RTG"},
{slug:"konsultacja-ortodontyczna-ze-zdjeciem-rtg-zeba", name:"Konsultacja ortodontyczna ze zdjęciem rtg zęba"},
{slug:"konsultacja-ortodontyczno-chirurgiczna", name:"Konsultacja ortodontyczno - chirurgiczna"},
{slug:"awaria-aparatu-ortodontycznego", name:"Awaria aparatu ortodontycznego"},
{slug:"konsultacja-chirurgiczna-z-ocena-zdjec-rtg-lub-tk", name:"Konsultacja chirurgiczna z oceną zdjęć RTG lub TK"},
{slug:"konsultacja-chirurgiczna-z-ocena-zdjec-rtg", name:"Konsultacja chirurgiczna z oceną zdjęć RTG"},
{slug:"konsultacja-z-wykorzystaniem-modeli-diagnostycznych", name:"Konsultacja z wykorzystaniem modeli diagnostycznych"},
{slug:"konsultacja-zdjecie-pantomograficzne", name:"Konsultacja + zdjęcie pantomograficzne"},
{slug:"konsultacja-zdjecie-rtg", name:"Konsultacja + zdjęcie rtg"},
{slug:"konsultacja-badanie-wewnatrzustne-plan-leczenia", name:"Konsultacja + badanie wewnątrzustne + plan leczenia"},
{slug:"konsultacja-przeglad-porada", name:"Konsultacja/ przegląd/ porada"},
{slug:"konsultacja-przeglad-porada-dzieci", name:"Konsultacja/ przegląd/ porada - dzieci"},
{slug:"konsultacja-wizyta-bolowa-pomoc-dorazna", name:"Konsultacja / wizyta bólowa / pomoc doraźna"},
{slug:"konsultacja-kwalifikacja-do-wybielania", name:"Konsultacja / kwalifikacja do wybielania"},
{slug:"konsultacja-badanie-periodontologiczne", name:"Konsultacja + badanie periodontologiczne"},
{slug:"konsultacja-cyfrowa-wizualizacja-usmiechu", name:"Konsultacja + cyfrowa wizualizacja uśmiechu "},
{slug:"konsultacja-tomografia-3d", name:"Konsultacja + tomografia 3D"},
{slug:"konsultacja-wyciski-analiza-komputerowa-modeli", name:"Konsultacja + wyciski + analiza komputerowa modeli"},
{slug:"konsultacja-chirurga-stomatologicznego", name:"Konsultacja chirurga stomatologicznego"},
{slug:"konsultacja-endodontyczna-i-stomatologii-zachowawczej", name:"Konsultacja endodontyczna i stomatologii zachowawczej"},
{slug:"konsultacja-z-badaniem-video-trichoskopem", name:"konsultacja z badaniem video trichoskopem"},
{slug:"konsultacja-specjalisty-chirurgii-szczekowo-twarzowego", name:"Konsultacja specjalisty chirurgii szczękowo-twarzowego"},
{slug:"konsultacja-z-elementami-rehabilitacji", name:"Konsultacja z elementami rehabilitacji"},
{slug:"konsultacja-porada-wystawienie-recepty", name:"Konsultacja + porada + wystawienie recepty"},
{slug:"konsultacja-z-wykorzystaniem-mikroskopu", name:"Konsultacja z wykorzystaniem mikroskopu"},
{slug:"konsultacja-implantologiczna-z-wyciskami", name:"Konsultacja implantologiczna z wyciskami"},
{slug:"konsultacja-implantologiczna-on-line", name:"Konsultacja implantologiczna on-line"},
{slug:"konsultacja-implantologiczna-plan-leczenia", name:"Konsultacja implantologiczna + plan leczenia"},
{slug:"korona-tymczasowa-wykonywana-w-gabinecie", name:"Korona tymczasowa – wykonywana w gabinecie"},
{slug:"korona-tymczasowa-wykonywana-w-pracowni-protetycznej", name:"Korona tymczasowa – wykonywana w pracowni protetycznej"},
{slug:"korona-metalowa-tytan", name:"Korona metalowa (tytan)"},
{slug:"korona-metalowa-licowana-porcelana", name:"Korona metalowa licowana porcelaną"},
{slug:"korona-metalowa-na-zeby-trzonowe-mleczne", name:"Korona metalowa na zęby trzonowe mleczne"},
{slug:"korona-protetyczna-cadcam", name:"Korona protetyczna CAD/CAM"},
{slug:"korona-pelnoceramiczna-z-ceramiki-tloczonej", name:"Korona pełnoceramiczna z ceramiki tłoczonej"},
{slug:"korona-pelnoceremiczna-krzemionkowa", name:"Korona pełnoceremiczna krzemionkowa"},
{slug:"korona-pelnoceramiczna-na-spieku-laserowym", name:"Korona pełnoceramiczna na spieku laserowym"},
{slug:"korona-pelnoceramiczna-pelna-lava", name:`Korona pełnoceramiczna pełna "lava"`},
{slug:"korona-ceramiczna-na-laczniku-indywidualnym-atlantis-gold", name:"Korona ceramiczna na łączniku indywidualnym Atlantis Gold"},
{slug:"korona-pelnoceramiczna-na-implancie", name:"Korona pełnoceramiczna na implancie"},
{slug:"korona-pelnoceramiczna-empress", name:"Korona pełnoceramiczna Empress"},
{slug:"konsultacja-plan-leczenia-z-kosztorysem", name:"Konsultacja + plan leczenia z kosztorysem"},
{slug:"konsultacja-implantologiczna-plan-leczenia-modele-diagnostyczne", name:"Konsultacja implantologiczna + plan leczenia + modele diagnostyczne"},
{slug:"konsultacja-implantologiczna-plan-leczenia-cbct-tk-wiazki-stozkowej-cone-beam-computed-tomography", name:"Konsultacja implantologiczna + plan leczenia + CBCT (TK wiązki stożkowej. Cone Beam Computed Tomography)"},
{slug:"konsultacja-kardiologiczna", name:"Konsultacja kardiologiczna"},
{slug:"konsultacja-periodontologiczna-plan-leczenia", name:"Konsultacja periodontologiczna + plan leczenia"},
{slug:"konsultacja-periodontologiczna-pomiar-glebokosci-kieszeni", name:"Konsultacja periodontologiczna + pomiar głębokości kieszeni  "},
{slug:"konsultacja-periodontologiczna-pomiar-glebokosci-kieszeni-diagram-plan-leczenia", name:"Konsultacja periodontologiczna + pomiar głębokości kieszeni + diagram + plan leczenia"},
{slug:"konsultacja-periodontologiczna", name:"Konsultacja periodontologiczna"},
{slug:"konsultacja-protetyczna-rtg-plan-leczennia", name:"Konsultacja protetyczna + RTG + plan leczennia"},
{slug:"wizyta-kontrolna-protetyczna", name:"Wizyta kontrolna protetyczna"},
{slug:"konsultacja-protetyczna-plan-leczenia-wycena", name:"Konsultacja protetyczna + plan leczenia + wycena"},
{slug:"konsultacja-protetyczna-plan-leczenia-wyciski-modele-diagnostyczne", name:"Konsultacja protetyczna + plan leczenia + wyciski + modele diagnostyczne"},
{slug:"konsultacja-pulmonologiczna", name:"Konsultacja pulmonologiczna"},
{slug:"konsultacja-plan-leczenia-pomiary-fotografia-twarzy-kamera-wewnatrzustna", name:"Konsultacja + plan leczenia + pomiary + fotografia twarzy + kamera wewnątrzustna"},
{slug:"konsultacja-plan-leczenia-skan-zebow-dokumentacja-fotograficzna", name:"Konsultacja + plan leczenia + skan zebów + dokumentacja fotograficzna"},
{slug:"konsultacja-plan-leczenia-z-analiza-zgryzu-i-twarzoczaszki", name:"Konsultacja + plan leczenia z analizą zgryzu i twarzoczaszki"},
{slug:"leczenie-kanalowe-endodontyczne-pod-mikroskopem-re-endo-powtorne-1-kanal", name:"Leczenie kanałowe (endodontyczne) pod mikroskopem re-endo (powtórne) - 1 kanał"},
{slug:"leczenie-kanalowe-endodontyczne-pod-mikroskopem-re-endo-powtorne-2-kanaly", name:"Leczenie kanałowe (endodontyczne) pod mikroskopem re-endo (powtórne) - 2 kanały"},
{slug:"naprawa-protezy-1-punkt", name:"Naprawa protezy - 1 punkt"},
{slug:"naprawa-protezy-z-wyciskiem", name:"Naprawa protezy z wyciskiem"},
{slug:"naprawa-retainera-aparat-staly-retencyjny", name:"Naprawa retainera (aparat stały retencyjny)"},
{slug:"narkoza-znieczulenie-ogolne-pobyt-w-szpitalu", name:"Narkoza (znieczulenie ogólne) + pobyt w szpitalu"},
{slug:"narkoza-znieczulenie-ogolne-30-min", name:"Narkoza (znieczulenie ogólne) - 30 min. "},
{slug:"narkoza-znieczulenie-ogolne-1h", name:"Narkoza (znieczulenie ogólne) - 1h "},
{slug:"narkoza-znieczulenie-ogolne-dzieci", name:"Narkoza (znieczulenie ogólne) dzieci"},
{slug:"naprawa-protezy-sklejenie", name:"Naprawa protezy - sklejenie"},
{slug:"odbudowa-kosci-3d-met-prof-khourego", name:"Odbudowa kości 3D met Prof. Khourego"},
{slug:"odbudowa-kosci-przy-uzyciu-blokow-kostnych", name:"Odbudowa kości przy użyciu bloków kostnych"},
{slug:"odbudowa-kosci-szczeki", name:"Odbudowa kości szczęki"},
{slug:"odbudowa-kosci-zuchwy", name:"Odbudowa kości żuchwy"},
{slug:"odbudowa-kosci-z-przeszczepem-kosci", name:"Odbudowa kości z przeszczepem kości"},
{slug:"odbudowa-zeba-do-leczenia-endodontycznego-kanalowego", name:"Odbudowa zęba do leczenia endodontycznego (kanałowego)"},
{slug:"odbudowa-kosci-od-przedsionka", name:"Odbudowa kości od przedsionka"},
{slug:"naprawa-aparatu-zdejmowanego", name:"Naprawa aparatu zdejmowanego"},
{slug:"naprawa-aparatu-ruchomego", name:"Naprawa aparatu ruchomego"},
{
    slug:"most-adhezyjny-kompozytowy-na-wloknie-szklanym",
    name:"Most adhezyjny kompozytowy na włóknie szklanym",
    seoText: `
        <h2>Most adhezyjny na włóknie szklanym - co to jest?</h2>
        <p>Most adhezyjny kompozytowy na włóknie szklanym to rodzaj mostu stomatologicznego,
        który należy do grupy stałych uzupełnień protetycznych. Jest wykorzystywany do
        uzupełnienia pojedynczych braków w uzębieniu w przedniej, jak i w bocznej części
        wyrostków zębodołowych. Jest to nowoczesna metoda uzupełnienia luk po ekstrakcji, która
        wykorzystuje włókna szklane, charakteryzujące się wysoką wytrzymałością.</p>
        <h2>Założenie mostu adhezyjnego na włóknie szklanym</h2>
        <p>Zabieg wyróżnia przede wszystkim bezbolesność i wysokie bezpieczeństwo oraz szybkość
        wykonania usługi. Przed zabiegiem konieczna jest 
        <a href="/uslugi-medyczne/konsultacja-stomatologiczna" target="_blank">konsultacja stomatologiczna</a>, podczas
        której przeprowadzany jest wnikliwy wywiad i dokładne badanie w celu wyeliminowania
        ewentualnych przeciwwskazań do zabiegu</p>
        <h2>Most adhezyjny kompozytowy na włóknie szklanym - przeciwwskazania</h2>
        <p>Zabieg założenia mostu adhezyjnego na włóknie szklanym nie będzie zalecany, jeśli na
        konsultacji stomatologicznej stwierdzono:</p>
        <ul>
            <li>duży stopnień wad zgryzu,</li>
            <li>bardzo krótkie zęby,</li>
            <li>zniszczoną koronę kliniczną zęba.</li>
        </ul>
        <h2>Most adhezyjny kompozytowy na włóknie szklanym - zalety</h2>
        <p>Do głównych zalet mostu adhezyjnego na włóknie szklanym zaliczamy:</p>
        <ul>
            <li>niemalże natychmiastowe uzupełnienie ubytku zębowego,</li>
            <li>bezpieczeństwo i bezbolesność zabiegu,</li>
            <li>brak konieczności szlifowania zębów sąsiadujących z luką zębową,</li>
            <li>duże walory estetyczne,</li>
            <li> niska cena w porównaniu do innych uzupełnień protetycznych.</li>
        </ul>
        <h2>Most adhezyjny kompozytowy na włóknie szklanym - cena</h2>
        <p>Za pomocą naszej platformy znaleźć można gabinet stomatologiczny w odpowiednim
        przedziale cenowym oraz wygodnym dla Ciebie położeniu, gdzie zostanie przeprowadzony
        zabieg założenia <a href="/uslugi-medyczne/stomatologia/most-adhezyjny" target="_blank">mostu adhezyjnego</a>. Za pomocą <a href="/" target="_blank">wyszukiwarki usług medycznych Hot Medi</a>
        znajdą Państwo gabinet stomatologiczny, który gwarantuje dogodne warunki i atrakcyjną
        cenę.</p>
    `
},
{slug:"most-adhezyjny-tymczasowy-1-punkt", name:"Most adhezyjny (tymczasowy) - 1 punkt"},
{slug:"most-adhezyjny-tymczasowy-szyna-aet", name:"Most adhezyjny tymczasowy - szyna AET"},
{slug:"most-adhezyjny-maryland", name:"Most adhezyjny Maryland"},
{slug:"most-adhezyjny-maryland-porcelanowy", name:"Most adhezyjny Maryland porcelanowy"},
{slug:"most-adhezyjny-maryland-konstrukcja-metalowa", name:"Most adhezyjny Maryland (konstrukcja metalowa)"},
{slug:"most-adhezyjny-maryland-konstrukcja-wlokno-szklane", name:"Most adhezyjny Maryland (konstrukcja włókno szklane)"},
{slug:"most-pelnoceramiczny", name:"Most pełnoceramiczny"},
{slug:"most-tymczasowy", name:"Most tymczasowy"},
{slug:"naciecia-ropnia-z-drenazem", name:"Nacięcia ropnia z drenażem"},
{slug:"naciecie-ropnia-znieczulenie", name:"Nacięcie ropnia + znieczulenie"},
{slug:"naciecie-ropnia-z-plukaniem-antybiotykiem", name:"Nacięcie ropnia z płukaniem antybiotykiem"},
{slug:"odbudowa-zeba-po-leczeniu-endodontycznym-kanalaowym", name:"Odbudowa zęba po leczeniu endodontycznym (kanałaowym)"},
{slug:"odbudowa-zeba-na-wkladzie-z-wlokna-szklanego", name:"Odbudowa zęba na wkładzie z włókna szklanego"},
{slug:"odbudowa-zeba-na-wkladzie-z-wlokna-pozlacanego", name:"Odbudowa zęba na wkładzie z włókna pozłacanego"},
{slug:"opatrunek-leczniczy-zab-wieokanalowy", name:"Opatrunek leczniczy - ząb wieokanałowy "},
{slug:"opatrunek-do-kieszeni", name:"Opatrunek do kieszeni"},
{slug:"opatrunek-do-zebodolu", name:"Opatrunek do zębodołu"},
{slug:"rewizja-zebodolu-opatrunek", name:"Rewizja zębodołu + opatrunek "},
{slug:"rewizja-zebodolu", name:"Rewizja zębodołu"},
{slug:"opatrunek-chirurgiczny", name:"Opatrunek chirurgiczny"},
{slug:"opatrunek-leczniczy-fuji", name:"Opatrunek leczniczy Fuji"},
{slug:"opatrunek-leczniczy-coltosol", name:"Opatrunek leczniczy Coltosol"},
{slug:"opatrunek-leczniczy-tlenek-cynku", name:"Opatrunek leczniczy tlenek cynku"},
{slug:"opatrunek-leczniczy-zalozony-pod-mikroskopem", name:"Opatrunek leczniczy założony pod mikroskopem"},
{slug:"opatrunek-leczniczy-w-przypadku-glebokiej-prochnicy", name:"Opatrunek leczniczy (w przypadku głębokiej próchnicy)"},
{slug:"opatrunek-leczniczy-miedzy-wizytami", name:"Opatrunek leczniczy między wizytami"},
{slug:"ortodontyczna-korekta-zwarcia", name:"Ortodontyczna korekta zwarcia"},
{slug:"ekstruzja-ortodontyczna-ortodontyczne-wydluzenie-korony-zeba", name:"Ekstruzja ortodontyczna (ortodontyczne wydłużenie korony zęba)"},
{slug:"osocze-bogatoplytkowe-regeneris", name:"Osocze bogatopłytkowe Regeneris"},
{slug:"osocze-bogatoplytkowe", name:"Osocze bogatopłytkowe"},
{slug:"otwarcie-zeba-martwego", name:"Otwarcie zęba martwego"},
{slug:"opatrunek-leczniczy-zab-jednokanalowy", name:"Opatrunek leczniczy - ząb jednokanałowy "},
{slug:"opatrunek-zmiana-opatrunku", name:"Opatrunek - zmiana opatrunku"},
{slug:"opatrunek-leczniczy-w-zebie-stalym-wypelnienie-tymczasowe", name:"Opatrunek leczniczy w zębie stałym (wypełnienie tymczasowe)"},
{slug:"odbudowa-zeba-po-leczeniu-endodontycznym-kanalaowym-na-wkladzie-z-wlokna-szklanego", name:"Odbudowa zęba po leczeniu endodontycznym (kanałaowym) na wkładzie z włókna szklanego"},
{slug:"odbudowa-zeba-na-formowce", name:"Odbudowa zęba na formówce"},
{slug:"odbudowa-zeba-na-implancie", name:"Odbudowa zęba na implancie"},
{slug:"odbudowa-zeba-na-cwieku", name:"Odbudowa zęba na ćwieku"},
{slug:"odbudowa-zeba-mlecznego", name:"Odbudowa zęba mlecznego  "},
{slug:"odbudowa-kata-siecznego", name:"Odbudowa kąta siecznego"},
{slug:"odsloniecie-implantu-laserem", name:"Odsłonięcie implantu laserem"},
{slug:"odsloniecie-implantu-lacznik-implantu-korona-porcelanowa-na-implancie", name:"Odsłonięcie implantu + łącznik implantu + korona porcelanowa na implancie"},
{slug:"odsloniecie-implantow-x6", name:"Odsłonięcie implantów x6"},
{slug:"odsloniecie-implantu-sruba-gojaca-sruba-przezdziaslowa", name:"Odsłonięcie implantu + śruba gojąca (śruba przezdziąsłowa)"},
{slug:"opatrunek-na-blone-sluzowa", name:"Opatrunek na blonę śluzową"},
{slug:"opatrunek-leczniczy-w-zebie-mlecznym-wypelnienie-czasowe", name:"Opatrunek leczniczy w zębie mlecznym (wypełnienie czasowe)"},
{slug:"opatrunek-leczniczy-w-zebie-mlecznym-wypelnienie-czasowe-fluor", name:"Opatrunek leczniczy w zębie mlecznym (wypełnienie czasowe) + fluor"},
{slug:"opatrunek-leczniczy-w-zebie-mlecznym-wypelnienie-czasowe-u-dziecka-niewspolpracujacego", name:"Opatrunek leczniczy w zębie mlecznym (wypełnienie czasowe) u dziecka niewspólpracującego"},
{slug:"opatrunek-leczniczy-w-zebie-mlecznym-wypelnienie-czasowe-tlenek-cynku", name:"Opatrunek leczniczy w zębie mlecznym (wypełnienie czasowe) + tlenek cynku"},
{slug:"wypelnienie-tymczasowe-kanalu", name:"Wypełnienie tymczasowe kanału"},
{slug:"most-adhezyjny-3-punktowy", name:"Most adhezyjny - 3 punktowy"},
{slug:"modele-orientacyjne", name:"Modele orientacyjne"},
{slug:"leczenie-nadwrazliwosci-wszystkie-zeby", name:"Leczenie nadwrażliwości - wszystkie zęby"},
{slug:"leczenie-nadwrazliwosci-1-luk", name:"Leczenie nadwrażliwości - 1 łuk"},
{slug:"leczenie-otwarte", name:"Leczenie otwarte"},
{slug:"leczenie-otwarte-naciecie-ropnia", name:"Leczenie otwarte / nacięcie ropnia"},
{slug:"leczenie-zachowawcze", name:"Leczenie zachowawcze"},
{slug:"leczenie-zachowawcze-koferdam", name:"Leczenie zachowawcze + koferdam"},
{slug:"licowka-kompozytowa-bonding", name:"Licówka kompozytowa - BONDING"},
{slug:"odbudowa-zeba-kompozytowa-1x-wlokno-szklane", name:"Odbudowa zęba kompozytowa + 1x włókno szklane"},
{slug:"odbudowa-zeba-kompozytowa-2x-wlokno-szklane", name:"Odbudowa zęba kompozytowa + 2x włókno szklane"},
{slug:"odbudowa-zeba-kompozytowa-koferdam", name:"Odbudowa zęba kompozytowa + koferdam"},
{slug:"odbudowa-zeba-kompozytowa-w-ksztaltce-celulidowej", name:"Odbudowa zęba kompozytowa w kształtce celulidowej"},
{slug:"odbudowa-kompozytowa-zeba-flow-injection", name:"Odbudowa kompozytowa zęba – Flow injection"},
{slug:"licowka-kompozytowa-enamel", name:"Licówka kompozytowa (Enamel)"},
{slug:"licowka-kompozytowa-bezposrednia-wykonana-w-gabinecie", name:"Licówka kompozytowa bezpośrednia (wykonana w gabinecie)"},
{slug:"licowka-kompozytowa-posrednia-wykonana-w-laboratorium", name:"Licówka Kompozytowa pośrednia (wykonana w laboratorium)"},
{slug:"licowka-porcelanowa-ceramika-skaleniowa", name:"Licówka porcelanowa (ceramika skaleniowa)"},
{slug:"leczenie-nadwrazliwosci-1-zab", name:"Leczenie nadwrażliwości - 1 ząb"},
{slug:"licowka-porcelanowa-lisi", name:"Licówka porcelanowa LISI"},
{slug:"leczenie-nadpotliwosci", name:"Leczenie nadpotliwości"},
{slug:"leczenie-kanalowe-pod-mikroskopem-usuniecie-zlamanego-narzedzia", name:"Leczenie kanałowe pod mikroskopem usunięcie złamanego narzędzia"},
{slug:"leczenie-kanalowe-endodontyczne-pod-mikroskopem-re-endo-powtorne-3-kanaly", name:"Leczenie kanałowe (endodontyczne) pod mikroskopem re-endo (powtórne) - 3 kanały"},
{slug:"leczenie-kanalowe-endodontyczne-pod-mikroskopem-re-endo-powtorne-4-kanaly", name:"Leczenie kanałowe (endodontyczne) pod mikroskopem re-endo (powtórne) - 4 kanały"},
{slug:"leczenie-kanalowe-endodontyczne-zeby-45-przedtrzonowce", name:"Leczenie kanałowe (endodontyczne) - zęby 4,5 (przedtrzonowce)"},
{slug:"leczenie-kanalowe-endodontyczne-zeby-123-siekacze-kly", name:"Leczenie kanałowe (endodontyczne) - zęby 1,2,3 (siekacze, kły)"},
{slug:"leczenie-kanalowe-endodontyczne-znieczulenie-zeby-123-siekacze-kly", name:"Leczenie kanałowe (endodontyczne) + znieczulenie - zęby 1,2,3 (siekacze, kły)"},
{slug:"leczenie-kanalowe-endodontyczne-zeby-678-trzonowce", name:"Leczenie kanałowe (endodontyczne) - zęby 6,7,8 (trzonowce)"},
{slug:"leczenie-kanalowe-endodontyczne-pod-mikroskopem-1-kanal", name:"Leczenie kanałowe (endodontyczne) pod mikroskopem - 1 kanał"},
{slug:"leczenie-kanalowe-endodontyczne-rtg-znieczulenie-mikroskop-koferdam-1-kanal", name:"Leczenie kanałowe (endodontyczne) + RTG + znieczulenie + mikroskop + koferdam - 1 kanał"},
{slug:"leczenie-kanalowe-endodontyczne-rtg-znieczulenie-mikroskop-koferdam-2-kanaly", name:"Leczenie kanałowe (endodontyczne) + RTG + znieczulenie + mikroskop + koferdam - 2 kanały"},
{slug:"leczenie-kanalowe-endodontyczne-rtg-znieczulenie-mikroskop-koferdam-3-kanaly", name:"Leczenie kanałowe (endodontyczne) + RTG + znieczulenie + mikroskop + koferdam - 3 kanały"},
{slug:"leczenie-kanalowe-endodontyczne-rtg-znieczulenie-mikroskop-koferdam-4-kanaly", name:"Leczenie kanałowe (endodontyczne) + RTG + znieczulenie + mikroskop + koferdam - 4 kanały"},
{slug:"leczenie-kanalowe-pod-mikroskopem-z-wypelnieniem-wstecznym", name:"Leczenie kanałowe pod mikroskopem z wypełnieniem wstecznym"},
{slug:"leczenie-kanalowe-endodontyczne-pod-mikroskopem-2-kanaly", name:"Leczenie kanałowe (endodontyczne) pod mikroskopem - 2 kanały"},
{slug:"leczenie-kanalowe-endodontyczne-pod-mikroskopem-3-kanaly", name:"Leczenie kanałowe (endodontyczne) pod mikroskopem - 3 kanały"},
{slug:"leczenie-kanalowe-endodontyczne-pod-mikroskopem-4-kanaly", name:"Leczenie kanałowe (endodontyczne) pod mikroskopem - 4 kanały"},
{slug:"leczenie-kanalowe-endodontyczne-pod-mikroskopem-zeby-45-przedtrzonowce", name:"Leczenie kanałowe (endodontyczne) pod mikroskopem - zęby 4,5 (przedtrzonowce)"},
{slug:"leczenie-kanalowe-endodontyczne-pod-mikroskopem-zeby-123-siekacze-i-kly", name:"Leczenie kanałowe (endodontyczne) pod mikroskopem zęby 1,2,3 (siekacze i kły)"},
{slug:"leczenie-kanalowe-endodontyczne-pod-mikroskopem-zeby-678-trzonowce", name:"Leczenie kanałowe (endodontyczne) pod mikroskopem zęby 6,7,8 (trzonowce)"},
{slug:"leczenie-kanalowe-endodontyczne-pod-mikroskopem-dodatkowy-kanal", name:"Leczenie kanałowe (endodontyczne) pod mikroskopem - dodatkowy kanał"},
{slug:"konsultacja-plan-leczenia-tk", name:"Konsultacja + plan leczenia + TK"},
{slug:"licowka-porcelanowa-ceramika-japonskaamerykanska", name:"Licówka porcelanowa (ceramika japońska/amerykańska)"},
{slug:"sinus-lift-otwarty-z-augumentacja-odbudowa-wyrostka", name:"Sinus lift otwarty z augumentacją - odbudowa wyrostka"},
{slug:"mezoterapia", name:"Mezoterapia"},
{slug:"mezoterapia-twarz-szyja-dekolt", name:"Mezoterapia twarz + szyja + dekolt"},
{slug:"usuniecie-zeba-mikrochirurgiczne", name:"Usunięcie zęba mikrochirurgiczne"},
{slug:"usuniecie-zeba-mikrochirurgiczne-augumentacja-kosci", name:"Usunięcie zęba mikrochirurgiczne + augumentacja kości"},
{slug:"mikroimplant-ortodontyczny-stalowy", name:"Mikroimplant ortodontyczny stalowy"},
{slug:"mikroimplant-tytanowy", name:"Mikroimplant tytanowy"},
{slug:"mikroproteza-akrylowa-1-zab", name:"Mikroproteza akrylowa 1 ząb"},
{slug:"mikroproteza-akrylowa-1-2-zeby", name:"Mikroproteza akrylowa 1-2 zęby"},
{slug:"mikroproteza-akrylowa-do-3-zebow", name:"Mikroproteza akrylowa do 3 zębów"},
{slug:"mikroproteza-akrylowa-do-5-zebow", name:"Mikroproteza akrylowa do 5 zębów"},
{slug:"mikroproteza-acetalowa", name:"Mikroproteza acetalowa"},
{slug:"mikroimplant-ortodontyczny-tad", name:"Mikroimplant ortodontyczny TAD"},
{slug:"mikroimplant-zuchwy-proteza-calkowita", name:"Mikroimplant żuchwy + proteza całkowita"},
{slug:"mock-up-probna-odbudowa-z-kompozytu", name:"MOCK UP (próbna odbudowa z kompozytu)"},
{slug:"korona-teleskopowa-metal-szlachetny", name:"Korona teleskopowa - metal szlachetny"},
{slug:"membrana-kolagenowa", name:"Membrana kolagenowa"},
{slug:"licowka-cyrkonowa", name:"Licówka cyrkonowa"},
{slug:"licowka-pelnoceramiczna-emax", name:"Licówka pełnoceramiczna / E.max"},
{slug:"lacznik-cyrkonowy-odbudowa-pelnoceramiczna-1-zab-cadcam", name:"łącznik cyrkonowy + odbudowa pełnoceramiczna - 1 ząb CAD/CAM"},
{slug:"lacznik-tytanowy", name:"Łącznik tytanowy"},
{slug:"lacznik-implantu-korona", name:"Łącznik implantu + korona"},
{slug:"lacznik-indywidualny-korona-cyrkonowa", name:"Łącznik indywidualny + korona cyrkonowa"},
{slug:"lacznik-indywidualny-do-implantu", name:"Łącznik indywidualny do implantu"},
{slug:"lacznik-katowy", name:"Łącznik kątowy"},
{slug:"lacznik-standardowy", name:"Łącznik standardowy"},
{slug:"lacznik-tymczasowy", name:"Łącznik tymczasowy"},
{slug:"luk-jezykowy", name:"Łuk językowy"},
{slug:"lyzka-indywidualna", name:"Łyżka indywidualna"},
{slug:"maska-twarzowa", name:"Maska twarzowa"},
{slug:"membrana", name:"Membrana"},
{slug:"korona-teleskopowa-metal-nieszlachetny", name:"Korona teleskopowa - metal nieszlachetny"},
{slug:"aparat-ruchomy-retencyjny-cementowany", name:"Aparat ruchomy retencyjny cementowany"},
{slug:"leczenia-lysienia", name:"Leczenia łysienia"},
{slug:"leczenie-nadwrazliwosci-szyjek", name:"Leczenie nadwrażliwości szyjek"},
{slug:"licowka-porcelanowa-bez-szlifowania-zeba", name:"Licówka porcelanowa bez szlifowania zęba"},
{slug:"licowki-cyfrowe-licowki-ceramiczne-skanowane-i-frezowane-cyfrowo", name:"Licówki cyfrowe (licówki ceramiczne skanowane i frezowane cyfrowo)"},
{slug:"aparat-ruchomy-blokowy", name:"Aparat ruchomy blokowy"},
{slug:"aparat-ruchomy-typu-twin-force", name:"Aparat ruchomy typu Twin - Force"},
{slug:"aparat-ruchomy-sruba-bertoniego", name:"Aparat ruchomy śrubą Bertoniego"},
{slug:"pozostale-aparaty", name:"Pozostałe aparaty"},
{slug:"badanie-kontrolne-z-fluoryzacja", name:"Badanie kontrolne z fluoryzacją"},
{slug:"dlutowanie-wewnatrzzebodolowe-chirurgicznne-usuniecie", name:"Dłutowanie wewnątrzzębodołowe (chirurgicznne usunięcie)"},
{slug:"chirurgiczne-odsloniecie-zeba-do-zabiegu", name:"Chirurgiczne odsłonięcie zęba do zabiegu"},
{slug:"kieretaz-otwarty-kolejny-zab", name:"Kieretaż otwarty - kolejny ząb"},
{slug:"konsultacja-anestezjologiczna", name:"Konsultacja anestezjologiczna"},
{slug:"osadzenie-korony-mostu-wykonanego-poza-gabinetem", name:"Osadzenie korony / mostu wykonanego poza gabinetem"},
{slug:"korona-tymczasowa-na-implancie", name:"Korona tymczasowa na implancie"},
{slug:"korona-ceramiczna-podbudowa-cyrkonowa", name:"Korona ceramiczna + podbudowa cyrkonowa"},
{slug:"usuniecie-korony", name:"Usunięcie korony"},
{slug:"rekonstrukcja-korony-zeba", name:"Rekonstrukcja korony zęba"},
{slug:"leczenie-zebodolu-suchego", name:"Leczenie zębodołu suchego"},
{slug:"leczenie-zapalen-dziasla", name:"Leczenie zapaleń dziąsła"},
{slug:"przygotowanie-do-leczenia-endodontycznego", name:"Przygotowanie do leczenia endodontycznego"},
{slug:"mezoterapia-twarz-szyja", name:"Mezoterapia twarz + szyja"},
{slug:"mikroproteza-akrylowa-do-4-zebow", name:"Mikroproteza akrylowa do 4 zębów"},
{slug:"usuniecie-starego-mostu", name:"Usunięcie starego mostu"},
{slug:"most-kompozytowy-na-wloknie-szklanym", name:"Most kompozytowy na włóknie szklanym"},
{slug:"most-kompozytowy-na-wloknie-szklanym-3-punktowy", name:"Most kompozytowy na włóknie szklanym - 3 punktowy"},
{slug:"most-kompozytowy-na-wloknie-szklanym-4-punktowy", name:"Most kompozytowy na włóknie szklanym - 4 punktowy"},
{slug:"most-kompozytowy-na-wloknie-szklanym-metoda-bezposrednia-1-punkt", name:"Most kompozytowy na włóknie szklanym metoda bezpośrednia - 1 punkt"},
{slug:"most-kompozytowy-na-wloknie-szklanym-metoda-posrednia-1-punkt", name:"Most kompozytowy na włóknie szklanym metoda pośrednia - 1 punkt"},
{slug:"odbarczenie-torbieli", name:"Odbarczenie torbieli"},
{slug:"naprawa-korony-porcelanowej", name:"Naprawa korony porcelanowej"},
{slug:"odbudowa-brzegu-siecznego", name:"Odbudowa brzegu siecznego"},
{slug:"odbudowa-zeba-kompozytowa", name:"Odbudowa zęba kompozytowa"},
{slug:"odbudowa-wyrostka", name:"Odbudowa wyrostka"},
{slug:"odbudowa-korony-zeba", name:"Odbudowa korony zęba"},
{slug:"ozonowanie-zebodolu", name:"Ozonowanie zębodołu"},
{slug:"polerowanie-zebow", name:"Polerowanie zębów"},
{slug:"proteza-tymczasowa", name:"Proteza tymczasowa"},
{slug:"reminalizacja-szkliwa", name:"Reminalizacja szkliwa"},
{slug:"zdjecie-szyny", name:"Zdjęcie szyny"},
{slug:"szyna-nagryzowa", name:"Szyna nagryzowa"},
{slug:"usuniecie-torbieli", name:"Usunięcie torbieli"},
{slug:"usuniecie-zmiany-na-wardze", name:"Usunięcie zmiany na wardze"},
{slug:"usuniecie-wlokniakow", name:"Usunięcie włókniaków"},
{slug:"usuniecie-zdjecie-szwow", name:"Usunięcie, zdjęcie szwów"},
{slug:"usuniecie-zeba-skomplikowane-ekstrakcja-chirurgiczna-skomplikowana", name:"Usunięcie zęba skomplikowane (ekstrakcja chirurgiczna skomplikowana)"},
{slug:"usuniecie-naddziaslaka", name:"Usunięcie naddziąślaka"},
{slug:"usuniecie-zeba-madrosci-skomplikowane-ekstrakcja-8-skomplikowana", name:"Usunięcie zęba mądrości skomplikowane (ekstrakcja 8 skomplikowana)"},
{slug:"ekstyrpacja-miazgi-usuniecie", name:"Ekstyrpacja miazgi (usunięcie)"},
{slug:"wizyta-adaptacyjna", name:"Wizyta adaptacyjna"},
{slug:"wizyta-nieefektywna", name:"Wizyta nieefektywna"},
{slug:"konsultacja-laryngologiczna", name:"Konsultacja laryngologiczna"},
{slug:"konsultacja-urologiczna", name:"Konsultacja urologiczna"},
{slug:"wklad-koronowo-korzeniowy-zloty", name:"Wkład koronowo-korzeniowy złoty"},
{slug:"wklad-koronowo-korzeniowy-porcelanowy", name:"Wkład koronowo-korzeniowy porcelanowy"},
{slug:"wklad-koronowo-korzeniowy-standardowy-z-wlokna-weglowego", name:"Wkład koronowo-korzeniowy standardowy z włókna węglowego"},
{slug:"wklad-koronowo-korzeniowy-cyrkonowy", name:"Wkład koronowo-korzeniowy cyrkonowy"},
{slug:"wklad-koronowo-korzeniowy-ceramiczny", name:"Wkład koronowo-korzeniowy ceramiczny"},
{slug:"wklad-koronowo-korzeniowy-lany-metalowy-dzielony", name:"Wkład koronowo-korzeniowy lany metalowy dzielony"},
{slug:"demontaz-aparatu-ortodontycznego-z-reteinerem-1-luk", name:"Demontaż aparatu ortodontycznego z reteinerem - 1 łuk"},
{slug:"proteza-zebowa", name:"Proteza zębowa"},
{slug:"szyna-relaksacyjna-zgryzowa", name:"Szyna relaksacyjna zgryzowa"},
{
    slug:"chirurgia-stomatologiczna-i-szczekowo-twarzowa",
    name:"Chirurgia stomatologiczna i szczękowo - twarzowa",
    seoText: `<h2>Chirurgia stomatologiczna</h2>
    <p>Chirurgia stomatologiczna obejmuje <b>zabiegi operacyjne w jamie ustnej.</b> Dostępne są m.in.
    zabiegi wszczepienia implantów, odtwarzanie tkanki kostnej i zabiegi na tkankach miękkich.
    Najpopularniejszym zabiegiem jest <a href="/uslugi-medyczne/stomatologia/usuniecie-zeba-madrosci-ekstrakcja-8" target="_blank">ekstrakcja ósemek</a>.</p>
    <h3>Zabiegi z zakresu chirurgii stomatologiczne</h3>
    <p>Zabiegi, jakie mogą przeprowadzać chirurdzy stomatologiczni, obejmują:</p>
    <ul>
        <li><a href="/uslugi-medyczne/stomatologia/naciecie-ropnia" target="_blank">nacięcie ropnia</a>,</li>
        <li><a href="/uslugi-medyczne/stomatologia/resekcja-wierzcholka-korzenia" target="_blank">resekcja wierzchołka korzenia</a>,</li>
        <li><a href="/uslugi-medyczne/stomatologia/przeszczep-kosci" target="_blank">przeszczep kości</a>.</li>
    </ul>
    <h2>Wskazania do wizyty u chirurga stomatologicznego</h2>
    <p>Do chirurga stomatologicznego zgłaszamy się najczęściej w przypadkach związanych z
    <b>zanikami kości na skutek utraty uzębienia, po urazach szczęki i wypadkach</b>. Z pomocą
    wyszukiwarki Hot Medi możesz szybko wyszukać placówkę w okolicy swojego miejsca
    zamieszkania - z najlepszą ofertą i najkorzystniejszym zakresem cenowym.</p>
    <h2>Czym jest chirurgia szczękowo-twarzowa?</h2>
    <p>Chirurgia szczękowo-twarzowa, tak samo jak stomatologiczna, obejmuje zabiegi w obrębie
    jamy ustnej, jednak jej zastosowanie jest nieco szersze i dotyczy także <b>kości żuchwy i
    zabiegów w obrębie szyi</b>.</p>
    <h3>Chirurgia szczękowo-twarzowa - zakres usług</h3>
    <p>Wśród przykładowych zabiegów, jakie może wykonać chirurg szczękowo-twarzowy,
    wyróżniamy:</p>
    <ul>
        <li>odtwarzanie kości w celu wszczepienia implantu,</li>
        <li>zniwelowanie defektów kostnych,</li>
        <li>leczenie wad kości twarzoczaszki, wynikających z nadmiernego wzrostu kości lub niedoborów w jej wzroście,</li>
        <li>usuwanie zębów mądrości w warunkach szpitalnych.</li>
    </ul>
    <p>Jednym z fenomenów wśród zabiegów chirurgii szczękowo-twarzowej jest tzw.
    <b>osteogeneza dystrakcyjna</b>. Zabieg ten polega na zwiększeniu ilości tkanki kostnej poprzez
    przecięcie kości i powolne jej rozciąganie. Zabieg ten wykonuje się w celu leczenia wad
    wrodzonych i nabytych czaszki twarzowej lub w przypadku ubytków powstałych na skutek
    leczenia onkologicznego.
    </p>
    <h2>Koszty leczenia u chirurga stomatologicznego i twarzowo-szczękowego</h2>
    <p>Do ustalenia pełnej <b>ceny za leczenie chirurgiczne</b> musimy wziąć pod uwagę koszty
    konsultacji, podczas której lekarz ustala zakres problemu, a także to, jakie badania należy
    wykonać przed leczeniem i czy nie będą potrzebne dodatkowe zabiegi przed wykonaniem
    właściwego leczenia. Wówczas bierzemy pod uwagę również koszt zleconych badań i
    innych zabiegów.</p>
    <p>Dochodzą tutaj koszty przeglądu stomatologicznego, a także całkowity czas leczenia, dobór
    i liczba zastosowanych materiałów, a także jakość i wybór metody leczenia, przy czym jeśli
    mamy do czynienia z metodą nową lub bardziej skomplikowaną, koszty będą wyższe.</p>
    <h3> Czy na prywatną wizytę do chirurga stomatologicznego potrzebne jest skierowanie?</h3>
    <p>Opieka prywatna, choć kosztowna, oszczędza nam konieczności udania się do lekarza
    rodzinnego lub innego po skierowanie na odpowiednie leczenie. Dzięki temu możliwe jest
    <b>znacznie szybsze umówienie wizyty i rozpoczęcie procedur leczniczych</b>. Dodatkowo
    całość leczenia może przyspieszyć platforma Hot Medi, dzięki której znajdziesz najlepszych
    specjalistów z całej Polski.
    </p>`
},
{slug:"usuniecia", name:"Usunięcia"},
{
    slug:"protetyka-i-implantoprotetyka",
    name:"Protetyka i implantoprotetyka",
    seoText: `<h2>Protetyka stomatologiczna</h2>
    <p>Jest to dziedzina <b>wspomagająca diagnozę i leczenie wad uzębienia</b> – zarówno
    wrodzonych, jak i tych stałych. Chociaż protezy są kojarzone przede wszystkim z naturalnym
    ubytkiem uzębienia u osób starszych, to mogą one pomagać również młodszym
    pacjentom w sytuacjach związanych z ubytkiem zębów na skutek chorób lub wypadków.</p>
    <h3>Czym jest implantoprotetyka?</h3>
    <p>Jest to dziedzina <b>łącząca w sobie protetykę i implantologię</b>. Na założonych w dziąsłach
    implantach osadzane są nowe protezy zęba, które dzięki nowoczesnym możliwościom
    mogą doskonale imitować prawdziwe uzębienie. W przeciwieństwie do klasycznej protezy
    implanty są na stałe osadzone w jamie ustnej.</p>
    <h2>Zakres usług protetyka stomatologa</h2>
    <p>W dziedzinie <b>protetyki oraz implantoprotetyki</b> możemy skorzystać m.in. z takich usług, jak
    <a href="/uslugi-medyczne/stomatologia/korony" target="_blank">założenie korony zęba</a>, 
    <a href="/uslugi-medyczne/stomatologia/most-adhezyjny" target="_blank">mostu adhezyjnego</a> (stanowiącego tymczasowe uzupełnienie
    braków w uzębieniu), czy też 
    <a href="/uslugi-medyczne/stomatologia/most-kompozytowy-na-wloknie-szklanym" target="_blank">mostu kompozytowego</a>,
    np. mocowanego na klej do
    sąsiednich zębów.</p>
    <h2>Protetyka cyfrowa w stomatologii</h2>
    <p><b>Protetyka cyfrowa</b> pozwala na <b>uzyskanie dokładnego obrazu uzębienia pacjenta</b> –
    projekt implantu nie odbywa się tutaj na podstawie wycisku, lecz właśnie na podstawie
    informacji uzyskanej z trójwymiarowego obrazu. Pozwala to na znaczne przyspieszenie
    rozwiązania problemów z uzębieniem lub dokładniejszą naprawę protezy</p>
    <h2>Jak wygląda wizyta u protetyka?</h2>
    <p>Wizyta rozpoczyna się od <b>przeprowadzenia wywiadu zdrowotnego z pacjentem</b>, podczas
    którego specjalista poznaje oczekiwania pacjenta, a także upewnia się, czy nie jest
    wymagane wcześniejsze wyleczenie ubytków w uzębieniu (np. 
    <a href="/uslugi-medyczne/stomatologia/leczenie-kanalowe-endodontyczne" target="_blank">leczenie kanałowe</a>). Z
    niezwykłą dokładnością badane są miejsca, w których ma się znaleźć implant – lekarz
    bada m.in. odległość od ważnych punktów anatomicznych, stopień ubytku i wyrostek
    zębowy.</p>
    <h3>Na jakie badania może skierować protetyk?</h3>
    <p>Przed rozpoczęciem leczenia protetycznego możemy otrzymać skierowanie na:</p>
    <ul>
        <li>OB (Odczyn Biernackiego),</li>
        <li>pełną morfologię krwi,</li>
        <li>hormony tarczycy,</li>
        <li>wapń,</li>
        <li>sód,</li>
        <li>potas,</li>
        <li>witamina D3,</li>
        <li>glukoza,</li>
        <li>obciążenie osteoporozą w przypadku kobiet przechodzących menopauzę.</li>
    </ul>
    <p>Oprócz wszystkich wyżej wymienionych badań konieczne jest wykonanie 
    <a href="/uslugi-medyczne/stomatologia/zdjecie-rtg-pantomograficzne" target="_blank">zdjęcia pantomograficznego</a>, 
    jakie pozwoli lekarzowi ocenić m.in. grubość i gęstość kości, a także
    grubość błony śluzowej i przebieg dolnego nerwu.</p>
    <h2>Od czego zależy cena za wizytę u protetyka?</h2>
    <p>Koszt zależny jest <b>od wielu zmiennych</b>. Wśród nich wyróżniamy m.in. lokalizację implantów,
    ich liczbę, założenie pojedynczej korony lub mostu, a także rodzaj materiału, tj. tytan,
    aluminium, cyrkon, porcelana, tlenek aluminium, czy tantal.</p>
    <h2>Ceny za wizytę u protetyka i implantoprotetyka w Hot Medi</h2>
    <p>Korzystając z pomocy wyszukiwarki Hot Medi, zyskujemy szansę na znalezienie najlepszych
    specjalistów z całej Polski lub, dzięki zawężeniu poszukiwań jedynie do wybranego obszaru,
    w pobliżu miejsca zamieszkania. Nasza porównywarka pokazuje <b>spodziewany przedział
    cenowy</b> i pozwala bezpośrednio przenieść się na stronę wybranej kliniki.</p>
    `
},
{slug:"implantologia", name:"Implantologia"},
{slug:"znieczulenia", name:"Znieczulenia"},
{slug:"szyny-okluzyjne-i-terapeutyczne", name:"Szyny okluzyjne i terapeutyczne"},
{
    slug:"stomatologia-estetyczna",
    name:"Stomatologia estetyczna",
    seoText: `<p>Piękny i zadbany uśmiech to marzenie wielu osób. Aby tak się prezentował, zęby
    muszą być zdrowe. Bardzo często w wyniku różnych schorzeń, czy zaniedbań, ich
    stan pozostawia wiele do życzenia. W takiej sytuacji z pomocą przychodzi
    stomatologia estetyczna.</p>
    <h3>Czym jest stomatologia estetyczna?</h3>
    <p>Stomatologia estetyczna to dziedzina, która ma na celu zapewnić pacjentom
    piękny uśmiech. Obejmuje wiele zabiegów z zakresu 
    <a href="/uslugi-medyczne/stomatologia/leczenie-zachowawcze" target="_blank">leczenia zachowawczego</a> oraz
    <a href="/uslugi-medyczne/stomatologia/protetyka-i-implantoprotetyka" target="_blank">protetycznego</a>. Do stomatologii estetycznej zalicza się również 
    <a href="/uslugi-medyczne/stomatologia/ortodoncja" target="_blank">ortodoncję</a> i
    wybielanie zębów. Wszystkie te działania mają zniwelować różnego rodzaju
    uszkodzenia i ubytki, a także sprawić, że uśmiech będzie zdrowy.</p>
    <h2>Najpopularniejsze zabiegi z zakresu stomatologii estetycznej</h2>
    <p>Lista zabiegów, którym można się poddać w ramach stomatologii estetycznej, jest
    bardzo długa. Jednym z nich jest 
    <a href="/uslugi-medyczne/stomatologia/wybielanie-zebow" target="_blank">wybielanie zębów</a>. 
    Wybierają go pacjenci, których
    zęby mają nieatrakcyjny kolor m.in. ze względu na osad czy kamień. W gabinecie
    stomatolog stosuje specjalny żel oraz naświetlanie lampą. Można również zdecydować się na 
    <a href="/uslugi-medyczne/stomatologia/wybielanie-zebow-nakladkowe" target="_blank">wybielanie zębów nakładkami</a>.
    Przeciwwskazaniem do wykonania tego zabiegu jest przede wszystkim zaawansowana próchnica oraz
    ubytki. Aby można było go przeprowadzić, uzębienie musi być w ogólnie dobrej
    kondycji.
    </p>
    <p>
    Innym zabiegiem z zakresu stomatologii estetycznej jest 
    <a href="/uslugi-medyczne/stomatologia/scaling-usuwanie-kamienia" target="_blank">scaling</a>. 
    Polega on na usunięciu kamienia nazębnego z powierzchni zębów. Jego nadmiar nie tylko nie
    wygląda estetycznie, ale także może prowadzić do wielu poważnych chorób. Skaling
    bardzo często jest uzupełniany 
    <a href="/uslugi-medyczne/stomatologia/piaskowanie" target="_blank">piaskowaniem</a>,
    polegającym na wygładzeniu powierzchni zębów. Ogranicza to osadzanie się zanieczyszczeń. Skaling nie może
    być przeprowadzony u kobiet w ciąży, a także osób z astmą oraz rozrusznikiem
    serca.    
    </p>
    <p>
    Stomatologia estetyczna to także odbudowa uszkodzonych zębów. Braki w
    uzębieniu to nie tylko problem estetyczny, mogą one powodować także zaburzenia
    fizjologiczne. Osoby z niepełnym uzębieniem bardzo często borykają się z
    problemem zmienionych rysów twarzy czy przesuwania się pozostałych zębów.
    Odbudowa jest możliwa dzięki zastosowaniu 
    <a href="/uslugi-medyczne/stomatologia/most-adhezyjny" target="_blank">mostów</a> oraz 
    <a href="/uslugi-medyczne/stomatologia/korony" target="_blank">koron</a>. Wyglądem oraz
    funkcją przypominają one naturalne zęby.
    </p>
    <h2>Przeciwwskazania do zabiegów z zakresu stomatologii estetycznej</h2>
    <p>Zabiegi z zakresu stomatologii estetycznej mogą być przeprowadzone wyłącznie u
    pacjentów cieszących się dobrym zdrowiem. Nie mogą im poddać się osoby
    poniżej 16. roku życia. Jest to bardzo ważne ze względu na to, że w tym wieku szczęka
    jeszcze się kształtuje. Ponadto zabiegi z zakresu stomatologii estetycznej nie są
    wskazane u kobiet w ciąży.</p>
    <h2>Stomatologia estetyczna - ceny zabiegów</h2>
    <p>Ceny są uzależnione głównie od renomy stomatologa, a także lokalizacji jego
    gabinetu. Wszystko zależy także od tego, jaki zabieg ma być przeprowadzony. Ceny
    za podstawowe usługi, takie jak wybielanie martwego zęba, zaczynają się od 100 zł.
    Zdecydowanie najdroższe jest założenie 
    <a href="/uslugi-medyczne/stomatologia/licowka-porcelanowa" target="_blank">licówek porcelanowych</a>, 
    które mogą kosztować nawet do 3 500 zł.
    </p>`
},
{
    slug:"stomatologia-zachowawcza",
    name:"Stomatologia zachowawcza",
    seoText:`
    <h2>Leczenie zachowawcze zębów</h2>
    <p>Stomatologia zachowawcza obejmuje <b>zabiegi zapobiegające powstawaniu ubytków w
    uzębieniu i rozwojowi próchnicy</b>. Ma ona na celu zachowanie pełnego zdrowia zębów, a
    także ich estetycznego wyglądu. Dzięki regularnym przeglądom stomatologicznym
    możemy wykryć choroby uzębienia we wczesnym stadium i zapobiegać ich rozwojowi.</p>
    <h2>Jakie usługi wchodzą w zakres stomatologii zachowawczej?</h2>
    <p>Wśród możliwych metod leczenia zachowawczego wyróżniamy:</p>
    <ul>
        <li><a href="/uslugi-medyczne/stomatologia/leczenie-nadwrazliwosci" target="_blank">leczenie nadwrażliwości zębów</a>,</li>
        <li><a href="/uslugi-medyczne/stomatologia/impregnacja-lapisowanie-zebow-mlecznych" target="_blank">lapisowanie zębów mlecznych</a>,</li>
        <li><a href="/uslugi-medyczne/stomatologia/scaling-usuwanie-kamienia" target="_blank">scalling</a>,</li>
        <li>piaskowanie i fluoryzacja,</li>
        <li>wykonanie powyższych zabiegów łącznie w ramach pełnej higienizacji zębów,</li>
        <li>zwalczanie paradontozy.</li>
    </ul>
    <p>Można wyróżnić również <a href="/uslugi-medyczne/stomatologia/pedodoncja-stomatologia-dziecieca" target="_blank">pedodoncję (stomatologię dziecięcą)</a>, jako że zęby dzieci
    wymagają nieco innego traktowania, choćby ze względu <b>na trwającą wciąż fazę
    rozwojową</b>. Stomatologia dziecięca obejmuje również wizyty adaptacyjne i wyjaśnianie
    maluchom, jak ważna jest prawidłowa higiena zębów.
    </p>
    <h3>Jak wygląda wizyta z zakresu stomatologii zachowawczej?</h3>
    <p>Wizyta rozpoczyna się od określenia problemu poprzez rozmowę z pacjentem, po czym
    specjalista przechodzi <b>do przeglądu stanu uzębienia</b>. Po zlokalizowaniu problemu, pora na
    wdrożenie metod leczenia lub zastosowanie zabiegów, zapewniających większą higienę
    zębów i ich zwiększoną ochronę przed zbyt szybkim pojawieniem się chorób i ubytków.</p>
    <h2>Leczenie zachowawcze - wskazania</h2>
    <p>Regularne przeglądy stomatologiczne są zalecane wszystkim <b>co najmniej raz na pół roku</b>,
    co pozwoli na wczesne wykrycie ubytków, chorób i stanów zapalnych. Raz do roku warto
    zrobić pełną higienizację zębów, czyli wspomniany już scalling, piaskowanie, polerowanie i
    fluoryzację.</p>
    <h2>Ceny za leczenie zachowawcze</h2>
    <p>Cena zależna jest od czynników takich jak <b>rodzaj problemu i stopień jego zaawansowania</b>
    – niższe koszty wygeneruje wykonywanie regularnych przeglądów. Cena wzrasta przy
    stosowaniu plomb, wypełnień, a także w sytuacji, gdy usunięcie problemu wymaga odbycia
    kilku wizyt lub dodatkowych badań.
    </p>
    <h3>Czym jest stomatologia zachowawcza z endodoncją?</h3>
    <p>Jest to dział stomatologii <b>skupiony na leczeniu już powstałych ubytków na skutek
    próchnicy</b>, a także na chronieniu zębów przed jej wystąpieniem. Dużą rolę odgrywa tu
    endodoncja, skupiająca się na <a href="/uslugi-medyczne/stomatologia/leczenie-kanalowe-endodontyczne" target="_blank">leczeniu kanałowym</a>, 
    czyli na usunięciu miazgi z kanałów
    korzeniowych zębów. Wbrew obiegowej opinii o bolesności takiego leczenia, obecnie
    dostępne są już skuteczne metody znieczulenia, dzięki którym dyskomfort odczuwany przez
    pacjenta jest zniwelowany do minimum.
    </p>
    <h3>Stomatologia zachowawcza - poznaj ceny w Hot Medi!</h3>
    <p>Dzięki naszej wyszukiwarce zyskujesz możliwość znalezienia odpowiedniej dla Ciebie usługi
    na terenie całej Polski lub na wybranym obszarze. Na mapie pojawi się informacja o liczbie
    placówek świadczących <b>wybraną usługę wraz z przedziałem cenowym</b>. Obok mapy
    znajdziesz listę proponowanych placówek, dzięki której przeniesiesz się bezpośrednio na
    stronę wybranej kliniki.</p>
    `
},
{
    slug:"leczenie-kanalowe-endodontyczne-pod-mikroskopem",
    name:"Leczenie kanałowe pod mikroskopem",
    seoText: `<h2>Leczenie kanałowe pod mikroskopem - ceny</h2>
        <p>Leczenie kanałowe pod mikroskopem to zaawansowany sposób na walkę z problemami
        endodontycznymi. Wykorzystując precyzyjny mikroskop, lekarze dentystyczni są w stanie zauważyć
        znacznie więcej detali w kanałach korzeniowych, co przekłada się na większą dokładność oraz
        skuteczność procedury. W efekcie, leczenie kanałowe pod mikroskopem pozwala na efektywniejsze
        oczyszczenie kanałów korzeniowych i jednocześnie zapobiega wielu komplikacjom.
        </p>
        <p>W Hot Medi pomożemy Ci znaleźć odpowiednią placówkę w adekwatnych cenach w Twojej okolicy!</p>
        <h2>Cena za mikroskopowe leczenie kanałowe</h2>
        <p>Cena leczenia kanałowego pod mikroskopem może różnić się znacznie w zależności od wielu
        czynników. Ceny mogą wahać się na podstawie lokalizacji gabinetu dentystycznego, złożoności
        przypadku, doświadczenia i kwalifikacji lekarza, a także od używanych materiałów i technologii. W
        Polsce cena za leczenie kanałowe pod mikroskopem może wynosić od kilkuset do nawet 1 500 zł.
        </p>
        <p>Zawsze jest jednak zalecane uzyskanie dokładnej wyceny od lekarza dentysty przed rozpoczęciem
        leczenia. Ponadto, warto porównać ceny i usługi w różnych gabinetach. Aby znaleźć
        najodpowiedniejszą ofertę, bardzo ułatwia nasza<a href="/"target="_blank"> wyszukiwarka usług medycznych Hot Medi</a>.</P>
        <h2>Leczenie endodontyczne pod mikroskopem, a tradycyjna metoda leczenia kanałowego- ceny</h2>
        <p>W porównaniu do tradycyjnej metody, leczenie kanałowe pod mikroskopem jest bardziej precyzyjne i
        skuteczne. Dzięki użyciu mikroskopu, stomatolog ma lepszy wgląd w strukturę zęba, co przekłada się
        na dokładniejsze oczyszczanie kanałów korzeniowych. W związku z tym, że do leczenia kanałowego
        pod mikroskopem wykorzystywane są bardziej zaawansowane, powoduje to wyższy koszt za
        wykonanie zabiegu w porównaniu z tradycyjną metodą<a href="/uslugi-medyczne/stomatologia/leczenie-kanalowe-endodontyczne"target="_blank"> leczenia kanałowego</a>.</p>
        <h2>Ceny za mikroskopowe leczenie kanałowe - jak znaleźć odpowiednią placówkę?</h2>
        <p>Leczenie kanałowe pod mikroskopem jest coraz bardziej popularne w Polsce i jest dostępne w wielu
        gabinetach stomatologicznych w całym kraju. Niestety tak duża ilość różnego rodzaju gabinetów
        oferujących tę usługę sprawia, że dokonanie odpowiedniego wyboru może być dość ciężkie i
        czasochłonne. Z tego też względu szukając specjalisty oferującego mikroskopowe leczenie kanałowe
        warto skorzystać z wyszukiwarek usług medycznych. Jednym z takich rozwiązań jest nasza platforma
        Hot Medi, która oferuje wyszukiwanie placówek medycznych oferujących daną usługę medyczną, w
        tym przypadku leczenie kanałowe pod mikroskopem.</p>
        <h2> Ceny za leczenie kanałowe pod mikroskopem w Hot Medi</h2>
        <p>Leczenie kanałowe pod mikroskopem to bardzo zaawansowana i skuteczna metoda leczenia zębów,
        jednak, aby tak było należy wybrać odpowiedni gabinet, w którym pracują doświadczeni i
        wykwalifikowani specjaliści. Korzystając z naszej platformy Hot Medi można bez większego problemu
        wyszukać najbliższy gabinet oferujący tego typu profesjonalną usługę i dodatkowo porównać ceny, aby
        wybrać tą najbardziej opłacalną</p>
        <p>Sprawdź także <a href="/uslugi-medyczne/stomatologia/korony"target="_blank"> ceny za korony zębowe</a> oraz <a href="/uslugi-medyczne/stomatologia/scaling-usuwanie-kamienia"target="_blank"> usuwanie kamienia nazębnego</a>.</p>
    `
},
{
    slug:"ortodoncja",
    name:"Ortodoncja",
    seoText: `<p>Ortodoncja jest częścią stomatologii, która zajmuje się diagnostyką, leczeniem oraz profilaktyką
    różnych problemów związanych z ułożeniem zębów. Specjalistą w tej dziedzinie jest ortodonta.
    Pracuje on z pacjentami w każdym wieku, w tym także z dziećmi.</p>
    <h2>Zakres usług ortodonty</h2>
    <p>Specjalista ten przede wszystkim bada rozwój oraz wzrost zębów i szczęki, a także diagnozuje
    ewentualne nieprawidłowości. Zajmuje się również leczeniem 
    <a href="/uslugi-medyczne/stomatologia/chirurgia-stomatologiczna-i-szczekowo-twarzowa" target="_blank">wad szczękowo-twarzowych</a>, zgryzu
    oraz wszelkich nieprawidłowości zębowych. Najczęstsze schorzenia, które są przedmiotem
    zainteresowań tego specjalisty, to:</p>
    <p><b>stłoczenie</b> - położenie zębów za bardzo na zewnątrz lub do wewnątrz w stosunku do innych,</p>
    <p><b>zgryz otwarty</b> - polegający na braku stykania się zębów dolnych z górnymi,</p>
    <p><b>tyłozgryz</b> - nadmierne nachodzenie górnych zębów na dolne,</p>
    <p><b>zgryz głęboki</b> - nadmierne nachodzenie górnych zębów na dolne w odcinku przednim,</p>
    <p><b>zgryz krzyżowy</b> - położenie dolnych zębów na zewnątrz w stosunku do górnych,</p>
    <p><b>przodozgryz</b> - nadmierne wysunięcie dolnych przednich zębów przed górne.</p>
    <p>Do gabinetu ortodontycznego często zgłaszają się pacjenci, u których występują zbyt duże przerwy
    między zębami lub w szczęce pojawiły się nadprogramowe zęby. Ortodonta jest specjalistą
    zajmującym się także brakami w uzębieniu oraz nieprawidłowym rozwojem szczęki lub żuchwy.</p>
    <h2>Kiedy należy zgłosić się do ortodonty?</h2>
    <p>Do gabinetu ortodontycznego warto zgłosić się po zaobserwowaniu nieprawidłowego ułożenia
    zębów lub innych problemów ze szczęką. Bardzo często stomatolog kieruje na wizytę do tego
    specjalisty, jeśli podczas kontroli wykryje nieprawidłowości. Warto także zapisać dziecko do
    ortodonty, jeśli rodzice zauważą, że jego zęby nie rosną prawidłowo.</p>
    <p>Jedną z najczęściej stosowanych metod leczenia ortodontycznego jest założenie aparatu. Pozwala
    on skorygować różne wady zgryzu. Aparat może być stały, który mocuje się na zębach na określony
    czas, lub ruchomy, zdejmowany np. podczas jedzenia czy mycia zębów.</p>
    <h2>Przeciwwskazania do leczenia ortodontycznego</h2>
    <p>Mimo że stosowanie <a href="/uslugi-medyczne/stomatologia/aparat-staly-metalowy-czesciowy" target="_blank">aparatu ortodontycznego</a> 
    jest skuteczne, to nie u każdego pacjenta można go założyć. Przeciwwskazaniem jest m.in. 
    obniżona odporność organizmu oraz stany zapalne w jamie
    ustnej. Tej metody nie stosuje się także w przypadku chorób miazgi zębowej lub przyzębia.</p>
    <h2>Ortodoncja - od czego zależą ceny?</h2>
    <p>Bardzo często w uzyskaniu pięknego uśmiechu niezbędna jest wizyta u specjalisty, jakim jest
    ortodonta. Ceny usług są zróżnicowane i zależą przede wszystkim od miasta, w którym ma on swój
    gabinet, a także od jego renomy. Koszt wizyty jest uzależniony od zakresu usług i tego, co dokładnie
    będzie robił ortodonta.</p>
    <p>Ceny pobrania 
    <a href="/uslugi-medyczne/stomatologia/wycisk-pod-modele-orientacyjne-diagnostyczne" target="_blank">wycisków diagnostycznych</a>, 
    czy drobnej <a href="/uslugi-medyczne/stomatologia/naprawa-aparatu-ortodontycznego" target="_blank">naprawy aparatu</a> 
    ruchomego zaczynają się od 100 zł w górę. Zdecydowanie najdroższe jest założenie 
    <a href="/uslugi-medyczne/stomatologia/aparat-ruchomy-invisalign-nakladki" target="_blank">aparatu Invisalign</a>, czyli przezroczystych
    nakładek, które są niewidoczne na zębach, a zapewniają ich powrót na prawidłową pozycję. Cena
    takiej usługi wynosi od kilku do kilkunastu tysięcy złotych.
    </p>`
},
{
    slug:"wypelnienia",
    name:"Wypełnienia zębów",
    seoText: `<p>Uszkodzony ząb nie tylko powoduje dolegliwości bólowe, ale także może prowadzić
    do poważniejszych problemów. W takiej sytuacji niezbędne jest wykonanie jego
    wypełnienia. Ma ono na celu uzupełnić powstałe ubytki, które pojawiły się w wyniku
    próchnicy lub uszkodzeń mechanicznych. Wypełnienie zęba to nic innego, jak
    materiał, który trwale łączy się z naturalnym zębem. Dzięki niemu odzyskuje on swój
    prawidłowy kształt.</p>
    <h2>Rodzaje wypełnień zębów</h2>
    <h3>Złote wypełnienia zębowe</h3>
    <p>Uszkodzony ząb może być wypełniony złotem. Takie plomby wykonuje się w
    laboratorium i następnie wkłada się w ubytek w gabinecie dentystycznym.
    Wypełnienia ze złota są dobrze tolerowane przez organizm. Wyróżnia je także
    trwałość wynosząca ponad 20 lat. Ich zaletą jest także odporność na uszkodzenia
    mechaniczne. Jednak takie plomby są bardzo drogie ze względu na kruszec
    wykorzystywany do ich produkcji.
    </p>
    <h3>Wypełnienia amalgamatowe</h3>
    <p>Innym wypełnieniem jest amalgamat. Również jest odporny na uszkodzenia oraz
    cechuje go żywotność wynosząca około 15 lat. Jest tańszy od złota, jednak nie
    prezentuje się zbyt estetycznie. Nie pasuje do koloru zębów, a ze względu na swój
    ciemny odcień bardzo często jest mylony z ubytkami próchnicznymi.</p>
    <h3>Wypełnienia kompozytowe</h3>
    <p>Wypełnienie stomatologiczne może być także wykonane z tworzywa sztucznego, a
    dokładniej żywicy kompozytowej. Pasuje kolorem do wyglądu naturalnych zębów,
    dlatego warto je zastosować w miejscach bardzo widocznych, np. na przodzie.
    Składniki wypełnienia miesza się ze sobą oraz umieszcza w ubytku, gdzie twardnieją.
    Sprawdzają się przede wszystkim w przypadku małych uszkodzeń. Nie są jednak
    bardzo trwałe, gdyż mogą wytrzymać około 10 lat. Ulegają stopniowemu zużyciu na
    skutek picia kawy i herbaty oraz palenia tytoniu.
    </p>
    <h3>Wypełnienia porcelanowe</h3>
    <p>Najlepszym rozwiązaniem są bez wątpienia wypełnienia porcelanowe. Są w stanie
    sobie poradzić także ze sporym ubytkiem. Wyróżnia je wysoka trwałość oraz
    odporność na uszkodzenia mechaniczne i przebarwienia.
    </p>
    <h2>Wskazania do wypełnienia zębów</h2>
    <p>Wypełnienie zęba można wykonać tylko po wyeliminowaniu przyczyny ubytku.
    Oznacza to, że wcześniej niezbędne jest przeprowadzenie 
    <a href="/uslugi-medyczne/stomatologia/leczenie-kanalowe-endodontyczne" target="_blank">leczenia kanałowego</a>. To,
    czy wykonanie wypełnienia jest możliwe, zależy przede wszystkim od oceny
    stomatologa.
    </p>
    <h2>Ile kosztuje wypełnienie zęba?</h2>
    <p>Przed udaniem się na zabieg, warto wiedzieć, ile kosztuje wypełnienie zęba. Cena
    jest uzależniona głównie od lokalizacji gabinetu oraz zastosowanej 
    <a href="/uslugi-medyczne/stomatologia/wypelnienie-ubytkow-zeba-plomba" target="_blank">plomby</a>.
    Najtańsze jest założenie <a href="/uslugi-medyczne/stomatologia/wypelnienia-ubytkow-zeba-mlecznego" target="_blank">wypełnienia do zęba mlecznego</a>, 
    które kosztuje od 100 zł. Od takiej kwoty zaczynają się również ceny 
    <a href="/uslugi-medyczne/stomatologia/wypelnienie-ubytkow-zeba-glass-jonomerowe-chemoutwardzalne" target="_blank">plomb kompozytowych chemoutwardzalnych</a>
    w ciemnych kolorach. Najdroższe są wypełnienia
    porcelanowe oraz złote.</p>
    <h2>Wypełnienie stomatologiczne - zalecenia po zabiegu</h2>
    <p>Mimo że nowoczesne plomby są trwałe i od razu nadają się do użytku, to warto
    zrezygnować przez około 2 godziny z jedzenia i picia. Dokładne zalecenia są jednak
    wydawane każdemu pacjentowi indywidualnie.</p>`
},
{
    slug:"rtg",
    name:"RTG stomatologiczne",
    seoText: `
        <h2>Zdjęcie RTG zębów</h2>
        <p>Zdjęcie rentgenowskie zębów, nazywane potocznie RTG zębów, to badanie z zakresu
        diagnostyki obrazowej, które umożliwia stomatologom, ortodontom oraz
        protetykom lepsze dopasowanie metody leczenia. Do badania wykorzystywane jest
        promieniowanie X, dzięki któremu widoczne są stany zapalne w zębie, liczba
        kanałów korzeniowych, a nawet budowa uzębienia.</p>
        <p>Dzięki RTG zębów możliwa jest szybka i bezbolesna diagnostyka chorób miazgi i
        przyzębia, wymagająca natychmiastowego leczenia.</p>
        <h2>Kiedy wykonuje się zdjęcie RTG zęba?</h2>
        <p>Badanie RTG zębów jest podstawą do prawidłowej oceny stanu uzębienia, tkanek
        znajdujących się w jamie ustnej oraz w kościach szczęki. To na podstawie 
        wykonanego zdjęcia stomatolog jest w stanie wybrać odpowiednią metodę leczenia.</p>
        <h2>RTG stomatologiczne - wskazania</h2>
        <p>Wskazaniem do przeprowadzenia RTG stomatologicznego jest:</p>
        <ul>
            <li>próchnica zębowa,</li>
            <li>trudny do zlokalizowania ból zęba,</li>
            <li>zapalenie miazgi zęba, prowadzące do <a href="/uslugi-medyczne/stomatologia/leczenie-kanalowe-endodontyczne" target="_blank">leczenia kanałowego</a>,</li>
            <li><a href="/uslugi-medyczne/stomatologia/usuniecie-zeba-ekstrakcja" target="_blank">ekstrakcja zęba</a>,</li>
            <li><a href="/uslugi-medyczne/stomatologia/ortodoncja" target="_blank">leczenie ortodontyczne</a>,</li>
            <li><a href="/uslugi-medyczne/stomatologia/protetyka-i-implantoprotetyka" target="_blank">leczenie protetyczne</a>.</li>
        </ul>
        <h2>Przeciwwskazania do zdjęcia RTG zębów</h2>
        <p>Nie należy wykonywać zdjęć RTG podczas ciąży, karmienia piersią oraz w trakcie
        miesiączkowania.</p>
        <p>Większość zdjęć RTG jest przeprowadzanych w gabinecie stomatologicznym.
        Pacjenci, którzy chcą skontrolować stan uzębienia w ramach NFZ, będą musieli
        jednak posiadać skierowanie od lekarza. W ramach NFZ można dwa razy w roku
        wykonać zdjęcie zębowe wewnątrzustne, a raz na 3 lata – zdjęcie
        pantomograficzne.</p>
        <h2>Ceny za RTG zęba w prywatnych gabinetach stomatologicznych</h2>
        <p>Koszt wykonania RTG zęba będzie różnił się w zależności od rodzaju badania
        obrazowego, jakie stomatolog zdecyduje wykonać. Niektóre prywatne gabinety
        stomatologiczne w ramach leczenia wykonują zdjęcie bezpłatnie.</p>
        <h2>Od czego zależy cena za zdjęcie RTG zęba?</h2>
        <p>Cena RTG stomatologicznego może się również różnić w zależności od rodzaju
        gabinetu dentystycznego oraz prywatnego punktu RTG w danym mieście.</p>
        <h3>Rodzaje zdjęć RTG zębów</h3>
        <p>Obecnie wyróżniamy kilka typów badania:</p>
        <h4>RTG punktowe zęba</h4>
        <p>Służy ono do sprawdzenia stanu konkretnych zębów w jamie ustnej. Najczęściej jest
        ono wykonywane przed rozpoczęciem leczenia kanałowego w celu weryfikacji
        rozległości zapalenia.</p>
        <h4>RTG pantomograficzne</h4>
        <p>Zdjęcie <a href="/uslugi-medyczne/stomatologia/zdjecie-rtg-pantomograficzne" target="_blank">RTG pantomograficzne</a> 
        pozwala na sprawdzenie stanu całej jamy ustnej, co
        przydaje się podczas planowania leczenia ortodontycznego bądź przy urazach
        szczęki i żuchwy. Obraz uchwytuje również ósemki, które w przyszłości mogą
        wywierać nacisk na pozostałe zęby w zgryzie.</p>
        <h4>RTG cefalometryczne</h4>
        <p><a href="/uslugi-medyczne/stomatologia/zdjecie-rtg-cefalometryczne" target="_blank">RTG cefalometryczne</a> najczęściej wykonywane jest w trakcie leczenia
        ortodontycznego oraz protetycznego. Oprócz wad zgryzu, widoczne są również
        wszystkie tkanki w jamie ustnej, kości czaszki oraz zatoki.</p>
        <h2>Jak przebiega wykonanie RTG stomatologicznego?</h2>
        <p>Dawka promieniowania X w trakcie badania nie jest szkodliwa dla pacjentów
        stomatologicznych. Jest ona zbyt mała, aby negatywnie wpłynąć na
        funkcjonowanie organizmu. Dodatkowo przed wykonaniem zdjęcia RTG zęba,
        stomatolog lub specjalista zakłada na pacjenta specjalny fartuch, który chroni
        przed promieniowaniem.</p>
        <p>Do samego zdjęcia RTG wykorzystuje się kliszę rentgenowską, która umieszczana
        jest przy zębie poddawanym badaniu. Samo zrobienia zdjęcia jest zupełnie
        bezbolesne.</p>`
},
{slug:"medycyna-estetyczna", name:"Medycyna estetyczna"},
{slug:"peeling", name:"Peeling"},
{slug:"patomorfologia", name:"Patomorfologia"},
{slug:"tomografia-komputerowa", name:"Tomografia komputerowa"},
{slug:"konsultacje", name:"Konsultacje"},
{slug:"konsultacja-stomatologiczna", name:"Konsultacja stomatologiczna"},
{slug:"laseroterapia", name:"Laseroterapia"},
{slug:"mezoterapia-odmladzanie-i-rewitalizacja-skory", name:"Mezoterapia - odmładzanie i rewitalizacja skóry"},
{slug:"endodoncja", name:"Endodoncja"},
{slug:"pedodoncja-stomatologia-dziecieca", name:"Pedodoncja (stomatologia dziecięca)"},
{slug:"periodontologia", name:"Periodontologia"},
{slug:"konsultacja-implantologiczna", name:"Konsultacja implantologiczna"},
{slug:"konsultacja-chirurgiczna", name:"Konsultacja chirurgiczna"},
{slug:"konsultacja-protetyczna", name:"Konsultacja protetyczna"},
{slug:"konsultacja-szczekowo-twarzowa", name:"Konsultacja szczękowo-twarzowa"},
{slug:"konsultacja-ortodontyczna", name:"Konsultacja ortodontyczna"},
{slug:"konsultacja-pedodontyczna", name:"Konsultacja pedodontyczna"},
{slug:"konsultacja-endodontyczna", name:"Konsultacja endodontyczna"},
{slug:"lakierowanie", name:"Lakierowanie"},
{slug:"lakowanie", name:"Lakowanie"},
{
    slug:"profilaktyka-i-higienizacja",
    name:"Profilaktyka i higienizacja stomatologiczna",
    seoText: `<p>Troska o stan jamy ustnej oraz zębów ma kluczowe znaczenie dla pięknego i
    zdrowego uśmiechu. W tym celu należy pamiętać o regularnych wizytach u
    stomatologa oraz poddawaniu się różnym zabiegom, takim jak na przykład
    higienizacja.</p>
    <h3>Czym jest higienizacja stomatologiczna?</h3>
    <p>Higienizacja stomatologiczna polega na profilaktycznym usunięciu osadów, w tym
    kamienia nazębnego. Jest uzupełnieniem standardowej higieny jamy ustnej
    wykonywanej w domu, gdyż samodzielne pozbycie się niektórych osadów jest
    bardzo trudne lub wręcz niemożliwe. Właściwa profilaktyka stomatologiczna
    pozwala nie tylko cieszyć się pięknym uśmiechem, ale i zdrowymi zębami. Dzięki
    regularnym przeglądom można uniknąć poważnych chorób, które mogą prowadzić
    nawet do utraty zębów.</p>
    <h2>Profilaktyka zębów - zakres usług</h2>
    <p>Profilaktyka zębów, polegająca na higienizacji, składa się najczęściej z kilku etapów.
    Pierwszym z nich jest skaling, czyli <a href="/uslugi-medyczne/stomatologia/scaling-usuwanie-kamienia" target="_blank">usuwanie kamienia nazębnego</a>. 
    Następnie stomatolog wykonuje <a href="/uslugi-medyczne/stomatologia/scaling-piaskowanie-lakierowanie-flooryzacja" target="_blank">piaskowanie</a>, 
    którego zadaniem jest pozbycie się osadów oraz wszelkich przebarwień. Wygładzanie powierzchni zębów, czyli polerowanie, ma
    na celu ograniczyć odkładanie się nalotów w przyszłości. Z kolei 
    <a href="/uslugi-medyczne/stomatologia/lakierowanie-fluorkowe-fluoryzacja" target="_blank">fluoryzacja</a> to
    wzmacnianie naturalnego szkliwa fluorem. Zabieg ten zwiększa odporność zębów
    na próchnicę.
    </p>
    <h2>Wskazania do higienizacji zębów</h2>
    <p>Higienizacja zębów może być wykonywana u każdego pacjenta, jednak szczególnie
    jest polecana osobom z tendencją do próchnicy. Dotyczy to przede wszystkim tych,
    którzy spożywają dużo cukrów w codziennej diecie. Zaleca się jednak, aby każdy
    poddawał się higienizacji raz lub dwa razy w ciągu roku.</p>
    <p>Osoby palące papierosy i pijące kawę lub herbatę są bardziej narażone na
    przebarwienia.</p>
    <h2>Przeciwwskazania do higienizacji stomatologicznej</h2>
    <p>Przeciwwskazanie do higienizacji zębów to przede wszystkim astma i osteoporoza
    oraz ciąża, w szczególności w pierwszym i trzecim trymestrze. Zabiegu nie
    przeprowadza się również u osób z rozrusznikiem serca.</p>
    <h2>Profilaktyka zębów — cena</h2>
    <p>Cena za higienizację i profilaktykę stomatologiczną zależy głównie od lokalizacji
    gabinetu oraz tego, czy wykonywany jest pojedynczy zabieg, czy pełen pakiet. Ceny
    wahają się od 100 zł za wizytę do nawet 1200 zł za pakiet higienizacyjny wraz z
    <a href="/uslugi-medyczne/stomatologia/wybielanie-zebow" target="_blank">wybielaniem zębów</a>.</p>
    <h2>Higienizacja zębów - ile trwa zabieg?</h2>
    <p>Długość trwania zabiegu zależy głównie od stanu jamy ustnej oraz ilości
    nagromadzonego kamienia i osadu. Pełna higienizacja zajmuje zazwyczaj od 40 do
    60 minut.</p>
    <h3>Profilaktyka i higienizacja stomatologiczna - zalecenia po zabiegu</h3>
    <p>Zabiegi są bezpieczne i bezbolesne. Po ich przeprowadzeniu pacjenci powinni przez
    około 2 godziny powstrzymać się od jedzenia i picia. W razie wystąpienia podrażnień
    dziąsła lub błony śluzowej jamy ustnej można zastosować delikatne płukanki
    ziołowe. Pełną higienizację można powtórzyć najwcześniej po upływie 6 miesięcy</p>`
},
{slug:"konsultacja-periodentologiczna", name:"Konsultacja periodentologiczna"},
{slug:"pakiet", name:"Pakiet"},
{
    slug:"konsultacje-stomatologiczne",
    name:"Konsultacje stomatologiczne",
    seoText: `<p>Konsultacje stomatologiczne to wizyty kontrolne u dentysty, podczas których specjalista ocenia stan
    uzębienia oraz całej jamy ustnej. Są one niezbędne do zachowania zdrowia oraz zapewnienia
    pięknego uśmiechu. Podczas takich wizyt stomatolog przeprowadza także różnego rodzaju zabiegi,
    które mają na celu wyeliminowanie problemów, z którymi boryka się pacjent.</p>
    <h2>Jak przebiega konsultacja stomatologiczna?</h2>
    <p>Kompleksowa konsultacja stomatologiczna powinna składać się z dwóch części. Pierwszą z nich jest
    wywiad lekarski, podczas którego dentysta zadaje pytania dotyczące ogólnego stanu zdrowia,
    przyjmowanych leków, czy niepokojących dolegliwości, które zaobserwował pacjent. Stomatolog
    pyta również o choroby przewlekłe, gdyż niektóre z nich, takie jak np. cukrzyca czy zakrzepica, mogą
    wpływać na stan uzębienia oraz jamy ustnej.</p>
    <p>Drugą część wizyty stanowi przegląd jamy ustnej. Podczas niego dentysta sprawdza stan zębów, a
    także założonych <a href="/uslugi-medyczne/stomatologia/wypelnienie-ubytkow-zeba-plomba" target="_blank">wypełnień</a>. 
    Ocenia, czy nie pojawiły się ogniska próchnicy lub inne niepokojące
    zmiany. Lekarz sprawdza również stan szyjek oraz korzeni zębowych i dziąseł. Pacjent powinien
    poinformować stomatologa, gdy w trakcie badania odczuje dolegliwości bólowe. Są one bowiem
    cenną wskazówką dla dentysty.</p>
    <h2>Jak często należy chodzić na konsultację stomatologiczną?</h2>
    <p>Do dentysty należy chodzić regularnie, najlepiej dwa razy w roku. W przypadku dzieci stomatolog
    powinien obejrzeć jamę ustną po pojawieniu się w niej pierwszych zębów. Zaleca się, aby maluchy
    odbywały konsultacje nawet co trzy miesiące. Podczas nich dentysta będzie mógł ocenić, czy zęby
    rozwijają się prawidłowo.</p>
    <h2>Najczęstsze przyczyny konsultacji stomatologicznych</h2>
    <p>Wizyty u dentysty bardzo często są jednak nieplanowane i wynikają z pojawienia się pewnych
    dolegliwości. Na konsultację stomatologiczną warto udać się w przypadku wystąpienia:</p>
    <ul>
        <li>bólu zębów podczas jedzenia,</li>
        <li>nagłego i silnego bólu, który nie ma żadnej konkretnej przyczyny,</li>
        <li>długotrwałego krwawienia dziąseł,</li>
        <li>nieprzyjemnego zapachu z ust,</li>
        <li>przebarwień na błonach śluzowych jamy ustnej.</li>
    </ul>
    <p>Wskazaniem do wizyty u stomatologa są także wszelkie uszkodzenia zębów lub pojawienie się w nich
    ubytków. Wdrożenie odpowiedniego leczenia pozwoli usunąć przyczyny dolegliwości, a bardzo
    często jest także sposobem na uratowanie zęba.</p>
    <h2>Konsultacja stomatologiczna — od czego zależą ceny za wizytę?</h2>
    <p>Cena konsultacji stomatologicznej zaczyna się od 100 zł. Zależy ona jednak od miasta, w którym
    zlokalizowany jest gabinet oraz jego renomy. Im bardziej skomplikowane leczenie trzeba zastosować,
    tym droższa będzie wizyta. Wielu dentystów przyjmuje pacjentów także w ramach umowy z NFZ.
    Jednak w wielu sytuacjach prywatne leczenie jest zdecydowanie lepszym pomysłem. Przede
    wszystkim umawiając taką wizytę, możesz liczyć na szybki termin, co w przypadku bólu zęba, czy
    innych problemów ma kluczowe znaczenie. Bardzo często lekarze prowadzący prywatne gabinety
    przyjmują pacjentów w różnych godzinach, także późnym wieczorem, co znacząco ułatwia
    dopasowanie terminu do indywidualnych potrzeb.
    </p>`
},
{slug:"pozostale-uslugi", name:"Pozostałe usługi"},
{slug:"augmentacja-kostna-gbr-guided-bone-regeneration", name:"Augmentacja kostna (GBR- Guided Bone Regeneration)"},
{slug:"zdjecie-korony-mostu", name:"Zdjęcie korony / mostu"},
{slug:"lacznik-indywidualny", name:"Łącznik indywidualny"},
{slug:"lacznik-cyrkonowy", name:"Łącznik cyrkonowy"},
{slug:"korona-porcelanowa", name:"Korona porcelanowa"},
{slug:"korona-metalowa", name:"Korona metalowa"},
{slug:"korona-tymczasowa", name:"Korona tymczasowa"},
{slug:"cementowanie-korony", name:"Cementowanie korony"},
{slug:"endokorona", name:"Endokorona"},
{slug:"piaskowanie", name:"Piaskowanie"},
{slug:"gingivektomia-gingivoplastyka", name:"Gingivektomia, Gingivoplastyka"},
{slug:"leczenie-amputacyjne-przyzyciowe-pulpotomia", name:"Leczenie amputacyjne przyżyciowe (Pulpotomia)"},
{slug:"wymiana-ligatur", name:"Wymiana ligatur"},
{slug:"lacznik", name:"Łącznik"},
{slug:"mikroproteza", name:"Mikroproteza"},
{
    slug:"most-adhezyjny",
    name:"Most adhezyjny",
    seoText: `<h2>Most adhezyjny - co to jest?</h2>
        <p>Most adhezyjny to tymczasowe rozwiązanie dla uzupełnienia braku zęba. Jeśli ekstrakcja
        zęba była konieczna, a chcemy zachować estetykę uśmiechu, warto zdecydować się na tę
        metodę uzupełnienia braku zęba, gdyż w czasie jednej lub dwóch wizyt most adhezyjny
        może zostać założony bez konieczności szlifowania zębów sąsiadujących. Jest ont metodą
        trwałą, niewyjmowaną, gdyż mocuje się go do zębów obok luki za pomocą służących do
        tego celu żywic i cementów.</p>
        <h2>Most adhezyjny - jak długo wytrzyma?</h2>
        <p>Most adhezyjny jest niestety metodą krótkotrwałą, zazwyczaj wykorzystywany jest jako most
        tymczasowy, z reguły wytrzymuje od 6 do 8 miesięcy. Jest podatny na uszkodzenia, dlatego
        wymaga ostrożności po jego założeniu.</p>
        <h2>Kiedy należy założyć most adhezyjny?</h2>
        <p>Ważnym jest, aby luka po usunięciu zęba nie pozostawała na dłużej bez uzupełnienia,
        ponieważ brak choćby jednego zęba znacząco wpływa na wady zgryzu. Sąsiadujące zęby z
        czasem przemieszczają się w stronę luki, w efekcie czego następują zmiany w łuku
        zębowym poprzez tworzenie się szczelin międzyzębowych. Braki w uzębieniu doprowadzają
        do zaniku kości, jak również powodują stany zapalne dziąseł.</p>
        <h3>Most adhezyjny - dlaczego warto?</h3>
        <p>Most adhezyjny posiada wiele zalet, a najważniejsze z nich to:</p>
        <ul>
            <li>szybkość wykonania uzupełnienia,</li>
            <li>mała inwazyjność zabiegu</li>
            <li>bezbolesność,</li>
            <li> brak konieczności szlifowania zębów sąsiadujących,</li>
            <li>stosunkowo niska cena w porównaniu z innymi metodami uzupełnień braków
            zębowych.</li>
        </ul>
        <h2>Most adhezyjny - przeciwwskazania</h2>
        <p>Niektóre wady zgryzu, np. tyłozgryz, zgryz głęboki, zgrzytanie zębami lub mocne zaciskanie
        zębów, stanowią przeciwwskazania do tej metody uzupełnienia. Bardzo ważnym czynnikiem
        jest to, aby zęby sąsiadujące były zdrowe, mocne, bez rozchwiań, bo to właśnie one tworzą
        solidną konstrukcję dla mostu.
        </p>
        <h2>Most adhezyjny - cena</h2>
        <p>Cena usługi założenia mostu adhezyjnego jest zależna od kilku czynników, a w
        szczególności od użytego materiału, lokalizacji oraz renomy gabinetu stomatologicznego.
        Za pomocą <a href="/" target="_blank">wyszukiwarki usług medycznych Hot Medi</a> mogą Państwo zaoszczędzić czas i
        pieniądze, decydując się na wizytę w miejscu, które gwarantuje dogodne warunki i atrakcyjną cenę.
        </p>
        <p>
        Zachęcamy również do zapoznania się z <a href="/uslugi-medyczne/stomatologia/most-adhezyjny-kompozytowy-na-wloknie-szklanym" target="_blank">cenami mostu adhezyjnego na włóknie szklanym.</a>
        </p>
    `
},
{slug:"naprawa-protezy", name:"Naprawa protezy"},
{slug:"narkoza-znieczulenie-ogolne", name:"Narkoza (znieczulenie ogólne)"},
{slug:"gaz-rozweselajacy-sedacja-wziewna", name:"Gaz rozweselający (sedacja wziewna)"},
{slug:"usuniecie-wkladu", name:"Usunięcie wkładu"},
{slug:"otwarcie-zeba", name:"Otwarcie zeba"},
{slug:"opatrunek-leczniczy", name:"Opatrunek leczniczy"},
{
    slug:"odbudowa-zeba",
    name:"Odbudowa zęba",
    seoText: `<h2>Odbudowa złamanego zęba - poznaj ceny</h2>
        <p>Zabieg odbudowy zęba jest konieczny, gdy nastąpi uszkodzenie jego korony z przyczyn
        mechanicznych, leczenia kanałowego, jak również poprzez rozległą próchnicę. Odbudowa
        zęba jest zabiegiem pozwalającym na odtworzenie tkanek zęba przy pomocy użytych do
        tego celu materiałów. Zachęcamy do zapoznania się z cenami tej usługi za pomocą
        wyszukiwarki usług medycznych Hot Medi , gdzie pomożemy Ci znaleźć odpowiednią
        placówkę w adekwatnych cenach w Twojej okolicy!</p>
        <h2>Cena za odbudowę zęba - od czego zależy?</h2>
        <p>Cena za zabieg odbudowy zęba zależy od stopnia uszkodzenia zęba, wykorzystanej metody
        odbudowy, użytych materiałów oraz od dodatkowych badań diagnostycznych i konsultacji
        w klinice, niezbędnych do przeprowadzenia zabiegu.</p>
        <h2>Wybór metody odbudowy zęba</h2>
        <p>Sposób odbudowy, jaki zostanie wybrany, jest zależny od wielu czynników, a w
        szczególności od:</p>
        <ul>
            <li>grubości pozostałych ścian zęba,</li>
            <li>ilości utraconych tkanek,</li>
            <li>warunków zgryzowych,</li>
            <li> wieku pacjenta,</li>
            <li>stanu pozostałych zębów i zdrowia dziąseł,</li>
            <li> od możliwości finansowych pacjenta.</li>
        </ul>
        <h2>Odbudowa zęba na korzeniu</h2>
        <p>Odbudowę zęba na korzeniu stosuje się po poważnym uszkodzeniu <a href="/uslugi-medyczne/stomatologia/korony" target="_blank">korony</a>.</p>
        <p>Rekonstrukcję zęba przeprowadza się różnymi metodami:</p>
        <ul>
            <li>przez <a href="/uslugi-medyczne/stomatologia/odbudowa-zeba-na-wkladzie-z-wlokna-szklanego" target="_blank">odbudowę zęba na włóknie szklanym</a>,</li>
            <li>przez <a href="/uslugi-medyczne/stomatologia/odbudowa-zeba-kompozytowa" target="_blank">odbudowę zęba materiałem kompozytowym</a>,</li>
            <li>przez zastosowanie <a href="/uslugi-medyczne/stomatologia/wklad-koronowo-korzeniowy" target="_blank">wkładu koronowo-korzeniowego</a>,</li>
            <li>przy zastosowaniu <a href="/uslugi-medyczne/stomatologia/korona-protetyczna-cadcam" target="_blank">korony protetycznej</a>.</li>
        </ul>
        <p>
        Wybór odpowiedniej metody zależy przede wszystkim od stopnia uszkodzenia zęba. Przed
        zabiegiem lekarz stomatolog musi dokładnie ocenić stan zdrowia zęba i korzenia, żeby
        wybrać najlepszą metodę, która będzie odpowiadała możliwościom finansowym pacjenta.
        </p>
        <h3>Zalety odbudowy zęba na korzeniu</h3>
        <p>Odbudowa zęba na korzeniu to bardzo skuteczne leczenie stomatologiczne, dzięki któremu
        zostaje przywrócony naturalny wygląd zęba. Sprawia, że są one wytrzymałe, co gwarantuje
        prawidłowe gryzienie i żucie. Zaletą jest także trwałość, która zależy od użytych materiałów.
        Efekty każdej odbudowy zęba utrzymują się minimum 5 lat. Zabieg ten poprawia estetykę
        jamy ustnej i zapobiega konieczności <a href="/uslugi-medyczne/stomatologia/usuniecie-zeba-ekstrakcja" target="_blank">usunięcia (ekstrakcji) zęba</a>.
        </p>
        <h2>Przeciwwskazania do odbudowy zęba na korzeniu</h2>
        <p>Odbudowa zęba na korzeniu nie w każdym przypadku jest możliwa. Przeciwwskazaniami do
        zastosowania tej metody są:</p>
        <ul>
            <li>duże zniszczenie korony zęba, które sięga okolicy poddziąsłowej,</li>
            <li>złamane lub zbyt krótkie korzenie,</li>
            <li>zaawansowane choroby przyzębia.</li>
        </ul>
        <p>Należy też powiadomić lekarza o uczuleniach i alergiach, aby użyty materiał nie uczulał pacjenta.</p>
        <h2>Odbudowa zęba na korzeniu</h2>
        <p>Koszt odbudowy zęba na korzeniu jest zależny przede wszystkim od stopnia uszkodzenia
        zęba oraz od zastosowanej metody i użytych materiałów do odbudowy. Możliwości
        finansowe pacjenta mogą być głównym czynnikiem, co do wyboru metody rekonstrukcji.
        Przed zabiegiem lekarz stomatolog ocenia stan jamy ustnej, wielkość ubytku zęba, stan
        korzenia, zgryz i otaczającego go kości czaszki. Po wnikliwej analizie stomatolog proponuje
        metodę odbudowy zęba biorąc pod uwagę oczekiwania pacjenta oraz jego możliwości
        finansowe.
        </p>
        <h3>Od czego zależy cena za odbudowę zęba na korzeniu?</h3>
        <p>Koszty zabiegów odbudowy zęba na korzeniu są zależne od lokalizacji i renomy gabinetu
        stomatologicznego, a także od zastosowanej metody leczenia. Zachęcamy do zapoznania
        się z cenami odbudowy zęba na korzeniu za pomocą <a href="/" target="_blank">wyszukiwarki usług medycznych Hot Medi</a>, 
        gdzie pomożemy Ci znaleźć odpowiednią placówkę w adekwatnych cenach w Twojej okolicy!
        </p>
        <h2>Odbudowa zęba po leczeniu kanałowym - cena</h2>
        <p>Odbudowa zęba po <a href="/uslugi-medyczne/stomatologia/leczenie-kanalowe-endodontyczne" target="_blank">leczeniu kanałowym</a> 
        powinna zostać wykonana możliwie szybko,
        ponieważ ząb po takim leczeniu pozostaje pusty i martwy w środku, i jest bardzo wrażliwy
        na uszkodzenia. W gabinetach stomatologicznych są różne możliwości 
        <a href="/uslugi-medyczne/stomatologia/odbudowa-zeba-po-leczeniu-endodontycznym-kanalaowym" target="_blank">odbudowy zęba po leczeniu kanałowym</a>
        z ceną zależną od sposobu odbudowy. Koszty ustalane są
        indywidualnie i są przedstawiane w trakcie przygotowania planu leczenia. Metoda
        odbudowy jest zależna od stopnia uszkodzenia zęba i jest ona dobierana indywidualnie
        biorąc pod uwagę ogólny stan tkanek jamy ustnej i możliwości finansowe pacjenta.</p>
        <h2>Metody odbudowy zęba po leczeniu kanałowym</h2>
        <ul>
            <li>odbudowa zęba kompozytem,</li>
            <li>odbudowa zęba na wkładzie z włókna szklanego,</li>
            <li>odbudowa zęba przy zastosowaniu korony protetycznej,</li>
            <li>odbudowa z zastosowaniem <a href="/uslugi-medyczne/stomatologia/endokorona" target="_blank">endokorony</a>.</li>
        </ul>
        <h2>Odbudowa złamanego zęba - cena</h2>
        <p>Odbudowa zęba to szereg zabiegów, które przywracają funkcjonalność oraz brakującą
        strukturę zęba w wyniku jego złamania.</p>
        <p>W stomatologii istnieją dziś liczne metody odbudowy złamanego zęba. Metoda zależna jest
        od stopnia uszkodzenia zęba, który uległ złamaniu. Jeśli jest ono niewielkie to zostaje
        uzupełnione kompozytem. Zdarza się, że złamany ząb musi być leczony kanałowo i
        odbudowany protetycznie. Stałe odbudowy protetyczne, takie jak licówki, korony i 
        <a href="/uslugi-medyczne/stomatologia/most-adhezyjny" target="_blank">mosty</a>,
        wykonywane są w przypadku większych pęknięć. Warto wspomnieć, że istnieje możliwość
        odbudowy zęba po urazie przy pomocy odłamanego fragmentu zęba. Jeśli ząb został
        ukruszony należy odszukać jego fragment i szybko udać się do dentysty. Cena odbudowy
        złamanego zęba zależna jest od wybranej metody i rozległości braków.
        </p>
        `
},
{slug:"leczenie-nadwrazliwosci", name:"Leczenie nadwrażliwości"},
{slug:"utrzymywacz-przestrzeni", name:"Utrzymywacz przestrzeni"},
{slug:"dewitalizacja-zeba-zatrucie", name:"Dewitalizacja zęba (zatrucie)"},
{slug:"inlay-onlay-overlay", name:"Inlay, Onlay, Overlay"},
{
    slug:"wklad-koronowo-korzeniowy",
    name:"Wkład koronowo-korzeniowy",
    seoText: `
        <h2>Czym jest wkład koronowo-korzeniowy?</h2>
        <p>Wkład koronowo-korzeniowy to stałe uzupełnienie protetyczne wykorzystywane
        między innymi do odbudowy <a href="/uslugi-medyczne/stomatologia/leczenie-kanalowe-endodontyczne" target="_blank">zębów leczonych kanałowo</a>. Wkład składa się z dwóch
        części – korony i korzenia. <a href="/uslugi-medyczne/stomatologia/korony" target="_blank">Korona</a> pełni funkcję estetyczną, ale jednocześnie stanowi
        bazę do przyszłej odbudowy zęba. Część korzenna natomiast pełni funkcję
        retencyjną, chroniącą osłabione korzenie przed złamaniem.</p>
        <h2>W jakim celu zakłada się wkład koronowo-korzeniowy?</h2>
        <p>Leczenie endodontyczne w wielu przypadkach może skończyć się złamaniem zęba,
        czego następstwem jest odbudowa korony zębowej bądź usunięcie zęba wraz z
        korzeniami i wstawienie <a href="/uslugi-medyczne/stomatologia/implantologia" target="_blank">implantu</a>. Wkład koronowo-korzeniowy ma na celu
        zmniejszenie ryzyka złamania zęba, a także przygotowanie go do dalszej odbudowy.</p>
        <p>Wkład koronowo-korzeniowy najczęściej umieszcza się w przypadku usunięcia
        tkanek twardych w zębach leczonych kanałowo. W dobrym gabinecie
        stomatologicznym, taki wkład jest wykorzystywany jeśli korona zęba w dużym
        stopniu ulegnie uszkodzeniu i konieczne jest jej dokładne odbudowanie.</p>
        <p>Zęby, które są leczone kanałowo są bardziej kruche i osłabione, co wynika z ich
        martwej struktury.</p>
        <h2>Ile wytrzymuje wkład koronowo-korzeniowy ?</h2>
        <p>Wkłady koronowo-korzeniowe wyróżniają się dużą wytrzymałością oraz trwałością,
        skutecznie chroniąc wyleczone kanałowo zęby przed złamaniem. To, ile konkretny
        wkład wytrzyma w jamie ustnej jest uzależnione przede wszystkim od rodzaju
        materiału wykorzystanego do jego wykonania oraz indywidualnych predyspozycji
        pacjenta czy prowadzonego przez niego trybu życia.</p>
        <h2>Rodzaje wkładów koronowo-korzeniowych</h2>
        <h3>Wkład koronowo-korzeniowy metalowy</h3>
        <p><a href="/uslugi-medyczne/stomatologia/wklad-koronowo-korzeniowy-lany-metalowy" target="_blank">Metalowe wkłady koronowo-korzeniowe</a> wykonuje się z materiałów takich jak pallad,
        złoto i srebro, tytan, nikiel i chrom. Są niezwykle wytrzymałe i rzadko ulegają
        uszkodzeniom mechanicznym. Metalowe wkłady wykorzystuje się do zębów
        bocznych, które są narażone na duży nacisk. Ten rodzaj wkładu zakłada się także u
        pacjentów z bruksizmem.</p>
        <h3>Wkład koronowo-korzeniowy z włókna szklanego</h3>
        <p><a href="/uslugi-medyczne/stomatologia/wklad-koronowo-korzeniowy-z-wlokna-szklanego" target="_blank">Wkłady koronowo-korzeniowe z włókna szklanego</a> 
        są mniej odporne na złamania mechaniczne niż te wykonane z metalu. Wyróżniają się jednak dużą estetyką, dzięki
        czemu można je z powodzeniem zakładać na zębach przednich.</p>
        <h3>Wkład koronowo-korzeniowy ceramiczny</h3>
        <p><a href="/uslugi-medyczne/stomatologia/wklad-koronowo-korzeniowy-ceramiczny" target="_blank">Wkłady ceramiczne</a> są wykonywane z masy ceramicznej. Oprócz dużej estetyki,
        wyróżniają się najniższą ceną w porównaniu do innych rodzajów wkładów koronowo-korzeniowych.</p>
        <h2>Ceny za założenie wkładu koronowo-korzeniowego</h2>
        <p>Koszt założenia wkładu koronowo-korzeniowego zależy indywidualnie od każdego
        przypadku. Każde leczenie kanałowe przebiega różnie i nie można przewidzieć
        jakiego typu wkład będzie niezbędny do tego, aby podtrzymać wytrzymałość
        martwego zęba.</p>
        <p>Na ostateczną cenę wkładu koronowo-korzeniowego ma zatem wpływ jego rodzaj,
        jak i również inne czynniki, które będą determinowały jego wytrzymałość. Warto
        zatem porównać ceny w gabinetach stomatologicznych, oferujących tego typu
        usługę, co pozwoli na oszacowanie potencjalnych kosztów.</p>
        <h2>Jak przebiega założenie wkładu koronowo-korzeniowego?</h2>
        <p>Zabieg założenia wkładu koronowo-korzeniowego wymaga kilku wizyt w gabinecie
        stomatologicznym oraz wykonania <a href="/uslugi-medyczne/stomatologia/rtg" target= "_blank">zdjęcia RTG</a>. Badanie obrazowe umożliwia
        zweryfikowanie, czy korzeń zęba nadaje się do założenia wkładu. Następnie stomatolog opracowuje korzeń i dobiera odpowiedni rozmiar wkładu
        koronowo-korzeniowego i przeprowadza leczenie endodontyczne w znieczuleniu
        miejscowym. Pobierany jest także wycisk, niezbędny do opracowania właściwego
        wkładu i korony.</p>`
},
{slug:"wzmocnienie-protez", name:"Wzmocnienie protez"},
{slug:"wymiana-zatrzasku", name:"Wymiana zatrzasku"},
{slug:"wax-up", name:"WAX UP"},
{slug:"podscielenie-protezy", name:"Podścielenie protezy"},
{
    slug:"licowki",
    name:"Licówki",
    seoText: `
        <h2>Licówki na zęby</h2>
        <p>Licówki to specjalne nakładki, które są naklejane przez stomatologa do przedniej
        części zęba. Dzięki licówkom można zmienić kolor zęba i jego kształt, bez
        konieczności poddawania się zabiegom stomatologicznym lub leczeniu
        ortodontycznemu.</p>
        <p>Na tego typu rozwiązanie często decydują się pacjenci stomatologiczni, którzy
        pragną jedynie poprawić wygląd swojego uśmiechu. Profesjonalnie wykonane i
        założone licówki wyglądem przypominają naturalne uzębienie.</p>
        <h2>Wskazania do założenia licówek</h2>
        <p>Licówki na zębach to funkcjonalne i względnie tanie rozwiązanie, jeśli zależy nam na
        szybkiej poprawie estetyki uśmiechu. Wskazaniem do założenia licówek są:</p>
        <ul>
            <li>przebarwiona płytka zębowa, która nie zmienia swojego koloru pomimo wielu
            prób <a href="/uslugi-medyczne/stomatologia/wybielanie-zebow" target="_blank">wybielania</a>,</li>
            <li>ukruszenie lub złamanie <a href="/uslugi-medyczne/stomatologia/korony" target="_blank">korony zęba</a>,</li>
            <li>zakrycia powierzchni zęba, którego przebarwienie powstało w wyniku <a href="/uslugi-medyczne/stomatologia/leczenie-kanalowe-endodontyczne" target="_blank">leczenia kanałowego</a>,</li>
            <li>diastema i chęć jej ukrycia,</li>
            <li>krzywy ząb, który nie wymaga <a href="/uslugi-medyczne/stomatologia/ortodoncja" target="_blank">leczenia ortodontycznego</a>.</li>
        </ul>
        <h2>Założenie licówek - przeciwwskazania</h2>
        <p>Przed założeniem licówek na zęby konieczne jest wyleczenie zębów z próchnicy i
        zadbać o prawidłową higienę jamy ustnej. Przeciwwskazaniem do ich noszenia jest
        bruksizm.</p>
        <h2>Rodzaje licówek</h2>
        <p>Pacjenci stomatologiczni mogą wybierać spośród kilku rodzajów licówek, które są
        zakładane w gabinetach. Różnią się one między sobą zarówno materiałem, z
        jakiego są wykonywane, trwałością i ceną.</p>
        <h3>Licówki akrylowe</h3>
        <p>Nakładki stomatologiczne na zęby są wykonywane z masy akrylowej. Ze względu na
        niska trwałość, są one wykorzystywane jako opcja tymczasowa – do czasu
        wykonania dedykowanych licówek przez protetyka.</p>
        <h3>Licówki kompozytowe</h3>
        <p><a href="/uslugi-medyczne/stomatologia/licowka-kompozytowa" target="_blank">Licówki kompozytowe</a>
        wyróżniają się średnią trwałością i w praktyce należy
        wymienić je po upływie kilku lat. Są natomiast elastyczne, spójne i łatwo
        dopasowują się je do zębów. Częściej niż inne nakładki stomatologiczne ulegają
        uszkodzeniom mechanicznym.</p>
        <h3>Licówki porcelanowe</h3>
        <p>Wykonane z masy <a href="/uslugi-medyczne/stomatologia/licowka-porcelanowa" target="_blank">porcelanowej licówki</a> są najtrwalszym rozwiązaniem w 
        <a href="/uslugi-medyczne/stomatologia/protetyka-i-implantoprotetyka" target="_blank">protetyce</a>.
        Dodatkowo wyróżniają się dużą twardością i odpornością na uszkodzenia czy
        przebarwienia. Ze względu na swoją budowę są również niewidoczne.</p>
        <h3>Licówki ceramiczne</h3>
        <p>Licówki ceramiczne to najchętniej wybierane rozwiązanie protetyczne przez
        pacjentów. Nie tylko wyglądem przypominają naturalne zęby, ale również nie są
        podatne na przebarwienia oraz <a href="/uslugi-medyczne/stomatologia/scaling-usuwanie-kamienia" target="_blank">osadzanie się kamienia nazębnego</a>.</p>
        <h2>Ceny za zakładanie licówek na zęby</h2>
        <p>Cena poszczególnych licówek nazębnych różni się w zależności od typu materiału
        użytego do ich wykonania. Na ostateczną cenę licówek na zęby wpływa również
        rodzaj uszkodzenia płytki oraz lokalizacja gabinetu stomatologicznego.</p>
        <p>Warto zatem porównać koszt licówek w różnych gabinetach stomatologicznych i
        zdecydować się na najkorzystniejszą opcję dla siebie.</p>
        <p>Na koszt założenia licówek zębowych z pewnością będzie miał wpływ całkowity stan
        zdrowia uzębienia (konieczność <a href="/uslugi-medyczne/stomatologia/wypelnienie-ubytkow-zeba-plomba" target="_blank">wyleczenia ubytków zębowych</a>) oraz dodatkowe
        badania, mające na celu wykluczyć przeciwwskazania.</p>
        <h2>Jak wygląda zakładanie licówek na zęby?</h2>
        <p>Założenie licówek nie jest natomiast skomplikowanym zabiegiem. Stomatolog
        pobiera wycisk, na podstawie którego dopasowuje wielkość nakładki. Przed
        założeniem licówki konieczne jest oszlifowanie zęba. Całość zabiegu wykonywana
        jest w znieczuleniu miejscowym.</p>`
},
{slug:"znieczulenie-miejscowe-nasiekowe", name:"Znieczulenie miejscowe nasiękowe"},
{slug:"naprawa-aparatu-ortodontycznego", name:"Naprawa aparatu ortodontycznego"},
{slug:"demontaz-aparatu-ortodontycznego", name:"Demontaż aparatu ortodontycznego"},
{slug:"leczenie-kanalowe-endodontyczne-rtg", name:"Leczenie kanałowe (endodontyczne) + RTG"},
{slug:"wyluszczenie-torbieli", name:"Wyłuszczenie torbieli"},
{slug:"wyciecie-zmiany-blony-sluzowej", name:"Wycięcie zmiany błony śluzowej"},
{slug:"sinus-lift-podniesienie-zatoki-szczekowej", name:"Sinus lift - podniesienie zatoki szczękowej"},
{slug:"rozszczepienie-wyrostka-zebodolowego", name:"Rozszczepienie wyrostka zębodołowego"},
{slug:"resekcja-wierzcholka-korzenia", name:"Resekcja wierzchołka korzenia"},
{slug:"radektomia", name:"Radektomia"},
{slug:"przeszczep-dziaslowy", name:"Przeszczep dziąsłowy"},
{slug:"pokrycie-recesji-dziasla", name:"Pokrycie recesji dziąsła"},
{slug:"poglebienie-przedsionka-jamy-ustnej", name:"Pogłębienie przedsionka jamy ustnej"},
{slug:"podciecie-wedzidelka", name:"Podcięcie wędzidełka"},
{slug:"plastyka-podciecie-wedzidelka", name:"Plastyka (podcięcie) wędzidełka"},
{slug:"naciecie-ropnia", name:"Nacięcie ropnia"},
{slug:"dlutowanie", name:"Dłutowanie"},
{slug:"leczenie-kanalowe-endodontyczne-rtg-koferdam", name:"Leczenie kanałowe (endodontyczne) + RTG + koferdam"},
{slug:"leczenie-kanalowe-endodontyczne-rtg-znieczulenie", name:"Leczenie kanałowe (endodontyczne) + RTG + znieczulenie"},
{slug:"leczenie-kanalowe-endodontyczne-znieczulenie", name:"Leczenie kanałowe (endodontyczne) + znieczulenie"},
{slug:"leczenie-kanalowe-endodontyczne-powtorne-re-endo", name:"Leczenie kanałowe (endodontyczne) powtórne (re-endo)"},
{slug:"aparat-staly-grubolukowy-podniebienny", name:"Aparat stały grubołukowy podniebienny"},
{slug:"aparat-staly-cienkolukowy", name:"Aparat stały cienkołukowy"},
{slug:"aparat-ruchomy-retencyjny", name:"Aparat ruchomy retencyjny"},
{slug:"wizyta-kontrolna", name:"Wizyta kontrolna"},
{slug:"pierwsza-wizyta", name:"Pierwsza wizyta"},
{slug:"konsultacja-plan-leczenia", name:"Konsultacja + plan leczenia"},
{slug:"mikroimplant-ortodontyczny", name:"Mikroimplant ortodontyczny"},
{slug:"badanie-stomatologiczne", name:"Badanie stomatologiczne"},
{slug:"szablon-chrurgiczny", name:"Szablon chrurgiczny"},
{slug:"odsloniecie-implantu", name:"Odsłonięcie implantu"},
{slug:"zamkniecie-perforacji", name:"Zamknięcie perforacji"},
{slug:"leczenie-biologiczne", name:"Leczenie biologiczne"},
{slug:"leczenie-kanalowe-endodontyczne-pod-mikroskopem-re-endo-powtorne", name:"Leczenie kanałowe (endodontyczne) pod mikroskopem re-endo (powtórne)"},
{slug:"leczenie-kanalowe-endodontyczne-rtg-znieczulenie-mikroskop-koferdam", name:"Leczenie kanałowe (endodontyczne) + RTG + znieczulenie + mikroskop + koferdam"},
{slug:"leczenie-kanalowe-endodontyczne-zeba-mlecznego", name:"Leczenie kanałowe (endodontyczne) zęba mlecznego"},
{slug:"wszczepienie-implantu", name:"Wszczepienie implantu"},
{slug:"szynowanie-zebow", name:"Szynowanie zębów"},
{slug:"kiretaz-otwarty", name:"Kiretaż otwarty"},
{slug:"wybielanie-zebow-lampa", name:"Wybielanie zebów lampą"},
{slug:"wybielanie-zebow-nakladkowe", name:"Wybielanie zębów nakładkowe"},
{slug:"wybielanie-zeba-martwego", name:"Wybielanie zęba martwego"},
{slug:"licowka-pelnoceramiczna", name:"Licówka pełnoceramiczna"},
{slug:"licowka-porcelanowa", name:"Licówka porcelanowa"},
{slug:"licowka-kompozytowa", name:"Licówka kompozytowa"},
{slug:"wypelnienia-ubytkow-zeba-mlecznego", name:"Wypełnienia ubytków zęba mlecznego"},
{slug:"punkt-w-moscie", name:"Punkt w moście"},
{slug:"proteza-overdenture", name:"Proteza overdenture"},
{slug:"proteza-acetalowa", name:"Proteza acetalowa"},
{slug:"wizyta-kontrola-aparatu", name:"Wizyta - kontrola aparatu"},
{slug:"konsultacja-ortodontyczna-plan-leczenia", name:"Konsultacja ortodontyczna + plan leczenia"},
{slug:"kiretaz-zamkniety", name:"Kiretaż zamknięty"},
{
    slug:"wypelnienie-ubytkow-zeba-plomba",
    name:"Wypełnienie ubytków zęba (plomba)",
    seoText: `<p>W zębach bardzo często pojawiają się ubytki, które najczęściej są konsekwencją nieleczonej
    próchnicy. Są to nie tylko nieestetyczne dziury, ale także poważny problem dla pacjentów. Mogą one
    bowiem być bardzo bolesne i prowadzić do innych dolegliwości. W takiej sytuacji należy zgłosić się
    do zaufanego stomatologa, który wykona wypełnienia.</p>
    <h2>Wypełnianie ubytku w zębie — na czym to polega?</h2>
    <p>Wypełnienia stosuje się w przypadku zębów, które zostały uszkodzone na skutek próchnicy lub
    urazów mechanicznych. Takie działanie pozwala przywrócić im nie tylko odpowiedni kształt, ale także
    ich funkcje. Do uzupełniania ubytków stosowana jest plomba dentystyczna. Wcześniej jednak
    stomatolog usuwa z wnętrza zęba zmieniony chorobowo materiał. Oczyszcza je, następnie
    zabezpiecza specjalnym wypełnieniem.</p>
    <h2>Najczęściej stosowane rodzaje wypełnień zębów</h2>
    <p>Do wypełniania ubytków w zębach stomatolodzy stosują różne materiały tj.:</p>
    <p><a href="/uslugi-medyczne/stomatologia/wklad-koronowo-korzeniowy-zloty" target="_blank">Złote wypełnienie</a></p>
    <p>Takiego rodzaju wypełnienie jest przygotowywane w laboratorium, a następnie umieszczane w
    chorym zębie. Złoto jest dobrze przyswajane przez tkanki dziąseł, a dodatkowo to wyjątkowo trwały
    materiał.</p>
    <p><b>Amalgamatowe</b></p>
    <p>Jeszcze do niedawna najpopularniejsze były wypełnienia amalgamatowe. Są to stopy metali
    zawierające rtęć. Jej zawartość jest śladowa, dlatego w żaden sposób nie wpływa negatywnie na
    zdrowie. Takie wypełnienia nie są jednak stosowane u kobiet w ciąży.</p>
    <p>Obecnie większość dentystów stosuje 
    <a href="/uslugi-medyczne/stomatologia/wypelnienie-ubytkow-zeba-kompozytowe-mikroskop-lub-lupa" target="_blank">wypełnienia kompozytowe</a>, 
    krzemowe, krzemowo-fosforowe oraz kompomery. Są one trwałe, a dodatkowo świetnie wyglądają umieszczone w ubytku.
    </p>
    <h2>Wskazania do wypełnienia ubytków w zębie</h2>
    <p>Głównym wskazaniem do przeprowadzenia wypełnienia zębów jest ich uszkodzenie mechaniczne
    lub pojawienie się zmian wywołanych próchnicą. Takie leczenie daje szansę na uratowanie zęba oraz
    uchronienie przed jego usunięciem.</p>
    <h2>Jak przebiega proces wypełniania ubytku zęba?</h2>
    <p>Stomatolog podczas wizyty przede wszystkim ocenia ubytek oraz wybiera metodę leczenia. Jeśli jest
    to konieczne, to może podać pacjentowi znieczulenie, które złagodzi dolegliwości bólowe. Następnie
    przechodzi do leczenia zęba. Rozwierca go, a następnie dokładnie oczyszcza jego wnętrze. Podczas
    borowania usuwane są wszelkie zanieczyszczenia, które się w nim znajdują. Następnie zakładane są
    plomby zębowe, które trzeba utwardzić oraz wyszlifować.</p>
    <h2>Od czego zależą ceny za wypełnienie ubytku w zębie (plomby)?</h2>
    <p>Koszt wypełnienia zęba zależy przede wszystkim od rodzaju zastosowanego materiału. Bez wątpienia
    najdroższe są plomby wykonywane ze złota. Takie leczenie jest również długotrwałe i wymaga co
    najmniej kilku wizyt. Ich częstotliwość wpływa więc na koszty całego leczenia.
    </p>
    <p>Cena wypełnienia zęba zależy także od lokalizacji gabinetu stomatologicznego oraz jego prestiżu.
    Wybierając placówkę, w której zostanie przeprowadzone leczenie, warto kierować się opiniami
    innych pacjentów. Musi być ono wykonane prawidłowo z wykorzystaniem najwyższej jakości
    materiałów, gdyż tylko wtedy efekty będą zadowalające. Wypełnienie zęba powinno wytrzymać
    bardzo długi czas oraz w żaden sposób nie wpływać negatywnie na komfort pacjenta.</p>`
},
{slug:"most-kompozytowy", name:"Most kompozytowy"},
{slug:"most-porcelanowy", name:"Most porcelanowy"},
{slug:"polerowanie", name:"Polerowanie"},
{slug:"usuniecie-zlamanego-narzedzia", name:"Usunięcie złamanego narzędzia"},
{slug:"pozostale-aparaty-ortodontyczne", name:"Pozostałe aparaty ortodontyczne"},
{slug:"korona-ceramiczna", name:"Korona ceramiczna"},
{slug:"wzmocnienie-zeba-materialem-technicznym", name:"Wzmocnienie zęba materiałem technicznym"},
{slug:"znieczulenie-powierzchniowe", name:"Znieczulenie powierzchniowe"},
{slug:"zatrzask-zamek-zasuwa", name:"Zatrzask, zamek, zasuwa"},
{slug:"kiretaz", name:"Kiretaż"},
];

export default medicalServiceSeoMap;
