import ManagerHome from './ManagerHome.vue';
import ViewInstitutions from './ViewInstitutions.vue';
import ViewUserData from '../shared/ViewUserData.vue';
import { NavigateOption } from '@/types';

const loggedUserOptions: NavigateOption[] = [
    {
        label: 'Start',
        component: ManagerHome,
        default: true,
        name: 'account',
        path: 'home'
    },
    {
        label: 'Twoje dane',
        component: ViewUserData,
        default: false,
        name: 'user-data',
        path: '/account/user-data',
    },
    {
        label: 'Placówki',
        component: ViewInstitutions,
        default: false,
        name: 'medical-services',
        path: '/account/medical-services',        
    }]

export default loggedUserOptions;